import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Card, Col, Container, Row} from "react-bootstrap";
import {
    BookOpen,
    Briefcase,
    File,
    Info,
    Mail,
    MessageCircle,
    Phone,
} from "react-feather";
import DebtorsDetailActiveProjectTable from "../../components/debiteuren/DebtorsDetailActiveProjectsTable";
import DebtorsDetailOverDateInvoiceTable from "../../components/debiteuren/DebtorsDetailOverDateInvoiceTable";
import DebtorsDetailOpenInvoiceTable from "../../components/debiteuren/DebtorsDetailOpenInvoiceTable";
import debtorsApi from "../../../api/debtors";
import {useParams} from "react-router-dom";
import EuroIcon from '@mui/icons-material/Euro';
import Loader from "../../../components/Loader";
import {euroFormat} from "../../../utils/usefullFunctions";
import DebtorsEditDetailsModal from "../../components/debiteuren/DebtorsEditDetailsModal";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import notyf from "../../../utils/notyfApi";

const DebtorsDetail = () => {
    const {id} = useParams()
    const [debtor, setDebtor] = useState()

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const res = await debtorsApi.getDebtor(id)
        if (!res.ok) return notyf.error("Error " + res.status)
        setDebtor(res.data)
    }
    if (!debtor) return <Loader/>
    return (
        <React.Fragment>
            <Helmet title={"Debiteur - " + debtor.project_naw.projects_naw_company_name}/>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Debiteur - {debtor.project_naw.projects_naw_company_name}</h1>
                {/* 1 rij die zorgt dat beide kolommen naast elkaar zijn */}
                <Row>
                    {/* linker col */}
                    <Col xl="4">
                        {/* blok 1 in linker col */}
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Debiteurdetails
                                    <GuardWrapper role={"write:debtors"}><DebtorsEditDetailsModal reload={getData} debtor={debtor}/></GuardWrapper>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <File/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Naam
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {debtor.project_naw.projects_naw_company_name}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Briefcase/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Adres
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <a
                                            href={"https://maps.google.com/?q=" + debtor.project_naw.projects_naw_street + debtor.project_naw.projects_naw_house_number + debtor.project_naw.projects_naw_city}
                                            target="_BLANK" rel="noreferrer">
                                            {`${debtor.project_naw.projects_naw_street} ${debtor.project_naw.projects_naw_house_number}, ${debtor.project_naw.projects_naw_city}`}
                                        </a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Phone/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Tel
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {debtor.project_naw.projects_naw_phonenumber}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Mail/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Mail
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <a href={`mailto:${debtor.project_naw.projects_naw_email}`}>{debtor.project_naw.projects_naw_email}</a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Info/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Termijn
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {debtor.project_naw.projects_naw_term_of_payment}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <EuroIcon/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Credit
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {euroFormat(debtor.project_naw.projects_naw_credit)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <BookOpen/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Bedrag
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {debtor.project_naw.projects_naw_vat_number}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <MessageCircle/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Opmerkingen
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {debtor.project_naw.projects_naw_comment || "Geen opmerking"}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Actieve projecten
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <DebtorsDetailActiveProjectTable data={debtor.projects}/>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* Col rechts in row 1 */}
                    <Col xl="8">
                        <Row>
                            <Col md="6" xl className="d-flex">
                                <Card className="flex-fill">
                                    <Card.Body>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div><h3 className="mb-2">Totaal over tijd</h3></div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <h3 className="mb-2 font-weight-bold"
                                                style={{color: "#ff5722"}}>{euroFormat(debtor.overdue_amount)}</h3>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="6" xl className="d-flex">
                                <Card className="flex-fill">
                                    <Card.Body>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div><h3 className="mb-2">Totaal openstaand</h3></div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <h3 className="mb-2 font-weight-bold">{euroFormat(debtor.open_amount)}</h3>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Facturen over de datum
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <DebtorsDetailOverDateInvoiceTable data={debtor.overdue_invoices}/>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Facturen openstaand
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <DebtorsDetailOpenInvoiceTable data={debtor.open_invoices}/>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
};

export default DebtorsDetail;
