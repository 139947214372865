import React from "react";
import {useFormikContext} from "formik";
import ErrorMessage from "./ErrorMessage";
import {Form} from "react-bootstrap";
import Select from "react-select";

/**
 * Renders a select form field with additional functionality.
 *
 * @param {Object} props - The input props.
 * @param {string} props.name - The name of the form field.
 * @param {Array} props.options - The array of options for the select field.
 * @param {string} props.width - The width of the select field.
 * @param {string} props.label - The label for the select field.
 * @param {Object} props.display - The display object specifying when to render the select field.
 * @param {boolean} [props.display.name] - The name of the field to compare against.
 * @param {any} [props.display.value] - The value to compare against.
 * @param {Function} props.feedback - The feedback function to call when the select value changes.
 * @param {Object} otherProps - Any other props to pass to the Select component.
 *
 * @returns {JSX.Element} - The rendered select form field.
 */
function AppFormFieldSelect({name, options, width, label, display, feedback = false, ...otherProps}) {
    const {setFieldTouched, setFieldValue, errors, touched, values} = useFormikContext();
    if (display) {
        if (values[display.name] !== display.value) return <></>
    }

    const handleChange = (e) => {
        setFieldValue(name, e.value)
        if (feedback) feedback(e.value)
    }
    return (
        <>
            {label && <Form.Label>{label}</Form.Label>}
            <Select options={options}
                    onBlur={() => setFieldTouched(name)}
                    onChange={e => handleChange(e)}
                    width={width}
                    noOptionsMessage={() => 'Niks gevonden'}
                    {...otherProps}
            />
            <ErrorMessage error={errors[name]} visible={touched[name]}/>
        </>
    );
}

export default AppFormFieldSelect;
