import {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {Form as FormikForm, FormField, SubmitButton} from "../../../components/forms/index"
import notyf from "../../../utils/notyfApi";
import {Edit2} from "react-feather";
import projectsApi from "../../../api/projects";

/**
 * Opens a modal for editing project reassessment
 * @param {object} options - The options for the reassessment modal
 * @param {number} options.id - The id of the project
 * @param {function} options.reload - The function to reload the project after reassessment
 * @return {JSX.Element} The reassessment modal component
 */
export default function ReassmentModal({id, reload}) {


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(false);
        const res = await projectsApi.editProjectReassessment(id, {
            re_estimate_mat: parseFloat(values.re_estimate_mat) || null,
            re_estimate_arb: parseFloat(values.re_estimate_arb) || null,
            re_estimate_oa: parseFloat(values.re_estimate_oa) || null,
            re_estimate_ove: parseFloat(values.re_estimate_ove) || null,
        })
        if (!res.ok) return notyf.error("Error " + res.status)
        reload()
        handleClose()
    };

    return (
        <>
            <Button variant="primary" className={"float-end"} onClick={handleShow}>
                <Edit2/>
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Re-estimate
                    </Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={{
                        re_estimate_mat: null,
                        re_estimate_arb: null,
                        re_estimate_oa: null,
                        re_estimate_ove: null,
                    }}
                    onSubmit={handleSubmit}
                >
                    <Modal.Body>

                        <FormField name={"re_estimate_mat"}
                                   label={"Materiaal (€)"}
                                   className={"mb-3"}
                                   control={"input"}
                                   type={"number"}
                        />
                        <FormField name={"re_estimate_arb"}
                                   label={"Arbeid (€)"}
                                   className={"mb-3"}
                                   control={"input"}
                                   type={"number"}
                        />
                        <FormField name={"re_estimate_oa"}
                                   label={"Onderaanneming (€)"}
                                   className={"mb-3"}
                                   control={"input"}
                                   type={"number"}
                        />
                        <FormField name={"re_estimate_ove"}
                                   label={"Overig (€)"}
                                   className={"mb-3"}
                                   control={"input"}
                                   type={"number"}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <SubmitButton title={"Opslaan"}/>
                    </Modal.Footer>
                </FormikForm>
            </Modal>
        </>
    );
}

