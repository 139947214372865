import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import React, {useEffect, useState} from "react";
import {Card, Row} from "react-bootstrap";
import offersApi from "../../../api/offertes";
import OfferteAccountModal from "./OfferteAccountModal";
import Loader from "../../../components/Loader";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import {NavLink} from "react-router-dom";
import notyf from "../../../utils/notyfApi";

/**
 * Renders a list of accounts for a given id.
 *
 * @param {object} props - The props object.
 * @param {number} props.id - The id of the account.
 * @return {JSX.Element} The rendered component.
 */
function OfferAccounts({id}) {
    const {SearchBar} = Search;
    const columns = [
        {
            dataField: "sales_account_id",
            text: "id",
            hidden: true,
        },
        {
            dataField: "sales_contact_firstname",
            text: "Naam",
            formatter: (_c, r) => {
                return <NavLink
                    to={`/contact/${r.sales_contact_id}`}> {`${r.sales_contact_firstname} ${r.sales_contact_surname_prefix || ""} ${r.sales_contact_lastname} `} </NavLink>
            },
            sort: true,
        },
        {
            dataField: "sales_contact_email",
            text: "Email",
            sort: true,
            formatter: (cell) => <a href={"mailto:" + cell}>{cell}</a>,
        },
        {
            dataField: "sales_account_name",
            text: "Bedrijf",
            sort: true,
            formatter: (cell, row) => <NavLink
                to={`/account/${row.sales_account_id}`}>{cell}</NavLink>,
        }
    ];

    const [list, setList] = useState(null);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        const res = await offersApi.getAccounts(id);
        if (!res.ok) return notyf.error("Error " + res.status)
        setList(res.data);
    };

    if (!list) return <Loader/>;
    return (
        <Card>
            <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                    Aanvragers
                    <GuardWrapper role={"write:offers"}>
                        <OfferteAccountModal id={id} reload={getData}/>
                    </GuardWrapper>
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <ToolkitProvider keyField="sales_account_id" data={list} columns={columns} search>
                    {(props) => (
                        <div>
                            <SearchBar srText={null}
                                       {...props.searchProps}
                                       placeholder={"Filter: Typ om te zoeken"}/>
                            <hr/>
                            {list.length ? <BootstrapTable
                                {...props.baseProps}
                                pagination={paginationFactory()}
                            /> : <Row className="justify-content-center align-self-center w-100 text-center">
                                Er zijn geen aanvragers gevonden.
                            </Row>}
                        </div>
                    )}
                </ToolkitProvider>
            </Card.Body>
        </Card>
    );
}

export default OfferAccounts;
