import {useState} from "react";
import {Modal, Form, Button} from "react-bootstrap";
import { MessageCircle} from "react-feather";
import debtorsApi from "../../../api/debtors";
import notyf from "../../../utils/notyfApi";

/**
 * Opens a modal to edit debtor details.
 *
 * @param {Object} options - The options for the modal.
 * @param {Object} options.debtor - The debtor object to edit.
 * @param {Function} options.reload - The function to reload the debtor data after editing.
 *
 * @returns {JSX.Element} - The rendered modal component.
 */
function DebtorsEditDetailsModal({debtor,reload}) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [comment, setComment] = useState(debtor.projects_naw_comment || "")

    const handleSubmit = async () => {
        const res = await debtorsApi.putDebtor(debtor.project_naw.projects_naw_id, {projects_naw_comment: comment})
        if (!res.ok) return notyf.error("Error " + res.status)
        reload()
        handleClose()
    }

    return (
        <>
            <Button className={"float-end"} onClick={handleShow} ><MessageCircle size={20} /></Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> Opmerking toevoegen aan {debtor.project_naw.projects_naw_company_name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Opmerking</Form.Label>
                        <Form.Control value={comment} onChange={e=>setComment(e.target.value)} as={"textarea"}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSubmit}>Aanpassen</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DebtorsEditDetailsModal;
