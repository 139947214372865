import React, {useEffect, useState} from "react";
import {Row} from "react-bootstrap";
import supplierApi from "../../../../api/suppliers";
import SupplierDetailOrderTable from "./SupplierDetailOrderTable";
import {useParams} from "react-router-dom";
import notyf from "../../../../utils/notyfApi";

/**
 * SupplierDetailOrderTableHandler - Handles the supplier order table for a specific supplier.
 *
 * @returns {*} - Supplier order table component if there are supplier orders available, otherwise
 * returns a message indicating that there are no orders available.
 *
 * @function SupplierDetailOrderTableHandler
 */
export default function SupplierDetailOrderTableHandler() {
    //get data from api
    const [supplierOrder, setSupplierOrder] = useState([]);
    const {id} = useParams();

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        const response = await supplierApi.getSupplierOrders(id);
        if (!response.ok) return notyf.error("Error " + response.status)
        setSupplierOrder(response.data);
    };

    //laat tabel zien met gegevens via props
    if (supplierOrder.length > 0) return <SupplierDetailOrderTable supplierOrder={supplierOrder}/>;
    else
        return (
            <Row className="justify-content-center align-self-center w-100 text-center">
                Er zijn geen bestellingen beschikbaar.
            </Row>
        );
}
