import portalClient from "./clients/portalClient";
import calculationClient from "./clients/calculationClient";
import projectFormClient from "./clients/projectFormClient";

/**
 * Retrieves all sales offers from the portal client.
 *
 * @returns {Promise} A Promise object that resolves to the result of the GET request.
 */
const getAllOffers = () => portalClient.get(`/sales_offers`);
/**
 * Retrieves the sales offer with the specified id from the portal.
 *
 * @param {string} id - The id of the sales offer to retrieve.
 * @returns {Promise<Object>} A Promise that resolves with the retrieved sales offer as an object.
 */
const getOffer = (id) => portalClient.get(`/sales_offers/${id}`);
/**
 * Sends a POST request to create a new sales offer.
 *
 * @param {object} data - The data for the new sales offer.
 * @returns {Promise} A Promise that resolves with the response from the server.
 */
const postOffer = (data) => portalClient.post(`/sales_offers`, data);
/**
 * Adds a contact to an offer
 *
 * @param {string} id - The ID of the sales offer
 * @param {Object} data - The data of the contact to add
 * @returns {Promise} Promise object representing the result of the request
 */
const addContactToOffer = (id, data) => portalClient.post(`/sales_offers/${id}/accounts`, data);

/**
 * Retrieves the history of a sales offer.
 *
 * @param {string} id - The ID of the sales offer.
 * @returns {Promise} - A promise that resolves with the sales offer history.
 */
const getHistory = (id) => portalClient.get(`/sales_offers/${id}/history`);

/**
 * Function to retrieve messages for a sales offer from the portal client.
 *
 * @param {number} id - The ID of the sales offer.
 * @returns {Promise} - A promise that resolves with the messages for the sales offer.
 */
const getMessages = (id) => portalClient.get(`/sales_offers/${id}/messages`);
/**
 * Deletes a message from the sales offer.
 *
 * @param {string} id - The ID of the sales offer.
 * @param {string} sId - The ID of the message to delete.
 */
const deleteMessage = (id, sId) => portalClient.delete(`/sales_offers/${id}/messages/${sId}`);
/**
 * Sends a message related to a sales offer.
 *
 * @param {string} id - The unique identifier of the sales offer.
 * @param {object} data - The data of the message to be sent.
 * @returns {Promise} - A promise representing the result of the operation.
 */
const postMessage = (id, data) => portalClient.post(`/sales_offers/${id}/messages`, data);

/**
 * Retrieves the tasks for a sales offer ID from the portal client.
 *
 * @param {string} id - The sales offer ID.
 * @returns {Promise} - A promise that resolves with the tasks for the sales offer ID.
 */
const getTasks = (id) => portalClient.get(`/sales_offers/${id}/tasks`);
/**
 * Creates a new task for a sales offer.
 *
 * @param {string} id - The ID of the sales offer.
 * @param {Object} data - The data of the task to be created.
 * @returns {Promise} - A promise that resolves to the created task response.
 */
const postTask = (id, data) => portalClient.post(`/sales_offers/${id}/tasks`, data);
/**
 * Updates the task for a specific sales offer.
 *
 * @param {string} id - The sales offer ID.
 * @param {string} sId - The task ID.
 * @param {object} data - The updated task data.
 * @returns {Promise} A promise that resolves when the request is completed successfully.
 */
const editTask = (id, sId, data) => portalClient.put(`/sales_offers/${id}/tasks/${sId}`, data);
/**
 * Deletes a task from a sales offer.
 *
 * @param {string} id - The ID of the sales offer.
 * @param {string} sId - The ID of the task to be deleted.
 * @returns {Promise} - A promise that resolves when the task is successfully deleted.
 */
const deleteTask = (id, sId) => portalClient.delete(`/sales_offers/${id}/tasks/${sId}`);

/**
 * Sends a POST request to create a new sales offer.
 *
 * @param {Object} data - The data for the sales offer.
 * @returns {Promise} - A Promise that resolves with the response from the server.
 */
const postNewOffer = (data) => portalClient.post(`/sales_offers`, data);
/**
 * Updates an existing sales offer with new data.
 *
 * @param {string} id - The ID of the sales offer to be updated.
 * @param {object} data - The new data to update the sales offer.
 * @returns {Promise} - A promise that resolves to the updated sales offer.
 */
const editExistingOffer = (id, data) => portalClient.put(`/sales_offers/${id}`, data);
/**
 * Updates the status of an existing sales offer.
 *
 * @param {string} id - The ID of the sales offer to be updated.
 * @param {string} data - The new status of the sales offer.
 * @returns {Promise} - A promise that resolves when the status is updated.
 */
const editExistingOfferStatus = (id, data) => portalClient.put(`/sales_offers/${id}/status?status=${data}`);
/**
 * Updates the finance details of an offer.
 *
 * @param {string} id - The ID of the sales offer.
 * @param {object} data - The new finance data to be updated.
 * @returns {Promise} A Promise object that resolves to the updated offer.
 */
const editOfferFinance = (id, data) => portalClient.put(`/sales_offers/${id}/finance`, data);

/**
 * Deletes a sales offer by its ID.
 *
 * @param {number} id - The ID of the sales offer to delete.
 * @returns {Promise} - A Promise representing the result of the delete operation.
 */
const deleteOffer = (id) => portalClient.delete(`/sales_offers/${id}`);


/**
 * Retrieves the accounts associated with a sales offer.
 *
 * @param {string} id - The ID of the sales offer.
 * @returns {Promise} - A promise that resolves with the response from the API.
 *
 * @example
 * getAccounts('123456')
 *   .then(response => {
 *     // Handle the response
 *   })
 *   .catch(error => {
 *     // Handle the error
 *   });
 */
const getAccounts = (id) => portalClient.get(`/sales_offers/${id}/accounts`);
/**
 * Sends a HTTP POST request to create an account associated with a sales offer.
 *
 * @param {string} id - The ID of the sales offer.
 * @param {object} data - The data to be sent in the request payload.
 * @returns {Promise} A Promise that resolves with the response from the server.
 */
const postAccount = (id, data) => portalClient.post(`/sales_offers/${id}/accounts`, data);
/**
 * @function editAccount
 * @description Edits an account with the given ID using the provided data.
 * @param {string} id - The ID of the sales offer.
 * @param {Object} data - The data for updating the account.
 * @returns {Promise} A Promise that resolves to the updated account.
 */
const editAccount = (id, data) => portalClient.put(`/sales_offers/${id}/accounts`, data);
/**
 * Deletes an account associated with a sales offer.
 *
 * @param {string} id - The ID of the sales offer.
 * @returns {Promise} A Promise that represents the asynchronous operation of deleting the account.
 */
const deleteAccount = (id) => portalClient.delete(`/sales_offers/${id}/accounts`);

/**
 * Fetches the calculations data for a given ID.
 *
 * @param {number} id - The ID of the calculations data.
 * @returns {Promise} A promise with the calculations data.
 */
const getCalculations = (id) => calculationClient.get(`/get/calculations/${id}`);
/**
 * Creates a new calculation.
 *
 * @param {Object} data - The data for the new calculation.
 * @return {Promise} - A promise that represents the request to create a new calculation.
 */
const createNewCalculation = (data) => calculationClient.post(`/crud/calculation/new`, data);
/**
 * Deletes a calculation from the server.
 *
 * @param {number} data - The ID of the calculation to delete.
 * @returns {Promise} - A Promise that is resolved if the calculation is successfully deleted, or rejected with an error otherwise.
 */
const deleteCalculation = (data) => calculationClient.post(`/crud/calculation/delete`, {id: data});
/**
 * Sends a request to the calculation client to duplicate a calculation with the provided data.
 *
 * @param {Object} data - The data of the calculation to be duplicated.
 * @returns {Promise} A promise that resolves when the calculation duplication is successful, or rejects with an error if unsuccessful.
 */
const copyCalculation = (data) => calculationClient.post(`/crud/calculation/duplicate`, data);

/**
 * Fetches all forms associated with a project.
 *
 * @param {string} id - The identifier of the project.
 * @return {Promise} A promise that resolves to the fetched forms data.
 */
const getAllForms = (id) => projectFormClient.get(`/project/${id}/forms`);
/**
 * Retrieves the form with the specified ID from the server.
 *
 * @param {string} id - The ID of the form to retrieve.
 * @returns {Promise} - A Promise that resolves to the form data.
 */
const getForm = (id) => projectFormClient.get(`/forms/${id}`);
/**
 * Creates a new form by making a POST request to the server.
 *
 * @param {Object} data - The data object to be sent as the request payload.
 * @returns {Promise<Object>} - A Promise that resolves to the response object.
 */
const createNewForm = (data) => projectFormClient.post(`/forms`, data);
/**
 * Deletes a form by its ID.
 *
 * @param {string} id - The ID of the form to delete.
 * @returns {Promise} - A promise that resolves with the result of the deletion process.
 */
const deleteForm = (id) => projectFormClient.delete(`/forms/${id}`);

/**
 * Retrieves all services for a given ID
 *
 * @param {Number} id - The ID of the service
 * @returns {Promise} - A promise that resolves with the forms associated with the service
 */
const getAllService = (id) => projectFormClient.get(`/service/${id}/forms`);
/**
 * Retrieves service data from the server based on the given ID.
 *
 * @param {number} id - The ID of the service to be retrieved.
 * @returns {Promise} - A Promise that will resolve with the requested service data.
 */
const getService = (id) => projectFormClient.get(`/service/${id}`);
/**
 * Creates a new service.
 *
 * @param {Object} data - The data required to create the service.
 * @returns {Promise} - A promise that resolves with the newly created service.
 */
const createNewService = (data) => projectFormClient.post(`/service`, data);
/**
 * Deletes a service with the given ID.
 *
 * @param {string} id - The ID of the service to delete.
 *
 * @returns {Promise} A promise that resolves when the service is successfully deleted.
 */
const deleteService = (id) => projectFormClient.delete(`/service/${id}`);


/**
 * This variable represents the API for managing offers, contacts, history, messages, tasks, accounts, calculations, forms, and services.
 *
 * @namespace
 * @property {Function} getAllOffers - Retrieves all offers.
 * @property {Function} getOffer - Retrieves an offer by ID.
 * @property {Function} postOffer - Creates a new offer.
 * @property {Function} addContactToOffer - Adds a contact to an offer.
 * @property {Function} getHistory - Retrieves the history of an offer.
 * @property {Function} getMessages - Retrieves all messages associated with an offer.
 * @property {Function} deleteMessage - Deletes a message associated with an offer.
 * @property {Function} postMessage - Creates a new message associated with an offer.
 * @property {Function} getTasks - Retrieves all tasks associated with an offer.
 * @property {Function} postTask - Creates a new task associated with an offer.
 * @property {Function} editTask - Updates a task associated with an offer.
 * @property {Function} deleteTask - Deletes a task associated with an offer.
 * @property {Function} postNewOffer - Creates a new offer.
 * @property {Function} editExistingOffer - Updates an existing offer.
 * @property {Function} editExistingOfferStatus - Updates the status of an existing offer.
 * @property {Function} editOfferFinance - Updates the finance details of an existing offer.
 * @property {Function} deleteOffer - Deletes an existing offer.
 * @property {Function} getAccounts - Retrieves all accounts.
 * @property {Function} postAccount - Creates a new account.
 * @property {Function} editAccount - Updates an existing account.
 * @property {Function} deleteAccount - Deletes an existing account.
 * @property {Function} getCalculations - Retrieves all calculations.
 * @property {Function} createNewCalculation - Creates a new calculation.
 * @property {Function} deleteCalculation - Deletes an existing calculation.
 * @property {Function} copyCalculation - Creates a copy of an existing calculation.
 * @property {Function} getAllForms - Retrieves all forms.
 * @property {Function} getForm - Retrieves a form by ID.
 * @property {Function} createNewForm - Creates a new form.
 * @property {Function} deleteForm - Deletes a form.
 * @property {Function} getAllService - Retrieves all services.
 * @property {Function} getService - Retrieves a service by ID.
 * @property {Function} createNewService - Creates a new service.
 * @property {Function} deleteService - Deletes a service.
 */
const offersApi = {
    getAllOffers,
    getOffer,
    postOffer,
    addContactToOffer,

    getHistory,

    getMessages,
    deleteMessage,
    postMessage,

    getTasks,
    postTask,
    editTask,
    deleteTask,


    postNewOffer,
    editExistingOffer,
    editExistingOfferStatus,
    editOfferFinance,
    deleteOffer,

    getAccounts,
    postAccount,
    editAccount,
    deleteAccount,

    getCalculations,
    createNewCalculation,
    deleteCalculation,
    copyCalculation,

    getAllForms,
    getForm,
    createNewForm,
    deleteForm,
    
    getAllService,
    getService,
    createNewService,
    deleteService,
};

export default offersApi;
