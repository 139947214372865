import React, {useEffect} from "react";
import {useFormikContext} from "formik";
import ErrorMessage from "./ErrorMessage";
import {Form} from "react-bootstrap";

/**
 * Function that renders a form field component with formik integration.
 *
 * @param {object} props - The properties of the form field.
 * @param {string} props.name - The name of the form field.
 * @param {string} props.display - The display condition for the form field to be visible.
 * @param {string} props.label - The label for the form field.
 * @param {string} props.defaultValue - The default value for the form field.
 * @param {object} props.otherProps - Additional properties to be passed to the form field component.
 *
 * @return {JSX.Element} - The rendered form field component.
 */
function AppFormField({name, display, label, defaultValue, ...otherProps}) {
    const {setFieldTouched, setFieldValue, errors, touched, values} = useFormikContext();

    useEffect(() => {
        if (defaultValue) setFieldValue(name, defaultValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (display) {
        if (values[display.name] !== display.value) return <></>
    }

    return (
        <>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Control

                onBlur={() => setFieldTouched(name)}
                onChange={text => setFieldValue(name, text.target.value)}
                value={values[name]}
                {...otherProps}
            />
            <ErrorMessage error={errors[name]} visible={touched[name]}/>
        </>
    );
}

export default AppFormField;
