import React, { useState } from "react";

import { SIDEBAR_POSITION, SIDEBAR_BEHAVIOR } from "../constants";
import useSettingsState from "../hooks/useSettingsState";

/**
 * Represents the initial state of a sidebar.
 *
 * @typedef {Object} SidebarInitialState
 * @property {boolean} isOpen - Indicates whether the sidebar is open or not.
 * @property {string} position - Specifies the position of the sidebar. Possible values are 'left', 'right'.
 * @property {string} behavior - Specifies the behavior of the sidebar. Possible values are 'sticky', 'static', 'floating'.
 */
const initialState = {
  isOpen: true,
  position: SIDEBAR_POSITION.LEFT,
  behavior: SIDEBAR_BEHAVIOR.STICKY,
};

/**
 * React context for sidebar state management.
 *
 * @typedef {Object} SidebarContext
 * @property {React.Context} SidebarContext.Provider - Provider component for providing the sidebar state to its children.
 * @property {React.Context} SidebarContext.Consumer - Consumer component for consuming the sidebar state.
 */
const SidebarContext = React.createContext(initialState);

/**
 * SidebarProvider is a functional component that acts as a provider for sidebar related settings.
 *
 * @param {object} props - The input props for the SidebarProvider component.
 * @param {ReactNode} props.children - The children components to be rendered within the SidebarProvider.
 *
 * @returns {ReactElement} - The JSX element representing the SidebarProvider.
 */
function SidebarProvider({ children }) {
  const [isOpen, setIsOpen] = useState(true);
  const [position, setPosition] = useSettingsState(
    "sidebarPosition",
    SIDEBAR_POSITION.LEFT
  );
  const [behavior, setBehavior] = useSettingsState(
    "sidebarBehavior",
    SIDEBAR_BEHAVIOR.STICKY
  );

  return (
    <SidebarContext.Provider
      value={{
        isOpen,
        setIsOpen,
        position,
        setPosition,
        behavior,
        setBehavior,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}

export { SidebarProvider, SidebarContext };
