import React, {useEffect, useState} from "react";
import Loader from "../../../components/Loader";
import notyf from "../../../utils/notyfApi";
import {Col, Row, Card, Button} from "react-bootstrap";
import adminUsersApi from "../../../api/adminUsers";
import dashboardApi from "../../../api/dashboard";
import adminBudgetApi from "../../../api/adminBudget";
import PieChartForDepartment from "../../charts/Chartjs/PieChartForDepartment";
import BarChartForDepartment from "../../charts/Chartjs/BarChartForDepartment";
import {useNavigate} from "react-router-dom";
import NewBarForDepartment from "../../charts/Chartjs/NewBarForDepartment";

/**
 * Renders the productivity overview for all departments.
 *
 * @returns {JSX.Element} The rendered component.
 */
export default function ProdVisAllDepsContentHandler() {
    const [loading, setLoading] = useState(true)
    const [allDepData, setallDepData] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true);
        getProductivityDepartmentInfo();
        setLoading(false);
    }, []);

    const getProductivityDepartmentInfo = async () => {
        const res = await dashboardApi.getProductivityDepartmentInfo()
        if (!res.ok) return notyf.error("Error " + res.status)
        setallDepData(res.data);
    };

    //laat zien met gegevens via props
    if (loading) return <Loader/>
    return <>
        <Row>
            <Col xs={6}>
                <Card>
                    <Card.Header>
                        <Card.Title tag="h5">Productiviteitsoverzicht voor Engineering </Card.Title>
                        <h6 className="card-subtitle text-muted">
                            Actuele data (licht) en budget data (donker)
                        </h6>
                    </Card.Header>
                    <Card.Body>
                        <Row className="mb-2">
                            <Col xs={4}>
                                <Row className="m-2 gx-0" style={{minHeight: "10em"}}>
                                    <PieChartForDepartment department={"engineering"}
                                                           allDepData={allDepData}
                                                           pieUse={"actual"}
                                    />
                                </Row>
                                <Row className="m-2 gx-0" style={{minHeight: "10em"}}>
                                    <PieChartForDepartment department={"engineering"}
                                                           pieUse={"budget"}
                                                           allDepData={allDepData}
                                    />
                                </Row>
                            </Col>
                            <Col xs={8}>
                                <NewBarForDepartment department={"engineering"}
                                                     hoursType={"PRODUCTIEF"}
                                                     allDepData={allDepData}
                                />
                            </Col>
                        </Row>
                        <Button onClick={() => navigate("/productivitydetail/engineering")}>Details voor
                            Engineering</Button>
                    </Card.Body>
                </Card>
            </Col>
                <Col xs={6}>
                    <Card>
                        <Card.Header>
                            <Card.Title tag="h5">Productiviteitsoverzicht voor ICT </Card.Title>
                            <h6 className="card-subtitle text-muted">
                                Actuele data (licht) en budget data (donker)
                            </h6>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-2">
                                <Col xs={4}>
                                    <Row className="m-2 gx-0" style={{minHeight: "10em"}}>
                                        <PieChartForDepartment department={"ict"}
                                                               allDepData={allDepData}
                                                               pieUse={"actual"}
                                        />
                                    </Row>
                                    <Row className="m-2 gx-0" style={{minHeight: "10em"}}>
                                        <PieChartForDepartment department={"ict"}
                                                               pieUse={"budget"}
                                                               allDepData={allDepData}
                                        />
                                    </Row>
                                </Col>
                                <Col xs={8}>
                                    <NewBarForDepartment department={"ict"}
                                                         hoursType={"PRODUCTIEF"}
                                                         allDepData={allDepData}
                                    />
                                </Col>
                            </Row>
                            <Button onClick={() => navigate("/productivitydetail/ict")}>Details voor ICT</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Card>
                        <Card.Header>
                            <Card.Title tag="h5">Productiviteitsoverzicht voor Service </Card.Title>
                            <h6 className="card-subtitle text-muted">
                                Actuele data (licht) en budget data (donker)
                            </h6>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-2">
                                <Col xs={4}>
                                    <Row className="m-2 gx-0" style={{minHeight: "10em"}}>
                                        <PieChartForDepartment department={"service"}
                                                               allDepData={allDepData}
                                                               pieUse={"actual"}
                                        />
                                    </Row>
                                    <Row className="m-2 gx-0" style={{minHeight: "10em"}}>
                                        <PieChartForDepartment department={"service"}
                                                               pieUse={"budget"}
                                                               allDepData={allDepData}
                                        />
                                    </Row>
                                </Col>
                                <Col xs={8}>
                                    <NewBarForDepartment department={"service"}
                                                         hoursType={"PRODUCTIEF"}
                                                         allDepData={allDepData}
                                    />
                                </Col>
                            </Row>
                            <Button onClick={() => navigate("/productivitydetail/service")}>Details voor
                                Service</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={6}>
                    <Card>
                        <Card.Header>
                            <Card.Title tag="h5">Productiviteitsoverzicht voor Uitvoering </Card.Title>
                            <h6 className="card-subtitle text-muted">
                                Actuele data (licht) en budget data (donker)
                            </h6>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-2">
                                <Col xs={4}>
                                    <Row className="m-2 gx-0" style={{minHeight: "10em"}}>
                                        <PieChartForDepartment department={"uitvoering"}
                                                               allDepData={allDepData}
                                                               pieUse={"actual"}
                                        />
                                    </Row>
                                    <Row className="m-2 gx-0" style={{minHeight: "10em"}}>
                                        <PieChartForDepartment department={"uitvoering"}
                                                               pieUse={"budget"}
                                                               allDepData={allDepData}
                                        />
                                    </Row>
                                </Col>
                                <Col xs={8}>
                                    <NewBarForDepartment department={"uitvoering"}
                                                         hoursType={"PRODUCTIEF"}
                                                         allDepData={allDepData}
                                    />
                                </Col>
                            </Row>
                            <Button onClick={() => navigate("/productivitydetail/uitvoering")}>Details voor
                                Uitvoering</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Card>
                        <Card.Header>
                            <Card.Title tag="h5">Productiviteitsoverzicht voor X-connect </Card.Title>
                            <h6 className="card-subtitle text-muted">
                                Actuele data (licht) en budget data (donker)
                            </h6>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-2">
                                <Col xs={4}>
                                    <Row className="m-2 gx-0" style={{minHeight: "10em"}}>
                                        <PieChartForDepartment department={"xconnect"}
                                                               allDepData={allDepData}
                                                               pieUse={"actual"}
                                        />
                                    </Row>
                                    <Row className="m-2 gx-0" style={{minHeight: "10em"}}>
                                        <PieChartForDepartment department={"xconnect"}
                                                               pieUse={"budget"}
                                                               allDepData={allDepData}
                                        />
                                    </Row>
                                </Col>
                                <Col xs={8}>
                                    <NewBarForDepartment department={"xconnect"}
                                                         hoursType={"PRODUCTIEF"}
                                                         allDepData={allDepData}
                                    />
                                </Col>
                            </Row>
                            <Button onClick={() => navigate("/productivitydetail/xconnect")}>Details voor
                                X-connect</Button>
                        </Card.Body>
                    </Card>
                </Col>
        </Row>
    </>
}