import React, {useCallback, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {Button, Card} from "react-bootstrap";
import {euroFormat} from "../../../../utils/usefullFunctions";
import filter from "@inovua/reactdatagrid-community/filter";
import ReactDataGridCustom from "../../../../components/customComponents/ReactDataGridCustom";
import GuardWrapper from "../../../../components/guards/GuardWrapper";
import {useExcelDownloder} from 'react-xls';
import TableViewIcon from "@mui/icons-material/TableView";
import moment from "moment";
import ProductItemModal from "../ProductItemModal";

/**
 * Default filter value that can be used for filtering products.
 *
 * @type {Object[]}
 * @property {string} name - The name of the filter field.
 * @property {string} operator - The operator to be used for filtering.
 * @property {string} type - The data type of the filter value.
 * @property {string|null} value - The default value for the filter field.
 */
const defaultFilterValue = [
    {name: 'product_name', operator: 'contains', type: 'string', value: ''},
    {name: 'product_description', operator: 'contains', type: 'string', value: ''},
    {name: 'product_list', operator: 'gte', type: 'number', value: null},
    {name: 'product_cost', operator: 'gte', type: 'number', value: null},
    {name: 'product_di', operator: 'gte', type: 'number', value: null},
    {name: 'product_ai', operator: 'gte', type: 'number', value: null},
    {name: 'product_do', operator: 'gte', type: 'number', value: null},
    {name: 'product_ao', operator: 'gte', type: 'number', value: null},
    {name: 'supplier_id', operator: 'contains', type: 'string', value: ''},
];

/**
 * Represents the columns used in a table.
 *
 * @typedef {Object} Column
 * @property {string} name - The name of the column.
 * @property {string} header - The header text displayed in the table.
 * @property {?function} render - The render function used to format the cell value. If not provided, the cell value will be displayed as is.
 * @property {number} defaultFlex - The default flex value for the column.
 * @property {?number} minWidth - The minimum width of the column in pixels.
 */
const columns = [
    {
        name: "product_name",
        header: "Productnaam",
        render: ({value, data}) => <NavLink to={`/product/${data.product_id}`}>{value}</NavLink>,
        defaultFlex: 1,
        minWidth: 110,
    },
    {
        name: "product_description",
        header: "Omschrijving",
        minWidth: 600,
        defaultFlex: 1,
    },
    {
        name: "product_list",
        header: "Bruto",
        render: ({value}) => euroFormat(value),
        defaultFlex: 1,
        minWidth: 150,
    },
    {
        name: "product_cost",
        header: "Netto",
        render: ({value}) => euroFormat(value),
        defaultFlex: 1,
        minWidth: 150,
    },
    {
        name: "product_di",
        header: "DI",
        defaultFlex: 1,
    },
    {
        name: "product_ai",
        header: "AI",
        defaultFlex: 1,
    },
    {
        name: "product_do",
        header: "DO",
        defaultFlex: 1,
    },
    {
        name: "product_ao",
        header: "AO",
        defaultFlex: 1,
    },
    {
        name: "supplier_id",
        header: "Leverancier",
        defaultFlex: 1,
        minWidth: 150,
    },
];

/**
 * Renders a table of products.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.products - The array of products.
 * @param {Function} props.reload - The reload function.
 *
 * @return {JSX.Element} - The JSX representation of the product table.
 */
export default function ProductTable({products, reload}) {
    const {ExcelDownloder, setData} = useExcelDownloder();
    const [dataSource, setDataSource] = useState([]);
    const [productsNew, setProductsNew] = useState([]);
    const [filterValue, setFilterValue] = useState(defaultFilterValue);
    const onFilterValueChange = useCallback((f) => {
        const data = filter(productsNew, f)
        setFilterValue(f);
        setDataSource(data);
        localStorage.setItem("productFilters", JSON.stringify(f))
    }, [dataSource])

    useEffect(() => {
        const data = []
        for (let p of products) {
            const t = {
                product_id: p.product_id,
                product_name: p.product_name,
                product_description: p.product_description,
                product_list: parseFloat(p["product_list"] || 0),
                product_cost: parseFloat(p["product_cost"] || 0),
                product_di: p.product_di,
                product_ai: p.product_ai,
                product_do: p.product_do,
                product_ao: p.product_ao,
                supplier_id: p.supplier_id,
            }
            data.push(t)
        }
        setProductsNew(data)
        const localFilters = localStorage.getItem("productFilters")
        if (localFilters) {
            setFilterValue(JSON.parse(localFilters))
            setDataSource(filter(data, JSON.parse(localFilters)))
        } else {
            setDataSource(data)
        }
    }, [])
    useEffect(() => {
        setData({producten: dataSource})
    }, [dataSource])

    return (<Card>
        <Card.Header className={"d-flex justify-content-between"}>
            <h3>Productenoverzicht</h3>
            <span>
            <Button variant={"info"} className={"me-1"} onClick={() => onFilterValueChange(defaultFilterValue)}>Reset
                filters</Button>
            <ExcelDownloder
                data={dataSource}
                filename={'producten' + moment().format("DD-MM-YYYY")}
                type={"link"}
            >
                <Button className={"me-1"} variant={"secondary"}><TableViewIcon fontSize={"small"}/></Button>
            </ExcelDownloder>
            <GuardWrapper role={"write:products"}><ProductItemModal reload={reload}/></GuardWrapper>
                </span>
        </Card.Header>
        <Card.Body>
            <ReactDataGridCustom
                idProperty="sales_offer_id"
                dataSource={dataSource}
                columns={columns}
                pagination
                defaultLimit={50}
                filterValue={filterValue}
                onFilterValueChange={onFilterValueChange}
            />
        </Card.Body>
    </Card>);

}