import client from "./clients/portalClient";

/**
 * Retrieves all service projects from the server.
 *
 * @returns {Promise<any>} A promise that resolves to the response containing
 *                        the service projects from the server.
 */
const getAllServiceProjects = () => client.get(`/service_projects`);
/**
 * Retrieves a service project from the server by its ID.
 *
 * @param {string} id - The ID of the service project to retrieve.
 * @returns {Promise} - A promise that resolves with the retrieved service project from the server.
 *
 * @throws {Error} - If the request to the server fails.
 */
const getServiceProject = (id) => client.get(`/service_projects/${id}`);
/**
 * Updates a service project with the specified ID.
 *
 * @param {string} id - The ID of the service project to update.
 * @param {object} data - The updated data for the service project.
 * @returns {Promise} A Promise that resolves to the updated service project.
 */
const editServiceProject = (id, data) => client.put(`/service_projects/${id}`, data);

/**
 * Retrieves the history of a service project.
 *
 * @param {string} id - The ID of the service project.
 * @returns {Promise} - A promise that resolves to the service project history.
 */
const getServiceProjectHistory = (id) => client.get(`/service_projects/${id}/history`);


/**
 * Retrieves the IP address of a service project based on the given ID.
 *
 * @param {string} id - The ID of the service project.
 * @returns {Promise<string>} A promise that resolves with the IP address of the service project.
 */
const getServiceProjectIp = (id) => client.get(`/service_projects/${id}/ip`);
/**
 * Sends a POST request to create an IP for a service project.
 *
 * @param {string} id - The ID of the service project.
 * @param {Object} data - The data containing the IP details.
 * @returns {Promise} - A Promise that resolves to the response of the POST request.
 */
const postServiceProjectIp = (id, data) => client.post(`/service_projects/${id}/ip`, data);
/**
 * Edit the IP address of a service project.
 *
 * @param {string} id - The ID of the service project.
 * @param {string} ipId - The ID of the IP address to be edited.
 * @param {Object} data - The updated IP address data.
 * @returns {Promise} - A Promise that resolves when the IP address has been successfully edited.
 */
const editServiceProjectIp = (id, ipId, data) => client.put(`/service_projects/${id}/ip/${ipId}`, data);
/**
 * Deletes an IP from a service project.
 *
 * @param {string} id - The ID of the service project.
 * @param {string} ipId - The ID of the IP to delete.
 * @returns {Promise} - A Promise representing the delete operation.
 */
const deleteServiceProjectIp = (id, ipId) => client.delete(`/service_projects/${id}/ip/${ipId}`);

/**
 * Retrieves the service project controller from the server.
 *
 * @param {string} id - The ID of the service project.
 * @returns {Promise} - A promise that resolves with the controller data retrieved from the server.
 */
const getServiceProjectController = (id) => client.get(`/service_projects/${id}/controller`);
/**
 * PostServiceProjectController function.
 *
 * @description Sends a POST request to the server to create a new service project controller.
 *
 * @param {number} id - The ID of the service project.
 * @param {Object} data - The data to be sent in the request body.
 *
 * @returns {Promise} A promise that resolves with the response from the server.
 */
const postServiceProjectController = (id, data) => client.post(`/service_projects/${id}/controller`, data);
/**
 * Updates the controller information for a service project.
 *
 * @param {string} id - The ID of the service project.
 * @param {string} ipId - The ID of the controller.
 * @param {object} data - The updated controller data to be sent.
 * @returns {Promise} A Promise that resolves to the updated service project after the update is completed.
 */
const editServiceProjectController = (id, ipId, data) => client.put(`/service_projects/${id}/controller/${ipId}`, data);
/**
 * Delete Service Project Controller.
 *
 * @param {string} id - The ID of the service project.
 * @param {string} ipId - The ID of the controller.
 * @returns {Promise} A Promise that resolves to the result of the delete operation.
 */
const deleteServiceProjectController = (id, ipId) => client.delete(`/service_projects/${id}/controller/${ipId}`);

/**
 * Get the login details for a specific service project.
 *
 * @param {number} id - The ID of the service project.
 * @returns {Promise} - A promise that resolves with the login details.
 */
const getServiceProjectLogin = (id) => client.get(`/service_projects/${id}/login`);
/**
 * Makes a POST request to login to a service project.
 *
 * @param {string} id - The ID of the service project.
 * @param {Object} data - The login data to be sent in the request body.
 * @returns {Promise} A Promise representing the server response.
 */
const postServiceProjectLogin = (id, data) => client.post(`/service_projects/${id}/login`, data);
/**
 * Updates the login for a service project with the specified ID and IP ID.
 *
 * @param {number} id - The ID of the service project.
 * @param {number} ipId - The IP ID of the login.
 * @param {object} data - The data to update the login with.
 * @returns {Promise} A promise that resolves when the login is successfully updated.
 */
const editServiceProjectLogin = (id, ipId, data) => client.put(`/service_projects/${id}/login/${ipId}`, data);
/**
 * Deletes the login configuration for a service project.
 *
 * @param {number} id - The ID of the service project.
 * @param {number} ipId - The ID of the login configuration to be deleted.
 * @returns {Promise} - A promise that resolves when the login configuration is successfully deleted.
 */
const deleteServiceProjectLogin = (id, ipId) => client.delete(`/service_projects/${id}/login/${ipId}`);

/**
 * Retrieves all service tickets from the server.
 *
 * @function getAllTickets
 * @returns {Promise} - A promise that resolves to the response from the server.
 *
 * @example
 * getAllTickets()
 *      .then((response) => {
 *          console.log(response.data); // Use response data
 *      })
 *      .catch((error) => {
 *          console.error(error); // Handle error
 *      });
 */
const getAllTickets = () => client.get(`/service_tickets`);
/**
 * Retrieves the ticket associated with a service project.
 *
 * @param {string} id - The ID of the service project.
 * @param {string} tId - The ID of the ticket.
 * @returns {Promise<Object>} - A promise that resolves with the ticket object.
 */
const getServiceProjectTicket = (id, tId) => client.get(`/service_projects/${id}/tickets/${tId}`);
/**
 * Retrieves all tickets associated with a service project.
 *
 * @param {string} id - The ID of the service project.
 * @returns {Promise} - A promise that resolves to the tickets associated with the service project.
 *
 * @throws {Error} - If the request fails or encounters an error.
 */
const getServiceProjectTickets = (id) => client.get(`/service_projects/${id}/tickets`);
/**
 * Creates a new ticket for a service project.
 *
 * @param {string} id - The ID of the service project.
 * @param {object} data - The data for the ticket.
 * @returns {Promise} - A Promise that resolves with the response from the server.
 */
const postServiceProjectTickets = (id, data) => client.post(`/service_projects/${id}/tickets`, data);
/**
 * Edit Service Project Tickets
 *
 * Updates a specific ticket within a service project by making a PUT request to the server.
 *
 * @param {string} id - The unique identifier of the service project.
 * @param {string} rId - The unique identifier of the ticket within the service project.
 * @param {Object} data - The updated data to be sent in the request body.
 * @returns {Promise} - A Promise that resolves with the server response if the request is successful,
 *                      or rejects with an error if the request fails.
 */
const editServiceProjectTickets = (id, rId, data) => client.put(`/service_projects/${id}/tickets/${rId}`, data);
/**
 * Deletes a ticket from a service project.
 *
 * @param {number} id - The ID of the service project.
 * @param {number} rId - The ID of the ticket to delete.
 * @returns {Promise} - A promise that resolves when the ticket is deleted.
 */
const deleteServiceProjectTickets = (id, rId) => client.delete(`/service_projects/${id}/tickets/${rId}`);

/**
 * Retrieve the history of ticket details for a given ticket ID.
 *
 * @param {string} tId - The ID of the ticket.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of ticket history objects.
 */
const getTicketDetailsHistory = (tId) => client.get(`/service_tickets/${tId}/history`);
/**
 * Retrieves the messages for a specific ticket details.
 *
 * @param {number} id - The ID of the service project.
 * @param {number} tId - The ID of the ticket.
 * @returns {Promise} A promise that resolves to the response from the server containing the ticket comments.
 */
const getTicketDetailsMessages = (id, tId) => client.get(`/service_projects/${id}/tickets/${tId}/comments`);
/**
 * Sends a new ticket comment to the server.
 *
 * @param {number} id - The ID of the service project.
 * @param {number} tId - The ID of the ticket.
 * @param {object} data - The data object containing the comment details.
 * @returns {Promise} - A Promise representing the result of the HTTP post request.
 */
const postTicketDetailsMessages = (id, tId, data) => client.post(`/service_projects/${id}/tickets/${tId}/comments`, data);
/**
 * Deletes a ticket details message.
 *
 * @param {string} id - The ID of the service project.
 * @param {string} sId - The ID of the ticket.
 * @param {string} tId - The ID of the comment.
 * @returns {Promise<void>} A Promise that resolves when the message is deleted successfully.
 */
const deleteTicketDetailsMessages = (id, sId, tId) => client.delete(`/service_projects/${id}/tickets/${sId}/comments/${tId}`);

/**
 * Retrieves the data of software BMS from the client.
 *
 * @returns {Promise} A promise that resolves with the data of software BMS.
 */
const getBMS = () => client.get(`/software_bms`);
/**
 * Retrieves the DDC (Device Description Configuration) from the server.
 *
 * @function
 * @returns {Promise} A Promise object that resolves to the DDC or rejects with an error.
 * @throws {Error} If an error occurs while retrieving the DDC from the server.
 */
const getDDC = () => client.get(`/software_ddc`);

/**
 * Retrieves the templates for a given service project ID and category.
 *
 * @param {string} id - The ID of the service project.
 * @param {string} cat - The category of the templates to retrieve.
 * @returns {Promise} - A promise that resolves with the response containing the templates.
 */
const getTemplates = (id, cat) => client.get(`/service_projects/${id}/template?template=${cat}`)

/**
 * Retrieves service messages for a given service project ID.
 *
 * @param {number} id - The ID of the service project.
 * @returns {Promise} A promise that resolves to the service messages for the specified service project ID.
 */
const getServiceMessages = (id) => client.get(`/service_projects/${id}/comments`)
/**
 * Sends a POST request to submit a service project comment.
 *
 * @param {string} id - The ID of the service project.
 * @param {Object} data - The data to be submitted as the comment.
 * @returns {Promise} - A Promise that resolves when the POST request is successful.
 */
const postServiceMessages = (id, data) => client.post(`/service_projects/${id}/comments`, data);
/**
 * Deletes a service message from a service project comment.
 *
 * @param {string} id - The ID of the service project.
 * @param {string} mId - The ID of the service project comment message.
 * @returns {Promise} A Promise that resolves when the service message is deleted successfully, or rejects with an error.
 */
const deleteServiceMessages = (id, mId) => client.delete(`/service_projects/${id}/comments/${mId}`);


/**
 * API for managing service projects and related entities.
 *
 * @namespace serviceProjectApi
 */
const serviceProjectApi = {
    getAllServiceProjects,
    getServiceProject,
    editServiceProject,
    getServiceProjectHistory,


    getServiceProjectIp,
    postServiceProjectIp,
    editServiceProjectIp,
    deleteServiceProjectIp,

    getServiceProjectController,
    postServiceProjectController,
    editServiceProjectController,
    deleteServiceProjectController,

    getServiceProjectLogin,
    postServiceProjectLogin,
    editServiceProjectLogin,
    deleteServiceProjectLogin,

    getAllTickets,
    getServiceProjectTicket,
    getServiceProjectTickets,
    postServiceProjectTickets,
    editServiceProjectTickets,
    deleteServiceProjectTickets,

    getTicketDetailsHistory,
    getTicketDetailsMessages,
    postTicketDetailsMessages,
    deleteTicketDetailsMessages,

    getBMS,
    getDDC,
    getTemplates,

    getServiceMessages,
    postServiceMessages,
    deleteServiceMessages
};

export default serviceProjectApi;