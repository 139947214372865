import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import notyf from "../../../utils/notyfApi";
import adminBudgetApi from "../../../api/adminBudget";
import {Trash} from "react-feather";

/**
 * Modal component for deleting a budget book year.
 *
 * @param {object} props - The properties passed to the component.
 * @param {number} props.id - The ID of the budget book year to be deleted.
 * @param {object} props.data - The data of the budget book year to be deleted.
 * @param {function} props.reload - The function to reload the data after deletion.
 *
 * @return {JSX.Element} The BudgetBookYearDeleteModal component.
 */
function BudgetBookYearDeleteModal({id, data, reload}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDelete = async () => {
        const res = await adminBudgetApi.deleteYearData(id)
        if (!res.ok) return notyf.error("Error " + res.status)
        reload();
    }

    return (
        <>
            <Trash className={"me-2 ms-2 float-end mb-0 cursor-pointer"} onClick={handleShow}/>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> Verwijder data </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Weet je zeker dat je {data?.productivity_year_year} wilt verwijderen?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDelete}>
                        Verwijderen
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Behouden
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default BudgetBookYearDeleteModal;
