import React, {useEffect, useState} from "react";
import ProductTable from "./ProductOverviewTable";
import productsApi from "../../../../api/products";
import Loader from "../../../../components/Loader";
import notyf from "../../../../utils/notyfApi";

/**
 * Retrieves product data from an API and displays it in a table format.
 * Uses the useState and useEffect hooks to handle data fetching and state management.
 * If product data is available, a ProductTable component is rendered with the retrieved data.
 * If product data is not available, a Loader component is rendered.
 * @returns {JSX.Element} - The rendered ProductTable or Loader component.
 */
export default function ProductOverviewTableHandler() {
    //get data from api
    const [products, setProducts] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const response = await productsApi.getAllProducts();
        if (!response.ok) return notyf.error("Error " + response.status)
        setProducts(response.data);
    };

    //laat tabel zien met gegevens via props
    if (products.length > 0) return <ProductTable reload={getData} products={products}/>;
    return <Loader/>
}