import React, {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import BudgetBookYearEditModal from "./BudgetBookYearEditModal";
import BudgetBookYearDeleteModal from "./BudgetBookYearDeleteModal";
import Loader from "../../../components/Loader";

/**
 * Represents the columns in a table.
 * @typedef {Object} Column
 * @property {number} id - The unique identifier of the column.
 * @property {string} name - The name of the column.
 * @property {string} header - The header text for the column.
 * @property {function} render - A function that renders the value of the column.
 * @property {number} flex - The flex size of the column.
 * @property {number} minWidth - The minimum width of the column.
 */
const columns = [
    {
        id: 0,
        name: "urentype",
        header: "Urentype",
        flex: 1,
        minWidth: 90,
    },
    {
        id: 1,
        name: "percentage",
        header: "Percentage",
        render: ({value}) => <> {value} % </>,
        flex: 1,
        minWidth: 90,
    },
    {
        id: 2,
        name: "uren",
        header: "Uren",
        render: ({value}) => <> {Math.round((value / 100) * 2080)} </>,
        flex: 1,
        minWidth: 90,
    },
    {
        id: 3,
        name: "dagen",
        header: "Dagen",
        render: ({value}) => <> {Math.round(((value / 100) * 2080) / 8)} </>,
        flex: 1,
        minWidth: 90,
    },
]

/**
 * Represents a form component for displaying the budget book year overview.
 *
 * @param {Object} options - The options for the component.
 * @param {Object} options.data - The data used to populate the form.
 * @param {function} options.reload - The function to reload the data.
 * @return {React.Component} - The budget book year overview form component.
 */
function BudgetBookYearOverviewForm({data, reload}) {
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        if (data) setDataSource([
            {
                "id": 0,
                "urentype": "Productieve uren",
                "percentage": data.productivity_year_productivity_percentage,
                "uren": data.productivity_year_productivity_percentage,
                "dagen": Math.round(data.productivity_year_productivity_percentage),
            },
            {
                "id": 1,
                "urentype": "Indirecte uren",
                "percentage": data.productivity_year_no_productivity_percentage,
                "uren": data.productivity_year_no_productivity_percentage,
                "dagen": Math.round(data.productivity_year_no_productivity_percentage),
            },
            {
                "id": 2,
                "urentype": "Snipperdagen, ATV, feestdagen",
                "percentage": data.productivity_year_holiday_percentage,
                "uren": data.productivity_year_holiday_percentage,
                "dagen": Math.round(data.productivity_year_holiday_percentage),
            },
            {
                "id": 3,
                "urentype": "Ziekte uren",
                "percentage": data.productivity_year_sick_days_percentage,
                "uren": data.productivity_year_sick_days_percentage,
                "dagen": Math.round(data.productivity_year_sick_days_percentage),
            },
            {
                "id": 4,
                "urentype": "",
                "percentage": data.productivity_year_productivity_percentage
                    + data.productivity_year_no_productivity_percentage
                    + data.productivity_year_holiday_percentage
                    + data.productivity_year_sick_days_percentage,
                "uren": data.productivity_year_productivity_percentage
                    + data.productivity_year_no_productivity_percentage
                    + data.productivity_year_holiday_percentage
                    + data.productivity_year_sick_days_percentage,
                "dagen": data.productivity_year_productivity_percentage
                    + data.productivity_year_no_productivity_percentage
                    + data.productivity_year_holiday_percentage
                    + data.productivity_year_sick_days_percentage,
            }])
    }, [data])

    if (data && dataSource)
        return (
            <React.Fragment>
                <Card>
                    <Card.Header>
                        <Card.Title>
                            Boekjaar gegevens - {data ? data?.productivity_year_year : undefined}

                            <BudgetBookYearDeleteModal reload={reload} id={data?.productivity_year_id} data={data}/>
                            <BudgetBookYearEditModal reload={reload} id={data?.productivity_year_id} exists={data}/>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <ReactDataGridCustom
                            idProperty="id"
                            dataSource={dataSource}
                            columns={columns}
                            // editable={true}
                            // editStartEvent={"click"}
                            // onEditComplete={onEditComplete}
                        />
                    </Card.Body>
                    <Card.Footer>
                        *De indirecte en productieve uren wijken lichtelijk af van de nordomatic manier omdat deze hier
                        berekend worden op de totale jaaruren i.p.v. het totaal aantal werkbare uren. Dit maakt niet uit als het budget maar ingevuld wordt
                        op basis van het percentage indirecte, ziekte, en vrije uren.
                    </Card.Footer>
                </Card>
            </React.Fragment>
        );
    else return <Loader/>;
}

export default BudgetBookYearOverviewForm;
