import React, {useCallback, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {Button, Card} from "react-bootstrap";
import {euroFormat} from "../../../utils/usefullFunctions";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import filter from "@inovua/reactdatagrid-community/filter";
import {useExcelDownloder} from "react-xls";
import moment from "moment";
import TableViewIcon from "@mui/icons-material/TableView";

/**
 * A group object consisting of a name and a header.
 * @typedef {Object} Group
 * @property {string} name - The name of the group.
 * @property {string} header - The header of the group.
 */
const groups = [
    {name: 'ddc', header: 'OH DDC apparatuur, netwerk en GBS'},
    {name: 'ddcUren', header: 'OH DDC apparatuur, netwerk en GBS uren'},
    {name: 'reg', header: 'OH Regelkast en Veldapparatuur'},
    {name: 'regUren', header: 'OH Regelkast en Veldapparatuur uren'},
    {name: 'boa', header: 'BOA & Monitoring'},
    {name: 'boaUren', header: 'BOA & Monitoring uren'},
]

/**
 * The default filter value.
 *
 * @type {Array<Object>}
 * @property {string} name - The name of the filter.
 * @property {string} operator - The operator used for filtering.
 * @property {string} type - The data type of the filter value.
 * @property {*} value - The default value of the filter.
 */
const defaultFilterValue = [
    {name: 'project_name', operator: 'contains', type: 'string', value: ''},
    {name: 'project_code', operator: 'contains', type: 'string', value: ''},
    {name: 'service_project_city', operator: 'contains', type: 'string', value: ''},
    {name: 'booking_cost_boa', operator: 'gte', type: 'number', value: null},
    {name: 'calculation_cost_boa', operator: 'gte', type: 'number', value: null},
    {name: 'booking_amount_boa', operator: 'gte', type: 'number', value: null},
    {name: 'calculation_amount_boa', operator: 'gte', type: 'number', value: null},
    {name: 'booking_cost_ohddc', operator: 'gte', type: 'number', value: null},
    {name: 'calculation_cost_ohddc', operator: 'gte', type: 'number', value: null},
    {name: 'booking_amount_ohddc', operator: 'gte', type: 'number', value: null},
    {name: 'calculation_amount_ohddc', operator: 'gte', type: 'number', value: null},
    {name: 'booking_cost_ohveld', operator: 'gte', type: 'number', value: null},
    {name: 'calculation_cost_ohveld', operator: 'gte', type: 'number', value: null},
    {name: 'booking_amount_ohveld', operator: 'gte', type: 'number', value: null},
    {name: 'calculation_amount_ohveld', operator: 'gte', type: 'number', value: null},
];

/**
 * Represents an array of column objects.
 * @typedef {Array} Columns
 * @property {string} name - The name of the column.
 * @property {string} header - The header of the column.
 * @property {number} width - The width of the column.
 * @property {function} render - The render function for the column's cell value.
 * @property {string} group - The group that the column belongs to.
 * @property {function} filterEditor - The filter editor for the column.
 */
const columns = [
    {
        name: "project_code",
        header: "Projectnummer",
        width: 150,
        render: ({value, data}) => <NavLink to={`/project/${data.project_id}`}>{value}</NavLink>,
    },
    {
        name: "project_name",
        header: "Projectnaam",
        render: ({value, data}) => <NavLink to={`/project/${data.project_id}`}>{value}</NavLink>,
        width: 300,
    },
    {
        name: "service_project_city",
        header: "Adres",
        width: 300,
        render: ({data}) => {
            if (data.service_project_street && data.service_project_street_nr && data.service_project_city) {
                return <a
                    href={encodeURI(`https://maps.google.com/?q=${data.service_project_street}+${data.service_project_street_nr}+${data.service_project_city}`)}
                    target="_BLANK" rel="noreferrer">
                    {data.service_project_street} {data.service_project_street_nr}, {data.service_project_city}
                </a>
            } else if (data.service_project_street && data.service_project_street_nr) {
                return <a
                    href={encodeURI(`https://maps.google.com/?q=${data.service_project_street}+${data.service_project_street_nr}`)}
                    target="_BLANK" rel="noreferrer">
                    {data.service_project_street} {data.service_project_street_nr}
                </a>
            } else if (data.service_project_street && data.service_project_city) {
                return <a
                    href={encodeURI(`https://maps.google.com/?q=${data.service_project_street}+${data.service_project_city}`)}
                    target="_BLANK" rel="noreferrer">
                    {data.service_project_street}, {data.service_project_city}
                </a>
            }
        },
    },
    {
        name: "hosting",
        header: "Hosting",
        render: ({value}) => {
            if (value === true)
                return <span className="badge bg-success">Ja</span>;
            else if (value === false)
                return <span className="badge bg-danger">Nee</span>;
            else
                return <span className="badge bg-warning text-dark">Onbekend</span>;
        },
    },
    {
        name: "maintenance",
        header: "Onderhoud",
        render: ({value}) => {
            if (value === true)
                return <span className="badge bg-success">Ja</span>;
            else if (value === false)
                return <span className="badge bg-danger">Nee</span>;
            else
                return <span className="badge bg-warning text-dark">Onbekend</span>;
        },
    },
    {
        name: "booking_cost_boa",
        header: "Geboekt",
        render: ({value}) => euroFormat(value),
        group: "boa",
        filterEditor: NumberFilter,
    },
    {
        name: "calculation_cost_boa",
        header: "Gecalculeerd",
        render: ({value}) => euroFormat(value),
        group: "boa",
        filterEditor: NumberFilter,
    },
    {
        name: "booking_amount_boa",
        header: "Geboekt",
        group: "boaUren",
        filterEditor: NumberFilter,
    },
    {
        name: "calculation_amount_boa",
        header: "Gecalculeerd",
        group: "boaUren",
        filterEditor: NumberFilter,
    },
    {
        name: "booking_cost_ohddc",
        header: "Geboekt",
        render: ({value}) => euroFormat(value),
        group: "ddc",
        filterEditor: NumberFilter,
    }
    ,
    {
        name: "calculation_cost_ohddc",
        header: "Gecalculeerd",
        group: "ddc",
        render: ({value}) => euroFormat(value),
        filterEditor: NumberFilter,
    }
    ,
    {
        name: "booking_amount_ohddc",
        header: "Geboekt",
        group: "ddcUren",
        filterEditor: NumberFilter,
    },
    {
        name: "calculation_amount_ohddc",
        header: "Gecalculeerd",
        group: "ddcUren",
        filterEditor: NumberFilter,
    },
    {
        name: "booking_cost_ohveld",
        header: "Geboekt",
        render: ({value}) => value ? euroFormat(value) : null,
        group: "reg",
        filterEditor: NumberFilter,
    },
    {
        name: "calculation_cost_ohveld",
        header: "Gecalculeerd",
        render: ({value}) => value ? euroFormat(value) : null,
        group: "reg",
        filterEditor: NumberFilter,
    },
    {
        name: "booking_amount_ohveld",
        header: "Geboekt",
        group: "regUren",
        filterEditor: NumberFilter,
    },
    {
        name: "calculation_amount_ohveld",
        header: "Gecalculeerd",
        group: "regUren"
    },
    {
        name: "calculation_cost_cons",
        header: "Storingsafhandeling tijdens kantoortijd",
        width: 300,
        render: ({value}) => {
            if (value > 0)
                return <span className="badge bg-success">Ja</span>;
            else if (value === false || value === undefined || value === null || value === "0" || value === 0)
                return <span className="badge bg-danger">Nee</span>;
        },
    },
    {
        name: " ",
        header: "Storingsafhandeling 24/7",
        width: 210,
        render: ({value, data}) => {
            if (data.calculation_cost_cons === true)
                return <span className="badge bg-danger">Ja</span>;
            else if (data.calculation_cost_cons === false || value === undefined || value === null || value === "0" || value >= 0)
                return <span className="badge bg-danger">Nee</span>;
        },
    },
    {
        name: "Ondersteuning ACS",
        header: "Ondersteuning ACS",
        width: 200,
        render: ({value}) => {
            if (value === true) return <span
                className="badge bg-success">Ja</span>; else if (value === false || value === undefined || value === null) return <span
                className="badge bg-danger">Nee</span>;
        },
    },
];

/**
 * Renders a service matrix table with filter options and download functionality.
 * @param {Object} serviceMatrixRow - The service matrix row data.
 * @returns {JSX.Element} - The rendered service matrix table.
 */
export default function ServiceMatrixTable({serviceMatrixRow}) {
    const [dataSource, setDataSource] = useState([]);
    const [filterValue, setFilterValue] = useState(defaultFilterValue);
    const {ExcelDownloder, setData} = useExcelDownloder();

    const onFilterValueChange = useCallback((f) => {
        const data = filter(serviceMatrixRow, f)
        setFilterValue(f);
        setDataSource(data);
        setData({matrix: data})
        localStorage.setItem("matrixFilters", JSON.stringify(f))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource])

    useEffect(() => {
        const localFilters = localStorage.getItem("matrixFilters")
        if (localFilters) {
            setFilterValue(JSON.parse(localFilters))
            setDataSource(filter(serviceMatrixRow, JSON.parse(localFilters)))
            setData({matrix: filter(serviceMatrixRow, JSON.parse(localFilters))})
        } else {
            setDataSource(serviceMatrixRow)
            setData({matrix: serviceMatrixRow})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<Card>
        <Card.Header className="mb-0">
            <Card.Title className="mb-0 d-flex justify-content-between">
                <h3 className="mb-0">Matrix</h3>
                <span>
                                          <ExcelDownloder
                                              data={dataSource}
                                              filename={'Matrix ' + moment().format("DD-MM-YYYY")}
                                              type={"link"}
                                          >
                                <Button className={"me-1 added-tiny-margin-top remove-margin-top-mob"}
                                        variant={"secondary"}>
                                    <TableViewIcon fontSize={"small"}/>
                                </Button>
                            </ExcelDownloder>
                <Button variant={"info"} className={"float-end"}
                        onClick={() => onFilterValueChange(defaultFilterValue)}>Reset
                    filters</Button>
                    </span>
            </Card.Title>
        </Card.Header>
        <Card.Body>
            <ReactDataGridCustom
                idProperty="sales_offer_id"
                dataSource={dataSource}
                columns={columns}
                groups={groups}
                pagination
                defaultLimit={50}
                filterValue={filterValue}
                onFilterValueChange={onFilterValueChange}
            />
        </Card.Body>
    </Card>);

}
