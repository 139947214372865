import React from "react";

/**
 * A wrapper component that wraps its children in a <div> element with a specified className.
 *
 * @param {Object} props - The properties of the component.
 * @param {React.ReactNode} props.children - The content to be wrapped.
 * @returns {React.ReactElement} The wrapped children in a <div> element.
 */
const Wrapper = ({ children }) => <div className="wrapper">{children}</div>;

export default Wrapper;
