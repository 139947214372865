import React, {useEffect, useState} from "react";
import {Pie} from "react-chartjs-2";
import Loader from "../../../components/Loader";

/**
 * Renders a pie chart component for a specified department's data.
 *
 * @param {Object} props - The component props.
 * @param {string} props.department - The name of the department.
 * @param {Object[]} props.allDepData - The data for all departments.
 * @param {string} props.pieUse - The type of data to display in the pie chart (either "actual" or "budget").
 *
 * @returns {React.Component} - The rendered pie chart component.
 */
const PieChartForDepartment = ({department, allDepData, pieUse,}) => {
    const [loading, setLoading] = useState(true)
    const [actualArray, setActualArray] = useState([0, 0, 0, 0])
    const [budgetArray, setBudgetArray] = useState([0, 0, 0, 0])
    let tempActualArray = [0, 0, 0, 0];
    let tempBudgetArray = [0, 0, 0, 0];

    useEffect(() => {
        setLoading(true);
        leRunOnce();
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allDepData]);

    const leRunOnce = async () => {
        let newDepArray = allDepData.filter(item => item.productivity_department === department)
        newDepArray.forEach(e => {
            if (pieUse === "actual") {
                //prod
                tempActualArray.splice(0, 1, tempActualArray[0] + parseInt(e.actual_productivity_hours));
                //niet prod
                tempActualArray.splice(1, 1, tempActualArray[1] + parseInt(e.actual_no_productivity_hours) + parseInt(e.actual_training_hours));
                //ziek
                tempActualArray.splice(2, 1, tempActualArray[2] + parseInt(e.actual_sick_hours));
                //vrij
                tempActualArray.splice(3, 1, tempActualArray[3] + parseInt(e.actual_holiday_hours));
            } else if (pieUse === "budget") {
                //prod
                tempBudgetArray.splice(0, 1, tempBudgetArray[0] + parseInt(e.budget_productivity_hours));
                //niet prod
                tempBudgetArray.splice(1, 1, tempBudgetArray[1] + parseInt(e.budget_no_productivity_hours) + parseInt(e.budget_training_hours));
                //ziek
                tempBudgetArray.splice(2, 1, tempBudgetArray[2] + parseInt(e.budget_sick_hours));
                //vrij
                tempBudgetArray.splice(3, 1, tempBudgetArray[3] + parseInt(e.budget_holiday_hours));
            }
        })
        if (pieUse === "actual") {
            setActualArray(tempActualArray);
        }
        if (pieUse === "budget") {
            setBudgetArray(tempBudgetArray);
        }
    }

    let data;
    if (pieUse === "actual") {
        data = {
            labels: ["Productief", "Niet Productief", "Ziek", "Vrij"],
            datasets: [
                {
                    data: actualArray,
                    backgroundColor: [
                        "#2ecc71",
                        "#3498db",
                        "#e74c3c",
                        "#f1c40f",
                    ],
                    borderColor: "transparent",
                },
            ],
        };
    } else {
        data = {
            labels: ["Budget productief", "Budget niet productief", "Budget ziek", "Budget vrij"],
            datasets: [
                {
                    data: budgetArray,
                    backgroundColor: [
                        "#208f4e",
                        "#1f5b83",
                        "#8b2e24",
                        "#917609",
                    ],
                    borderColor: "transparent",
                },
            ],
        };
    }

    const options = {
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    let dataset = data.datasets[tooltipItem.datasetIndex];
                    let meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    let total = meta.total;
                    let currentValue = dataset.data[tooltipItem.index];
                    let percentage = parseFloat((currentValue / total * 100).toFixed(1));
                    return currentValue + ' (' + percentage + '%)';
                },
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        }
    };

    if (loading) return <Loader/>
    return (
        <>
            <Pie data={data} options={options}/>
        </>
    );
};

export default PieChartForDepartment;
