import React from "react";
import { Container, Row, Col } from "react-bootstrap";

/**
 * Represents the footer component for a web page.
 *
 * @returns {JSX.Element} - The rendered footer component.
 */
const Footer = () => (
  <footer className="footer">
    <Container fluid>
      <Row className="text-muted">
        <Col xs="6" className="text-start">
          <ul className="list-inline">
            <li className="list-inline-item">
              <span className="text-muted" href="#">
                <a href="https://y-con.ui.com/helpdesk/add" target="_BLANK" rel="noreferrer">Support</a>
              </span>
            </li>
            {/*<li className="list-inline-item">*/}
            {/*  <span className="text-muted" href="#">*/}
            {/*    Help Center*/}
            {/*  </span>*/}
            {/*</li>*/}
            {/*<li className="list-inline-item">*/}
            {/*  <span className="text-muted" href="#">*/}
            {/*    Privacy*/}
            {/*  </span>*/}
            {/*</li>*/}
            {/*<li className="list-inline-item">*/}
            {/*  <span className="text-muted" href="#">*/}
            {/*    Terms of Service*/}
            {/*  </span>*/}
            {/*</li>*/}
          </ul>
        </Col>
        <Col xs="6" className="text-end">
          <p className="mb-0">
            &copy; {new Date().getFullYear()} -{" "}
            {/* Appstack template (betaald) aangepast door Y-con ICT */}
            <span href="/" className="text-muted">
              Y-con
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
