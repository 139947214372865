import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Card, Col, Container, Form, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import productsApi from "../../../api/products";
import ProductItemModal from "../../components/producten_inkoop/ProductItemModal";
import ProductDeleteItemModal from "../../components/producten_inkoop/ProductDeleteItemModal";
import moment from "moment";
import Loader from "../../../components/Loader";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import notyf from "../../../utils/notyfApi";
import {euroFormat} from "../../../utils/usefullFunctions";

const Productdetail = () => {
    const {id} = useParams();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        const res = await productsApi.getProduct(id);
        if (!res.ok) notyf.error("Error", res.status)
        setProduct(res.data);
    };

    if (!product) return <Loader/>;
    return (
        <React.Fragment>
            <Helmet title={`${product.supplier_id} | ${product.product_name}`}/>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">{product.supplier_id} | {product.product_name}</h1>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Card.Title className="mb-0">
                                    Product details
                                    <GuardWrapper role={"write:products"}>
                                        <ProductItemModal product={product} reload={getData}/>
                                        <ProductDeleteItemModal product={product}/>
                                    </GuardWrapper>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Product</Form.Label>
                                        <Form.Control value={product.product_name} disabled/>
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>Beschrijving</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            value={product.product_description}
                                            rows={5}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Row>
                                        <Form.Group as={Col} md="4" className="mb-3">
                                            <Form.Label>Leverancier</Form.Label>
                                            <Form.Control
                                                value={product.supplier_id}
                                                disabled
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" className="mb-3">
                                            <Form.Label>Netto</Form.Label>
                                            <Form.Control
                                                value={euroFormat(product.product_cost)}
                                                disabled
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" className="mb-3">
                                            <Form.Label>Bruto</Form.Label>
                                            <Form.Control
                                                value={euroFormat(product.product_list)}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>Opmerking</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            value={product.product_comment}
                                            rows={3}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Row>
                                        <Form.Group as={Col} md="6" className="mb-3">
                                            <Form.Label>Gemaakt op</Form.Label>
                                            <Form.Control
                                                value={moment(product.product_modified_on).format("DD-MM-YYYY HH:mm")}
                                                disabled
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className="mb-3">
                                            <Form.Label>Gewijzigd op</Form.Label>
                                            <Form.Control
                                                value={moment(product.product_modified_on).format("DD-MM-YYYY HH:mm")}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default Productdetail;
