import React from "react";
import {Form, InputGroup, Nav, Navbar} from "react-bootstrap";
import useSidebar from "../../hooks/useSidebar";
import NavbarUser from "./NavbarUser";
import {Search} from "react-feather";
import {useNavigate} from "react-router-dom";

/**
 * Represents a Navbar Component.
 * @constructor
 * @returns {JSX.Element} - The JSX generated by the component.
 */
const NavbarComponent = () => {
    const {isOpen, setIsOpen} = useSidebar();
    const navigate = useNavigate();

    const handleSubmit = e => {
        e.preventDefault()
        navigate("/search/" + e.target[0].value)
    }

    return (
        <Navbar variant="light" expand className="navbar-bg">
      <span
          className="sidebar-toggle d-flex"
          onClick={() => {
              setIsOpen(!isOpen);
          }}
      >
        <i className="hamburger align-self-center"/>
      </span>

            {/*<Form inline="true" className="d-none d-sm-inline-block" onSubmit={() => navigate(`/elasticsearch/id`) }>*/}
            <Form inline="true" className="d-inline-block" onSubmit={handleSubmit} id={"overalzoeken"}>
                <InputGroup className="input-group-navbar">
                    <Form.Control placeholder={("Alles doorzoeken")} aria-label="Alles doorzoeken"/>
                    <span className={"addButtonToInput"}>
            <Search className="feather"/>
          </span>
                </InputGroup>
            </Form>
            {/*<div>*/}
            {/*    <Info className={"uitleg"} onMouseEnter={() => setOpenTooltip(true)}*/}
            {/*          onMouseLeave={() => setOpenTooltip(false)}/>*/}
            {/*    <div style={{display: openTooltip ? "block" : "none"}} className={"uitlegtext"}>*/}
            {/*        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the*/}
            {/*        industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type*/}
            {/*        and scrambled it to make a type specimen book. It has survived no*/}
            {/*    </div>*/}
            {/*</div>*/}

            <Navbar.Collapse>
                <Nav className="navbar-align">
                    <NavbarUser/>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default NavbarComponent;
