import React, {useCallback, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {Badge, Button, Card, Container} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import offersApi from "../../../api/offertes";
import {euroFormat} from "../../../utils/usefullFunctions";
import moment from "moment";
import AddOfferModal from "../../components/verkoop/AddOfferModal";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import filter from '@inovua/reactdatagrid-community/filter'
import notyf from "../../../utils/notyfApi";
import TableViewIcon from "@mui/icons-material/TableView";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import Loader from "../../../components/Loader";
import {useExcelDownloder} from "react-xls";

/**
 * defaultFilterValue represents the default filter values to be used in a filtering system.
 * It is an array of objects, where each object represents a single filter.
 * Each filter object contains the following properties:
 * - name: The name of the filter
 * - operator: The operator to be used in the filter
 * - type: The type of the filter value
 * - value: The default value for the filter
 *
 * Example usage:
 * const defaultFilterValue = [
 *    {name: 'sales_offer_name', operator: 'contains', type: 'string', value: ''},
 *    {name: 'user_name', operator: 'contains', type: 'string', value: ''},
 *    {name: 'sales_offer_reference', operator: 'contains', type: 'string', value: ''},
 *    {name: 'sales_offer_folder_nr', operator: 'gte', type: 'number', value: null},
 *    {name: 'sales_offer_status', operator: 'eq', type: 'select', value: null},
 *    {name: 'sales_offer_phase', operator: 'eq', type: 'select', value: null},
 *    {name: 'sales_offer_type', operator: 'eq', type: 'select', value: null},
 *    {name: 'sales_offer_country', operator: 'contains', type: 'string', value: ''},
 *    {name: 'sales_offer_city', operator: 'contains', type: 'string', value: ''},
 *    {name: 'sales_offer_margin', operator: 'contains', type: 'string', value: ''},
 *    {name: 'sales_offer_scoring_chance', operator: 'gte', type: 'number', value: null},
 *    {name: 'sales_offer_quotation_amount', operator: 'gte', type: 'number', value: null},
 * ];
 */
const defaultFilterValue = [
    {name: 'sales_offer_name', operator: 'contains', type: 'string', value: ''},
    {name: 'user_name', operator: 'contains', type: 'string', value: ''},
    {name: 'sales_offer_reference', operator: 'contains', type: 'string', value: ''},
    {name: 'sales_offer_folder_nr', operator: 'gte', type: 'number', value: null},
    {name: 'sales_offer_status', operator: 'eq', type: 'select', value: null},
    {name: 'sales_offer_phase', operator: 'eq', type: 'select', value: null},
    {name: 'sales_offer_type', operator: 'eq', type: 'select', value: null},
    {name: 'sales_offer_country', operator: 'contains', type: 'string', value: ''},
    {name: 'sales_offer_city', operator: 'contains', type: 'string', value: ''},
    {name: 'sales_offer_margin', operator: 'contains', type: 'string', value: ''},
    {name: 'sales_offer_scoring_chance', operator: 'gte', type: 'number', value: null},
    {name: 'sales_offer_quotation_amount', operator: 'gte', type: 'number', value: null},
];

/**
 * Represents a list of columns for a table.
 * @typedef {Object} Column
 * @property {string} name - The name of the column.
 * @property {string} header - The header text of the column.
 * @property {number} [width] - The width of the column (optional).
 * @property {Function} [render] - The function to render the cell value (optional).
 * @property {Function} [filterEditor] - The function to render the filter editor (optional).
 * @property {Object} [filterEditorProps] - The props for the filter editor (optional).
 */
const columns = [
    {
        name: "sales_offer_reference",
        header: "Offertenummer",
        render: ({value, data}) => <NavLink to={`/offer/${data.sales_offer_id}`}>{value}</NavLink>,
    },
    {
        name: "sales_offer_name",
        header: "Offertenaam",
        width: 350,
        render: ({value, data}) => <NavLink to={`/offer/${data.sales_offer_id}`}>{value}</NavLink>,
    },
    {
        name: "user_name",
        header: "Calculator",
    },

    {
        name: "sales_offer_folder_nr",
        header: "Map",
        filterEditor: NumberFilter,
    },
    {
        name: "sales_offer_status",
        header: "Status",
        render: ({value}) => {
            if (value === "Open") return <Badge bg={"info"}>OPEN</Badge>
            if (value === "Gemist") return <Badge bg={"danger"}>GEMIST</Badge>
            if (value === "Binnengehaald") return <Badge bg={"success"}>BINNENGEHAALD</Badge>
        },
        filterEditor: SelectFilter,
        filterEditorProps: {
            placeholder: 'Alles',
            dataSource: [
                {
                    id: "Open",
                    label: "Open"
                },
                {
                    id: "Gemist",
                    label: "Gemist"
                },
                {
                    id: "Binnengehaald",
                    label: "Binnengehaald"
                },
            ]
        },
    },
    {
        name: "sales_offer_phase",
        header: "Fase",
        render: ({value}) => {

            if (value === "Kwalificeren") return <Badge bg={"primary"}>Aanvraag</Badge>
            if (value === "Voorstel") return <Badge bg={"info"}>Voorstel</Badge>
            if (value === "Ontwikkeling") return <Badge bg={"warning"}>Onderhandeling</Badge>
            else return <Badge bg={"success"}>Gesloten</Badge>
        },
        filterEditor: SelectFilter,
        filterEditorProps: {
            placeholder: 'Alles',
            dataSource: [
                {
                    id: "Voorstel",
                    label: "Voorstel"
                },
                {
                    id: "Ontwikkeling",
                    label: "Onderhandeling"
                },
                {
                    id: "Binnengehaald",
                    label: "Gesloten"
                },
                {
                    id: "Kwalificeren",
                    label: "Aanvraag"
                },
            ]
        },
    },
    {
        name: "sales_offer_type",
        header: "Type",
        render: ({value}) => <Badge bg={"info"}>{value}</Badge>,
        filterEditor: SelectFilter,
        filterEditorProps: {
            placeholder: 'Alles',
            dataSource: [
                {
                    id: "Project",
                    label: "Project"
                },
                {
                    id: "Klein project",
                    label: "Klein project"
                },
                {
                    id: "Service",
                    label: "Service"
                },
                {
                    id: "Meerwerk",
                    label: "Meerwerk"
                },
                {
                    id: "Webshop order",
                    label: "Webshop order"
                },
            ]
        },
    },
    {
        name: "sales_offer_city",
        header: "Plaatsnaam",
    },
    {
        name: "sales_offer_country",
        header: "Land",
    },
    {
        name: "sales_offer_margin",
        header: "Marge(%)",
        render: ({value}) => {
            if (value) return `${parseFloat(value)}%`
            return null
        },

    },
    {
        name: "sales_offer_scoring_chance",
        header: "Scoringskans(%)",
        render: ({value}) => {
            if (value) return `${parseFloat(value)}%`
            return null
        },
    },
    {
        name: "sales_offer_quotation_amount",
        header: "Offertebedrag",
        render: ({value}) => value ? euroFormat(value) : null,
        filterEditor: NumberFilter,

    },
    {
        name: "Winst",
        header: "Winst(basis)",
        render: ({data}) => {
            if (parseFloat(data.sales_offer_quotation_amount) && parseFloat(data.sales_offer_margin)) {
                return euroFormat(parseFloat(data.sales_offer_quotation_amount) * (parseFloat(data.sales_offer_margin) / 100))
            }
            return null
        },
    },
    {
        name: "sales_offer_expected_completion_date",
        header: "Verwachte opleverdatum",
        render: ({value}) => value ? moment(value).format("DD-MM-YYYY") : null,
    },
    {
        name: "sales_offer_expected_assignment_date",
        header: "Verwachte opdrachtdatum",
        render: ({value}) => value ? moment(value).format("DD-MM-YYYY") : null,
    },
    {
        name: "sales_offer_modified_on",
        header: "Bijgewerkt op",
        render: ({value}) => value ? moment(value).format("DD-MM-YY HH:mm") : null,
    },
    {
        name: "sales_offer_created_on",
        header: "Aangemaakt op",
        render: ({value}) => value ? moment(value).format("DD-MM-YY HH:mm") : null,
    },
]


/**
 * Represents the component for displaying an overview of offers.
 * @constructor
 */
const OfferOverview = () => {
    const [offers, setOffers] = useState([])
    const [dataSource, setDataSource] = useState({offers: []})
    const [filterValue, setFilterValue] = useState(defaultFilterValue);
    const {ExcelDownloder, setData} = useExcelDownloder();

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const res = await offersApi.getAllOffers()
        if (!res.ok) return notyf.error("Error " + res.status)

        setOffers(res.data)
        const localFilters = localStorage.getItem("offerFilters")
        if (localFilters) {
            setFilterValue(JSON.parse(localFilters))
            convertData(filter(res.data, JSON.parse(localFilters)))
            setDataSource({offers: filter(res.data, JSON.parse(localFilters))})
        } else {
            convertData(res.data)
            setDataSource({offers: res.data})
        }
    }

    const convertData = (data) => {
        let converted = []

        for (let r of data) {
            converted.push({
                Offertenummer: r.sales_offer_reference,
                Offertenaam: r.sales_offer_name,
                Calculator: r.user_name,
                Map: parseFloat(r.sales_offer_folder_nr) || 0,
                Status: r.sales_offer_status,
                Fase: r.sales_offer_phase,
                Type: r.sales_offer_type,
                Plaatsnaam: r.sales_offer_city,
                Land: r.sales_offer_country,
                "Marge(%)": parseFloat(r.sales_offer_margin) || null,
                "Scoringskans(%)": parseFloat(r.sales_offer_scoring_chance) || null,
                Offertebedrag: parseFloat(r.sales_offer_quotation_amount) || null,
                "Winst(basis)": (parseFloat(r.sales_offer_quotation_amount) && parseFloat(r.sales_offer_margin)) ?
                    euroFormat(parseFloat(r.sales_offer_quotation_amount) * (parseFloat(r.sales_offer_margin) / 100)) : null,
                "Verwachte opleverdatum": r.sales_offer_expected_completion_date ? moment(r.sales_offer_expected_completion_date).format("DD-MM-YYYY") : null,
                "Verwachte opdrachtdatum": r.sales_offer_expected_assignment_date ? moment(r.sales_offer_expected_assignment_date).format("DD-MM-YYYY") : null,
                "Bijgewerkt op": r.sales_offer_modified_on ? moment(r.sales_offer_modified_on).format("DD-MM-YYYY") : null,
                "Aangemaakt op": r.sales_offer_created_on ? moment(r.sales_offer_created_on).format("DD-MM-YYYY") : null,

            })
        }
        setData({
            offers: converted
        })

    }


    const handleContextMenu = async (outcome, rowProps) => {
        if (rowProps.data.sales_offer_status === outcome) return null
        const resOffer = await offersApi.editExistingOfferStatus(rowProps.data.sales_offer_id, outcome);
        if (!resOffer.ok) return notyf.error("Error " + resOffer.status)
        getData()
    }

    const onFilterValueChange = useCallback((f) => {
        const data = filter(offers, f)
        setFilterValue(f);
        setDataSource({offers: data});
        convertData(data)
        localStorage.setItem("offerFilters", JSON.stringify(f))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offers])

    const renderRowContextMenu = (menuProps, {rowProps}) => {
        menuProps.autoDismiss = true
        if (rowProps.data.sales_offer_status === "Open") {
            menuProps.items = [
                {
                    label: "Binnengehaald",
                    onClick: () => handleContextMenu("Binnengehaald", rowProps)
                },
                {
                    label: "Gemist",
                    onClick: () => handleContextMenu("Gemist", rowProps)
                }
            ]
        } else {
            menuProps.items = [
                {
                    label: "Open",
                    onClick: () => handleContextMenu("Open", rowProps)
                },
            ]
        }
    }

    if (!offers.length) return <Loader/>
    return (<><Helmet title="Offerte overzicht"/>
            <Container fluid className="p-0">
                <Card className={"d-flex"}>
                    <>
                        <Card.Header>
                            <Card.Title tag="h5" className="mb-0 d-flex">
                                <div className={"w-100 d-flex justify-content-between"}>
                                    <h3 className={"mb-0"}>Offerte overzicht</h3>
                                    <span>
                                    <Button variant={"info"} className={"me-1"}
                                            onClick={() => onFilterValueChange(defaultFilterValue)}>Reset filters</Button>
                                        <ExcelDownloder
                                            data={dataSource}
                                            filename={'Offertes ' + moment().format("DD-MM-YYYY")}
                                            type={"link"}>
                                                <Button variant={"secondary"}>
                                                    <TableViewIcon fontSize={"small"}/>
                                                </Button>
                                        </ExcelDownloder>
                                    <GuardWrapper role={"write:offers"}><AddOfferModal/></GuardWrapper>
                                    </span>
                                </div>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <ReactDataGridCustom
                                idProperty="sales_offer_id"
                                dataSource={dataSource.offers}
                                columns={columns}
                                pagination
                                defaultLimit={500}
                                filterValue={filterValue}
                                onFilterValueChange={onFilterValueChange}
                                renderRowContextMenu={renderRowContextMenu}

                            />
                        </Card.Body>
                    </>
                </Card>

            </Container>
        </>

    )
};


export default OfferOverview;
