import React, {useCallback, useContext, useEffect, useState} from "react";
import {Button, ButtonGroup, Card, ToggleButton} from "react-bootstrap";
import projectsApi from "../../../api/projects";
import {NavLink, useParams} from "react-router-dom";
import {euroFormat} from "../../../utils/usefullFunctions";
import PermissionsContext from "../../../utils/context";
import Loader from "../../../components/Loader";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import notyf from "../../../utils/notyfApi";
import TableViewIcon from '@mui/icons-material/TableView';
import moment from "moment";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import filter from "@inovua/reactdatagrid-community/filter";
import {useExcelDownloder} from "react-xls";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

/**
 * The default filter value for a project.
 *
 * @type {Array<Object>}
 * @property {string} name - The name of the filter field.
 * @property {string} operator - The operator for the filter field.
 * @property {string} type - The type of the filter field.
 * @property {*} value - The default value for the filter field.
 */
const defaultFilterValue = [
    {name: 'Projectnaam', operator: 'contains', type: 'string', value: ''},
    {name: 'Projectcode', operator: 'contains', type: 'string', value: ''},
    {name: 'Projectleider', operator: 'eq', type: 'select', value: null},
    {name: 'Verkoop', operator: 'gte', type: 'number', value: null},
    {name: 'Calculatie', operator: 'gte', type: 'number', value: null},
    {name: 'Geboekt', operator: 'gte', type: 'number', value: null},
    {name: 'Mutaties', operator: 'gte', type: 'number', value: null},
    {name: 'Gefactureerd', operator: 'gte', type: 'number', value: null},
    {name: "Te_factureren", operator: 'gte', type: 'number', value: null},
];
/**
 * Represents the default filter values for a user with no permissions.
 *
 * @typedef {Object} Filter
 * @property {string} name - The name of the filter field.
 * @property {string} operator - The operator used to filter the data.
 * @property {string} type - The type of filter field.
 * @property {string|null} value - The value used for filtering.
 */
const defaultFilterValueNoPermissions = [
    {name: 'Projectnaam', operator: 'contains', type: 'string', value: ''},
    {name: 'Projectcode', operator: 'contains', type: 'string', value: ''},
    {name: 'Projectleider', operator: 'eq', type: 'select', value: null},
];

/**
 * Represents the list of columns used in a table.
 *
 * @typedef {Array} Columns
 * @property {string} name - The name of the column.
 * @property {string} header - The header text for the column.
 * @property {Function} render - A function that renders the content for a cell in the column.
 * @property {number} flex - The flex value for the column.
 * @property {number} minWidth - The minimum width of the column.
 * @property {React.Component} filterEditor - The filter editor component for the column.
 * @property {Object} filterEditorProps - Additional props for the filter editor.
 */
const columns = [
    {
        name: "Projectcode",
        header: "Projectcode",
        render: ({value, data}) => <NavLink to={`/project/${data.project_id}`}>{value}</NavLink>,
        flex: 1,
        minWidth: 90,

    },
    {
        name: "Projectnaam",
        header: "Projectnaam",
        render: ({value, data}) => <NavLink to={`/project/${data.project_id}`}>{value}</NavLink>,
        flex: 3,
        minWidth: 200,

    },
    {
        name: "Projectleider",
        header: "Projectleider",
        flex: 1,
        minWidth: 125,
        filterEditor: SelectFilter,
        filterEditorProps: {
            placeholder: 'Alles',
            dataSource: [
                {
                    id: "Wannes van de Vondel",
                    label: "Wannes van de Vondel"
                },
                {
                    id: "Lander van Dun",
                    label: "Lander van Dun"
                },
                {
                    id: "Frank Horemans",
                    label: "Frank Horemans"
                },
                {
                    id: "Tom Vreys",
                    label: "Tom Vreys"
                },
                {
                    id: "Dieter de Becker",
                    label: "Dieter de Becker"
                }
            ]
        },
    },
    {
        name: "Verkoop",
        header: "Verkoop",
        render: ({value}) => {
            if (value) return euroFormat(value)
            return ""
        },
        filterEditor: NumberFilter,
        flex: 1,
        minWidth: 100,
    },
    {
        name: "Calculatie",
        header: "Calculatie",
        render: ({value}) => {
            if (value) return euroFormat(value)
            return ""
        },
        filterEditor: NumberFilter,
        flex: 1,
        minWidth: 100,
    },
    {
        name: "Geboekt",
        header: "Geboekt",
        render: ({value}) => {
            if (value) return euroFormat(value)
            return ""
        },
        filterEditor: NumberFilter,
        flex: 1,
        minWidth: 100,
    },
    {
        name: "Mutaties",
        header: "Mutaties",
        render: ({value, cellProps, data}) => {
            if (data.Mutaties && !data.old_mutation) cellProps.className = cellProps.className + " bg-danger text-light"
            return value
        },
        filterEditor: NumberFilter,
        flex: 1,
        minWidth: 100,
    },
    {
        name: "POC",
        header: "POC",
        render: ({value: POC, cellProps, data}) => {
            if (!parseFloat(data.Geboekt) || !parseFloat(data.Calculatie)) return ""
            if (POC < 90) {
                cellProps.className = cellProps.className + ""
            } else if (POC >= 90 && POC < 100) cellProps.className = cellProps.className + " bg-danger text-light"
            else cellProps.className = cellProps.className + " bg-success text-light"
            return `${POC.toFixed(0)}%`
        },
        flex: 1,
        minWidth: 100,
    },
    {
        name: "Marge",
        header: "Marge",
        render: ({value, data}) => {
            if (!parseFloat(data.Verkoop) || !parseFloat(data.Calculatie)) return ""
            return `${(value).toFixed(0)}%`
        },
        flex: 1,
        minWidth: 100,
    },
    {
        name: "Marge_Act",
        header: "Marge Act",
        render: ({value, data}) => {
            if (!parseFloat(data.Verkoop) || !parseFloat(data.Geboekt) || !value) return ""
            return `${(value).toFixed(0)}%`
        },
        flex: 1,
        minWidth: 100,
    },
    {
        name: "Gefactureerd",
        header: "Gefactureerd",
        render: ({value, cellProps, data}) => {
            if (!value) return ""
            if (data.Gefactureerd === data.Verkoop) cellProps.className = cellProps.className + " bg-success text-light"
            else if (data.Gefactureerd < data.Verkoop) cellProps.className = cellProps.className + ""
            else if (data.Gefactureerd > data.Verkoop) cellProps.className = cellProps.className + " bg-danger text-light"
            return euroFormat(value)
        },
        flex: 1,
        minWidth: 100,
    },
    {
        name: "Te_factureren",
        header: "Te fact.",
        render: ({value}) => {
            if (value) return euroFormat(value)
            return ""
        },
        filterEditor: NumberFilter,
        flex: 1,
        minWidth: 100,
    },
    {
        name: "Cost_overrun",
        header: "Cost overrun",
        render: ({value: overrun, cellProps, data}) => {
            if (!overrun) return ""
            if (overrun > 0 && !(!parseFloat(data.Geboekt) || !parseFloat(data.Calculatie))) cellProps.className = cellProps.className + " bg-danger text-light"
            if (!parseFloat(data.Geboekt) || !parseFloat(data.Calculatie)) return ""
            if (parseFloat(data.Geboekt) > parseFloat(data.Calculatie)) return euroFormat(overrun)
            return ""
        },
        flex: 1,
        minWidth: 100,
    },
];

/**
 * @typedef {Object} Column
 * @property {string} name - The name of the column
 * @property {string} header - The header text for the column
 * @property {function} render - The render function for the column values
 * @property {number} flex - The flex value for the column width
 * @property {Object} filterEditor - The filter editor for the column
 * @property {Object} filterEditorProps - The filter editor properties
 *
 * @typedef {Array<Column>} Columns
 *
 * Represents the columns configuration for the "NoPermissions" table.
 *
 * @type {Columns}
 */
const columnsNoPermissions = [
    {
        name: "Projectcode",
        header: "Projectcode",
        render: ({value, data}) => <NavLink to={`/project/${data.project_id}`}>{value}</NavLink>,
        flex: 1,
    },
    {
        name: "Projectnaam",
        header: "Projectnaam",
        flex: 1,
    },
    {
        name: "Projectleider",
        header: "Projectleider",
        flex: 1,
        filterEditor: SelectFilter,
        filterEditorProps: {
            placeholder: 'Alles',
            dataSource: [
                {
                    id: "Dick Bor",
                    label: "Dick Bor"
                },
                {
                    id: "Felicia Brosens",
                    label: "Felicia Brosens"
                },
                {
                    id: "John de Kroon",
                    label: "John de Kroon"
                },
                {
                    id: "Martijn Heerings",
                    label: "Martijn Heerings"
                },
                {
                    id: "Niels Leijen",
                    label: "Niels Leijen"
                },
                {
                    id: "Wilco Versteeg",
                    label: "Wilco Versteeg"
                },
            ]
        },
    },
];


/**
 * Retrieves and displays project overview table data.
 */
export default function ProjectOverviewTableHandler() {
    const {leader} = useParams()
    const [projects, setProjects] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [category, setCategory] = useState("active")
    const {permissions} = useContext(PermissionsContext)
    const {ExcelDownloder, setData} = useExcelDownloder();
    const [filterValue, setFilterValue] = useState(defaultFilterValue);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category]);

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let localFilters = JSON.parse(localStorage.getItem("projectFilters"))
        if (leader) {
            localFilters[2].value = leader
        }
        if (localFilters) {
            setFilterValue(localFilters)
            setDataSource(filter(projects, localFilters))
        } else {
            setDataSource(projects)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects]);

    const getData = async () => {
        const response = await projectsApi.getAllProjects(category);
        if (!response.ok) return notyf.error("Error " + response.status)
        const data = []
        for (let p of response.data) {
            const t = {
                project_id: p.project_id,
                Projectcode: p.project_code,
                Projectnaam: p.project_name,
                Projectleider: p.projects_employees_employee_name,
                Verkoop: parseFloat(p["project_contract_amount"] || 0),
                Calculatie: parseFloat(p["calc_cost_total"] || 0),
                Geboekt: parseFloat(p["booking_cost_total"] || 0),
                Mutaties: p.mutation_count,
                POC: (parseFloat(p.booking_cost_total) && parseFloat(p.calc_cost_total)) ? ((parseFloat(p.booking_cost_total) / parseFloat(p.calc_cost_total)) * 100) : null,
                Marge: (parseFloat(p.calc_cost_total) && parseFloat(p.project_contract_amount)) ? ((1 - (parseFloat(p.calc_cost_total) / parseFloat(p.project_contract_amount))) * 100) : null,
                Marge_Act: (parseFloat(p.booking_cost_total) && parseFloat(p.project_contract_amount)) ? ((1 - (p.booking_cost_total / p.project_contract_amount)) * 100) : null,
                Gefactureerd: parseFloat(p.billed_invoice_amount || 0),
                Te_factureren: parseFloat(p.unbilled_invoice_amount || 0),
                Cost_overrun: (parseFloat(p.booking_cost_total) && parseFloat(p.calc_cost_total)) ? parseFloat(p.booking_cost_total) - parseFloat(p.calc_cost_total) : null,
                old_mutation: p.old_mutation,
            }
            data.push(t)
        }
        setProjects(data)
    };

    const onFilterValueChange = useCallback((f) => {
        const data = filter(projects, f)
        setFilterValue(f);
        setDataSource(data);
        localStorage.setItem("projectFilters", JSON.stringify(f))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects, filterValue])


    useEffect(() => {
        setData({projecten: dataSource})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource])

    //laat tabel zien met gegevens via props
    if (projects.length > 0) return <Card>
        <Card.Header>
            <Card.Title tag="h5" className="mb-0 d-flex">
                <div className={"w-100 d-flex justify-content-between"}>
                    <h3 className={"mb-0"}>Projectresultaten</h3>

                    <div>
                        <Button variant={"info"} className={"me-1"}
                                onClick={() => onFilterValueChange(defaultFilterValue)}>Reset filters</Button>
                        <ButtonGroup className={"me-1"}>
                            <ToggleButton type="radio" checked={category === "active"} value={1}
                                          onClick={() => setCategory("active")}>Actief
                            </ToggleButton>
                            <ToggleButton type="radio" checked={category === "inactive"} value={2}
                                          onClick={() => setCategory("inactive")}>Inactief
                            </ToggleButton>
                            <ToggleButton type="radio" checked={category === ""} value={3}
                                          onClick={() => setCategory("")}>Alles
                            </ToggleButton>
                        </ButtonGroup>
                        <ExcelDownloder
                            data={dataSource}
                            filename={'projecten ' + moment().format("DD-MM-YYYY")}
                            type={"link"}
                        >
                            <Button className={"me-1 added-tiny-margin-top remove-margin-top-mob"}
                                    variant={"secondary"}>
                                <TableViewIcon fontSize={"small"}/>
                            </Button>
                        </ExcelDownloder>
                    </div>
                </div>
            </Card.Title>
        </Card.Header>
        <Card.Body>
            <ReactDataGridCustom
                idProperty="project_id"
                dataSource={dataSource}
                columns={permissions.includes("read_manager:projects") ? columns : columnsNoPermissions}
                pagination
                defaultLimit={50}
                filterValue={filterValue}
                onFilterValueChange={onFilterValueChange}
            />
        </Card.Body>
    </Card>;
    return <Loader/>
}
