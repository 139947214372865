import React, {useEffect, useMemo, useState} from "react";
import {Pie} from "react-chartjs-2";
import {Card, Col, Row} from "react-bootstrap";
import BarChartForPerson from "./BarChartForPerson";
import Loader from "../../../components/Loader";

/**
 * The PersonHandler component is responsible for handling the data and rendering visualizations for a specific person.
 *
 * @param {Object} param - The parameter object.
 * @param {string} param.user - The name of the person.
 * @param {Array} param.data - The data to be displayed.
 * @param {string} param.department - The department of the person.
 * @returns {JSX.Element} - The JSX element to be rendered.
 */
const PersonHandler = ({user, data, department}) => {
    const [loading, setLoading] = useState(true)
    const [actualArray, setActualArray] = useState([0, 0, 0, 0])
    let tempActualArray = [0, 0, 0, 0];


    useEffect(() => {
        setLoading(true);
        initialRun();
        setLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // this gets executed after updating the state


    // let actualUsedArray = dataSource.filter(item => item.productivity_department === department)
    // 1 eruit halen en via een promise optioneel inladen in render


    const initialRun = async () => {
        data.forEach(e => {
            //prod
            tempActualArray.splice(0, 1, tempActualArray[0] + parseInt(e.actual_productivity_hours));
            //niet prod
            tempActualArray.splice(1, 1, tempActualArray[1] + parseInt(e.actual_no_productivity_hours) + parseInt(e.actual_training_hours));
            //ziek
            tempActualArray.splice(2, 1, tempActualArray[2] + parseInt(e.actual_sick_hours));
            //vrij
            tempActualArray.splice(3, 1, tempActualArray[3] + parseInt(e.actual_holiday_hours));
        })
        setActualArray(tempActualArray);
    }

    const dataforpie = useMemo(() => {
        return {
            labels: ["Productief", "Niet Productief", "Ziek", "Vrij"],
            datasets: [
                {
                    data: actualArray,
                    backgroundColor: [
                        "#2ecc71",
                        "#3498db",
                        "#e74c3c",
                        "#f1c40f",
                    ],
                    borderColor: "transparent",
                },
            ],
        }
    }, [actualArray]);

    const optionsforpie = useMemo(() => {
        return {
            maintainAspectRatio: true,
            legend: {
                display: false,
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        let dataset = data.datasets[tooltipItem.datasetIndex];
                        let meta = dataset._meta[Object.keys(dataset._meta)[0]];
                        let total = meta.total;
                        let currentValue = dataset.data[tooltipItem.index];
                        let percentage = parseFloat((currentValue / total * 100).toFixed(1));
                        return currentValue + ' (' + percentage + '%)';
                    },
                    title: function (tooltipItem, data) {
                        return data.labels[tooltipItem[0].index];
                    }
                }
            }
        }
    }, []);


    if (loading) return <Card><Loader/></Card>
    return <>
        <Row className={"mb-5"}>
            <h2>{user}</h2>
            <Col xs={4}>
                <Pie data={dataforpie} options={optionsforpie}/>
            </Col>
            <Col xs={8}>
                <BarChartForPerson department={department}
                                   hoursType={"PRODUCTIEF"}
                                   persondata={data}
                                   user={user}
                                   actualColour={"#2ecc71"}
                                   limit={true}
                />
            </Col>

        </Row>
        <Row className={"mb-5"}>
            <Col xs={4}>
                <BarChartForPerson department={department}
                                   hoursType={"NIET PRODUCTIEF"}
                                   persondata={data}
                                   user={user}
                                   actualColour={"#3498db"}
                                   limit={true}
                />
            </Col>
            <Col xs={4}>
                <BarChartForPerson department={department}
                                   hoursType={"VRIJ"}
                                   persondata={data}
                                   user={user}
                                   actualColour={"#f1c40f"}
                                   limit={true}
                />
            </Col>
            <Col xs={4}>
                <BarChartForPerson department={department}
                                   hoursType={"ZIEK"}
                                   persondata={data}
                                   user={user}
                                   actualColour={"#e74c3c"}
                                   limit={true}
                />
            </Col>
        </Row>

    </>
}

export default PersonHandler;