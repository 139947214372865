import React, {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import {NavLink} from "react-router-dom";
import Loader from "../../../components/Loader";

/**
 * Renders a form containing budget function product and rates data.
 *
 * @param {object} options - The function options.
 * @param {object} options.data - The data object containing current year's productivity data.
 * @param {object} options.data2 - The data object containing previous year's productivity data.
 *
 * @return {JSX.Element} The rendered form component.
 */
function BudgetFunctionProdAndRatesForm({data, data2}) {
// TODO Hier zit een bug als je van 2 cols naar 3 cols gaat en dan weer terug naar de vergelijking dat ie dan een error geeft.
    const [columns, setColumns] = useState([
            {
                id: 0,
                name: "Titel",
                header: "Titel",
                render: ({value, data}) => <NavLink to={`/project/${data.project_id}`}>{value}</NavLink>,
                flex: 1,
                minWidth: 90,
            },
            {
                id: 1,
                name: "huidigjaar",
                header: `${data?.productivity_year_year}`,
                flex: 1,
                minWidth: 90,
            },
        ]
    )

    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        if (data && data2 && data !== data2) {
            setColumns([
                {
                    id: 0,
                    name: "Titel",
                    header: "Titel",
                    render: ({value}) => <>{value}</>,
                    flex: 1,
                    minWidth: 90,
                },
                {
                    id: 1,
                    name: "huidigjaar",
                    header: `${data?.productivity_year_year}`,
                    flex: 1,
                    minWidth: 90,
                },
                {
                    id: 2,
                    name: "vorigjaar",
                    header: `${data2?.productivity_year_year}`,
                    flex: 1,
                    minWidth: 90,
                },
            ])
            setDataSource([
                {
                    "id": 0,
                    "Titel": "prodPL",
                    "huidigjaar": data.productivity_year_prod_pl_percentage + "%",
                    "vorigjaar": data2.productivity_year_prod_pl_percentage + "%",
                },
                {
                    "id": 1,
                    "Titel": "ProdENG",
                    "huidigjaar": data.productivity_year_prod_eng_percentage + "%",
                    "vorigjaar": data2.productivity_year_prod_eng_percentage + "%",

                },
                {
                    "id": 1,
                    "Titel": "PL",
                    "huidigjaar": "€" + data.productivity_year_pl_rate,
                    "vorigjaar": "€" + data2.productivity_year_pl_rate,
                },
                {
                    "id": 1,
                    "Titel": "ENG",
                    "huidigjaar": "€" + data.productivity_year_eng_rate,
                    "vorigjaar": "€" + data2.productivity_year_eng_rate,
                },
                {
                    "id": 1,
                    "Titel": "IBS",
                    "huidigjaar": "€" + data.productivity_year_ibs_rate,
                    "vorigjaar": "€" + data2.productivity_year_ibs_rate,
                },
                {
                    "id": 1,
                    "Titel": "TEK",
                    "huidigjaar": "€" + data.productivity_year_tek_rate,
                    "vorigjaar": "€" + data2.productivity_year_tek_rate,
                },
                {
                    "id": 1,
                    "Titel": "WERKVB",
                    "huidigjaar": "€" + data.productivity_year_werkvb_rate,
                    "vorigjaar": "€" + data2.productivity_year_werkvb_rate,
                },
                {
                    "id": 1,
                    "Titel": "PAN",
                    "huidigjaar": "€" + data.productivity_year_pan_rate,
                    "vorigjaar": "€" + data2.productivity_year_pan_rate,
                },])
        } else if (data && data2) {
            setColumns([
                {
                    id: 0,
                    name: "Titel",
                    header: "Titel",
                    // render: ({value, data}) => <NavLink to={`/project/${data.project_id}`}>{value}</NavLink>,
                    flex: 1,
                    minWidth: 90,
                },
                {
                    id: 1,
                    name: "huidigjaar",
                    header: `${data?.productivity_year_year}`,
                    flex: 1,
                    minWidth: 90,
                },
            ])
            setDataSource([
                {
                    "id": 0,
                    "Titel": "prodPL",
                    "huidigjaar": data.productivity_year_prod_pl_percentage + "%",
                },
                {
                    "id": 1,
                    "Titel": "ProdENG",
                    "huidigjaar": data.productivity_year_prod_eng_percentage + "%",

                },
                {
                    "id": 1,
                    "Titel": "PL",
                    "huidigjaar": "€" + data.productivity_year_pl_rate,
                },
                {
                    "id": 1,
                    "Titel": "ENG",
                    "huidigjaar": "€" + data.productivity_year_eng_rate,
                },
                {
                    "id": 1,
                    "Titel": "IBS",
                    "huidigjaar": "€" + data.productivity_year_ibs_rate,
                },
                {
                    "id": 1,
                    "Titel": "TEK",
                    "huidigjaar": "€" + data.productivity_year_tek_rate,
                },
                {
                    "id": 1,
                    "Titel": "WERKVB",
                    "huidigjaar": "€" + data.productivity_year_werkvb_rate,
                },
                {
                    "id": 1,
                    "Titel": "PAN",
                    "huidigjaar": "€" + data.productivity_year_pan_rate,
                },])
        }
    }, [data, data2])


    if (data && data2 && dataSource)
    return (
        <React.Fragment>
            <Card>
                <Card.Header>
                    <Card.Title>Functie specifieke gegevens - INDICATIE</Card.Title>
                </Card.Header>
                <Card.Body>
                    <ReactDataGridCustom
                        idProperty="id"
                        dataSource={dataSource}
                        columns={columns}
                        // editable={true}
                        // editStartEvent={"click"}
                        // onEditComplete={onEditComplete}
                        // pagination
                        // defaultLimit={50}
                    />
                </Card.Body>
                <Card.Footer>
                </Card.Footer>
            </Card>
        </React.Fragment>
    );
    else return <Loader/>;
}

export default BudgetFunctionProdAndRatesForm;
