import React, {useEffect, useMemo, useState} from "react";
import {Card} from "react-bootstrap";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import {NavLink} from "react-router-dom";
import notyf from "../../../utils/notyfApi";
import adminBudgetApi from "../../../api/adminBudget";
import adminUsersApi from "../../../api/adminUsers";
import Loader from "../../../components/Loader";


/**
 * Generates data for a budget person data grid table.
 *
 * @param {number} year - The year for which the data is generated.
 * @returns {void}
 */
function BudgetPersonDataGridTable({year}) {
    const [allUserData, setAllUserData] = useState(null);
    const [allWeekstatenUsers, setAllWeekstatenUsers] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);


    const columns = useMemo(() => [
        {
            id: 0,
            name: "name",
            header: "Naam",
            render: ({value, data}) => <NavLink to={`/users/${data.user_id}`}>{value}</NavLink>,
            flex: 1,
            minWidth: 140,
        },
        {
            id: 1,
            name: "productivity_department",
            header: "Afdeling",
            flex: 1,
            minWidth: 100,
        },
        {
            id: 2,
            name: "productivity_fte",
            header: "FTEs",
            flex: 1,
            minWidth: 75,
        },
        {
            id: 3,
            name: "productivity_productive_percentage",
            header: "Productief",
            flex: 1,
            minWidth: 100,
        },
        {
            id: 4,
            name: "productivity_employment_months",
            header: "Dienstmaanden",
            flex: 1,
            minWidth: 90,
        },
        {
            id: 5,
            name: "jaaruren",
            header: "Jaaruren",
            render: ({
                         value,
                         data
                     }) => <> {data.productivity_fte ? Math.round((data.productivity_fte * 2080) * (data.productivity_employment_months / 12)) : ""} </>,
            flex: 1,
            minWidth: 90,
        },
        {
            id: 6,
            name: "prodbudget",
            header: "Productief budget",
            render: ({value, data}) => <> {data.productivity_fte ?
                Math.round(((data.productivity_fte * (data.productivity_productive_percentage / 100) * (data.productivity_year_productivity_percentage / 100) * 2080)
                    * (data.productivity_employment_months / 12)) - (data.productivity_detail_correction_sick_hours * (data.productivity_productive_percentage / 100))
                    - data.productivity_detail_old_age_free_hours) : ""}
            </>,
            flex: 1,
            minWidth: 130,
        },
        {
            id: 7,
            name: "prodpermaand",
            header: "Productief per maand",
            render: ({value, data}) => <> {data.productivity_fte ?
                Math.round((((data.productivity_fte * (data.productivity_productive_percentage / 100) * (data.productivity_year_productivity_percentage / 100) * 2080)
                    * (data.productivity_employment_months / 12)) - (data.productivity_detail_correction_sick_hours * (data.productivity_productive_percentage / 100))
                    - data.productivity_detail_old_age_free_hours) / 12) : ""}
            </>,
            flex: 1,
            minWidth: 130,
        },
        {
            id: 8,
            name: "indirectbudget",
            header: "Indirect budget",
            render: ({value, data}) => <> {data.productivity_fte ?
                Math.round(data.productivity_fte * (data.productivity_year_no_productivity_percentage / 100) * 2080 * (data.productivity_employment_months / 12)
                    + ((data.productivity_fte) * (1 - (data.productivity_productive_percentage / 100)) * (data.productivity_year_productivity_percentage / 100) * 2080)
                    * (data.productivity_employment_months / 12) - (data.productivity_detail_correction_sick_hours * (1 - (data.productivity_productive_percentage / 100)))) : ""}
                {/*(fte x percentage jaar indirect x 2080 x (maanden in dienst / 12)) + (fte x (100% - pers prod uren) x percentage jaar directe uren * 2080 *( maanden in dienst / 12))*/}
            </>,
            flex: 1,
            minWidth: 130,
        },
        {
            id: 9,
            name: "indirectpermaand",
            header: "Indirect per maand",
            render: ({value, data}) => <> {data.productivity_fte ?
                Math.round((data.productivity_fte * (data.productivity_year_no_productivity_percentage / 100) * 2080 * (data.productivity_employment_months / 12)
                    + ((data.productivity_fte) * (1 - (data.productivity_productive_percentage / 100)) * (data.productivity_year_productivity_percentage / 100) * 2080)
                    * (data.productivity_employment_months / 12) - (data.productivity_detail_correction_sick_hours * (1 - (data.productivity_productive_percentage / 100)))) / 12) : ""}
            </>,
            flex: 1,
            minWidth: 130,
        },
        {
            id: 10,
            name: "vrijbudget",
            header: "Vrij budget",
            render: ({value, data}) => <> {data.productivity_fte ?
                Math.round((data.productivity_fte * (data.productivity_year_holiday_percentage / 100) *
                    2080 * (data.productivity_employment_months / 12)) + data.productivity_detail_old_age_free_hours) : ""}

            </>,
            flex: 1,
            minWidth: 90,
        },
        {
            id: 11,
            name: "vrijpermaand",
            header: "Vrij per maand",
            render: ({value, data}) => <> {data.productivity_fte ?
                Math.round(((data.productivity_fte * (data.productivity_year_holiday_percentage / 100) *
                    2080 * (data.productivity_employment_months / 12)) + data.productivity_detail_old_age_free_hours) / 2) : ""}

            </>,
            flex: 1,
            minWidth: 90,
        },
        {
            id: 12,
            name: "ziekbudget",
            header: "Ziek budget",
            render: ({value, data}) => <>
                {data.productivity_fte ? Math.round(data.productivity_fte * (data.productivity_year_sick_days_percentage / 100) *
                    2080 * (data.productivity_employment_months / 12)  + data.productivity_detail_correction_sick_hours) : ""}
            </>,
            flex: 1,
            minWidth: 90,
        },
        {
            id: 13,
            name: "ziekpermaand",
            header: "Ziek per maand",
            render: ({value, data}) => <>{data.productivity_fte ?
                Math.round((data.productivity_fte * (data.productivity_year_sick_days_percentage / 100) *
                    2080 * (data.productivity_employment_months / 12) + data.productivity_detail_correction_sick_hours) / 12) : ""} </>,
            flex: 1,
            minWidth: 90,
        },
        {
            id: 14,
            name: "productivity_detail_rate",
            header: "tarief",
            render: ({value, data}) => <>{data.productivity_detail_rate ? "€" + value : ""} </>,
            flex: 1,
            minWidth: 90,
        },
        {
            id: 15,
            name: "dekking",
            header: "Dekking",
            render: ({value, data}) => <>{data.productivity_fte ? "€" +
                Math.round(((data.productivity_fte * (data.productivity_productive_percentage / 100) * (data.productivity_year_productivity_percentage / 100) * 2080)
                    - (data.productivity_detail_correction_sick_hours * (data.productivity_productive_percentage / 100)))
                    * (data.productivity_employment_months / 12)) * data.productivity_detail_rate : ""} </>,
            flex: 1,
            minWidth: 90,
        },
        {
            id: 16,
            name: "dekkingpermaand",
            header: "Dekking per maand",
            render: ({value, data}) => <>{data.productivity_fte ? "€" +
                Math.round(((((data.productivity_fte * (data.productivity_productive_percentage / 100) * (data.productivity_year_productivity_percentage / 100) * 2080)
                    - (data.productivity_detail_correction_sick_hours * (data.productivity_productive_percentage / 100)))
                    * (data.productivity_employment_months / 12)) * data.productivity_detail_rate) / 12) : ""} </>,
            flex: 1,
            minWidth: 90,
        },
    ], [])

    useEffect(() => {
        const timer = setTimeout(() => {
            getEverythingForBudgetRules();
            getAllWeekstatenUsers();
        });
        return () => {
            clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year]);

    useEffect(() => {
        if (allWeekstatenUsers && allUserData && year) {
            createCombined(); // only call func2 if the state is not undefined or null
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allUserData, year, allWeekstatenUsers]) // this gets executed after updating the state


    const getEverythingForBudgetRules = async () => {
        //dit is de data voor de accountgegevens
        const res = await adminBudgetApi.getEverythingForBudgetRules();
        if (!res.ok) return notyf.error("Error " + res.status)
        setAllUserData(res.data);
    };

    const getAllWeekstatenUsers = async () => {
        //dit is de data voor de accountgegevens
        const res = await adminUsersApi.getUsersByRole("Weekstaten");
        if (!res.ok) return notyf.error("Error " + res.status)
        setAllWeekstatenUsers(res.data);
    };


    const createCombined = async () => {
        setLoader(true);
        let selectedYear = year.productivity_year_id;
        const filteredyearUserdata = allUserData.filter(data => data.productivity_year_f_id === selectedYear);
        const userEntries = allWeekstatenUsers.users;
        //combine all existing data, extra data about the year, and the filtered year data into 1 big var. This can still be optimalised a lot.
        const combined = userEntries.map(u => {
            return {...u, ...filteredyearUserdata.filter(r => r.productivity_user_f_id === u.user_id)[0], ...year}
        })
        setDataSource(combined);
        setLoader(false);
    }

    if (!loader && year && dataSource)
        return (
            <React.Fragment>
                <Card>
                    <Card.Header>
                        <Card.Title>Budget overzicht - {year ? year?.productivity_year_year : ""}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <ReactDataGridCustom
                            idProperty="productivity_year_id"
                            dataSource={dataSource}
                            columns={columns}
                            pagination
                            defaultLimit={50}
                        />
                    </Card.Body>
                    <Card.Footer>
                    </Card.Footer>
                </Card>
            </React.Fragment>
        );
    else
        return <Loader/>;
}

export default BudgetPersonDataGridTable;
