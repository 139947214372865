import React from "react";
import {Dropdown} from "react-bootstrap";
import {LogOut, User} from "react-feather";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";

/**
 * Renders a logout button.
 *
 * @returns {React.Element} The logout button component.
 */
const LogoutButton = () => {
    const {logout, isAuthenticated} = useAuth0();

    return (
        isAuthenticated && (
            <Dropdown.Item
                onClick={() => logout({returnTo: window.location.origin})}
            > <LogOut size={18} className="align-middle me-2"/>
                Sign out
            </Dropdown.Item>
        )
    );
};

/**
 * Represents a Navbar User component.
 *
 * @returns {JSX.Element} The Navbar User component.
 */
const NavbarUser = () => {
    const {user, isAuthenticated} = useAuth0();

    const navigate = useNavigate();
    return (
        <>
            <Dropdown className="nav-item" align="end">
                {/*<span className="d-inline-block d-sm-none">*/}
                {/*  <Dropdown.Toggle as="a" className="nav-link">*/}
                {/*    <Settings size={18} className="align-middle"/>*/}
                {/*  </Dropdown.Toggle>*/}
                {/*</span>*/}
                {isAuthenticated && (
                    <span className="d-inline-block">
            <Dropdown.Toggle as="a" className="nav-link">
              <img
                  src={user.picture}
                  className="avatar img-fluid rounded-circle me-1"
                  alt={user.name}
              />
              <span className={"d-none d-sm-inline-block"}>{user.name} </span>
            </Dropdown.Toggle>
          </span>
                )}
                <Dropdown.Menu drop="end">
                    <Dropdown.Item user={user} onClick={() => navigate(`../userprofile`)}>
                        <User size={18} className="align-middle me-2"/>
                        Profile
                    </Dropdown.Item>
                    {/*<Dropdown.Item>Help</Dropdown.Item>*/}
                    <LogoutButton/>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default NavbarUser;
