import {configureStore} from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";

/**
 * Configures the store using the given reducer.
 *
 * @param {Object} options - The configuration options for the store.
 * @param {Function} options.reducer - The reducer function to be used for the store.
 * @returns {Object} - The configured store object.
 */
export const store = configureStore({
    reducer: {
        counter: counterReducer,
    },
});
