import React from "react";

import { LAYOUT } from "../constants";
import useSettingsState from "../hooks/useSettingsState";

/**
 * Represents the initial state of the application.
 *
 * @typedef {Object} InitialState
 * @property {LAYOUT} layout - The layout type for the application.
 */
const initialState = {
  layout: LAYOUT.FLUID,
};

/**
 * Context object for managing layout state in a React component.
 *
 * @class
 * @classdesc The `LayoutContext` provides a way to share layout state between components in a React application.
 * @param {Object} initialState - The initial state of the layout context.
 * @returns {Context} The layout context object.
 *
 * @example
 * import React from 'react';
 *
 * // Create a new layout context with an initial state
 * const LayoutContext = React.createContext(initialState);
 *
 * function App() {
 *   // Use the layout context in your component
 *   const layout = React.useContext(LayoutContext);
 *
 *   // Render your component and access layout state and methods
 *   return (
 *     <div>
 *       <h1>Layout Context Example</h1>
 *       <p>Window width: {layout.windowWidth}</p>
 *     </div>
 *   );
 * }
 *
 * export default App;
 */
const LayoutContext = React.createContext(initialState);

/**
 * Provides layout settings to child components.
 *
 * @param {Object} options - The options for the LayoutProvider.
 * @param {ReactNode} options.children - The child components to be rendered within the LayoutProvider.
 *
 * @return {JSX.Element} - The LayoutProvider component.
 */
function LayoutProvider({ children }) {
  const [layout, setLayout] = useSettingsState("layout", LAYOUT.FLUID);

  return (
    <LayoutContext.Provider
      value={{
        layout,
        setLayout,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export { LayoutProvider, LayoutContext };
