import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import React from "react";
import {NavLink} from "react-router-dom";

const {SearchBar} = Search;
/**
 * The columns variable defines an array of objects representing table columns.
 * Each object contains the following properties:
 *
 * @typedef {Object} Column
 * @property {string} dataField - The key used to retrieve the data for the column from each table row.
 * @property {string} text - The header text to be displayed for the column.
 * @property {function} formatter - An optional function used to format the content of the cell.
 *                                 The function takes two parameters: cell (the cell content) and row (the current row object).
 *                                 It should return the formatted content to be displayed in the cell.
 * @property {boolean} hidden - An optional boolean value indicating whether the column should be hidden or not.
 *
 * @type {Array<Column>}
 */
const columns = [
    {
        dataField: "product_order_id",
        text: "id",
        hidden: true,
    },
    {
        dataField: "product_order_reference",
        text: "Inkoopordernummer",
        formatter: (cell, row) => <NavLink to={`/order/${row.product_order_id}`}>{cell}</NavLink>
    },
    {
        dataField: "project_name",
        text: "Projectnaam",
        formatter: (cell, row) => <NavLink to={`/project/${row.project_id}`}>{cell}</NavLink>
    },
    {
        dataField: "user_name",
        text: "Inkoper",
    },
    {
        dataField: "product_order_order_date",
        text: "Besteldatum",
    },
    {
        dataField: "product_order_status",
        text: "Status",
        formatter: (cell) => {
            if (cell === "geleverd") return <span className="badge bg-success">Geleverd</span>;
            else if (cell === "retour") return <span className="badge bg-info">Retour</span>;
            else if (cell === "incompleet") return <span className="badge bg-danger">Incompleet</span>;
            else if (cell === "besteld") return <span className="badge bg-secondary">Besteld</span>;
        },
    },
];


/**
 * Creates a supplier detail order table.
 *
 * @param {Object} supplierOrder - The supplier order data.
 * @returns {JSX.Element} The JSX element representing the supplier detail order table.
 */
export default function SupplierDetailOrderTable({supplierOrder}) {
    return (
        <ToolkitProvider
            keyField="product_order_id"
            data={supplierOrder}
            columns={columns}
            search
        >
            {(props) => (
                <>
                    <div className={"w-100 d-flex justify-content-between"}>
                        <SearchBar
                            className={"form-control wn-100 mb-3"}
                            placeholder={"Filter: Typ om te zoeken"}
                            srText={null}
                            {...props.searchProps}
                        />
                    </div>
                    <BootstrapTable
                        {...props.baseProps}
                        pagination={paginationFactory()}
                    />
                </>
            )}
        </ToolkitProvider>
    );
}

