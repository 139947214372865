import React from "react";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import {NavLink} from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {euroFormat} from "../../../../utils/usefullFunctions";

const {SearchBar} = Search;
/**
 * Represents a list of columns for a table.
 *
 * @typedef {Object} Column
 * @property {string} dataField - The name of the data field corresponding to the column.
 * @property {string} text - The display text of the column.
 * @property {Function} [formatter] - A function used to format the column value.
 * @property {boolean} [hidden] - Indicates whether the column should be hidden.
 * @property {boolean} [sort] - Indicates whether the column should be sortable.
 * @property {string} [classes] - Additional CSS classes to be applied to the column.
 */
const columns = [
    {
        dataField: "projects_naw_id",
        text: "id",
        hidden: true,
    },
    {
        dataField: "projects_naw_company_name",
        text: "Bedrijf",
        formatter: (c, r) => <NavLink to={`/debtor/${r.projects_naw_id}`}>{c}</NavLink>,
        sort: true,
    },
    {
        dataField: "projects_naw_credit",
        text: "credit",
        formatter: c => euroFormat(c),
        sort: true,
    },
    {
        dataField: "sum_outstanding_amount",
        text: "Openstaand",
        formatter: c => euroFormat(c),
        sort: true,
    },
    {
        dataField: "exceedance",
        text: "Niet Verzekerd",
        formatter: c => euroFormat(c),
        classes: 'makeStyleRed',
        sort: true,
    },

];
/**
 * Represents a BlacklistTable component.
 *
 * @param {Object} data - The data used for rendering the table.
 * @returns {JSX.Element} - The rendered BlacklistTable component.
 */
const BlacklistTable = ({data}) => {
    return <>
        <ToolkitProvider
            keyField="projects_naw_id"
            data={data}
            columns={columns}
            search
        >
            {(props) => (
                <>
                    <div className={"w-100 d-flex justify-content-between"}>
                        <SearchBar
                            className={"form-control wn-100"}
                            placeholder={"Filter: Typ om te zoeken"}
                            srText={null}
                            {...props.searchProps}
                        />
                    </div>
                    <hr/>
                    <BootstrapTable
                        {...props.baseProps}
                        pagination={paginationFactory()}
                    />
                </>
            )}
        </ToolkitProvider>
    </>
}

export default BlacklistTable