import ReactDataGrid from "@inovua/reactdatagrid-community";
import {i18nTable, THEME} from "../../constants";
import React from "react";
import useTheme from "../../hooks/useTheme";

/**
 * A custom wrapper component for ReactDataGrid.
 * @param {Object} otherProps - Additional props for ReactDataGrid.
 * @returns {JSX.Element} - The rendered ReactDataGrid component.
 */
const ReactDataGridCustom = ({...otherProps}) => {
    const {theme} = useTheme();

    return <ReactDataGrid
        style={{minHeight: "65vh"}}
        i18n={i18nTable}
        theme={theme === THEME.DARK ? "default-dark" : "default-light"}
        emptyText={"Geen data beschikbaar verwijder of reset je filters"}
        {...otherProps}
    />
}

export default ReactDataGridCustom
