import React from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import WeeklyReportContentHandler from "../../components/algemeen/WeeklyReportContentHandler";

// Weekstaten pagina
const Weeklyreport = () => (
    <React.Fragment>
        <Helmet title="Weekstaten"/>
        <Container fluid className="p-0">
            <WeeklyReportContentHandler/>
        </Container>
    </React.Fragment>
);

export default Weeklyreport;
