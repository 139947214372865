import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import React, {useMemo, useState} from "react";
import {euroFormat} from "../../../utils/usefullFunctions";
import ProductOrdersDetailModel from "../producten_inkoop/product_order_details/ProductOrdersDetailModal";
import {uid} from "uid";
import {Trash} from "react-feather";
import notyfApi from "../../../utils/notyfApi";
import productsApi from "../../../api/products";
import {Badge} from "react-bootstrap";

/**
 * Renders a table displaying the order details.
 *
 * @param {Object} options - The options object.
 * @param {Object} options.order - The order object.
 * @param {Array} options.list - The list of order details.
 * @param {function} options.reload - The reload function.
 *
 * @returns {JSX.Element} The rendered table component.
 */
function OrderDetailOrderTable({order, list, reload}) {
    const {SearchBar} = Search;
    const listTotal = useMemo(() => {
        if (list.length) return [...list, {
            product_order_detail_id: uid(),
            product_name: "Totaal",
            product_order_detail_price_total: list.reduce(function (previousValue, currentValue) {
                return previousValue + parseFloat(currentValue.product_order_detail_price_total)
            }, 0)
        }]
        else return []
    }, [list])

    const deleteOrder = async (id) => {
        const res = await productsApi.deleteProductOrderDetail(id)
        if (!res.ok) return notyfApi("Error " + res.status)
        reload()
    }
    const [columns] = useState(
        [
            {
                dataField: "product_order_detail_id",
                hidden: true
            },
            {
                dataField: "product_name",
                text: "Product",
                formatter: (c, r) => {
                    if (c === "Totaal") return <strong>{c}</strong>
                    if (c === "DIV") return <ProductOrdersDetailModel order={order} orderDetails={r}
                                                                      reload={reload}>{r.product_order_detail_comment || r.product_description}</ProductOrdersDetailModel>
                    return <ProductOrdersDetailModel order={order} orderDetails={r}
                                                     reload={reload}>{c}</ProductOrdersDetailModel>
                },
                sort: true,
            },
            {
                dataField: "product_order_detail_price_per_unit",
                text: "Prijs",
                formatter: c => c ? euroFormat(c) : null,
                sort: true,
            },
            {
                dataField: "product_order_detail_amount",
                text: "Aantal",
                sort: true,
            },
            {
                dataField: "product_order_detail_price_total",
                text: "Totaal",
                formatter: c => euroFormat(c),
                sort: true,
            },
            {
                dataField: "product_order_detail_status",
                text: "Status",
                sort: true,
                formatter: c => {
                    if(c === "besteld") return <Badge className={"me-1"} bg={"info"}>Besteld</Badge>
                    if(c === "retour") return <Badge className={"me-1"} bg={"danger"}>Retrour</Badge>
                    if(c === "geleverd") return <Badge className={"me-1"} bg={"success"}>Geleverd</Badge>
                },
            },
            {
                dataField: "actions",
                text: "",
                formatter: (_c, r) => {
                    if (r.product_name !== "Totaal") return <Trash className={"cursor-pointer"} onClick={() => deleteOrder(r.product_order_detail_id)}/>
                }
            },
        ]
    )
    return (
        <>
            <ToolkitProvider
                keyField="product_order_detail_id"
                data={listTotal}
                columns={columns}
                search
            >
                {(props) => (
                    <div>
                        <SearchBar srText={null}
                                   {...props.searchProps}
                                   placeholder={"Filter: Typ om te zoeken"}/>
                        <hr/>
                        <BootstrapTable
                            {...props.baseProps}
                            pagination={paginationFactory()}
                        />
                    </div>
                )}
            </ToolkitProvider>
        </>
    );
}

export default OrderDetailOrderTable;
