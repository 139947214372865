import React from "react";
import { Helmet } from "react-helmet-async";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Col, Container, Row } from "react-bootstrap";
import XconnectWebshopTableHandler from "../../components/verkoop/xconnectwebshop/xconnectWebshopTableHandler";

/**
 * Represents a Shopify Webshop X-connect.
 *
 * @returns {React.Fragment} - The webshop component.
 *
 * @example
 *  <XconnectWebshop />
 */
const XconnectWebshop = () => (
  <React.Fragment>
    <Helmet title="Shopify Webshop X-connect" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Shopify Webshop X-connect</h1>
      <Row>
        <Col xl="12">
            <XconnectWebshopTableHandler />
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default XconnectWebshop;
