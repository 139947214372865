import React, {useEffect, useState} from "react";
import {Badge, Col, Row, Table} from "react-bootstrap";
import WeeklyReportDayItemModal from "./WeeklyReportDayItemModal";
import moment from "moment"
import {Close, HourglassBottom} from "@mui/icons-material";
import weekstatenApi from "../../../api/weeklyReports";

/**
 * Represents a weekly report day item.
 *
 * @param {Object} props - The props object containing the following properties:
 *   @param {boolean} props.completed - Indicates if the day item is completed.
 *   @param {Array} props.weekstaat - An array of weekstaat objects.
 *   @param {Date} props.date - The date of the day item.
 *   @param {function} props.reload - A function to reload the data.
 *   @param {Object} props.userTimesheetPermissions - The user's timesheet permissions.
 *
 * @return {JSX.Element} The rendered WeeklyReportDayItem component.
 */
function WeeklyReportDayItem({completed, weekstaat, date, reload, userTimesheetPermissions}) {
    const [total, setTotal] = useState(0)
    useEffect(() => {
        // Set the start and end date when the weekstaat is changed and calculate the new total
        if (weekstaat.length) {
            let tempTotal = 0
            for (let item of weekstaat) {
                let begin = moment(item.timesheet_start_time, "HH:mm:ss")
                let end = moment(item.timesheet_end_time, "HH:mm:ss")
                tempTotal += end.diff(begin, "hours", true)
            }
            setTotal(tempTotal)
        } else setTotal(0)
    }, [weekstaat])

    const deleteItem = async (id) => {
        // Handle delete of a booking
        const res = await weekstatenApi.deleteWeekstaatItem(id)
        if (res.ok) reload()
    }

    return (
        <>
            <Row style={{border: "1px solid #ddd"}}>
                <Col xl="12" className={"weekstatenTableHeader"}>
                    <details open={weekstaat.length}>
                        <summary>
                            <h4 className={"m-3 d-flex justify-content-between align-items-center"}>
                                {moment(date).format("dddd D MMMM YYYY")}
                                <span className={"d-flex justify-content-between align-items-center"}>
                                    {!completed && <WeeklyReportDayItemModal date={date}
                                                                             userTimesheetPermissions={userTimesheetPermissions}
                                                                             reload={reload}
                                                                             curStartHour={moment(weekstaat?.at(-1)?.timesheet_end_time || "08:30:00", "HH:mm:ss")}
                                                                             exists={false}
                                    />}
                                    <Badge bg={"info"} style={{minWidth: 90, minHeight: 34.7}} className={"m-2 p-1"}>
                                        {total} uur <HourglassBottom className={"ms-1"}/>
                                    </Badge>
                                </span>
                            </h4>
                        </summary>
                        <Row>
                            <Col className={"weekstatenTable"}>
                                <Table width={"100%"}>
                                    <thead>
                                    <tr>
                                        <th className={"col-lg-3"}>Project</th>
                                        <th className={"col-lg-1"}>Onderdeel</th>
                                        <th className={"col-lg-1"}>Code</th>
                                        <th className={"col-lg-1"}>Begintijd</th>
                                        <th className={"col-lg-1"}>Eindtijd</th>
                                        <th className={"col-lg-4"}>Toelichting</th>
                                        <th className={"col-lg-1"}></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {weekstaat.map((item) => (
                                        <tr key={item.timesheet_id}>
                                            <td className={"col-lg-3"}>
                                                {item.project_code} {item.project_name}
                                            </td>
                                            <td className={"col-lg-1"}>{item.timesheet_section}</td>
                                            <td className={"col-lg-1"}>{item.timesheet_code}</td>
                                            <td className={"col-lg-1"}>{moment(item.timesheet_start_time, "HH:mm:ss").format("H:mm")}</td>
                                            <td className={"col-lg-1"}>{moment(item.timesheet_end_time, "HH:mm:ss").format("H:mm")}</td>
                                            <td className={"col-lg-4"}>
                                                {item.timesheet_comment}
                                            </td>
                                            {!completed ?
                                                <td className={"col-lg-1 sm0ll-mobile-fix"}>
                                                    <WeeklyReportDayItemModal date={date}
                                                                              reload={reload}

                                                                              userTimesheetPermissions={userTimesheetPermissions}
                                                                              curStartHour={moment(weekstaat?.at(-1)?.timesheet_end_time || "08:30:00", "HH:mm:ss")}
                                                                              exists={item}
                                                    />
                                                    <Close onClick={() => deleteItem(item.timesheet_id)}
                                                           className={"cursor-pointer"}/></td> :
                                                <td className={"col-lg-1"}></td>}
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </details>
                </Col>
                <Col xl="2">
                </Col>
            </Row>
        </>
    );
}

export default WeeklyReportDayItem;
