import React, {useEffect, useState} from "react";
import watchdogApi from "../../../api/service_watchdog";
import WatchdogTable from "./WatchdogTable";
import Loader from "../../../components/Loader";
import notyf from "../../../utils/notyfApi";

/**
 * The WatchdogTableHandler function retrieves data from an API and displays it in a table.
 *
 * @returns The WatchdogTable component with the retrieved data as a data source.
 */
export default function WatchdogTableHandler() {
    //get data from api
    const [watchdog, setWatchdog] = useState([]);
        const [loading, setLoading] = useState(true);


    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        const response = await watchdogApi.getAllWatchdogs();
        if (!response.ok) return notyf.error("Error " + response.status)
        setWatchdog({watchdog: response.data});
                setLoading(false)
    };

    //laat tabel zien met gegevens via props
    if (loading) return  <Loader/>
    return <WatchdogTable dataSource={watchdog} reload={getData}/>;
}
