import client from "./clients/portalClient";

/**
 *
 */
const getAllUsers = () => client.get(`/users`);
/**
 * Retrieves a single user from the server.
 *
 * @param {number} id - The ID of the user to retrieve.
 * @returns {Promise} A Promise that resolves to the user data.
 */
const getSingleUser = (id) => client.get(`/users/${id}`);
// de ID is "auth0|1", "auth0|29" etc
/**
 *
 */
const editSingleUser = (id, data) => client.post(`/users`, id, data);
/**
 *
 */
const editSingleUserPerms = (id, data) => client.put(`/users/${id}`, data);
/**
 * Sends a POST request to create a new user.
 *
 * @param {object} data - The user data to be sent in the request's body.
 * @returns {Promise} A Promise that resolves to the response of the POST request.
 */
const postNewUser = (data) => client.post(`/users`, data);
/**
 * Get users by role.
 *
 * @param {string} role - The role of the users to retrieve.
 * @returns {Promise} A Promise that resolves to the result of the API call.
 *
 * @throws {Error} If the role parameter is not a string.
 *
 * @example
 * // Usage:
 * getUsersByRole("admin").then((result) => {
 *   console.log(result); // Handle the result
 * }).catch((error) => {
 *   console.error(error); // Handle any errors
 * });
 */
const getUsersByRole = (role) => client.get(`/users?role=${role}`);
/**
 * Retrieves the weekly state from the timesheet dashboard.
 *
 * @function getWeekstaten
 * @returns {Promise} A promise that resolves with the weekly state data.
 */
const getWeekstaten = () => client.get("/timesheet/dashboard")



/**
 * Retrieves the budget year data for a specific user.
 *
 * @param {string} id - The user ID for which to retrieve the budget year data.
 * @returns {Promise} A Promise representing the asynchronous request for the budget year data.
 */
const getUserBudgetYearData = (id) => client.get(`/users/${id}/productivity`);
/**
 * Posts user budget data to the server.
 *
 * @param {object} data - The user budget data to be posted.
 * @returns {Promise} A Promise that resolves when the data is successfully posted.
 */
const postUserBudgetData = (data) => client.post(`/productivity_details`, data);
/**
 * Deletes the productivity details for a specific year based on the provided ID.
 *
 * @param {string} id - The ID of the productivity details to be deleted.
 * @returns {Promise} A Promise that resolves to the result of the deletion request.
 */
const deleteUserEditYearData = (id) => client.delete(`/productivity_details/${id}`);
/**
 * Updates the productivity details for a user by ID.
 *
 * @param {string} id - The ID of the user.
 * @param {object} data - The data to update the productivity details.
 * @returns {Promise} - A Promise that resolves when the productivity details are successfully updated.
 */
const editUserEditYearData = (id, data) => client.put(`/productivity_details/${id}`, data);

/**
 * An object that provides access to various admin users API endpoints.
 *
 * @typedef {Object} adminUsersApi
 * @property {Function} getAllUsers - Fetches all users.
 * @property {Function} getSingleUser - Fetches a single user by ID.
 * @property {Function} editSingleUser - Edits a single user.
 * @property {Function} editSingleUserPerms - Edits permissions for a single user.
 * @property {Function} postNewUser - Creates a new user.
 * @property {Function} getUsersByRole - Fetches users by role.
 * @property {Function} getWeekstaten - Fetches the weekstate for users.
 * @property {Function} postUserBudgetData - Posts budget data for a user.
 * @property {Function} getUserBudgetYearData - Fetches budget year data for a user.
 * @property {Function} deleteUserEditYearData - Deletes edit year data for a user.
 * @property {Function} editUserEditYearData - Edits edit year data for a user.
 */
const adminUsersApi = {
  getAllUsers,
  getSingleUser,
  editSingleUser,
  editSingleUserPerms,
  postNewUser,
  getUsersByRole,
  getWeekstaten,
  postUserBudgetData,
  getUserBudgetYearData,
  deleteUserEditYearData,
  editUserEditYearData
};

export default adminUsersApi;
