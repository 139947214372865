import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import notyf from "../../../utils/notyfApi";
import {Trash} from "react-feather";
import adminUsersApi from "../../../api/adminUsers";

/**
 * Deletes a user admin details for a specific year.
 *
 * @param {Object} options The options for deleting the user admin details.
 * @param {string} options.id The ID of the user.
 * @param {Object} options.data The data for the year to be deleted.
 * @param {boolean} options.reload Flag indicating if the data should be reloaded after deletion.
 *
 * @return {JSX.Element} The JSX element representing the user admin details delete year component.
 */
function UserAdminDetailsDeleteYear({id, data, reload}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDelete = async () => {
        const res = await adminUsersApi.deleteUserEditYearData(id)
        if (!res.ok) return notyf.error("Error " + res.status)
        reload()
        handleClose()
    }


    return (
        <>
            <Trash className={"me-2 ms-2 float-end mb-0 cursor-pointer"} onClick={handleShow}/>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> Verwijder jaarregel </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Weet je zeker dat je {data?.productivity_year_year} wilt verwijderen?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDelete}>
                        Verwijderen
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Behouden
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UserAdminDetailsDeleteYear;
