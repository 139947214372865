import React, {useContext} from "react";
import {Helmet} from "react-helmet-async";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {Badge, Card, Col, Container, Row} from "react-bootstrap";
import Settings from "../../../components/Settings";
import {Briefcase, MapPin} from "react-feather";
import {Link} from "react-router-dom";
import PermissionsContext from "../../../utils/context";
import {useAuth0} from "@auth0/auth0-react";
import moment from "moment";

const ProfileDetails = () => {
    const {permissions} = useContext(PermissionsContext);
    const {user} = useAuth0();
    const roles = () => {
        let roleArray = [];
        for (let i = 0; i < permissions.length; i++) {
            let badgecontent;
            switch (permissions[i]) {
                case "admin:admin":
                    badgecontent = "Generieke admin";
                    break;
                case "admin:timesheets":
                    badgecontent = "Weekstaten admin";
                    break;
                case "graph:dashboard":
                    badgecontent = "Dashboard grafiek inzage";
                    break;
                case "read:administration_dashboard":
                    badgecontent = "Administratie dashboard lezen";
                    break;
                case "read:cet":
                    badgecontent = "CET lezen";
                    break;
                case "read:debtors":
                    badgecontent = "Debiteuren lezen";
                    break;
                case "read:invoices":
                    badgecontent = "Facturen lezen";
                    break;
                case "read_manager:projects":
                    badgecontent = "Projectleider lezen";
                    break;
                case "read:naw":
                    badgecontent = "NAW lezen";
                    break;
                case "read:offers":
                    badgecontent = "Offertes lezen";
                    break;
                case "read:products":
                    badgecontent = "Producten lezen";
                    break;
                case "read:projects":
                    badgecontent = "Projecten lezen";
                    break;
                case "read:service":
                    badgecontent = "Service lezen";
                    break;
                case "read:service_hosting":
                    badgecontent = "Hosting lezen";
                    break;
                case "read:service_matrix":
                    badgecontent = "Servicematrix lezen";
                    break;
                case "read:tickets":
                    badgecontent = "Tickets lezen";
                    break;
                case "read:users":
                    badgecontent = "Gebruikers lezen";
                    break;
                case "read:watchdog":
                    badgecontent = "Watchdog lezen";
                    break;
                case "user:timesheets":
                    badgecontent = "Weekstaten gebruiker";
                    break;
                case "write:cet":
                    badgecontent = "CET schrijven";
                    break;
                case "write:invoices":
                    badgecontent = "Facturen schrijven";
                    break;
                case "write_manager:projects":
                    badgecontent = "Projectleider schrijven";
                    break;
                case "write:naw":
                    badgecontent = "NAW schrijven";
                    break;
                case "write:offers":
                    badgecontent = "Offertes schrijven";
                    break;
                case "write:products":
                    badgecontent = "Producten schrijven";
                    break;
                case "write:projects":
                    badgecontent = "Projecten schrijven";
                    break;
                case "write:service":
                    badgecontent = "Service schrijven";
                    break;
                case "write:templates":
                    badgecontent = "Templates schrijven";
                    break;
                case "write:tickets":
                    badgecontent = "Tickets schrijven";
                    break;
                case "write:watchdog":
                    badgecontent = "Watchdog schrijven";
                    break;
                default:
                    badgecontent = "Nieuwe rol @userprofile"
                    break;
            }
            roleArray.push(<Badge bg="primary" className="me-2 my-1" key={i}>{badgecontent}</Badge>);
        }
        return roleArray;
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                    Profiel Details
                </Card.Title>
            </Card.Header>
            <Card.Body className="text-center">
                <img
                    src={user.picture}
                    alt={user.name}
                    className="img-fluid rounded-circle mb-2"
                    width="128"
                    height="128"
                />
                <Card.Title tag="h5" className="mb-0">
                    {user.name}
                </Card.Title>
                <div className="text-muted mb-2">{user.email}</div>

                <div>
                    <Settings/>
                </div>
            </Card.Body>
            <hr className="my-0"/>
            <Card.Body>
                <Card.Title tag="h5">Rollen</Card.Title>
                {roles()}
            </Card.Body>
            <hr className="my-0"/>
            <Card.Body>
                <Card.Title tag="h5">About</Card.Title>
                <ul className="list-unstyled mb-0">
                    <li className="mb-1">
                        <Briefcase width={14} height={14} className="me-1"/> Persoonlijke ID:{" "}
                        <Link to="/">{user.sub.slice(6)}</Link>
                    </li>
                    <li className="mb-1">
                        <MapPin width={14} height={14} className="me-1"/> Laatst geüpdatet: {" "}
                        <Link to="/">{moment(user.updated_at).format("HH:mm op DD/MM/YY")}</Link>
                    </li>
                </ul>
            </Card.Body>
            <hr className="my-0"/>
        </Card>
    );
}

const userProfile = () => (
    <React.Fragment>
        <Helmet title="Gebruikersinstellingen"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Gebruikersinstellingen</h1>
            <Row>
                <Col xl="4">
                    <ProfileDetails/>
                </Col>
                <Col xl="8">
                    <Card>
                        <Card.Header>
                            <Card.Title tag="h5" className="mb-0">
                                Werkzaamheden worden hier nog uitgevoerd.
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <p>Verzoeken voor specifieke profiel info zijn welkom.</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </React.Fragment>
);

export default userProfile;
