import React from "react";
import {Helmet} from "react-helmet-async";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {Container} from "react-bootstrap";
import NawContactsTableHandler from "../../components/algemeen/NawContactsTableHandler";

// NAW contacten table
const NawContacts = () => (
    <React.Fragment>
        <Helmet title="NAW contactpersonen"/>
        <Container fluid className="p-0">
            <NawContactsTableHandler/>
        </Container>
    </React.Fragment>
);

export default NawContacts;
