import React from "react";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import {NavLink} from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {euroFormat} from "../../../../utils/usefullFunctions";

const {SearchBar} = Search;
/**
 * Represents the columns configuration for a table.
 * Each column object in the array should have the following properties:
 *
 * @property {string} dataField - The field name of the data to be displayed in the column.
 * @property {string} text - The header text of the column.
 * @property {function} [formatter] - A formatter function to be applied to the cell value.
 * @property {boolean} [hidden] - Specifies if the column should be hidden in the table.
 * @property {string} [classes] - CSS classes to be applied to the column.
 * @property {boolean} [sort] - Specifies if the column should be sortable.
 */
const columns = [
    {
        dataField: "projects_naw_id",
        text: "id",
        hidden: true,
    },
    {
        dataField: "projects_naw_company_name",
        text: "Bedrijf",
        formatter: (c, r) => <NavLink to={`/debtor/${r.projects_naw_id}`}>{c}</NavLink>,
        sort: true,
    },
    {
        dataField: "sum_outstanding_amount",
        text: "Openstaand",
        formatter: c => euroFormat(c),
        sort: true,
    },
    {
        dataField: "sum_overdue_amount",
        text: "Over tijd",
        formatter: c => euroFormat(c),
        classes: 'makeStyleRed',
        sort: true,
    },

];

/**
 * A component to display an openstaand table.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.data - The data to be displayed in the table.
 *
 * @returns {JSX.Element} The rendered component.
 */
const OpenstaandTable = ({data}) => {
    return <>
        <ToolkitProvider
            keyField="projects_naw_id"
            data={data}
            columns={columns}
            search
        >
            {(props) => (
                <>
                    <div className={"w-100 d-flex justify-content-between"}>
                        <SearchBar
                            className={"form-control wn-100"}
                            placeholder={"Filter: Typ om te zoeken"}
                            srText={null}
                            {...props.searchProps}
                        />
                    </div>
                    <hr/>
                    <BootstrapTable
                        {...props.baseProps}
                        pagination={paginationFactory()}
                    />
                </>
            )}
        </ToolkitProvider>
    </>
}

export default OpenstaandTable