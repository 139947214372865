import client from "./clients/portalClient";

/**
 * Retrieves all service hosting data from the client.
 *
 * @returns {Promise} A promise that resolves with the service hosting data.
 */
const getAllServiceHosting = () => client.get(`/service_hosting`);

/**
 * Represents a service hosting API.
 * @namespace
 */
const serviceHostingApi = {
  getAllServiceHosting,
};

export default serviceHostingApi;
