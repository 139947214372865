import React from "react";
import {useFormikContext} from "formik";

import ErrorMessage from "./ErrorMessage";
import {Form} from "react-bootstrap";

/**
 * Render a checkbox input component for a form.
 *
 * @param {Object} props - The properties to configure the checkbox input.
 * @param {string} props.name - The name of the checkbox input field.
 * @param {boolean} props.display - Whether to display the checkbox or not (conditional rendering).
 * @param {string} props.label - The label text for the checkbox input.
 * @returns {React.Component} - The rendered checkbox component.
 */
function AppFormCheckbox({name, display, label}) {
    const {setFieldTouched, setFieldValue, errors, touched, values} = useFormikContext();
    if (display) {
        if (values[display.name] !== display.value) return <></>
    }

    return (
        <>
            <Form.Check className={"d-flex align-items-center "}>
                <Form.Check.Input className={"big-checkbox"} type={"checkbox"}
                                  label={label}
                                  checked={values[name]}
                                  onBlur={() => setFieldTouched(name)}
                                  onChange={() => setFieldValue(name, !values[name])}/>
                <Form.Check.Label>{label}</Form.Check.Label>

            </Form.Check>
            <ErrorMessage error={errors[name]} visible={touched[name]}/>
        </>
    );
}

export default AppFormCheckbox;
