import React, {useEffect, useRef, useState} from "react";
import {useFormikContext} from "formik";
import ErrorMessage from "./ErrorMessage";
import {Col, Form, Row} from "react-bootstrap";

/**
 * The API key for accessing Google APIs.
 * This variable is typically stored in an environment variable named REACT_APP_GOOGLE_API_KEY.
 *
 * @type {string}
 */
const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
/**
 * The URL for the Google Maps API JavaScript file.
 *
 * @constant
 * @type {string}
 */
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
/**
 * Load an external JavaScript file asynchronously.
 *
 * @param {string} src - The source URL of the JavaScript file to load.
 * @returns {Promise} - A promise that resolves with the loaded script element.
 */
function loadAsyncScript(src) {
    return new Promise(resolve => {
        const script = document.createElement("script");
        Object.assign(script, {
            type: "text/javascript",
            async: true,
            src
        })
        script.addEventListener("load", () => resolve(script));
        document.head.appendChild(script);
    })
}

/**
 * Extracts address information from a place object.
 *
 * @param {object} place - The place object to extract address from.
 * @returns {object} - The extracted address object.
 */
const extractAddress = (place) => {
    const address = {
        number: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        plain() {
            const number = this.number ? this.number + ", " : "";
            const street = this.address ? this.street + ", " : "";
            const city = this.city ? this.city + ", " : "";
            const zip = this.zip ? this.zip + ", " : "";
            const state = this.state ? this.state + ", " : "";
            return street + number + city + zip + state + this.country;
        }
    }

    if (!Array.isArray(place?.address_components)) {
        return address;
    }

    place.address_components.forEach(component => {
        const types = component.types;
        const value = component.long_name;
        const short = component.short_name;
        if (types.includes("street_number")) {
            address.number = value;
        }
        if (types.includes("route")) {
            address.street = value;
        }
        if (types.includes("locality")) {
            address.city = value;
        }
        if (types.includes("administrative_area_level_1")) {
            address.state = short;
        }
        if (types.includes("postal_code")) {
            address.zip = value;
        }
        if (types.includes("country")) {
            address.country = short;
        }
    });
    return address;
}

/**
 * Updates the address field based on user input and sets the corresponding formik values.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.names - An object containing the names of the formik values for each address field.
 * @param {string} props.label - The label for the address input field.
 * @returns {JSX.Element} - The rendered AppFormFieldAddress component.
 */
function AppFormFieldAddress({names, label,}) {
    const {setFieldTouched, setFieldValue, errors, touched, values} = useFormikContext();
    const searchInput = useRef(null);
    const [address, setAddress] = useState({
        number: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "",
    });
    const [firstRun, setFirstRun] = useState(true)

    // init gmap script
    const initMapScript = () => {
        // if script already loaded
        if (window.google) {
            return Promise.resolve();
        }
        const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
        return loadAsyncScript(src);
    }

    // do something on address change
    const onChangeAddress = (autocomplete) => {
        const place = autocomplete.getPlace();
        setAddress(extractAddress(place));
    }

    // init autocomplete
    const initAutocomplete = () => {
        if (!searchInput.current) return;
        const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
        autocomplete.setFields(["address_component", "geometry"]);
        autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));
    }
    useEffect(() => {
        if (firstRun) setFirstRun(false)
        else {
            for (let x of Object.keys(names)) {
                setFieldValue(names[x], address[x])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address])


    // load map script after mounted
    useEffect(() => {
        initMapScript().then(() => initAutocomplete())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Row className="search mb-1">
                <Col>
                    {label && <Form.Label>{label}</Form.Label>}
                    <div style={{position: "relative"}}>
                        <Form.Control
                            onBlur={() => setFieldTouched(values[names.city])}
                            ref={searchInput}
                        />
                    </div>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col lg={10}>
                    <Form.Group>
                        <Form.Label>Straat</Form.Label>
                        <Form.Control disabled={true} value={address.street || values[names.street]}/>
                    </Form.Group>
                </Col>
                <Col lg={2}>
                    <Form.Group>
                        <Form.Label>Straat Nr.</Form.Label>
                        <Form.Control disabled={true} value={address.number || values[names.number]}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col lg={3}>
                    <Form.Group>
                        <Form.Label>Provincie</Form.Label>
                        <Form.Control disabled={true} value={address.state || values[names.state]}/>
                    </Form.Group>
                </Col>
                <Col lg={2}>
                    <Form.Group>
                        <Form.Label>Postcode</Form.Label>
                        <Form.Control disabled={true} value={address.zip || values[names.zip]}/>
                    </Form.Group>
                </Col>
                <Col lg={7}>
                    <Form.Group>
                        <Form.Label>Plaats</Form.Label>
                        <Form.Control disabled={true} value={address.city || values[names.city]}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col>
                    <Form.Group>
                        <Form.Label>Land</Form.Label>
                        <Form.Control disabled={true} value={address.country || values[names.country]}/>
                    </Form.Group>
                </Col>
            </Row>
            <ErrorMessage error={errors[names.city]} visible={touched[names.city]}/>
        </>
    );
}

export default AppFormFieldAddress;
