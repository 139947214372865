import ReactDataGrid from "@inovua/reactdatagrid-community";

/**
 * Represents the possible positions of a sidebar.
 *
 * @enum {string}
 */
export const SIDEBAR_POSITION = {
    LEFT: "left",
    RIGHT: "right",
};

/**
 * Represents the different behaviors of a sidebar.
 *
 * @constant
 * @type {Object}
 * @property {string} STICKY - The sidebar sticks to the viewport when scrolling.
 * @property {string} FIXED - The sidebar remains fixed in its position regardless of scrolling.
 * @property {string} COMPACT - The sidebar is displayed in a compact mode.
 */
export const SIDEBAR_BEHAVIOR = {
    STICKY: "sticky",
    FIXED: "fixed",
    COMPACT: "compact",
};

/**
 * LAYOUT represents the available layout options.
 *
 * @type {Object}
 * @property {string} FLUID - The fluid layout option.
 * @property {string} BOXED - The boxed layout option.
 */
export const LAYOUT = {
    FLUID: "fluid",
    BOXED: "boxed",
};

/**
 * Object representing the theme options.
 *
 * @typedef {Object} Theme
 *
 * @property {string} DEFAULT - The default theme.
 * @property {string} COLORED - The colored theme.
 * @property {string} DARK - The dark theme.
 * @property {string} LIGHT - The light theme.
 */
export const THEME = {
    DEFAULT: "default",
    COLORED: "colored",
    DARK: "dark",
    LIGHT: "light",
};

/**
 * Object representing the light theme palette.
 *
 * @typedef {Object} THEME_PALETTE_LIGHT
 * @property {string} primary - The primary color.
 * @property {string} primary-dark - The dark shade of the primary color.
 * @property {string} primary-light - The light shade of the primary color.
 * @property {string} secondary - The secondary color.
 * @property {string} success - The success color.
 * @property {string} info - The info color.
 * @property {string} warning - The warning color.
 * @property {string} danger - The danger color.
 * @property {string} white - The white color.
 * @property {string} gray-100 - The gray 100 color.
 * @property {string} gray-200 - The gray 200 color.
 * @property {string} gray-300 - The gray 300 color.
 * @property {string} gray-400 - The gray 400 color.
 * @property {string} gray-500 - The gray 500 color.
 * @property {string} gray-600 - The gray 600 color.
 * @property {string} gray-700 - The gray 700 color.
 * @property {string} gray-800 - The gray 800 color.
 * @property {string} gray-900 - The gray 900 color.
 * @property {string} black - The black color.
 * @property {string} blue - The blue color.
 * @property {string} green - The green color.
 * @property {string} red - The red color.
 */
export const THEME_PALETTE_LIGHT = {
    primary: "#3B82EC",
    "primary-dark": "#1659c7",
    "primary-light": "#84aef2",
    secondary: "#495057",
    success: "#4BBF73",
    info: "#1F9BCF",
    warning: "#f0ad4e",
    danger: "#d9534f",
    white: "#fff",
    "gray-100": "#f4f7f9",
    "gray-200": "#e2e8ee",
    "gray-300": "#dee6ed",
    "gray-400": "#ced4da",
    "gray-500": "#adb5bd",
    "gray-600": "#6c757d",
    "gray-700": "#495057",
    "gray-800": "#020202",
    "gray-900": "#212529",
    black: "#000",
    blue: "#1d82cc",
    green: "#8bc34b",
    red: "#d9534f"
};

/**
 * Object representing the dark theme palette.
 *
 * @type {{
 *  primary-dark: string,
 *  primary-light: string,
 *  white: string,
 *  gray-100: string,
 *  gray-200: string,
 *  gray-300: string,
 *  gray-400: string,
 *  gray-500: string,
 *  gray-600: string,
 *  gray-700: string,
 *  gray-800: string,
 *  gray-900: string,
 *  black: string
 * }}
 */
export const THEME_PALETTE_DARK = {
    ...THEME_PALETTE_LIGHT,
    "primary-dark": "#84aef2",
    "primary-light": "#1659c7",
    white: "#293042",
    "gray-100": "#3e4555",
    "gray-200": "#545968",
    "gray-300": "#696e7b",
    "gray-400": "#7f838e",
    "gray-500": "#9498a1",
    "gray-600": "#a9acb3",
    "gray-700": "#bfc1c6",
    "gray-800": "#d4d6d9",
    "gray-900": "#eaeaec",
    black: "#fff",
};

/**
 * Represents an object containing internationalization (i18n) strings for a ReactDataGrid component.
 *
 * @type {Object}
 * @property {string} pageText - The text to display for the page label.
 * @property {string} ofText - The text to display between the current page and total page count.
 * @property {string} perPageText - The text to display for the "Results per page" label.
 * @property {string} showingText - The text to display for the "Showing" label.
 * @property {string} clearAll - The text to display for the "Clear All" button.
 * @property {string} clear - The text to display for the "Clear" button.
 * @property {string} showFilteringRow - The text to display for the "Show Filtering Row" button.
 * @property {string} hideFilteringRow - The text to display for the "Hide Filtering Row" button.
 * @property {string} dragHeaderToGroup - The text to display for the "Drag Header to Group" tooltip.
 * @property {string} disable - The text to display for the "Disable" button.
 * @property {string} enable - The text to display for the "Enable" button.
 * @property {string} sortAsc - The text to display for the "Sort Ascending" button.
 * @property {string} sortDesc - The text to display for the "Sort Descending" button.
 * @property {string} unsort - The text to display for the "Unsort" button.
 * @property {string} group - The text to display for the "Group" button.
 * @property {string} ungroup - The text to display for the "Ungroup" button.
 * @property {string} lockStart - The text to display for the "Lock Start" button.
 * @property {string} lockEnd - The text to display for the "Lock End" button.
 * @property {string} unlock - The text to display for the "Unlock" button.
 * @property {string} columns - The text to display for the "Columns" label.
 * @property {string} contains - The text to display for the "Contains" option in a filter dropdown.
 * @property {string} startsWith - The text to display for the "Starts With" option in a filter dropdown.
 * @property {string} endsWith - The text to display for the "Ends With" option in a filter dropdown.
 * @property {string} notContains - The text to display for the "Does Not Contain" option in a filter dropdown.
 * @property {string} neq - The text to display for the "Not Equal" option in a filter dropdown.
 * @property {string} eq - The text to display for the "Equal" option in a filter dropdown.
 * @property {string} notEmpty - The text to display for the "Not Empty" option in a filter dropdown.
 * @property {string} lt - The text to display for the "Less Than" option in a filter dropdown.
 * @property {string} lte - The text to display for the "Less Than or Equal" option in a filter dropdown.
 * @property {string} gt - The text to display for the "Greater Than" option in a filter dropdown.
 * @property {string} gte - The text to display for the "Greater Than or Equal" option in a filter dropdown.
 * @property {Object} calendar - An object containing calendar-specific i18n strings.
 * @property {string} calendar.todayButtonText - The text to display for the "Today" button in the calendar.
 * @property {string} calendar.clearButtonText - The text to display for the "Clear" button in the calendar.
 * @property {string} calendar.okButtonText - The text to display for the "OK" button in the calendar.
 * @property {string} calendar.cancelButtonText - The text to display for the "Cancel" button in the calendar.
 */
export const i18nTable = Object.assign({}, ReactDataGrid.defaultProps.i18n, {
    pageText: 'Pagina ',
    ofText: ' van ',
    perPageText: 'Resultaten per pagina',
    showingText: 'Weergeeft',
    clearAll: 'Alles wissen',
    clear: 'Wissen',
    showFilteringRow: 'Laat gefilterde rij zien',
    hideFilteringRow: 'Verstop gefilterde rij',
    dragHeaderToGroup: 'Sleep kop naar groep',
    disable: 'Uitzetten',
    enable: 'Aanzetten',
    sortAsc: 'Sorteer van beneden naar boven',
    sortDesc: 'Sorteer van boven naar beneden',
    unsort: 'Uitsorteren',
    group: 'Groepeer',
    ungroup: 'Groepering opheffen',
    lockStart: 'Zet begin vast',
    lockEnd: 'Zet einde vast',
    unlock: 'Ontgrendelen',
    columns: 'Kolommen',
    contains: 'Bevat',
    startsWith: 'Begint met',
    endsWith: 'Eindigt met',
    notContains: 'Bevat niet',
    neq: 'Niet gelijk',
    eq: 'Is gelijk',
    notEmpty: 'Niet leeg',
    lt: 'Minder dan',
    lte: 'Minder dan of gelijk',
    gt: 'Groter dan',
    gte: 'Groter dan of gelijk',

    calendar: {
        todayButtonText: "Vandaag",
        clearButtonText: "Wissen",
        okButtonText: "OK",
        cancelButtonText: "Annuleren",
    }
})

/**
 * Days of the week.
 *
 * @type {string[]}
 */
const days = ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za']
/**
 * The array 'months' represents the twelve months of the year in Dutch.
 *
 * @type {string[]}
 * @constant
 */
const months = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December']

/**
 * Provides localization and formatting functionality for date picker.
 *
 * @type {Object}
 * @property {Object} localize - Contains localization functions for day and month.
 * @property {Function} localize.day - Function to localize day based on index.
 * @property {Function} localize.month - Function to localize month based on index.
 * @property {Object} formatLong - Contains formatting functions.
 * @property {Function} formatLong.date - Function to format date in long format.
 */
export const localeDatapicker = {
    localize: {
        day: n => days[n],
        month: n => months[n]
    },
    formatLong: {
        date: () => 'mm/dd/yyyy'
    }
}
