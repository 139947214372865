import React, {useContext, useEffect, useState} from "react";
import {Alert, Badge, Button, Card, Modal, Spinner} from "react-bootstrap";
import WeeklyReportWeekPart from "../../components/algemeen/WeeklyReportWeekPart";
import WeeklyReportChartPart from "../../components/algemeen/WeeklyReportChartPart";
import weekstatenApi from "../../../api/weeklyReports";
import {useAuth0} from "@auth0/auth0-react";
import moment from "moment";
import Loader from "../../../components/Loader";
import {useParams} from "react-router-dom";
import PermissionsContext from "../../../utils/context";
import notyf from "../../../utils/notyfApi";
import {Lock} from "@mui/icons-material";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import adminUsersApi from "../../../api/adminUsers";
import VacationWeekModal from "./VacationWeekModal";

/**
 * WeeklyReportContentHandler is a React component responsible for displaying the weekly report content.
 *
 * @returns {JSX.Element} The rendered JSX element.
 */
export default function WeeklyReportContentHandler() {
    const {user} = useAuth0();// Get user from auth0
    const {week, year, id} = useParams() // Take the week number, year and user id from the parameters, only if available
    const {permissions} = useContext(PermissionsContext) // Permissions of the user
    const [weekstaat, setWeekstaat] = useState(false); // Weekstaat array
    const [isLoading, setIsloading] = useState(true); // Loading boolean
    const [singleUserTimesheetPermissions, setSingleUserTimesheetPermissions] = useState(); // Permissions for the user on which things he can book hours
    const [currentDate] = useState(year ? moment().year(year).week(week) : moment()); // The actual current date or date from the parameters
    const [fakeDate, setFakeDate] = useState(year ? moment().year(year).week(week) : moment()); // The adjusted date by the user
    const [totalHours, setTotalHours] = useState(moment()); // Total amount of booked hours
    const [loader, setLoader] = useState(false); // Overlay loading boolean




    useEffect(() => {
        getData(currentDate);
        getDataTimesheetPermissions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (weekstaat) { // If there is a weekstaat array calculate the total hours booked
            let total = 0;
            for (let item of weekstaat.timesheet) {
                let begin = moment(item.timesheet_start_time, "HH:mm:ss")
                let end = moment(item.timesheet_end_time, "HH:mm:ss")
                total += end.diff(begin, "hours", true)
            }
            setTotalHours(total)
        }
    }, [weekstaat])

    const handleDateChange = (amount) => {
        // Handle date change, if no amount is given than set it to the current week
        // Hiermee kan je de zichtbare week veranderen
        if (!amount) {
            setFakeDate(moment())
            getData(moment())
        } else {
            setFakeDate(fakeDate.add(amount, "weeks"))
            getData(fakeDate)
        }
    }

    const getData = async (date) => {
        // Standard get call
        const response = await weekstatenApi.getWeekstaat({
            user: id || user.sub,
            iso_week: date.week(),
            year: date.year()
        });
        if (!response.ok) return notyf.error("Error " + response.status)
        setWeekstaat(response.data);
        setIsloading(false);
    };

    const resetWeek = async () => {
        // This removes all the bookings from the week
        const res = await weekstatenApi.resetWeekstaat({
            user: id || user.sub,
            iso_week: fakeDate.week(),
            year: fakeDate.year()
        })
        if (!res.ok) return notyf.error("Error " + res.status)
        getData(fakeDate)
    }
    const toggleWeek = async () => {
        // Set the week active or inactive
        // Indienen
        const res = await weekstatenApi.toggleWeekstaat({
            user: id || user.sub,
            iso_week: fakeDate.week(),
            year: fakeDate.year(),
            completed: !weekstaat.timesheet_completed
        })
        if (!res.ok) return notyf.error("Error " + res.status)
        getData(fakeDate)
    }

    const getDataTimesheetPermissions = async () => {
        // Dit is de data voor de accountgegevens
        const res = await adminUsersApi.getSingleUser(id || user.sub);
        if (!res.ok) return notyf.error("Error " + res.status)
        if (res.data.app_metadata?.timesheet_permissions === undefined) {
            setSingleUserTimesheetPermissions({
                ENG: true,
                IBS: true,
                PAN: true,
                PL: true,
                WERKVB: true,
                TEK: true,
                BOA: true,
                OHDDC: true,
                OHVELD: true,
                STOR: true
            });
        } else setSingleUserTimesheetPermissions(res.data.app_metadata.timesheet_permissions);
    };

    const handleVacation = async () => {
        // Set the whole week on vacation for a user
        setLoader(true)
        for (let i = 0; i <= 4; i++) {
            const res = await weekstatenApi.postWeekstaatItem({
                timesheet_section: "Vrij",
                timesheet_codetimesheet_code: "SNIP",
                timesheet_comment: "vakantie",
                timesheet_date: fakeDate.startOf('week').add(i, "d").format("YYYY-MM-DD"),
                timesheet_full_day: true,
                timesheet_start_time: "08:30:00",
                timesheet_end_time: "09:30:00",
                timesheet_code: "SNIP",
                timesheet_project_id: 809,
                timesheet_user_id: user.id || user.sub
            })
            if (!res.ok) notyf.error("Er ging iets mis met de vakantiedagen invullen")
            setTimeout(() => {
                return null
            }, 100)

        }
        getData(fakeDate)
        setLoader(false)
    }


    //laat tabel zien met gegevens via props
    if (isLoading) return <Loader/>
    return (
        <>   {loader && (<div className="overlay-loading">
                    <Spinner
                        style={{
                            marginTop: "25%",
                            marginLeft: "50%",
                            color: "#8BC34B",
                        }}
                        animation="border"
                    />
                </div>
            )}

            {weekstaat.timesheet_completed && <Alert variant={"primary"} className={"mb-2"}>
                <div className="alert-icon">
                    <Lock/>
                </div>
                <div className="alert-message" style={{fontSize: 14}}>
                    <strong>Deze weekstaat is ingediend</strong>
                </div>
            </Alert>}
            <Card>
                <Card.Header>
                    <Card.Title tag="h5" className="mb-0">
                        <h3>{weekstaat.user_name || user.name}</h3>
                        <p> Week {moment(fakeDate).week()}: {moment(weekstaat.week_start).format("D MMMM YYYY")} - {moment(weekstaat.week_end).format("D MMMM YYYY")}</p>
                        <Button onClick={() => handleDateChange(-1)} className={"me-1"}> {"<"} </Button>
                        <Button onClick={() => handleDateChange(false)} className={"me-1"}> {"Vandaag"} </Button>
                        <Button onClick={() => handleDateChange(1)}> {">"} </Button>
                    </Card.Title>
                     <VacationWeekModal handleVacation={handleVacation}/>
                </Card.Header>
                <Card.Body>
                    <WeeklyReportChartPart weekstaat={weekstaat}/>
                    <WeeklyReportWeekPart completed={weekstaat.timesheet_completed} reload={() => getData(fakeDate)}
                                          weekstaat={weekstaat}
                                          userTimesheetPermissions={singleUserTimesheetPermissions}/>
                </Card.Body>
                <Card.Footer style={{display: "flex", justifyContent: "flex-end"}}>
                    {!weekstaat.timesheet_completed && <ResetModal task={resetWeek}/>}
                    <CompleteModal total={totalHours} toggleWeek={toggleWeek} completed={weekstaat.timesheet_completed}
                                   permission={permissions.includes("user:timesheets")}/>
                </Card.Footer>
            </Card>
        </>
    );
};

// Modal for the complete button and the open button
/**
 * Represents a modal component used for submitting or opening a timesheet.
 * @param {Object} props - The properties of the CompleteModal component.
 * @param {function} props.toggleWeek - The function called when submitting or opening a timesheet.
 * @param {boolean} props.completed - Indicates if the timesheet is completed or not.
 * @param {number} props.total - The total number of hours for the timesheet.
 * @param {boolean} props.permission - Indicates if the user has permission to open the timesheet.
 * @returns {JSX.Element} The CompleteModal component JSX.
 */
const CompleteModal = ({toggleWeek, completed, total, permission}) => {
    const [show, setShow] = useState(false);
    return <>
        {!completed &&
            <Button disabled={!total} onClick={() => setShow(!show)} className={"m-2"}>Weekstaat indienen</Button>}
        {completed && permission &&
            <GuardWrapper role={"admin:timesheets"}><Button disabled={!total} onClick={() => setShow(!show)}
                                                            className={"m-2"}>Weekstaat openen</Button></GuardWrapper>}
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header>
                <Modal.Title>Weekstaat</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {completed ? <p>Weet u zeker dat u uw weekstaat wil openen?</p>
                    : <p>Weet u zeker dat u uw weekstaat wil indienen? Uw weekstaat wordt hierna op slot gezet.</p>}
                {total < 40 && <Badge bg={"danger"}>Pas op, U heeft pas {total} uur ingevuld</Badge>}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShow(!show)} variant={"secondary"} className={"float-end"}>Annuleren</Button>
                <Button onClick={() => {
                    setShow(false)
                    toggleWeek()
                }} className={"float-end"}>Versturen</Button>
            </Modal.Footer>
        </Modal>
    </>
}

// Modal for the reset button
/**
 * Renders a modal component for resetting a task.
 *
 * @component
 * @param {Object} props - The properties of the component.
 * @param {Function} props.task - The function to execute when the reset button is clicked.
 * @returns {JSX.Element} The modal component for resetting a task.
 *
 * @example
 * <ResetModal
 *   task={() => {
 *     // Reset task logic goes here
 *   }}
 * />
 */
const ResetModal = ({task}) => {
    const [show, setShow] = useState(false);
    return <>
        <Button variant={"danger"} className={"m-2"} onClick={() => setShow(!show)}>Reset</Button>
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header>
                <Modal.Title>Weekstaat</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Weet u het zeker?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShow(!show)} variant={"secondary"} className={"float-end"}>Annuleren</Button>
                <Button onClick={() => {
                    setShow(false)
                    task()
                }} className={"float-end"} variant={"danger"}>Resetten</Button>
            </Modal.Footer>
        </Modal>
    </>

}