import {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {Form as FormikForm, FormField, FormSelectFieldOffers, SubmitButton} from "../../../../components/forms"
import * as Yup from 'yup';
import {useParams} from "react-router-dom";
import offersApi from "../../../../api/offertes";
import {Copy} from "react-feather";
import notyf from "../../../../utils/notyfApi";

/**
 * Opens a modal for offer calculations.
 *
 * @param {Object} options - The options for the modal.
 * @param {boolean} options.duplicate - Determines if the calculation should be duplicated.
 * @param {string} options.cId - The calculation ID.
 * @param {string} options.name - The name of the calculation.
 * @param {string} options.reference - The reference of the calculation.
 * @param {function} options.reload - The function to reload the calculations.
 *
 * @return {JSX.Element} - The modal component.
 */
export default function OfferCalculationsModal({duplicate, cId, name, reference, reload}) {
    const {id} = useParams();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleSubmit = async (sid, data) => {
        if (duplicate) {
            const response = await offersApi.copyCalculation({calculation_id: cId, ...data});
            if (!response.ok) return notyf.error("Error " + response.status)
        } else {
            const response = await offersApi.createNewCalculation({portal_offer_id: sid, ...data});
            if (!response.ok) return notyf.error("Error " + response.status)
        }
        reload()
        handleClose()
    };

    return (
        <>
            {duplicate ? <Copy className={"cursor-pointer"} onClick={handleShow}/> :
                <Button variant="primary" className={"float-end"} onClick={handleShow}>
                    +
                </Button>}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> {duplicate ? "Calculatie dupliceren - " + reference + " " + name : "Nieuwe Calculatie aanmaken"}</Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={{
                        calculation_name: name + `${duplicate ? " (duplicaat)" : ""}` || "",
                        calculation_reference: reference || "",
                        offer_country: "NL",
                        sales_offer_id: id,
                    }}

                    // Hier is het validatieschema van alle velden
                    validationSchema={Yup.object({
                        calculation_name: Yup.string()
                            .max(250, 'Moet minder dan 250 karakters zijn')
                            .required('Offertenaam moet ingevuld worden'),
                        calculation_reference: Yup.string()
                            .max(250, 'Moet minder dan 250 karakters zijn')
                            .required('Offertereferentie moet ingevuld worden'),
                    })}

                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(false);
                        handleSubmit(id, values);
                    }}
                >
                    <Modal.Body>
                        <FormField name={"calculation_name"}
                                   label={"Offertenaam:"}
                                   width={"100%"}
                                   placeholder="Vul de offertenaam hier in"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"calculation_reference"}
                                   label={"Offertereferentie"}
                                   width={"100%"}
                                   placeholder={"Vul de offertereferentie hier in"}
                                   rows={3}
                                   className={"mb-3"}
                        />

                        {/*<FormField name={"offer_country"}*/}
                        {/*           label={"Land"}*/}
                        {/*           width={"100%"}*/}
                        {/*           placeholder={"Vul de offertereferentie hier in"}*/}
                        {/*           rows={3}*/}
                        {/*           className={"mb-3"}*/}
                        {/*           as={"select"}*/}
                        {/*>*/}
                        {/*    <option value={"NL"}>Nederland</option>*/}
                        {/*    <option value={"BE"}>België</option>*/}
                        {/*</FormField>*/}
                        {duplicate && <FormSelectFieldOffers label={"Offerte (optioneel)"} name={"offer"}/>}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Annuleren
                        </Button>
                        <SubmitButton title={"Opslaan"} variant="primary"/>
                    </Modal.Footer>
                </FormikForm>
            </Modal>
        </>
    );
}

