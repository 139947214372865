import React, {useEffect, useState} from "react";
import {Col, Row, Table} from "react-bootstrap";
import DoughnutChart from "../../charts/Chartjs/Doughnut";
import moment from "moment";

/**
 * Generates a weekly report chart and table based on the given week state.
 *
 * @param {Object} weekstaat - The week state object containing timesheet data.
 *
 * @return {JSX.Element} - The generated weekly report chart and table components.
 */
export default function WeeklyReportChartPart({weekstaat}) {
    const [hours, setHours] = useState({
        productief: 0,
        nietpr: 0,
        vrij: 0,
    })

    useEffect(() => {
        let productief = 0;
        let nietpr = 0;
        let vrij = 0;
        for (let item of weekstaat.timesheet) {
            if (item.timesheet_section === "Project") {
                let begin = moment(item.timesheet_start_time, "HH:mm:ss")
                let end = moment(item.timesheet_end_time, "HH:mm:ss")
                productief += end.diff(begin, "hours", true)
            } else if (item.timesheet_section === "Nietpr") {
                let begin = moment(item.timesheet_start_time, "HH:mm:ss")
                let end = moment(item.timesheet_end_time, "HH:mm:ss")
                nietpr += end.diff(begin, "hours", true)
            } else {
                let begin = moment(item.timesheet_start_time, "HH:mm:ss")
                let end = moment(item.timesheet_end_time, "HH:mm:ss")
                vrij += end.diff(begin, "hours", true)
            }

        }
        setHours({productief, nietpr, vrij})
    }, [weekstaat])

    return (
        <Row className={"mb-5 mt-5"}>
            <Col xl="6">
                <DoughnutChart hours={hours}/>
            </Col>
            <Col xl="6">
                <Table className={"table"} size="sm">
                    <tbody>
                    <tr>
                        <td>Productief</td>
                        <td>{hours.productief} uur</td>
                    </tr>
                    <tr>
                        <td>Niet productief</td>
                        <td>{hours.nietpr} uur</td>
                    </tr>
                    <tr>
                        <td>Ziek en verlof</td>
                        <td>{hours.vrij} uur</td>
                    </tr>
                    <tr>
                        <td>Totaal geboekt</td>
                        <td>{hours.productief + hours.nietpr + hours.vrij} uur</td>
                    </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>);
};