import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {Form as FormikForm, FormField, SubmitButton} from "../../../components/forms";
import * as Yup from "yup";
import notyf from "../../../utils/notyfApi";
import adminBudgetApi from "../../../api/adminBudget";

/**
 * Represents a modal for creating a new year for budget data.
 *
 * @param {function} reload - The function to reload the budget data after creating a new year.
 * @returns {ReactElement} A React modal component for creating a new year for budget data.
 */
const BudgetCreateYearModal = ({reload}) => {
    const [show, setShow] = useState(false);

    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(false);
        let valuesparsed = {
            productivity_year_year: parseInt(values.productivity_year_year),
            productivity_year_productivity_percentage: parseFloat(values.productivity_year_productivity_percentage),
            productivity_year_no_productivity_percentage: parseFloat(values.productivity_year_no_productivity_percentage),
            productivity_year_holiday_percentage: parseFloat(values.productivity_year_holiday_percentage),
            productivity_year_sick_days_percentage: parseFloat(values.productivity_year_sick_days_percentage),
            productivity_year_prod_pl_percentage: parseFloat(values.productivity_year_prod_pl_percentage),
            productivity_year_prod_eng_percentage: parseFloat(values.productivity_year_prod_eng_percentage),
            productivity_year_pl_rate: parseFloat(values.productivity_year_pl_rate),
            productivity_year_eng_rate: parseFloat(values.productivity_year_eng_rate),
            productivity_year_ibs_rate: parseFloat(values.productivity_year_ibs_rate),
            productivity_year_tek_rate: parseFloat(values.productivity_year_tek_rate),
            productivity_year_werkvb_rate: parseFloat(values.productivity_year_werkvb_rate),
            productivity_year_pan_rate: parseFloat(values.productivity_year_pan_rate),
        };
        const res = await adminBudgetApi.postNewYearData(valuesparsed)
        if (!res.ok) return notyf.error("Error " + res.status)
        setShow(false)

        reload();
    }

    return (
        <>
            <button className="btn btn-primary float-end" onClick={() => {
                setShow(!show);
            }}>+
            </button>
            <Modal show={show} onHide={() => setShow(!show)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Een nieuw jaar aanmaken voor productiviteit
                    </Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={{
                        productivity_year_year: "",
                        productivity_year_productivity_percentage: "",
                        productivity_year_no_productivity_percentage: "",
                        productivity_year_holiday_percentage: "",
                        productivity_year_sick_days_percentage: "",
                        productivity_year_prod_pl_percentage: "",
                        productivity_year_prod_eng_percentage: "",
                        productivity_year_pl_rate: "",
                        productivity_year_eng_rate: "",
                        productivity_year_ibs_rate: "",
                        productivity_year_tek_rate: "",
                        productivity_year_werkvb_rate: "",
                        productivity_year_pan_rate: "",
                    }}

                    // Hier is het validatieschema van alle velden
                    validationSchema={Yup.object({
                        productivity_year_year: Yup.number(),
                        productivity_year_productivity_percentage: Yup.number(),
                        productivity_year_no_productivity_percentage: Yup.number(),
                        productivity_year_holiday_percentage: Yup.number(),
                        productivity_year_sick_days_percentage: Yup.number(),
                        productivity_year_prod_pl_percentage: Yup.number(),
                        productivity_year_prod_eng_percentage: Yup.number(),
                        productivity_year_pl_rate: Yup.number(),
                        productivity_year_eng_rate: Yup.number(),
                        productivity_year_ibs_rate: Yup.number(),
                        productivity_year_tek_rate: Yup.number(),
                        productivity_year_werkvb_rate: Yup.number(),
                        productivity_year_pan_rate: Yup.number(),
                    })}
                    onSubmit={handleSubmit}
                >
                    <Modal.Body>
                        <FormField name={"productivity_year_year"}
                                   label={"Jaar"}
                                   width={"100%"}
                                   placeholder="Vul hier het gewenste jaar in om het aan te maken (2022, 2023)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_productivity_percentage"}
                                   label={"productieve Uren (gebruik geen %)"}
                                   width={"100%"}
                                   placeholder="Vul hier de gewenste productieve uren op jaarbasis in in procenten (40.5, 68.2 of 80.8)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_no_productivity_percentage"}
                                   label={"indirecte Uren (gebruik geen %)"}
                                   width={"100%"}
                                   placeholder="Vul hier het aantal indirecte uren op jaarbasis in in procenten (40.5, 68.2 of 80.8)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_holiday_percentage"}
                                   label={"snipperdagen Uren (gebruik geen %)"}
                                   width={"100%"}
                                   placeholder="Vul hier het aantal snipperdagen uren op jaarbasis in in procenten (40.5, 68.2 of 80.8)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_sick_days_percentage"}
                                   label={"Ziekte Uren (gebruik geen %)"}
                                   width={"100%"}
                                   placeholder="Vul hier het aantal ziekte uren op jaarbasis in in procenten (40.5, 68.2 of 80.8)"
                                   className={"mb-3"}
                                   control={"input"}
                        />

                        <FormField name={"productivity_year_prod_pl_percentage"}
                                   label={"ProdPL"}
                                   width={"100%"}
                                   placeholder="Vul hier de gewenste waarde in in procenten (40.5, 68.2 of 80.8)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_prod_eng_percentage"}
                                   label={"ProdENG"}
                                   width={"100%"}
                                   placeholder="Vul hier de gewenste waarde in in procenten (40.5, 68.2 of 80.8)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_pl_rate"}
                                   label={"PL"}
                                   width={"100%"}
                                   placeholder="Vul hier de gewenste waarde in in euro's (78.00, 67.50, 74.25)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_eng_rate"}
                                   label={"ENG"}
                                   width={"100%"}
                                   placeholder="Vul hier de gewenste waarde in in euro's (78.00, 67.50, 74.25)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_ibs_rate"}
                                   label={"IBS"}
                                   width={"100%"}
                                   placeholder="Vul hier de gewenste waarde in in euro's (78.00, 67.50, 74.25)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_tek_rate"}
                                   label={"TEK"}
                                   width={"100%"}
                                   placeholder="Vul hier de gewenste waarde in in euro's (78.00, 67.50, 74.25)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_werkvb_rate"}
                                   label={"WERKVB"}
                                   width={"100%"}
                                   placeholder="Vul hier de gewenste waarde in in euro's (78.00, 67.50, 74.25)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_pan_rate"}
                                   label={"PAN"}
                                   width={"100%"}
                                   placeholder="Vul hier de gewenste waarde in in euro's (78.00, 67.50, 74.25)"
                                   className={"mb-3"}
                                   control={"input"}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => setShow(false)}>
                            Annuleren
                        </Button>
                        <SubmitButton title={"Opslaan"} variant="primary"/>
                    </Modal.Footer>
                </FormikForm>
            </Modal>
        </>
    )
}

export default BudgetCreateYearModal;