import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Col, Container, Dropdown, DropdownButton, Row} from "react-bootstrap";
import BudgetBookYearOverviewForm from "../../components/admin/BudgetBookYearOverviewForm";
import BudgetFunctionProdAndRatesForm from "../../components/admin/BudgetFunctionProdAndRatesForm";
import BudgetPersonDataGridTable from "../../components/admin/BudgetPersonDataGridTable";
import BudgetCreateYearModal from "../../components/admin/BudgetCreateYearModal";
import notyf from "../../../utils/notyfApi";
import adminBudgetApi from "../../../api/adminBudget";
import Loader from "../../../components/Loader";

/**
 * Represents a Budget component.
 *
 * The Budget component retrieves data from an API and displays it in various forms, such as tables and dropdowns.
 * It also allows users to select different years to compare budget data.
 *
 * @returns {JSX.Element} The rendered Budget component.
 */
const Budget = () => {
    //get data from api
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true)
        const response = await adminBudgetApi.getALlDates();
        if (!response.ok) return notyf.error("Error " + response.status)
        setAllData(response.data);
        setValueDropdown(response.data[0]?.productivity_year_year)
        setValueDropdown2(response.data[0]?.productivity_year_year)
        setLoading(false)
    };

    // set values for dropdown year buttons to compare
    const [valueDropdown, setValueDropdown] = React.useState("");
    const handleChange = (event) => {
        setValueDropdown(event);
    };

    const [valueDropdown2, setValueDropdown2] = React.useState("");
    const handleChange2 = (event) => {
        setValueDropdown2(event);
    };

    //Check if data exists to return tables etc
    if (!loading && allData) return (
        <React.Fragment>
            <Helmet title="Budget"/>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Budget &nbsp;
                    <DropdownButton title={valueDropdown} onSelect={handleChange}>
                        {allData.map(y => <Dropdown.Item key={y.productivity_year_year}
                                                         eventKey={y.productivity_year_year}>{y.productivity_year_year}</Dropdown.Item>)}
                    </DropdownButton> &nbsp; vergelijken met
                    &nbsp;
                    <DropdownButton title={valueDropdown2} onSelect={handleChange2}>
                        {allData.map(z => <Dropdown.Item key={z.productivity_year_year}
                                                         eventKey={z.productivity_year_year}>{z.productivity_year_year}</Dropdown.Item>)}
                    </DropdownButton>
                    {/* the plus button in the top right corner*/}
                    <BudgetCreateYearModal reload={getData}/>
                </h1>
                <Row>
                    <Col xl={6}>
                        {/* Top left modal */}
                        <BudgetBookYearOverviewForm
                            reload={getData}
                            data={allData.filter(year => year.productivity_year_year === valueDropdown)[0]}/>
                    </Col>
                    <Col xl={6}>
                        {/* Top right modal */}
                        <BudgetFunctionProdAndRatesForm
                            data={allData.filter(year => year.productivity_year_year === valueDropdown)[0]}
                            data2={allData.filter(z => z.productivity_year_year === valueDropdown2)[0]}/>
                    </Col>
                </Row>
                <Row>
                    <Col xl="12">
                        {/* Big bottom modal */}
                        <BudgetPersonDataGridTable
                            year={allData?.find(year => year.productivity_year_year === valueDropdown)}
                        />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
    //Return loader if data doesnt exist
    else
        return <Loader/>;
}

export default Budget;
