import React, {useState} from "react";
import {Copy, Eye} from "react-feather";
import notyfApi from "./notyfApi";


/**
 * Formats a number into the Euro currency format.
 *
 * @param {number} value - The number to be formatted.
 * @return {string} - The number formatted into the Euro currency format.
 */
export const euroFormat = (value) => {
    return Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR'
    }).format(value)
}

// Custom password component which copies the text when the eye is pressed
/**
 * Component for displaying a password.
 * @param {Object} props - The component props.
 * @param {string} props.text - The password text to display.
 * @returns {JSX.Element} - The component JSX element.
 */
export const DisplayPassword = ({text}) => {
    const [show, setShow] = useState(false)

    const toggle = async () => {
        if (show) return setShow(!show)
        setShow(!show)
        navigator.clipboard.writeText(text)
        notyfApi.success(text + " gekopieerd")
    }

    if (!text) return <></>
    return <span><Eye className={"cursor-pointer me-2"} onClick={() => toggle()}/> {show ? text : "****"}</span>
}

// Custom copy text component which copies text when symbol is clicked
/**
 * Renders a component that displays text with a copy button.
 *
 * @param {object} props - The properties object.
 * @param {string} props.text - The text to display and copy.
 * @returns {element} The rendered component.
 */
export const TextWithCopy = ({text}) => {
    const copy = async () => {
        navigator.clipboard.writeText(text)
        notyfApi.success(text + " gekopieerd")
    }

    if (!text) return <></>
    return <span> <Copy className={"cursor-pointer me-2"} onClick={() => copy()}/>{text}</span>
}


// Make a link out of any text
/**
 * Parses the given link and returns the parsed link.
 *
 * @param {string} link - The link to be parsed.
 * @returns {string} - The parsed link.
 */
export const parseLink = link => link.startsWith("http://") || link.startsWith("https://") ? link : `//${link}`;