import React, {useEffect, useState} from "react";
import projectsApi from "../../../api/projects";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {Button, Form, Modal} from "react-bootstrap";
import engineeringApi from "../../../api/engineering";
import notyf from "../../../utils/notyfApi";

const {SearchBar} = Search;

/**
 * FollowingModal component displays a modal that contains a table of projects.
 * The component allows the user to toggle the "CET" checkbox for each project
 * and provides a search functionality for filtering the projects.
 *
 * @component
 * @param {function} reload - A callback function to reload data.
 * @returns {JSX.Element} - The rendered React component.
 */
const FollowingModal = ({reload}) => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState([]);
    const handleClose = () => {
        reload()
        setShow(false)
    };
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (show) getData();
    }, [show]);

    const getData = async () => {
        const res = await projectsApi.getAllProjects("active");
        if (!res.ok) return notyf.error("Error " + res.status)
        setData(res.data);
    };

    const handleToggle = async (row) => {
        const res = await engineeringApi.editEngineering(row.project_id, {project_cet: !row.project_cet})
        if (!res.ok) return notyf.error("Error " + res.status)
        getData()
    }

    const columns = [
        {
            dataField: "project_id",
            text: "id",
            hidden: true,
        }, {
            dataField: "project_code",
            text: "Code",
            hidden: true,
        },
        {
            dataField: "project_name",
            text: "Project",
            formatter: (_c, r) => `${r.project_code} ${r.project_name}`
        },
        {
            dataField: "project_cet",
            text: "CET",
            formatter: (c, r) => <Form.Check>
                <Form.Check.Input className={"big-checkbox"}
                                  type={"checkbox"}
                                  checked={c}
                                  onChange={() => handleToggle(r)}
                />
            </Form.Check>
        },
    ];

    return (
        <>
            <Button onClick={handleShow} variant="primary" style={{width: "175px"}}>Te volgen projecten</Button>
            <Modal show={show} dialogClassName={"modal-75w"} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Boekingen
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ToolkitProvider
                        keyField="project_id"
                        data={data || []}
                        columns={columns}
                        search
                    >
                        {(props) => (
                            <div>
                                <SearchBar
                                    className={"form-control w-100 mb-2"}
                                    placeholder={"Filter: Typ om te zoeken"}
                                    srText={null}
                                    {...props.searchProps}
                                />
                                <BootstrapTable
                                    {...props.baseProps}
                                    pagination={paginationFactory()}
                                />
                            </div>
                        )}
                    </ToolkitProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        sluiten
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default FollowingModal