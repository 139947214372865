import { apiProductsClient } from "./clients/portalClient";
import client from "./clients/portalClient";

/**
 * Retrieves all products from the client.
 *
 * @function getAllProducts
 * @returns {Promise} - A promise that resolves with the products data.
 */
const getAllProducts = () => apiProductsClient.get(`/products`);
/**
 * Retrieves a product by its ID
 *
 * @param {string} id - The ID of the product to retrieve
 * @returns {Promise} A Promise that resolves to the retrieved product
 */
const getProduct = (id) => apiProductsClient.get(`/products/${id}`);
/**
 * Creates a new product by sending a POST request to the server.
 *
 * @param {Object} data - The data of the product to create.
 * @returns {Promise} A promise that resolves to the server's response.
 */
const postNewProduct = (data) => apiProductsClient.post(`/products`, data);
/**
 * Edit an existing product.
 *
 * @param {number} id - The ID of the product to be edited.
 * @param {object} data - The updated data for the product.
 * @returns {Promise} - A Promise that resolves to the result of the update operation.
 */
const editExistingProduct = (id, data) => apiProductsClient.put(`/products/${id}`, data);
/**
 * Deletes a product by its ID.
 *
 * @param {number} id - The ID of the product to delete.
 * @returns {Promise} A Promise that resolves when the product deletion is successful, or rejects with an error message.
 */
const deleteProduct = (id) => apiProductsClient.delete(`/products/${id}`);

/**
 * Retrieves all product orders from the server.
 *
 * @returns {Promise} A promise that resolves with the retrieved product orders.
 */
const getAllProductOrders = () => client.get(`/product_orders`);
/**
 * Retrieves the product order with the specified ID from the server.
 *
 * @param {string} id - The ID of the product order.
 * @returns {Promise} - A Promise that resolves to the product order data.
 */
const getProductOrder = (id) => client.get(`/product_orders/${id}`);
/**
 * Function to create a new product order.
 *
 * @param {Object} data - The data object containing the details of the product order.
 * @returns {Promise} - A promise that resolves to the response of the request.
 *
 * @throws {Error} - If the request fails or encounters an error.
 */
const postNewProductOrder = (data) => client.post(`/product_orders`, data);
/**
 * Updates an existing product order with the provided data.
 *
 * @param {number} id - The ID of the product order to be updated.
 * @param {Object} data - The new data to be set for the product order.
 * @returns {Promise} - A Promise representing the completion of the update operation.
 */
const editExistingProductOrder = (id, data) => client.put(`/product_orders/${id}`, data);
/**
 * Deletes a product order.
 *
 * @param {string} id - The ID of the product order to delete.
 * @returns {Promise} - A promise that resolves when the product order is successfully deleted.
 */
const deleteProductOrder = (id) => client.delete(`/product_orders/${id}`);
/**
 * Retrieves the details of all product order for a specific ID.
 *
 * @param {number} id - The ID of the product order.
 * @returns {Promise} - A promise that resolves with the product order details.
 */
const getAllProductOrderDetails = (id) => client.get(`/product_orders/${id}/details`);

/**
 * Fetches the details of a product order from the server.
 *
 * @param {number} id - The ID of the product order detail to fetch.
 * @returns {Promise} - A promise that resolves with the product order detail data.
 */
const getProductOrderDetail = (id) => client.get(`/product_order_detail/${id}`);
/**
 * Creates a new product order detail by making a POST request to the `/product_order_detail` endpoint.
 *
 * @param {object} data - The data to be sent in the request.
 * @returns {Promise} A Promise representing the result of the POST request.
 */
const postNewProductOrderDetail = (data) => client.post(`/product_order_detail`, data);
/**
 * Edits an existing product order detail.
 *
 * @param {number} id - The ID of the product order detail to be edited.
 * @param {Object} data - The updated data for the product order detail.
 * @returns {Promise} - A promise that resolves with the response from the server.
 */
const editExistingProductOrderDetail = (id, data) => client.put(`/product_order_detail/${id}`, data);
/**
 * Deletes a product order detail with the specified ID.
 *
 * @param {number} id - The ID of the product order detail to delete.
 * @returns {Promise} A Promise that resolves when the product order detail is deleted successfully.
 */
const deleteProductOrderDetail = (id) => client.delete(`/product_order_detail/${id}`);

/**
 * Retrieves the messages related to a specific product order.
 *
 * @param {number} id - The ID of the product order.
 * @returns {Promise<Array>} - A promise that resolves to an array of messages.
 */
const getProductOrderMessages = (id) => client.get(`/product_orders/${id}/comments`);
/**
 * Sends a POST request to add a comment to a product order.
 *
 * @param {string} id - The ID of the product order.
 * @param {Object} data - The comment data to be sent.
 * @returns {Promise} - A promise representing the result of the POST request.
 */
const postProductOrderMessages = (id,data) => client.post(`/product_orders/${id}/comments`, data);
/**
 * Deletes a specific product order message.
 *
 * @param {string} id - The identifier of the product order.
 * @param {string} sId - The identifier of the specific message.
 * @returns {Promise} - A promise that resolves to the success or failure of the deletion operation.
 */
const deleteProductOrderMessages = (id, sId) => client.delete(`/product_orders/${id}/comments/${sId}`);


/**
 * Provides functions for managing products, product orders, product order details, and product order messages.
 *
 * @namespace
 */
const productsApi = {
    getAllProducts,
    getProduct,
    postNewProduct,
    editExistingProduct,
    deleteProduct,

    getAllProductOrders,
    getProductOrder,
    postNewProductOrder,
    editExistingProductOrder,
    deleteProductOrder,

    getAllProductOrderDetails,
    getProductOrderDetail,
    postNewProductOrderDetail,
    editExistingProductOrderDetail,
    deleteProductOrderDetail,

    getProductOrderMessages,
    postProductOrderMessages,
    deleteProductOrderMessages,
};

export default productsApi;
