import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import {Form as FormikForm, FormField, SubmitButton} from "../../../../components/forms"
import * as yup from 'yup';
import BootstrapTable from "react-bootstrap-table-next";
import {euroFormat} from "../../../../utils/usefullFunctions";
import cellEditFactory from 'react-bootstrap-table2-editor';
import moment from "moment";

// for pdf
import {Document, Font, Image, Page, StyleSheet, Text, usePDF, View} from '@react-pdf/renderer';
import {DataTableCell, Table, TableBody, TableCell, TableHeader} from "@david.kucsai/react-pdf-table";
import RobotoRegular from "../../../../assets/fonts/Roboto-Regular.ttf"
import RobotoBold from "../../../../assets/fonts/Roboto-Bold.ttf"
import RobotoItalic from "../../../../assets/fonts/Roboto-Italic.ttf"
import RobotoBoldItalic from "../../../../assets/fonts/Roboto-BoldItalic.ttf"
import Logo from "../../../../assets/img/NordomaticLogoBlue.png";

/**
 * This variable represents the Yup schema for validating a customer data.
 * The schema includes the following fields:
 * - customer_name: Required string field representing the customer name.
 * - address: Required string field representing the customer address.
 * - zipcode: Required string field representing the customer zip code. The value should match the format 'xxxx AA' where 'x' is a digit and 'A' is a letter. Valid examples: '1234 AB
 *', '5678CD'. Invalid examples: '123 AB', '5678  CD'.
 * - city: Required string field representing the customer city.
 * - contact: Required string field representing the customer contact person.
 *
 * @type {object}
 *
 * @example
 * // Example usage of the schema
 * const data = {
 *   customer_name: "John Doe",
 *   address: "123 Main St",
 *   zipcode: "1234 AB",
 *   city: "Sample City",
 *   contact: "Jane Smith"
 * };
 *
 * // Validate the data against the schema
 * const isValid = await schema.isValid(data);
 *
 * if (isValid) {
 *   console.log("Data is valid");
 * } else {
 *   console.log("Data is invalid");
 * }
 */
const schema = yup.object().shape({
    customer_name: yup.string().required("Klantnaam is niet ingevuld"),
    adress: yup.string().required("Adres is niet ingevuld"),
    zipcode: yup.string().required("postcode is niet ingevuld"),
    city: yup.string().required("Stad naam is niet ingevuld"),
    contact: yup.string().required("Contactpersoon is niet ingevuld")
});

/**
 * Modal component for creating a product order pakbon.
 *
 * @param {object} order - The product order data.
 * @param {array} orderDetailsList - The list of product order details.
 * @return {JSX.Element} The modal component.
 */
function ProductOrdersPakbonModal({order, orderDetailsList}) {
    const [show, setShow] = useState(false);
    const [selectedData, setSelectedData] = useState([])
    const [printReady, setPrintReady] = useState(false)
    const [stateValues, setValues] = useState({
        customer_name: order.projects_naw_company_name,
        adress: order.service_project_street + " " + order.service_project_street_nr,
        zipcode: order.service_project_postcode,
        city: order.service_project_city,
        contact: "",
        comment: ""
    })

    const [instance, updateInstance] = usePDF({
        document: <MyDoc order={order} values={stateValues} data={selectedData}/>
    });
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [columns] = useState(
        [
            {
                dataField: "product_order_detail_id",
                hidden: true
            },
            {
                dataField: "product_name",
                text: "Product",
                formatter: (c, r) => {
                    if (c === "DIV") return r.product_order_detail_comment
                    return c
                }
            },
            {
                dataField: "product_description",
                text: "Omschrijving",
            },
            {
                dataField: "product_order_detail_price_per_unit",
                text: "Prijs",
                formatter: c => euroFormat(c)
            },
            {
                dataField: "product_order_detail_amount",
                text: "Aantal",
            },
            {
                dataField: "product_order_detail_price_total",
                text: "Totaal",
                formatter: c => euroFormat(c)
            },
            {
                dataField: "product_order_detail_status",
                text: "Status",
            },
        ]
    )

    const cellEdit = () => cellEditFactory({
        mode: 'click',
        afterSaveCell: (oldValue, newValue, row) => {
            if (selectedData.filter(r => r.product_order_detail_id === row.product_order_detail_id)[0])
                setSelectedData(selectedData.map(obj => selectedData.find(o => o.id === obj.id) || obj))
        }
    })

    const handleSubmit = (values) => {
        setValues(values)
        updateInstance()
        setPrintReady(true)
    }
    useEffect(() => {
        if (orderDetailsList) setSelectedData(orderDetailsList)
    }, [orderDetailsList])

    useEffect(() => {
        if (printReady === true) {
            updateInstance()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printReady])

    if (!orderDetailsList?.length) return <></>
    return (
        <>
            <Button className={"float-end btn-576-float-none mx-1"} onClick={handleShow}>
                Pakbon
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Pakbon maken
                    </Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={stateValues}

                    // Hier is het validatieschema van alle velden
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                >
                    <Modal.Body>
                        <Col className={"m-4"}>
                            <Row>
                                VERZEND DETAILS
                                <hr/>
                            </Row>
                            <Row>
                                <Col>
                                    <FormField name={"customer_name"}
                                               label={"Klantnaam"}
                                               className={"mb-3"}
                                               control={"input"}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormField name={"adress"}
                                               label={"Adres"}
                                               className={"mb-3"}
                                               control={"input"}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <FormField name={"zipcode"}
                                               label={"Postcode"}
                                               className={"mb-3"}
                                               control={"input"}
                                    />
                                </Col>
                                <Col>
                                    <FormField name={"city"}
                                               label={"Stad"}
                                               className={"mb-3"}
                                               control={"input"}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormField name={"contact"}
                                               label={"Contactpersoon"}
                                               className={"mb-3"}
                                               control={"input"}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormField name={"comment"}
                                               label={"Opmerking"}
                                               className={"mb-3"}
                                               control={"input"}
                                               as={"textarea"}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <BootstrapTable
                            cellEdit={cellEdit()}
                            columns={columns}
                            keyField={"product_order_detail_id"}
                            data={orderDetailsList}
                            selectRow={{
                                mode: 'checkbox',
                                clickToEdit: true,
                                onSelect: (row, isSelect) => {
                                    if (isSelect) setSelectedData([...selectedData, row])
                                    else setSelectedData(selectedData.filter(r => r.product_order_detail_id !== row.product_order_detail_id))
                                },
                                onSelectAll: (isSelect, rows) => {
                                    if (isSelect) setSelectedData(rows)
                                    else setSelectedData([])
                                },
                                selected: selectedData.map(r => r.product_order_detail_id)
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Annuleren
                        </Button>
                        {!printReady ? <SubmitButton title={"Opslaan"} variant="primary"/> :
                            instance.loading ? <Button><Spinner animation="border" variant="light" size="sm"/></Button>
                                : <a href={instance.url}
                                     download={`${order.product_order_reference} ${order.project_code} PAKBON.pdf`}>
                                    <Button onClick={() => setPrintReady(false)} variant={"success"}>Download</Button>
                                </a>
                        }
                    </Modal.Footer>
                </FormikForm>
            </Modal>
        </>
    );
}

export default ProductOrdersPakbonModal;

/**
 * Represents a document component with a specific layout and content.
 *
 * @param {Object} order - The order information.
 * @param {Array} values - An array of values for rendering address information.
 * @param {Array} data - An array of data to be rendered in a table.
 * @returns {React.Component} - The document component.
 */
const MyDoc = ({order, values, data}) => {
    return (
        <Document>
            <Page style={styles.body}>
                <View style={{flexDirection: "row", justifyContent: "space-between"}}>
                    <Image style={styles.yconLogo} src={Logo}/>
                </View>
                <View style={{flexDirection: "row", justifyContent: "space-between", marginBottom: 20, marginTop: 50}}>
                    <View style={{marginLeft: 20}}>
                        <Text style={styles.title}>AFLEVERADRES</Text>
                        <View style={{flexDirection: "row"}}>
                            <View style={{marginRight: 15}}>
                                <Text style={styles.textSmallAdres}>ADRES</Text>
                                <Text style={styles.textSmall}>CONTACT</Text>
                                <Text style={styles.textSmall}>DATUM</Text>
                                {values.comment ? <Text style={styles.textSmall}>OPMERKING</Text> : null}
                            </View>
                            <View style={{marginRight: 15}}>
                                <Text style={styles.textSmallAdres}>:</Text>
                                <Text style={styles.textSmall}>:</Text>
                                <Text style={styles.textSmall}>:</Text>
                                {values.comment ? <Text style={styles.textSmall}>:</Text> : null}
                            </View>
                            {values.customer_name ? <View>
                                <Text style={styles.textSmall}>{values?.customer_name}</Text>
                                <Text style={styles.textSmall}>{values?.adress}</Text>
                                <Text style={styles.textSmall}>{`${values?.zipcode} ${values?.city}`}</Text>
                                <Text style={styles.textSmall}>{values?.contact}</Text>
                                <Text style={styles.textSmall}>{moment().format("DD-MM-YYYY")}</Text>
                                {values.comment ? <Text style={styles.textSmall}>{values.comment}</Text> : null}
                            </View> : null}
                        </View>
                    </View>
                    <View style={{width: 200}}>
                        <Text style={styles.textSmallRightBold}>Nordomatic Belgium BV</Text>
                        <Text style={styles.textSmallRight}>Herentalsesteenweg 81A</Text>
                        <Text style={styles.textSmallRight}>2280 Grobbendonk, Belgium</Text>
                        <Text style={styles.textSmallRight}>BTW nr: BE0826403970</Text>
                        <Text style={styles.textSmallRight}>IBAN: BE76 0016 1290 6195</Text>
                        <Text style={styles.textSmallRight}>(+32) 1449 0280</Text>
                        <Text style={styles.textSmallRight}>sm-be-info@nordomatic.com</Text>
                    </View>
                </View>
                <View style={{
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                    paddingVertical: 2,
                    alignContent: "center",
                    justifyContent: "center",
                    marginBottom: 20
                }}>
                    <Text style={styles.pageTitle}> PAKBON
                        - {order.product_order_reference} / {order.project_name} </Text>
                </View>
                <View>
                    <Table style={styles.tableStyle}
                           data={data}>
                        <TableHeader style={styles.tableStyle} includeTopBorder={false} includeRightBorder={false}
                                     includeLeftBorder={false}>
                            <TableCell style={styles.tableStyleHeader} weighting={0.3}>
                                PRODUCT
                            </TableCell>
                            <TableCell style={styles.tableStyleHeader} weighting={0.6}>
                                BESCHRIJVING
                            </TableCell>
                            <TableCell style={styles.tableStyleHeader} weighting={0.1}>
                                QTY
                            </TableCell>
                        </TableHeader>
                        <TableBody includeTopBorder={false} includeRightBorder={false} includeLeftBorder={false}
                                   includeBottomBorder={false}>
                            <DataTableCell style={styles.tableStyleCell}
                                           weighting={0.3}
                                           getContent={(r) => r.product_name === "DIV" ? r.product_order_detail_comment : r.product_name}
                            />
                            <DataTableCell style={styles.tableStyleCell}
                                           weighting={0.6}
                                           getContent={(r) => r.product_description}
                            />
                            <DataTableCell style={styles.tableStyleCell}
                                           weighting={0.1}
                                           getContent={(r) => r.product_order_detail_amount}
                            />
                        </TableBody>
                    </Table>
                </View>
            </Page>
        </Document>
    )
};

Font.register({
    family: 'Roboto', fonts: [
        {src: RobotoRegular}, // font-style: normal, font-weight: normal
        {src: RobotoItalic, fontStyle: 'italic'},
        {src: RobotoBoldItalic, fontStyle: 'italic-bold'},
        {src: RobotoBold, fontStyle: 'bold'},
    ]
});

Font.registerHyphenationCallback(word => [word]);

/**
 * @typedef {Object} Styles
 * @property {Object} body - The style for the body.
 * @property {number} body.paddingTop - The top padding of the body.
 * @property {number} body.paddingBottom - The bottom padding of the body.
 * @property {number} body.paddingHorizontal - The horizontal padding of the body.
 * @property {number} body.fontSize - The font size of the body.
 * @property {Object} pageTitle - The style for the page title.
 * @property {string} pageTitle.fontFamily - The font family of the page title.
 * @property {string} pageTitle.fontStyle - The font style of the page title.
 * @property {number} pageTitle.fontSize - The font size of the page title.
 * @property {string} pageTitle.textAlign - The text alignment of the page title.
 * @property {Object} title - The style for the title.
 * @property {string} title.fontFamily - The font family of the title.
 * @property {string} title.fontStyle - The font style of the title.
 * @property {number} title.fontSize - The font size of the title.
 * @property {number} title.marginBottom - The bottom margin of the title.
 * @property {Object} titleRight - The style for the title aligned to the right.
 * @property {string} titleRight.fontFamily - The font family of the title.
 * @property {string} titleRight.fontStyle - The font style of the title.
 * @property {number} titleRight.fontSize - The font size of the title.
 * @property {string} titleRight.textAlign - The text alignment of the title.
 * @property {number} titleRight.marginBottom - The bottom margin of the title.
 * @property {Object} subTitle - The style for the subtitle.
 * @property {string} subTitle.fontFamily - The font family of the subtitle.
 * @property {string} subTitle.fontStyle - The font style of the subtitle.
 * @property {number} subTitle.fontSize - The font size of the subtitle.
 * @property {string} subTitle.textAlign - The text alignment of the subtitle.
 * @property {Object} textSmall - The style for the small text.
 * @property {string} textSmall.fontFamily - The font family of the small text.
 * @property {number} textSmall.fontSize - The font size of the small text.
 * @property {number} textSmall.lineHeight - The line height of the small text.
 * @property {Object} textSmallRight - The style for the small text aligned to the right.
 * @property {string} textSmallRight.fontFamily - The font family of the small text.
 * @property {number} textSmallRight.fontSize - The font size of the small text.
 * @property {string} textSmallRight.textAlign - The text alignment of the small text.
 * @property {number} textSmallRight.lineHeight - The line height of the small text.
 * @property {Object} textSmallRightBold - The style for the bold small text aligned to the right.
 * @property {string} textSmallRightBold.fontFamily - The font family of the bold small text.
 * @property {string} textSmallRightBold.fontStyle - The font style of the bold small text.
 * @property {number} textSmallRightBold.fontSize - The font size of the bold small text.
 * @property {string} textSmallRightBold.textAlign - The text alignment of the bold small text.
 * @property {number} textSmallRightBold.lineHeight - The line height of the bold small text.
 * @property {Object} textSmallAdres - The style for the small address text aligned to the right.
 * @property {string} textSmallAdres.fontFamily - The font family of the small address text.
 * @property {number} textSmallAdres.fontSize - The font size of the small address text.
 * @property {string} textSmallAdres.textAlign - The text alignment of the small address text.
 * @property {number} textSmallAdres.lineHeight - The line height of the small address text.
 * @property {Object} tableStyle - The style for the table.
 * @property {number} tableStyle.fontSize - The font size of the table.
 * @property {string} tableStyle.justifyContent - The content justification of the table.
 * @property {number} tableStyle.marginBottom - The bottom margin of the table.
 * @property {Object} tableStyleCell - The style for the table cell.
 * @property {number} tableStyleCell.fontSize - The font size of the table cell.
 * @property {string} tableStyleCell.justifyContent - The content justification of the table cell.
 * @property {number} tableStyleCell.padding - The padding of the table cell.
 * @property {string} tableStyleCell.borderRightColor - The border right color of the table cell.
 * @property {string} tableStyleCell.borderBottom - The bottom border style of the table cell.
 * @property {Object} tableStyleHeader - The style for the table header.
 * @property {number} tableStyleHeader.fontSize - The font size of the table header.
 * @property {string} tableStyleHeader.justifyContent - The content justification of the table header.
 * @property {number} tableStyleHeader.padding - The padding of the table header.
 * @property {string} tableStyleHeader.borderRightColor - The border right color of the table header.
 * @property {Object} yconLogo - The style for the Ycon logo.
 * @property {number} yconLogo.top - The top position of the Ycon logo.
 * @property {number} yconLogo.width - The width of the Ycon logo.
 * @property {string} yconLogo.height - The height of the Ycon logo.
 */
const styles = StyleSheet.create(
    {
        body: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 50,

            fontSize: 10
        },
        pageTitle: {
            fontFamily: "Roboto",
            fontStyle: "bold",
            fontSize: 16,
            textAlign: "center",

        },
        title: {
            fontFamily: "Roboto",
            fontStyle: "bold",
            fontSize: 12,
            marginBottom: 5,

        },
        titleRight: {
            fontFamily: "Roboto",
            fontStyle: "bold",
            fontSize: 12,
            textAlign: "right",
            marginBottom: 5
        },
        subTitle: {
            fontFamily: "Roboto",
            fontStyle: "bold",
            fontSize: 11,
            textAlign: "right",

        },
        textSmall: {
            fontFamily: "Roboto",
            fontSize: 9,
            lineHeight: 1.3,
        },
        textSmallRight: {
            fontFamily: "Roboto",
            fontSize: 9,
            textAlign: "right",
            lineHeight: 1.3,
        },
        textSmallRightBold: {
            fontFamily: "Roboto",
            fontStyle: "bold",
            fontSize: 9,
            textAlign: "right",
            lineHeight: 1.3,
        },
        textSmallAdres: {
            fontFamily: "Roboto",
            fontSize: 9,
            textAlign: "right",
            lineHeight: 3.9,
        },
        tableStyle: {
            fontSize: 9,
            justifyContent: "start",
            marginBottom: 20,
        },
        tableStyleCell: {
            fontSize: 9,
            justifyContent: "start",
            padding: 5,
            borderRightColor: "#ffffff",
            borderBottom: "1 solid #cccccc"
        },
        tableStyleHeader: {
            fontSize: 9,
            justifyContent: "start",
            padding: 5,
            borderRightColor: "#ffffff"
        },
        yconLogo: {top: 0, width: "auto", height: 40}

    }
)