import React from "react";

import SidebarNavList from "./SidebarNavList";

/**
 * Represents a sidebar navigation section component.
 * @param {Object} props - The properties for the component.
 * @param {string} props.title - The title of the section.
 * @param {Array} props.pages - The list of pages for the section.
 * @param {string} props.className - The custom CSS class name for the section.
 * @returns {JSX.Element} The rendered sidebar navigation section.
 */
const SidebarNavSection = (props) => {
  const { title, pages, className, ...rest } = props;

  return (
    <React.Fragment {...rest}>
      {title && <li className="sidebar-header">{title}</li>}
      <SidebarNavList pages={pages} depth={0} />
    </React.Fragment>
  );
};

export default SidebarNavSection;
