import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import usePalette from "../../../../hooks/usePalette";
import debtorsApi from "../../../../api/debtors";
import BootstrapTable from "react-bootstrap-table-next";
import {NavLink} from "react-router-dom";
import {euroFormat} from "../../../../utils/usefullFunctions";
import notyfApi from "../../../../utils/notyfApi";
import moment from "moment";

/**
 * Represents a configuration object for rendering columns in a table.
 *
 * @typedef {Object} ColumnConfig
 * @property {string} dataField - The name of the data field to display in the column.
 * @property {string} text - The header text for the column.
 * @property {boolean} [hidden] - Indicates if the column should be hidden. Default is false.
 * @property {FormatterFunction} [formatter] - The function to format the cell value of the column.
 */
const columns = [
    {
        dataField: "projects_invoices_id",
        text: "id",
        hidden: true,
    },
    {
        dataField: "projects_invoices_invoicenumber",
        text: "Bedrijf",
        formatter: (c, r) => <NavLink to={`/invoice/${r.projects_invoices_id}`}>{c}</NavLink>,
    },
    {
        dataField: "projects_naw_company_name",
        text: "Bedrijf",
        formatter: (c, r) => <NavLink to={`/debtor/${r.projects_naw_id}`}>{c}</NavLink>,
    },
    {
        dataField: "projects_invoices_outstanding_amount",
        text: "Openstaand",
        formatter: c => euroFormat(parseFloat(c)),
    },
    {
        dataField: "projects_invoices_expiration_date",
        text: "Verval datum",
        formatter: c => moment(c, "YYYY-MM-DD").format("DD-MM-YYYY"),
    },
];

/**
 * Represents a Debtor Dashboard Modal component.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The content to be displayed inside the modal.
 * @param {Date} props.before - The start date to fetch invoices from.
 * @param {Date} props.after - The end date to fetch invoices from.
 * @returns {ReactNode} The DebtorDashboardModal component.
 */
const DebtorDashboardModal = ({children, before, after}) => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        getData()
        setShow(true)
    };

    const getData = async () => {
        const res = await debtorsApi.getInvoicesByDate(before, after)
        if (!res.ok) return notyfApi.error("Error " + res.status)
        setData(res.data)
    }

    return (
        <>
            <span onClick={handleShow} className={"cursor-pointer"} style={{color: usePalette().primary}}>
                {children}
            </span>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Facturen
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BootstrapTable
                        keyField="projects_invoices_id"
                        data={data}
                        columns={columns}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default DebtorDashboardModal;