import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import {NavLink} from "react-router-dom";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";

/**
 * Represents a search bar component in the application.
 *
 * @class
 */
const {SearchBar} = Search;
/**
 * Represents the columns for a sales offer table.
 *
 * @typedef {Object} Column
 * @property {string} dataField - The name of the property in the data object to display in the column.
 * @property {string} text - The text to display in the column header.
 * @property {function} formatter - The function to format the cell value. Takes in the cell value and the row data as parameters and returns the formatted JSX element.
 * @property {boolean} hidden - Whether the column should be hidden initially.
 * @property {boolean} sort - Whether the column should be sortable.
 */
const columns = [
    {
        dataField: "sales_offer_id",
        text: "sales_offer_id",
        hidden: true,
    },
    {
        dataField: "sales_offer_reference",
        text: "Referentie",
        formatter: (cell, row) => <NavLink
            to={`/offer/${row.sales_offer_id}`}>{cell}</NavLink>,
        sort: true,
    },
    {
        dataField: "sales_offer_name",
        text: "Offertenaam",
        formatter: (cell, row) => <NavLink
            to={`/offer/${row.sales_offer_id}`}>{cell}</NavLink>,
        sort: true,
    },
    {
        dataField: "sales_offer_status",
        text: "Status",
        formatter: (cell) => {
            if (cell === "Binnengehaald") return <span className="badge bg-success">Binnengehaald</span>;
            else if (cell === "Gemist") return <span className="badge bg-danger">Gemist</span>;
            else if (cell === "Open") return <span className="badge bg-info">Open</span>;
            else return <span className="badge bg-warning text-dark">Onbekend</span>;
        },
        sort: true,
    },
    {
        dataField: "sales_offer_created_on",
        text: "Datum",
        formatter: (cell) => moment(cell).format("DD MMMM YYYY"),
        sort: true,
    },
];

/**
 * Creates a table for displaying contact detail offers.
 *
 * @param {Object} detailOffers - The detail offers data to be displayed in the table.
 * @returns {HTMLElement} - The HTML element representing the table.
 */
export default function NawContactsDetailOffersTable({detailOffers}) {
    return (
        <ToolkitProvider
            keyField="sales_offer_id"
            data={detailOffers}
            columns={columns}
            search
        >
            {(props) => (
                <div>
                    <SearchBar srText={null}
                               {...props.searchProps}
                               placeholder={"Filter: Typ om te zoeken"}/>
                    <hr/>
                    <BootstrapTable
                        {...props.baseProps}
                        pagination={paginationFactory()}
                    />
                </div>
            )}
        </ToolkitProvider>
    );
}



