import {create} from "apisauce";
import {getCookie, setCookie} from "../../utils/cookies";
import jwtDecode from "jwt-decode";

/**
 * Creates an API client object.
 *
 * @param {Object} config - The configuration object.
 * @param {string} config.baseURL - The base URL of the API.
 * @param {Object} config.headers - The headers to be included in the API requests.
 * @returns {Object} - The API client object.
 */
const apiClient = create({
    baseURL: process.env.REACT_APP_PORTAL_URL,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
    },
});

/**
 * Creates an API client object.
 *
 * @param {Object} config - The configuration object.
 * @param {string} config.baseURL - The base URL of the API.
 * @param {Object} config.headers - The headers to be included in the API requests.
 * @returns {Object} - The API client object.
 */
export const apiProductsClient = create({
    baseURL: process.env.REACT_APP_PRODUCT_URL,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
    },
});

apiClient.addAsyncRequestTransform(async (request) => {

    const authToken = getCookie("ibess_auth0_token")
    if (jwtDecode(authToken).exp < Math.round(Date.now() / 1000)) {
        setCookie("ibess_auth0_token", null)
        window.location.reload()
        return;
    }

    if (!authToken) return;
    if (!request.headers["authorization"]) request.headers["authorization"] = "Bearer " + authToken;

});

apiProductsClient.addAsyncRequestTransform(async (request) => {

    const authToken = getCookie("ibess_auth0_token")
    if (jwtDecode(authToken).exp < Math.round(Date.now() / 1000)) {
        setCookie("ibess_auth0_token", null)
        window.location.reload()
        return;
    }

    if (!authToken) return;
    if (!request.headers["authorization"]) request.headers["authorization"] = "Bearer " + authToken;

});

export default apiClient;
