import {Button, Modal} from "react-bootstrap";
import {useState} from "react";
import {faUmbrellaBeach} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

/**
 * Opens a modal for selecting vacation week and handles the submission.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.handleVacation - The function to be called when submitting the vacation week.
 * @returns {JSX.Element} - The vacation week modal component.
 */
export default function VacationWeekModal({handleVacation}) {

    const [show, setShow] = useState(false)

    const handleSubmit = () => {
        setShow(false)
        handleVacation()
    }
    return <>
        <Button variant={"warning"} style={{position: "absolute", right: 20, top: 70,}} onClick={() => setShow(true)}>
            <FontAwesomeIcon icon={faUmbrellaBeach}
                             className={window.screen.width <= 576 ? "" : "me-1"}/> <span
            className="hideOn576 ">Vakantie</span></Button>
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Vakantie
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Weet je zeker dat je elke dag als vakantie wilt invullen voor deze week? Je weekstaat moet leeg zijn
                hiervoor.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Annuleren
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Opslaan
                </Button>

            </Modal.Footer>
        </Modal>
    </>
}