import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte

// Create an instance of Notyf
/**
 * Represents an instance of the Notyf notification library.
 *
 * @class
 * @constructor
 */
const notyf = new Notyf();

export default notyf