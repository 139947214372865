import { useContext } from "react";
import { SidebarContext } from "../contexts/SidebarContext";

/**
 * Retrieves the value of SidebarContext using useContext.
 *
 * @return {object} The value of SidebarContext.
 */
const useSidebar = () => useContext(SidebarContext);

export default useSidebar;
