import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {Form as FormikForm, FormField, SubmitButton} from "../../../components/forms";
import * as Yup from "yup";
import notyf from "../../../utils/notyfApi";
import adminRates from "../../../api/adminRates";

/**
 * Function to render a modal for adding hourly rates.
 *
 * @param {Object} options - The options object.
 * @param {function} options.reload - The function to reload the data after creating a new hourly rate.
 *
 * @return {JSX.Element} The JSX element representing the HourlyRatesAddModal component.
 */
function HourlyRatesAddModal({reload}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const createNewHourlyRate = async (data) => {
        const res = await adminRates.postNewRates(data);
        if (!res.ok) return notyf.error("Error " + res.status)
        reload();
        handleClose();
    };

    return (
        <React.Fragment>
            <button className="btn btn-primary float-end" onClick={handleShow}>+</button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Nieuw uurtarief aanmaken</Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={{
                        project_rate_reference: "",
                        project_rate_engineering: "",
                        project_rate_ibs: "",
                        project_rate_manager: "",
                        project_rate_pan: "",
                        project_rate_tek: "",
                        project_rate_warranty: "",
                        project_rate_risc: "",
                    }}

                    // Hier is het validatieschema van alle velden
                    validationSchema={Yup.object({

                        project_rate_reference: Yup.string()
                            .trim()
                            .required('Er moet een referentienaam worden ingevuld.'),
                        project_rate_engineering: Yup.number()
                            .required('Engineering moet ingevuld worden.'),
                        project_rate_ibs: Yup.number()
                            .required('In bedrijf stellen moet ingevuld worden.'),
                        project_rate_manager: Yup.number()
                            .required('Projectleider moet ingevuld worden.'),
                        project_rate_pan: Yup.number()
                            .required('Paneelbouw op locatie moet ingevuld worden.'),
                        project_rate_tek: Yup.number()
                            .required('Tekenwerk moet ingevuld worden.'),
                        project_rate_warranty: Yup.number()
                            .required('Garantie moet ingevuld worden.'),
                        project_rate_risc: Yup.number()
                            .required('Risico moet ingevuld worden.'),
                    })}

                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(false);
                        let valuesparsed = {
                            project_rate_reference: values.project_rate_reference,
                            project_rate_engineering: parseFloat(values.project_rate_engineering),
                            project_rate_ibs: parseFloat(values.project_rate_ibs),
                            project_rate_manager: parseFloat(values.project_rate_manager),
                            project_rate_pan: parseFloat(values.project_rate_pan),
                            project_rate_tek: parseFloat(values.project_rate_tek),
                            project_rate_warranty: parseFloat(values.project_rate_warranty),
                            project_rate_risc: parseFloat(values.project_rate_risc),
                        };
                        createNewHourlyRate(valuesparsed);
                    }}
                >
                    <Modal.Body>
                        <FormField name={"project_rate_reference"}
                                   label={"Referentie:*"}
                                   width={"100%"}
                                   placeholder={"Vul hier de referentienaam in"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"project_rate_engineering"}
                                   label={"Engineering uurtarief:*"}
                                   width={"100%"}
                                   placeholder="Vul het Engineeringsuurtarief hier in"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"project_rate_ibs"}
                                   label={"In bedrijf stellen uurtarief:*"}
                                   width={"100%"}
                                   placeholder={"Vul hier het IBS uurtarief in"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"project_rate_manager"}
                                   label={"Projectleider uurtarief:*"}
                                   width={"100%"}
                                   placeholder="Vul het Projectleidersuurtarief hier in"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"project_rate_pan"}
                                   label={"Paneelbouw op locatie uurtarief:*"}
                                   width={"100%"}
                                   placeholder={"Vul hier het Paneelbouw op locatie uurtarief in"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"project_rate_tek"}
                                   label={"Tekenwerk uurtarief:*"}
                                   width={"100%"}
                                   placeholder="Vul het Tekenwerk uurtarief hier in"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"project_rate_warranty"}
                                   label={"Garantie in jaren:*"}
                                   width={"100%"}
                                   placeholder={"Vul hier de Garantie in"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"project_rate_risc"}
                                   label={"Risico (%):*"}
                                   width={"100%"}
                                   placeholder={"Vul hier het Risico in"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <div
                            style={{
                                color: 'hsl(0, 0%, 40%)',
                                display: 'inline-block',
                                fontSize: 12,
                                fontStyle: 'italic',
                                marginTop: '1em',
                            }}
                        >
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Niet aanmaken
                        </Button>
                        <SubmitButton title={"Aanmaken"} variant="primary"/>
                    </Modal.Footer>
                </FormikForm>
            </Modal>
        </React.Fragment>
    );
}

export default HourlyRatesAddModal;
