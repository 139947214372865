import React, {useEffect, useState} from "react";
import {Bar} from "react-chartjs-2";
import moment from "moment/moment";
import Loader from "../../../components/Loader";


/**
 * Renders a bar chart for a specific department's productivity data.
 * @param {Object} department - The department for which to display the productivity data.
 * @param {Array} allDepData - An array of all department productivity data.
 * @param {string} hoursType - The type of hours to display (e.g. "actual", "budget").
 * @returns {JSX.Element} - The rendered bar chart component.
 */
const NewBarForDepartment = ({department, allDepData, hoursType}) => {
        const [loading, setLoading] = useState(true)
        const [actualArrayCumlatief, setActualArrayCumlatief] = useState([])
        const [actualArrayNonCumlatief, setActualArrayNonCumlatief] = useState([])
        const [budgetArrayCumlatief, setBudgetArrayCumlatief] = useState([])
        const [budgetArrayNonCumlatief, setBudgetArrayNonCumlatief] = useState([])
        const [deltaValueArray, setDeltaValueArray] = useState([])


        useEffect(() => {
            setLoading(true);
            leRunOnce();
            setLoading(false);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [allDepData]);

        const leRunOnce = async () => {
            let tempActualCumlativeArray = [];
            let additioncounterActual = 0;
            let additioncounterBudget = 0;
            let tempActualNonCumlativeArray = [];
            let tempBudgetCumlativeArray = [];
            let tempBudgetNonCumlativeArray = [];
            let newDepArray = allDepData.filter(item => item.productivity_department === department)

            newDepArray.forEach(e => {
                additioncounterActual = additioncounterActual + parseInt(e.actual_productivity_hours);
                additioncounterBudget = additioncounterBudget + parseInt(e.budget_productivity_hours);
                tempActualCumlativeArray.push(parseInt(additioncounterActual));
                tempActualNonCumlativeArray.push(parseInt(e.actual_productivity_hours));
                tempBudgetCumlativeArray.push(parseInt(additioncounterBudget));
                tempBudgetNonCumlativeArray.push(parseInt(e.budget_productivity_hours));
            })

            let DeltaArray = tempActualCumlativeArray.map((item, index) => item - tempBudgetCumlativeArray[index]);

            setActualArrayCumlatief(tempActualCumlativeArray);
            setActualArrayNonCumlatief(tempActualNonCumlativeArray);
            setBudgetArrayCumlatief(tempBudgetCumlativeArray);
            setBudgetArrayNonCumlatief(tempBudgetNonCumlativeArray);
            setDeltaValueArray(DeltaArray);
        }

        let data;
        data = {
            labels: ["Jan", "Feb", "Mrt", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
            datasets: [
                {
                    type: "line",
                    label: "Budget Cumlatief",
                    data: budgetArrayCumlatief,
                    backgroundColor: [
                        "#3498db",
                    ],
                    fill: false,
                    borderColor: "#3498db",
                    order: 3,

                },
                {
                    type: "line",
                    label: "Actual prod Cumlatief",
                    data: actualArrayCumlatief,
                    backgroundColor: [
                        "#2ecc71",
                    ],
                    fill: false,
                    borderColor: "#2ecc71",
                    order: 1,
                },
                {
                    type: "line",
                    label: "Delta",
                    data: deltaValueArray,
                    backgroundColor: [
                        "red",
                    ],
                    fill: false,
                    borderColor: "red",
                    order: 1,

                },
                {
                    label: "Actual prod",
                    data: actualArrayNonCumlatief,
                    backgroundColor: [
                        "#2ecc71",
                        "#2ecc71",
                        "#2ecc71",
                        "#2ecc71",
                        "#2ecc71",
                        "#2ecc71",
                        "#2ecc71",
                        "#2ecc71",
                        "#2ecc71",
                        "#2ecc71",
                        "#2ecc71",
                        "#2ecc71",
                    ],
                    borderColor: "transparent",
                    order: 2,
                },
                {
                    label: "Budget",
                    data: budgetArrayNonCumlatief,
                    backgroundColor: [
                        "#3498db",
                        "#3498db",
                        "#3498db",
                        "#3498db",
                        "#3498db",
                        "#3498db",
                        "#3498db",
                        "#3498db",
                        "#3498db",
                        "#3498db",
                        "#3498db",
                        "#3498db",
                    ],
                    borderColor: "transparent",
                    order: 2,
                },

            ],
        };

        const options = {
            maintainAspectRatio: false,
            legend: {
                display: true,
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        let dataset = data.datasets[tooltipItem.datasetIndex];
                        let currentValue = dataset.data[tooltipItem.index];
                        return currentValue ;
                    },
                    title: function (tooltipItem, data) {
                        return data.labels[tooltipItem[0].index];
                    }
                }
            },
            scales: {
                yAxes: [
                    {
                        gridLines: {
                            display: true,
                        },
                        stacked: false,
                        ticks: {
                            stepSize: 200,
                        },
                    },
                ],
                xAxes: [
                    {
                        stacked: false,
                        gridLines: {
                            color: "transparent",
                        },
                    }
                ],
            }
        };

        if (loading) return <Loader/>
        return (
            <>
                <Bar data={data} options={options}/>
            </>
        )
            ;
    }
;


export default NewBarForDepartment;
