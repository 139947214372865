import {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import productsApi from "../../../api/products";
import {useNavigate, useParams} from "react-router-dom";
import notyf from "../../../utils/notyfApi";

/**
 * Displays a modal for deleting a product item.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.product - The product to be deleted.
 *
 * @return {ReactElement} The rendered delete item modal.
 */
function ProductDeleteItemModal({product}) {
    const {id} = useParams();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDelete = async () => {
        const res = await productsApi.deleteProduct(id)
        if (!res.ok) return notyf.error("Error " + res.status)
        navigate("/products_overview")
    }

    return (
        <>
            <Button variant="danger" className={"me-2 float-end mb-0"} onClick={handleShow}>
                Verwijderen
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> Verwijder product </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Weet je zeker dat je {product.product_name} wilt verwijderen?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Niet aanpassen
                    </Button>
                    <Button variant="primary" onClick={handleDelete}> Verwijderen </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ProductDeleteItemModal;
