import React, {useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {Form as FormikForm, FormField, FormFieldAddress, SubmitButton} from "../../../components/forms";
import * as Yup from "yup";
import nawContactsApi from "../../../api/nawContacts";
import FormSelectFieldAccounts from "../../../components/forms/FormSelectFieldAccounts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import notyf from "../../../utils/notyfApi";

/**
 * Function to render the modal for adding or editing a contact.
 *
 * @param {object} options - The options object.
 * @param {boolean} options.exists - Flag indicating whether the contact already exists.
 * @param {function} options.reload - Function to reload the contacts list after adding or editing a contact.
 * @param {object} options.company - The company object.
 */
function NawContactsAddContactModal({exists, reload, company}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const createNawUserData = async (data) => {
        let res
        if (exists) {
            res = await nawContactsApi.editNawContact(exists.sales_contact_id, {
                ...data,
                sales_contact_account_id: parseInt(data.sales_contact_account_id),
            });
        } else {
            res = await nawContactsApi.PostNewNawContact({
                ...data,
                sales_contact_account_id: parseInt(data.sales_contact_account_id),
            });
        }

        if (!res.ok) return notyf.error("Error " + res.status)
        reload()
        handleClose();

    };


    //regex voor telefoon
    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

    return (
        <React.Fragment>
            <Button variant="primary"
                    className={exists ? exists.sales_contact_id ? "btn-576-float-none float-end added-tiny-margin" :
                            "float-end added-tiny-margin" :
                        "float-end added-tiny-margin"}
                    onClick={handleShow}>
                {exists ? exists.sales_contact_id ? <><FontAwesomeIcon icon={faEdit}
                                                                       className={"me-1 marginResetOn576"}/> <span
                    className={"hideOn576"}>Aanpassen</span></> : "+" : "+"}
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{exists ? "Contact aanpassen" : "Nieuwe contact toevoegen"}</Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={{
                        sales_contact_title: exists?.sales_contact_title || "",
                        sales_contact_firstname: exists?.sales_contact_firstname || "",
                        sales_contact_surname_prefix: exists?.sales_contact_surname_prefix || "",
                        sales_contact_lastname: exists?.sales_contact_lastname || "",
                        sales_contact_account_id: exists?.sales_contact_account_id || company?.sales_contact_account_id || "",
                        sales_contact_work_nr: exists?.sales_contact_work_nr || "",
                        sales_contact_mobile_nr: exists?.sales_contact_mobile_nr || "",
                        sales_contact_email: exists?.sales_contact_email || "",
                        sales_contact_job_title: exists?.sales_contact_job_title || "",
                        sales_contact_personal_city: exists?.sales_contact_personal_city || "",
                        sales_contact_personal_country: exists?.sales_contact_personal_country || "",
                        sales_contact_personal_email: exists?.sales_contact_personal_email || "",
                        sales_contact_personal_phone_nr: exists?.sales_contact_personal_phone_nr || "",
                        sales_contact_personal_postal_code: exists?.sales_contact_personal_postal_code || "",
                        sales_contact_personal_province: exists?.sales_contact_personal_province || "",
                        sales_contact_personal_street: exists?.sales_contact_personal_street || "",
                        sales_contact_personal_street_nr: exists?.sales_contact_personal_street_nr || "",
                    }}

                    // Hier is het validatieschema van alle velden
                    validationSchema={Yup.object({
                        sales_contact_title: Yup.string()
                            .required('Aanspreektitel moet ingevuld worden.'),
                        sales_contact_firstname: Yup.string()
                            .max(100, 'Moet minder dan 100 karakters zijn')
                            .required('Voornaam moet ingevuld worden.'),
                        sales_contact_surname_prefix: Yup.string()
                            .max(50, 'Moet minder dan 50 karakters zijn'),
                        sales_contact_lastname: Yup.string()
                            .max(100, 'Moet minder dan 100 karakters zijn')
                            .required('Achternaam moet ingevuld worden.'),
                        sales_contact_account_id: Yup.number()
                            .required('Bedrijf moet ingevuld worden.'),
                        sales_contact_work_nr: Yup.string()
                            .matches(phoneRegExp, 'Telefoonnummer klopt niet. (voldoet niet aan regex expressie)'),
                        sales_contact_mobile_nr: Yup.string()
                            .matches(phoneRegExp, 'Telefoonnummer klopt niet. (voldoet niet aan regex expressie)'),
                        sales_contact_email: Yup.string()
                            .email()
                            .required('Email moet ingevuld worden.'),
                        sales_contact_job_title: Yup.string()
                    })}

                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(false);
                        createNawUserData(values);
                    }}
                >
                    <Modal.Body>
                        <Row>
                            <Col lg={2}>
                                <FormField name={"sales_contact_title"}
                                           label={"Aanspreektitel:*"}
                                           width={"100%"}
                                           placeholder="Vul het type hier in"
                                           className={"mb-3"}
                                           as={"select"}
                                >
                                    <option value="">-</option>
                                    <option value="Dhr.">Dhr.</option>
                                    <option value="Mevr.">Mevr.</option>
                                </FormField>
                            </Col>
                            <Col>
                                <FormField name={"sales_contact_firstname"}
                                           label={"Voornaam:*"}
                                           width={"100%"}
                                           placeholder={"Vul hier de voornaam in"}
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                            <Col lg={2}>
                                <FormField name={"sales_contact_surname_prefix"}
                                           label={"Tussenvoegsel:"}
                                           width={"100%"}
                                           placeholder={"Vul hier het tussenvoegsel in"}
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                            <Col>
                                <FormField name={"sales_contact_lastname"}
                                           label={"Achternaam:*"}
                                           width={"100%"}
                                           placeholder="Vul de achternaam hier in"
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                        </Row>
                        <FormSelectFieldAccounts name={"sales_contact_account_id"}
                                                 className={"mb-3"}
                                                 label={"Bedrijf:*"}/>
                        <Row>
                            <Col>
                                <FormField name={"sales_contact_work_nr"}
                                           label={"Werk telefoonnummer:"}
                                           width={"50%"}
                                           placeholder="Vul het werk telefoonnummer hier in"
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                            <Col>
                                <FormField name={"sales_contact_mobile_nr"}
                                           label={"Mobiele telefoonnummer:"}
                                           width={"50%"}
                                           placeholder="Vul het mobiele telefoonnummer hier in"
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                        </Row>
                        <FormField name={"sales_contact_email"}
                                   label={"Email:*"}
                                   width={"100%"}
                                   placeholder={"Vul hier de email in"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"sales_contact_job_title"}
                                   label={"Functie:"}
                                   width={"100%"}
                                   placeholder={"Vul hier de functie in"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <h3>Persoonlijke details</h3>
                        <hr/>
                        <FormField name={"sales_contact_personal_email"}
                                   label={"Email:"}
                                   width={"100%"}
                                   placeholder={"Vul hier de email in"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"sales_contact_personal_phone_nr"}
                                   label={"Telefoonnummer:"}
                                   width={"100%"}
                                   placeholder={"Vul hier het telefoonnummer in"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormFieldAddress names={{
                            number: "sales_contact_personal_street_nr",
                            street: "sales_contact_personal_street",
                            city: "sales_contact_personal_city",
                            state: "sales_contact_personal_province",
                            zip: "sales_contact_personal_postal_code",
                            country: "sales_contact_personal_country",
                        }}
                                          label={"Adres:"}
                        />
                        <div
                            style={{
                                color: 'hsl(0, 0%, 40%)',
                                display: 'inline-block',
                                fontSize: 12,
                                fontStyle: 'italic',
                                marginTop: '1em',
                            }}
                        >
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Niet aanmaken
                        </Button>
                        <SubmitButton title={"Opslaan"} variant="primary"/>
                    </Modal.Footer>
                </FormikForm>
            </Modal>
        </React.Fragment>
    );
}

export default NawContactsAddContactModal;
