import React from "react";
import {useFormikContext} from "formik";
import {Button} from "react-bootstrap";

/**
 * Renders a submit button component.
 *
 * @param {Object} props - The props for the submit button.
 * @param {string} props.title - The title of the button.
 * @param {boolean} [props.disabled=false] - Indicates if the button is disabled. Default is false.
 *
 * @return {ReactElement} The submit button component.
 */
function SubmitButton({title, disabled = false}) {
    const {handleSubmit} = useFormikContext();
    return <Button onClick={handleSubmit} disabled={disabled}>{title}</Button>;
}

export default SubmitButton;
