import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import React from "react";
import {NavLink} from "react-router-dom";
import {Badge, Row} from "react-bootstrap";

/**
 * Renders a table of project result detail orders.
 *
 * @param {Object} options - The*/
function ProjectResultDetailOrdersTable({data}) {
    const {SearchBar} = Search;
    const columns = [
        {
            dataField: "product_order_id",
            text: "id",
            hidden: true,
        },
        {
            dataField: "product_order_reference",
            text: "Order",
            formatter: (c, r) => <NavLink to={`/order/${r.product_order_id}`}>{c}</NavLink>,
            sort: true,
        },
        {
            dataField: "project_order_order_status",
            text: "Status",
            formatter: c => {
                if (c === "geleverd") return <Badge bg={"success"}>GELEVERD</Badge>
                if (c === "retour") return <Badge bg={"danger"}>RETOUR</Badge>
                if (c === "incompleet") return <Badge bg={"secondary"}>INCOMPLEET</Badge>
                return <Badge bg={"info"}>OPENSTAAND</Badge>
            },
            sort: true,
        },
        {
            dataField: "product_supplier_name",
            text: "Leverancier",
            sort: true,
        },
    ];

    if (data.length > 0) return (
        <ToolkitProvider keyField="product_order_id" data={data} columns={columns} search>
            {(props) => (
                <div>
                    <SearchBar srText={null}
                               {...props.searchProps}
                               placeholder={"Filter: Typ om te zoeken"}/>
                    <hr/>
                    <BootstrapTable
                        {...props.baseProps}
                        pagination={paginationFactory()}
                    />
                </div>
            )}
        </ToolkitProvider>
    );
    else
        return (
            <Row className="justify-content-center align-self-center w-100 text-center">
                Er zijn geen bestellingen gevonden.
            </Row>
        );


}

export default ProjectResultDetailOrdersTable;
