import {Button, Card, Col, Form, Modal, Row, Tab, Tabs} from "react-bootstrap";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {Edit2, Trash,} from "react-feather";
import * as Yup from "yup";
import {Form as FormikForm, FormField, FormFieldDatePicker, SubmitButton} from "../../../components/forms";
import adminUsersApi from "../../../api/adminUsers";
import projectsApi from "../../../api/projects";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import notyf from "../../../utils/notyfApi";

/**
 * Represents a component that displays activity menu for a specific project.
 *
 * @param {Object} props - The props object.
 * @param {number} props.id - The ID of the project.
 * @param {string} props.project - The name of the project.
 *
 * @returns {JSX.Element} The rendered component.
 */
const ActivityMenu = ({id, project}) => {
    const [messages, setMessages] = useState([])
    const [tasks, setTasks] = useState([])
    useEffect(() => {
        getTasks()
        getMessages()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project])

    const getMessages = async () => {

        const resMessages = await projectsApi.getProjectMessages(id)
        if (!resMessages.ok) return notyf.error("Error " + resMessages.status)

        setMessages(resMessages.data)
    }
    const getTasks = async () => {

        const resTasks = await projectsApi.getProjectTasks(id)
        if (!resTasks.ok) return notyf.error("Error " + resTasks.status)

        setTasks(resTasks.data)
    }
    const deleteMessage = async (mId) => {

        const r = await projectsApi.deleteProjectMessages(id, mId)
        if (!r.ok) return notyf.error("Error " + r.status)

        getMessages()
    }

    const deleteTask = async (mId) => {

        const r = await projectsApi.deleteProjectTasks(id, mId)
        if (!r.ok) return notyf.error("Error " + r.status)
        getTasks()
    }
    const handleTaskToggle = async (task) => {

        const r = await projectsApi.editProjectTasks(id, task.projects_task_id, {
            ...task,
            projects_task_completed: !task.projects_task_completed
        })
        if (!r.ok) return notyf.error("Error " + r.status)
        getTasks()
    }
    return (<Card>
            <Card.Body>
                <Tabs defaultActiveKey="Berichten">
                    <Tab eventKey="Berichten" title="Berichten">
                        <div className={"pt-3"}>
                            <Row><Col> <GuardWrapper role={"write:projects"}>
                                <AddMessageModal id={id} reload={getMessages}/>
                            </GuardWrapper>
                            </Col></Row>
                            {!messages.length &&
                                <Row className="justify-content-center align-self-center w-100 text-center">
                                    Geen berichten gevonden.
                                </Row>}
                            {messages.map(m => <div key={m.project_comment_id}>
                                <Row>
                                    <Col className={"d-80-left"}>
                                        <div>
                                            <strong>{m.user_name}</strong>
                                            <br/>
                                            <p>{m.project_comment_comment}</p>
                                            <small
                                                className="text-muted">{moment(m.project_comment_created_on).format("DD-MM-YYYY HH:mm")}</small>
                                            <br/>
                                        </div>
                                    </Col>
                                    <Col lg={1} className={"d-20-right"}>
                                        <Trash className={"cursor-pointer mt-2 d-float-right"}
                                               onClick={() => deleteMessage(m.project_comment_id)}/>
                                    </Col>
                                </Row>

                            </div>)}
                        </div>
                    </Tab>
                    <Tab eventKey="Tasks" title="Taken">
                        <div className={"pt-3"}>

                            <Row>
                                <Col>
                                    <GuardWrapper role={"write:projects"}>
                                        <AddTicketModal id={id} exists={false} reload={getTasks}/>
                                    </GuardWrapper>
                                </Col>
                            </Row>
                            {!tasks.length &&
                                <Row className="justify-content-center align-self-center w-100 text-center">
                                    Geen taken gevonden.
                                </Row>}

                            {tasks.map(task => <Row key={task.projects_task_id} className="d-flex">
                                    <Col lg={2} className={"d-flex align-items-center justify-content-center d-10-left"}>
                                        <Form.Check>
                                            <Form.Check.Input className={"big-checkbox"}
                                                              type={"checkbox"}
                                                              label={`Bestaand contactpersoon`}
                                                              checked={task.projects_task_completed}
                                                              onChange={() => handleTaskToggle(task)}/>
                                        </Form.Check>
                                    </Col>
                                    <Col className={"d-70-mid"}>
                                        {task.projects_task_completed ?
                                            <del><strong>{task.projects_task_title}</strong></del> :
                                            <strong>{task.projects_task_title}</strong>}
                                        <br/>
                                        <p>{task.projects_task_comment}</p>
                                        {moment(task.projects_task_date_reminder).format("DD-MM-YYYY")}

                                        <small className="text-muted"> - {task.user_name}</small>

                                    </Col>
                                    <Col lg={2} className={"d-20-right"}>
                                        <GuardWrapper role={"write:projects"}>
                                            <AddTicketModal id={id} exists={task} reload={getTasks}/>
                                        </GuardWrapper>
                                        <Trash className={"cursor-pointer mt-2"}
                                               onClick={() => deleteTask(task.projects_task_id)}/>

                                    </Col>
                                    <hr/>
                                </Row>
                            )}

                        </div>
                    </Tab>
                </Tabs>
            </Card.Body>
        </Card>
    )
}

export default ActivityMenu


/**
 * Modal component for adding a message.
 *
 * @component
 * @param {Object} props - The props passed to the component.
 * @param {number} props.id - The ID of the project.
 * @param {Function} props.reload - A function to reload the data.
 * @returns {JSX.Element} The modal component.
 */
const AddMessageModal = ({id, reload}) => {
    const [show, setShow] = useState(false);
    const [comment, setComment] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleSubmit = async () => {
        if (comment) {
            const r = await projectsApi.postProjectMessages(id, {project_comment_comment: comment})
            if (!r.ok) return notyf.error("Error " + r.status)
            handleClose()
            reload()
        }
    }

    return (
        <>
            <Button className={"position-absolute top-0 end-0 m-3"} onClick={handleShow}>
                +
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Berichten
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Opmerking toevoegen</Form.Label>
                        <Form.Control value={comment} onChange={e => setComment(e.target.value)} as="textarea"
                                      placeholder="Opmerking"/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Annuleren
                    </Button>
                    <Button variant="primary" disabled={!comment} onClick={handleSubmit}>
                        Toevoegen
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}

/**
 * AddTicketModal component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.id - The ID of the ticket.
 * @param {boolean} props.exists - Indicates whether the ticket already exists or not.
 * @param {Function} props.reload - The function to reload the tickets.
 */
const AddTicketModal = (
    {
        id, exists, reload
    }
) => {
    const [show, setShow] = useState(false);
    const [users, setUsers] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        const res = await adminUsersApi.getUsersByRole()
        if (!res.ok) return notyf.error("Error " + res.status)
        setUsers(res.data.users)

    }

    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(false)
        if (exists) {
            let date = moment(values.projects_task_date_reminder).toDate()
            values["projects_task_date_reminder"] = moment(date).format("YYYY-MM-DD")
            const res = await projectsApi.editProjectTasks(id, exists.projects_task_id, {
                ...values,
                projects_task_completed: false
            })
            if (!res.ok) return notyf.error("Error " + res.status)
        } else {
            let date = moment(values.projects_task_date_reminder).toDate()
            values["projects_task_date_reminder"] = moment(date).format("YYYY-MM-DD")
            const res = await projectsApi.postProjectTasks(id, values)
            if (!res.ok) return notyf.error("Error " + res.status)
        }

        handleClose()
        reload()

    }

    return (
        <>
            {exists ? <Edit2 className={"cursor-pointer mt-2"} onClick={handleShow}/> :
                <Button className={"position-absolute top-0 end-0 m-3"} onClick={handleShow}>
                    +
                </Button>
            }

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Taak aanmaken
                    </Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={{
                        projects_task_title: exists?.projects_task_title || "",
                        projects_task_comment: exists?.projects_task_comment || "",
                        projects_task_date_reminder: moment(exists?.projects_task_date_reminder).toDate() || new Date(),
                        projects_task_task_user: exists?.projects_task_task_user || "",

                    }}


                    validationSchema={Yup.object({

                        projects_task_title: Yup.string().required('Titel moet ingevuld worden'),
                        projects_task_comment: Yup.string().required('Contactpersoon moet ingevuld worden').nullable(),
                        projects_task_date_reminder: Yup.date().required("Beschrijving moet ingevuld worden"),
                        projects_task_task_user: Yup.string().required("Medewerker moet gekozen worden"),
                    })
                    }

                    onSubmit={handleSubmit}
                >
                    <Modal.Body>
                        <FormField name={"projects_task_title"}
                                   label={"Taak"}
                                   width={"100%"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormFieldDatePicker
                            name={"projects_task_date_reminder"}
                            label={"Herinnering"}
                            width={"100%"}
                            className={"mb-3 form-control"}
                            control={"input"}
                        />
                        <FormField name={"projects_task_task_user"}
                                   label={"Voor"}
                                   width={"100%"}
                                   className={"mb-3"}
                                   as={"select"}
                        >
                            <option value={""}>Selecteer een eigenaar</option>
                            {users.map(user => <option key={user.user_id} value={user.user_id}>{user.name}</option>)}
                        </FormField>


                        <FormField name={"projects_task_comment"}
                                   label={"Beschrijving"}
                                   width={"100%"}
                                   className={"mb-3"}
                                   control={"input"}
                                   as={"textarea"}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => setShow(false)}>
                            Annuleren
                        </Button>
                        <SubmitButton title={"Opslaan"} variant="primary"/>
                    </Modal.Footer>
                </FormikForm>

            </Modal>
        </>
    );
}