import React from "react";

/**
 * Represents a content component.
 *
 * @component
 * @param {Object} props - The properties of the component.
 * @param {ReactNode} props.children - The content to be rendered.
 * @returns {ReactNode} The content component.
 */
const Content = ({ children }) => <div className="content">{children}</div>;

export default Content;
