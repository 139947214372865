import client from "./clients/portalClient";

/**
 * Retrieves all suppliers from the server.
 *
 * @returns {Promise} A promise that resolves to the response data containing all suppliers.
 */
const getAllSuppliers = () => client.get(`/product_suppliers`);
/**
 * Retrieves information about a specified supplier from the server.
 *
 * @param {number} id - The ID of the supplier to retrieve information for.
 * @returns {Promise<Object>} A Promise that resolves with the supplier information.
 */
const getSupplier = (id) => client.get(`/product_suppliers/${id}`);
/**
 * Retrieves the supplier orders for a given product supplier ID.
 *
 * @param {number} id - The ID of the product supplier.
 * @returns {Promise} A promise that resolves with the supplier orders details.
 */
const getSupplierOrders = (id) => client.get(`/product_suppliers/${id}/details`);
/**
 * Edit an existing supplier in the system.
 *
 * @param {string} id - The ID of the supplier to edit.
 * @param {object} data - The updated data for the supplier.
 * @throws {Error} If an error occurs while editing the supplier.
 * @returns {Promise} A Promise that resolves once the supplier is successfully edited.
 */
const editExistingSupplier = (id, data) => client.put(`/product_suppliers/${id}`, data);
/**
 * Sends a POST request to create a new supplier for a product.
 *
 * @param {object} data - The data containing the supplier details.
 * @returns {Promise} - A Promise representing the API request to create a new supplier.
 */
const postNewSupplier = (data) => client.post(`/product_suppliers`, data);

/**
 * Represents an API for managing suppliers.
 *
 * @namespace supplierApi
 */
const supplierApi = {
  getAllSuppliers,
  getSupplier,
  getSupplierOrders,
  editExistingSupplier,
  postNewSupplier
};

export default supplierApi;
