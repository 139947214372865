import React, {useEffect, useState} from "react";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {Card, Col, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {NavLink} from "react-router-dom";
import moment from "moment";
import dashboardApi from "../../../api/dashboard";
import notyf from "../../../utils/notyfApi";

/**
 * Variable to store an array of column objects.
 *
 * @type {Array<Object>}
 */
const columns = [
    {
        dataField: "id",
        text: "id",
        hidden: true
    },
    {
        dataField: "name",
        text: "Project naam",
        formatter: (c, r) => <NavLink to={`/${r.type}/${r.linkId}`}>{c}</NavLink>,
    },
    {
        dataField: "title",
        text: "Titel",
    },
    {
        dataField: "reminder",
        text: "Herinnering",
        formatter: (c) => moment(c, "YYYY-MM-DD").format("DD-MM-YYYY"),
    },
    {
        dataField: "type",
        text: "Type",
        formatter: (c) => {
            if (c === "offer") return "Offerte"
            return "Project"
        }
    },
];

/**
 * Creates a task list component.
 * @param {Array} tasks - The array of tasks to display in the task list.
 * @returns {Object} - The task list component.
 */
const TaskList = ({tasks}) => {
    return <BootstrapTable
        keyField="id"
        data={tasks}
        columns={columns}
    />
};
// TODO finished tasks
/**
 * Represents a task table.
 * @constructor
 */
const TaskTable = () => {
    const [tasks, setTasks] = useState([])
    const [finishedTasks, setFinishedTasks] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        const res = await dashboardApi.getTasks()
        if (!res.ok) return notyf.error("Error " + res.status)
        let temp = []
        let tempf = []
        for (let t of res.data.project_tasks) {
            if (!t.projects_task_completed) temp.push({
                id: t.projects_task_id,
                linkId: t.project_f_id,
                name: t.project_name,
                title: t.projects_task_title,
                reminder: t.projects_task_date_reminder,
                type: "project"
            })
            else tempf.push({
                id: t.projects_task_id,
                linkId: t.project_f_id,
                name: t.project_name,
                title: t.projects_task_title,
                reminder: t.projects_task_date_reminder,
                type: "project"
            })

        }
        for (let t of res.data.sales_offer_tasks) {
            if (!t.sales_offer_task_completed) temp.push({
                id: t.sales_offer_task_id,
                linkId: t.sales_offer_f_id,
                name: t.sales_offer_name,
                title: t.sales_offer_task_title,
                reminder: t.sales_offer_task_date_reminder,
                type: "offer"
            })
            else tempf.push({
                id: t.sales_offer_task_id,
                linkId: t.sales_offer_f_id,
                name: t.sales_offer_name,
                title: t.sales_offer_task_title,
                reminder: t.sales_offer_task_date_reminder,
                type: "offer"
            })
        }
        setTasks(temp)
        setFinishedTasks(tempf)
        setLoading(false)
    }
    if (loading) return <></>
    return (<>
            <Col lg={6}>
                <Card>
                    <Card.Header><h3>Taken</h3></Card.Header>
                    <Card.Body>
                        {tasks.length ? <TaskList tasks={tasks}/> :
                            <Row className="justify-content-center align-self-center w-100 text-center">
                                Er zijn geen taken gevonden.
                            </Row>}
                    </Card.Body>
                </Card>
                {/*<Card>*/}
                {/*    <Card.Header><h3>Afgeronde taken</h3></Card.Header>*/}
                {/*    <Card.Body>*/}
                {/*        {finishedTasks.length ? <TaskList tasks={finishedTasks}/> :*/}
                {/*            <Row className="justify-content-center align-self-center w-100 text-center">*/}
                {/*                Er zijn geen taken gevonden.*/}
                {/*            </Row>}*/}
                {/*    </Card.Body>*/}
                {/*</Card>*/}
            </Col>
        </>
    )
};

export default TaskTable;
