import {create} from "apisauce";
import {getCookie, setCookie} from "../../utils/cookies";
import jwtDecode from "jwt-decode";

/**
 * Creates an API client instance.
 *
 * @param {object} config - Configuration object for the API client.
 * @param {string} config.baseURL - The base URL for the API requests.
 * @param {object} config.headers - The headers to be included in the API requests.
 * @returns {object} - The API client instance.
 */
const apiClient = create({
    baseURL: process.env.REACT_APP_PROJECT_FORM_URL,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
    },
});

apiClient.addAsyncRequestTransform(async (request) => {

    const authToken = getCookie("ibess_auth0_token")
    if (jwtDecode(authToken).exp < Math.round(Date.now() / 1000)) {
        setCookie("ibess_auth0_token", null)
        window.location.reload()
    }
    if (!authToken) return;
    if (!request.headers["authorization"]) request.headers["authorization"] = "Bearer " + authToken;

});

export default apiClient;
