import React, {useEffect, useState} from "react";
import {useFormikContext} from "formik";
import ErrorMessage from "./ErrorMessage";
import {Form} from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import notyf from "../../utils/notyfApi";


/**
 * Render a select field for a form with options fetched from an API endpoint.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.name - The name attribute for the select field.
 * @param {number} params.width - The width of the select field in pixels.
 * @param {string} params.label - The label text for the select field.
 * @param {...any} params.otherProps - Additional props to be spread on the select field.
 *
 * @return {JSX.Element} - The rendered select field component.
 */
function AppFormSelectField({name, width, label, ...otherProps}) {
    const {setFieldTouched, setFieldValue, errors, touched, values} = useFormikContext();
    const [searchTerm, setSearchTerm] = useState("")
    const [options, setOptions] = useState([])
    const getOptions = () => {
        if (!searchTerm) return setOptions([])
        axios.get(`${process.env.REACT_APP_PORTAL_URL}/search/offers?q=${searchTerm}`).then(r => {
                let temp = []
                for (let offer of r.data.offers) {
                    temp.push({
                        value: offer,
                        label: `${offer.sales_offer_reference} ${offer.sales_offer_name}`
                    })
                }
                setOptions(temp)
            }
        ).catch(() => notyf.error("Error met opties ophalen"))
    }

    useEffect(() => {
        if (typeof values[name] === "number" && typeof searchTerm !== "number") setSearchTerm(values[name])
        getOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm])

    const handleChange= (e) => {
        setFieldValue("sales_offer_id", e.value.sales_offer_id)
        setFieldValue("calculation_name", e.value.sales_offer_name)
        setFieldValue("calculation_reference", e.value.sales_offer_reference)
    }

    const keyHandler = e => {
        const input = String.fromCharCode(e.keyCode);
        if (/[a-zA-Z]/.test(input)) setSearchTerm(e.target.value + e.key)
        if (e.keyCode === 8) setSearchTerm(searchTerm.slice(0, -1))
    }
    return (
        <>
            {label && <Form.Label>{label}</Form.Label>}
            <Select options={options}
                    tabIndex="0"
                    onKeyDown={keyHandler}
                    onBlur={() => setFieldTouched(name)}
                    onChange={handleChange }
                    defaultValue={{label: "Indien leeg wordt het naar dezelfde offerte gekopieerd", value: null}}
                    width={width}
                    noOptionsMessage={() => 'Geen offertes gevonden'}
                    {...otherProps}
            />
            <ErrorMessage error={errors[name]} visible={touched[name]}/>
        </>
    );
}

export default AppFormSelectField;
