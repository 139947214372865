import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button } from "react-bootstrap";

const ErrorPage = () => {
    const location = useLocation();
    return (
        <React.Fragment>
            <Helmet title="Error"/>
            <div className="mt-5 text-center">
                <h1 className="display-1 fw-bold">{location.state?.code || "404"}</h1>
                <p className="h1">{location.state?.message || "Pagina niet gevonden."}</p>
                <Link to="/">
                    <Button variant="primary" size="lg">
                        Terug naar de website
                    </Button>
                </Link>
            </div>
        </React.Fragment>
    )
};

export default ErrorPage;