import React, {useEffect, useState} from "react";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {Card, Col, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {NavLink} from "react-router-dom";
import moment from "moment";
import dashboardApi from "../../../api/dashboard";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import notyf from "../../../utils/notyfApi";

/**
 * Represents a list of column configurations for a table.
 * Each column configuration specifies the data field, text label, and optional formatting function.
 *
 * @typedef {Object} ColumnConfig
 * @property {string} dataField - The data field name for the column.
 * @property {string} text - The text label for the column.
 * @property {boolean} [hidden=false] - Indicates whether the column should be hidden.
 * @property {function} [formatter] - Optional function to format the column value.
 */
const columns = [
    {
        dataField: "sales_offer_created_on",
        text: "id",
        hidden: true
    },
    {
        dataField: "sales_offer_name",
        text: "Project naam",
        formatter: (c, r) => <NavLink to={`/offer/${r.sales_offer_id}`}>{c}</NavLink>,
    },
    {
        dataField: "sales_offer_planning_date",
        text: "Datum",
        formatter: (c) => moment(c, "YYYY-MM-DD").format("DD-MM-YYYY"),
    },
];


/**
 * Represents a TaskList component.
 * This component provides a table view of tasks.
 *
 * @param {Object} data - The data to be displayed in the table.
 *
 * @returns {JSX.Element} The TaskList component.
 */
const TaskList = ({data}) => {
    return <ToolkitProvider
        keyField="sales_offer_created_on"
        data={data}
        columns={columns}
    >
        {(props) => (<div>
            <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
            />
        </div>)}
    </ToolkitProvider>
};

/**
 * Represents a TaskTable component.
 * @constructor
 */
const TaskTable = () => {
    const [planning, setPlanning] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const res = await dashboardApi.getPlanning()
        if (!res.ok) return notyf.error("Error " + res.status)
        setPlanning(res.data)
        setLoading(false)
    }

    if (loading) return <></>
    return (<>
            <Col lg={6}>
                <Card>
                    <Card.Header><h3>Planning</h3></Card.Header>
                    <Card.Body>
                        {planning ? <TaskList data={planning}/> :
                            <Row className="justify-content-center align-self-center w-100 text-center">
                                Er is geen planning gevonden.
                            </Row>}
                    </Card.Body>
                </Card>
            </Col>
        </>
    )
};

export default TaskTable;
