import React, {useEffect, useState} from "react";
import PersonHandler from "./PersonHandler";
import Loader from "../../../components/Loader";
import {Col, Row} from "react-bootstrap";

/**
 * Handles the rendering of a chart displaying person-related data.
 *
 * @param {Object} params - The parameters needed for rendering the chart.
 * @param {string} params.department - The department name to filter the data.
 * @param {Object[]} params.allDepUserData - An array of objects representing user data for all departments.
 * @returns {React.Element} - The rendered chart component.
 */
const PersonChartHandler = ({department, allDepUserData}) => {
    const [loading, setLoading] = useState(true)
    const [uniquePersonSet, setUniquePersonSet] = useState(new Set())

    useEffect(() => {
        setLoading(true);
        initialRunOnce();
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allDepUserData]);

    const initialRunOnce = async () => {
        let tempSet = new Set()

        allDepUserData.forEach(e => {
            tempSet.add(e.user_name);
        })
        setUniquePersonSet(tempSet);
    }

    if (loading) return <Loader/>
    return (
        <>
            {[...uniquePersonSet].map(u => <PersonHandler key={u}
                                                          user={u}
                                                          department={department}
                                                          data={allDepUserData.filter(item => item.user_name === u)}/>)}
            <Row>
                <Col xs={12}>
                    <h4>Hoe is alles opgebouwd?</h4>
                    <p>
                        Budget per maand, op basis van aantal werkdagen in een maand, met 1 fte. 365 (1 jaar) - 52x2
                        (weekend) = 261 / 12 (maand) = 21.75 werkdagen per maand.
                        Dit komt neer op 174 (21.75x8) uren in de maand. Hier gaan afgerond 23 (280/12) vrije uren (25 vak, 5 atv, 4-7 feest, = gem 35x8=280u) en 5 (60/12)
                        ziekte uren per maand vanaf.
                        Dan hou je nog 146 (174-23-5) werkbare uren over.
                        Volgens Nordomatic wordt gerekend op 14.6 (10%) niet productieve uren En hier tellen we nog 1.6 (20/12)
                        opleidingsuren bij op voor een afgerond totaal van 16 (14.6 + 1.6).
                        Dit houdt in dat er afhankelijk van de afronding 129-130 (146-16) productieve uren gedraait moeten worden.
                    </p>
                </Col>
            </Row>
        </>
    );
};


export default PersonChartHandler;
