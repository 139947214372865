import React, {useEffect, useState} from "react";
import Loader from "../../../../components/Loader";
import notyf from "../../../../utils/notyfApi";
import xconnectWebshopApi from "../../../../api/xconnectWebshop";
import XconnectWebshopTable from "./xconnectWebshopTable";

/**
 * Fetches data from an API and renders a table with the retrieved products.
 *
 * @return {JSX.Element} - The table component with the products or a loading spinner.
 */
export default function XconnectWebshopTableHandler() {
    //get data from api
    const [products, setProducts] = useState([]);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        const response = await xconnectWebshopApi.getAllProducts();
        if (!response.ok) return notyf.error("Error " + response.status)
        setProducts(response.data.products);
    };

    //laat tabel zien met gegevens via props
    if (products.length > 0) return <XconnectWebshopTable products={products}/>;
    else
        return <Loader/>
}
