import React, {useEffect, useMemo, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Card, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {useParams} from "react-router-dom";
import adminUsersApi from "../../../api/adminUsers";
import notyf from "../../../utils/notyfApi";
// import UserAdminDetailsPortalModal from "../../components/admin/UserAdminDetailsPortalModal";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import UserAdminDetailsBudgetModal from "../../components/admin/UserAdminDetailsBudgetModal";
import UserAdminDetailsDeleteYear from "../../components/admin/UserAdminDetailsDeleteYear";
import UserAdminDetailsEditYearModal from "../../components/admin/UserAdminDetailsEditYearModal";
import {
    Form as FormikForm,
    FormCheckbox,
    SubmitButton
} from "../../../components/forms";
import * as Yup from "yup";


/**
 * Represents the UserAdminDetails component.
 *
 * @returns {JSX.Element} The UserAdminDetails component.
 */
const UserAdminDetails = () => {
    const {id} = useParams();
    const [singleUser, setSingleUser] = useState(null);
    const [userBudgetYearData, setUserBudgetYearData] = useState([]);

    const columns = useMemo(() => [
        {
            id: 0,
            name: "productivity_year_year",
            header: "Jaar",
            flex: 1,
            minWidth: 90,
        },
        {
            id: 1,
            name: "productivity_department",
            header: "Afdeling",
            render: ({value, data}) => <div>{value}</div>,
            flex: 1,
            minWidth: 90,
        },
        {
            id: 2,
            name: "productivity_fte",
            header: "FTE's",
            render: ({value, data}) => <div>{value}</div>,
            flex: 1,
            minWidth: 90,
        },
        {
            id: 3,
            name: "productivity_productive_percentage",
            header: "Productiviteit",
            render: ({value, data}) => <div>{value}</div>,
            flex: 1,
            minWidth: 90,
        },
        {
            id: 4,
            name: "productivity_employment_months",
            header: "Maanden in dienst",
            render: ({value, data}) => <div>{value}</div>,
            flex: 1,
            minWidth: 90,
        },
        {
            id: 5,
            name: "productivity_detail_rate",
            header: "Tarief",
            render: ({value, data}) => <>{data.productivity_detail_rate ? "€" + value : ""}</>,
            flex: 1,
            minWidth: 90,
        },

        {
            id: 6,
            name: "productivity_detail_old_age_free_hours",
            header: "Correctie vrije uren",
            //voormalig oude dag uren - nu correctie vrije uren. Logica is hetzelfde, label is anders
            render: ({value, data}) => <>{data.productivity_detail_old_age_free_hours ? value : 0}</>,
            flex: 1,
            minWidth: 90,
        },
        {
            id: 7,
            name: "productivity_detail_correction_sick_hours",
            header: "Correctie ziekte uren",
            render: ({value, data}) => <>{data.productivity_detail_correction_sick_hours ? value : 0}</>,
            flex: 1,
            minWidth: 90,
        },
        {
            id: 8,
            name: "actie",
            header: "Acties",
            render: ({value, data}) => <><UserAdminDetailsDeleteYear id={data?.productivity_detail_id} data={data}
                                                                     reload={getBudgetYearDataForSingleUser}/>
                <UserAdminDetailsEditYearModal id={data?.productivity_detail_id}
                                               exists={data}
                                               reload={getBudgetYearDataForSingleUser}
                /></>,
            flex: 1,
            minWidth: 90,
            maxWidth: 90,
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [])

    useEffect(() => {
        getDataForSignleUser();
        getBudgetYearDataForSingleUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataForSignleUser = async () => {
        //dit is de data voor de accountgegevens
        const res = await adminUsersApi.getSingleUser(id);
        if (!res.ok) return notyf.error("Error " + res.status)
        setSingleUser(res.data);
    };

    const getBudgetYearDataForSingleUser = async () => {
        //dit is de metadata voor de accounts year gegevens
        const result = await adminUsersApi.getUserBudgetYearData(id);
        if (!result.ok) return notyf.error("Error " + result.status)
        setUserBudgetYearData(result.data);
    };

    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(false);
        let valuesParsed = {"timesheet_permissions": values}
        const res = await adminUsersApi.editSingleUserPerms(singleUser.user_id, valuesParsed)

        if (res.ok) return notyf.success("Succesvol opgeslagen")
        if (!res.ok) return notyf.error("Error " + res.status)
    }

    if (!singleUser) return <Spinner/>;
    return (
        <React.Fragment>
            <Helmet title="Gebruiker aanpassen"/>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Gebruikersdetails </h1>
                <Row>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col lg="3">
                                        <h4>
                                            Accountgegevens
                                            {/*<UserAdminDetailsPortalModal singleUser={singleUser}/>*/}
                                        </h4>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Naam</Form.Label>
                                            <Form.Control placeholder={singleUser.name} disabled/>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Gebruikersnaam</Form.Label>
                                            <Form.Control placeholder={singleUser.nickname} disabled/>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control placeholder={singleUser.email} disabled/>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Laatste IP adres</Form.Label>
                                            <Form.Control placeholder={singleUser.last_ip} disabled/>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>UserID</Form.Label>
                                            <Form.Control placeholder={singleUser.user_id} disabled/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg="9">
                                        <p>
                                            <strong>
                                                Account budgetdata <UserAdminDetailsBudgetModal
                                                reload={getBudgetYearDataForSingleUser} singleUser={singleUser}/>
                                            </strong>
                                        </p>
                                        <ReactDataGridCustom
                                            idProperty="jaar"
                                            dataSource={userBudgetYearData}
                                            columns={columns}
                                            pagination
                                            defaultLimit={50}
                                        />

                                    </Col>
                                </Row>

                                {/* Hier komt het formulier voor de vinkjes */}
                                <Row className={"mt-3 mb-3"}>
                                    <Col>
                                        <h4>Weekstaten uren toestemming</h4>
                                    </Col>
                                </Row>
                                <FormikForm
                                    initialValues={{
                                        ENG: typeof (singleUser?.app_metadata?.timesheet_permissions) === "undefined" ? true : singleUser.app_metadata.timesheet_permissions.ENG,
                                        IBS: typeof (singleUser?.app_metadata?.timesheet_permissions) === "undefined" ? true : singleUser.app_metadata.timesheet_permissions.IBS,
                                        PAN: typeof (singleUser?.app_metadata?.timesheet_permissions) === "undefined" ? true : singleUser.app_metadata.timesheet_permissions.PAN,
                                        PL: typeof (singleUser?.app_metadata?.timesheet_permissions) === "undefined" ? true : singleUser.app_metadata.timesheet_permissions.PL,
                                        WERKVB: typeof (singleUser?.app_metadata?.timesheet_permissions) === "undefined" ? true : singleUser.app_metadata.timesheet_permissions.WERKVB,
                                        TEK: typeof (singleUser?.app_metadata?.timesheet_permissions) === "undefined" ? true : singleUser.app_metadata.timesheet_permissions.TEK,
                                        BOA: typeof (singleUser?.app_metadata?.timesheet_permissions) === "undefined" ? true : singleUser.app_metadata.timesheet_permissions.BOA,
                                        OHDDC: typeof (singleUser?.app_metadata?.timesheet_permissions) === "undefined" ? true : singleUser.app_metadata.timesheet_permissions.OHDDC,
                                        OHVELD: typeof (singleUser?.app_metadata?.timesheet_permissions) === "undefined" ? true : singleUser.app_metadata.timesheet_permissions.OHVELD,
                                        STOR: typeof (singleUser?.app_metadata?.timesheet_permissions) === "undefined" ? true : singleUser.app_metadata.timesheet_permissions.STOR
                                    }}

                                    // Hier is het validatieschema van alle velden
                                    validationSchema={Yup.object({
                                        ENG: Yup.bool(),
                                        IBS: Yup.bool(),
                                        PAN: Yup.bool(),
                                        PL: Yup.bool(),
                                        WERKVB: Yup.bool(),
                                        TEK: Yup.bool(),
                                        BOA: Yup.bool(),
                                        OHDDC: Yup.bool(),
                                        OHVELD: Yup.bool(),
                                        STOR: Yup.bool(),
                                    })}
                                    onSubmit={handleSubmit}
                                >
                                    <Row>
                                        <Col lg="2">
                                            <FormCheckbox name={"ENG"}
                                                          label={"Engineering"}
                                                          className={"mb-3"}
                                            />
                                        </Col>
                                        <Col lg="2">
                                            <FormCheckbox name={"IBS"}
                                                          label={"In bedrijf stellen"}
                                                          className={"mb-3"}
                                            />
                                        </Col>
                                        <Col lg="2">
                                            <FormCheckbox name={"PAN"}
                                                          label={"Panneelbouw"}
                                                          className={"mb-3"}
                                            />
                                        </Col>
                                        <Col lg="2">
                                            <FormCheckbox name={"PL"}
                                                          label={"Projectbegeleiding"}
                                                          className={"mb-3"}
                                            />
                                        </Col>
                                        <Col lg="2">
                                            <FormCheckbox name={"WERKVB"}
                                                          label={"Werkvoorbereiding"}
                                                          className={"mb-3"}
                                            />
                                        </Col>
                                        <Col lg="2">
                                            <FormCheckbox name={"TEK"}
                                                          label={"Tekenen"}
                                                          className={"mb-3"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className={"mb-3"}>
                                        <Col lg="2">
                                            <FormCheckbox name={"BOA"}
                                                          label={"Beheer op afstand"}
                                                          className={"mb-3"}
                                            />
                                        </Col>
                                        <Col lg="2">
                                            <FormCheckbox name={"OHDDC"}
                                                          label={"Onderhoud DDC apparatuur"}
                                                          className={"mb-3"}
                                            />
                                        </Col>
                                        <Col lg="2">
                                            <FormCheckbox name={"OHVELD"}
                                                          label={"Onderhoud veldapparatuur"}
                                                          className={"mb-3"}
                                            />
                                        </Col>
                                        <Col lg="2">
                                            <FormCheckbox name={"STOR"}
                                                          label={"Storing"}
                                                          className={"mb-3"}
                                            />
                                        </Col>
                                    </Row>
                                    <SubmitButton title={"Opslaan"} variant="primary"/>
                                </FormikForm>
                            </Card.Body>
                            <Card.Footer>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
};

export default UserAdminDetails;
