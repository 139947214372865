import React from "react";
import {Row} from "react-bootstrap";
import NawContactsDetailOffersTable from "./NawContactsDetailOffersTable";

/**
 * Renders a table containing detail offers.
 * If there are no detail offers, a message saying "Er zijn geen offertes gevonden." is displayed.
 *
 * @param {Object} options - The options object.
 * @param {Array} options.detailOffers - Array containing the detail offers to be displayed.
 *
 * @returns {JSX.Element} - The table containing detail offers or a message if there are no detail offers.
 */
function NawContactsDetailOffersTableHandler({detailOffers}) {
    //laat tabel zien met gegevens via props
    if (detailOffers.length > 0) return <NawContactsDetailOffersTable detailOffers={detailOffers}/>;
    else
        return (
            <Row className="justify-content-center align-self-center w-100 text-center">
                Er zijn geen offertes gevonden.
            </Row>
        );
}

export default NawContactsDetailOffersTableHandler;