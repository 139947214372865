import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

/**
 * Represents the initial state of an application.
 *
 * @typedef {Object} InitialState
 * @property {number} value - The initial value.
 * @property {string} status - The initial status.
 */
const initialState = {
    value: 0,
    status: "idle",
};

// A mock function to mimic making an async request for data
/**
 * Fetches a count of data after a specified amount of time.
 *
 * @param {number} [amount=1] - The amount of data to fetch.
 * @return {Promise<{data: number}>} A promise that resolves with an object containing the fetched amount of data.
 */
export function fetchCount(amount = 1) {
    return new Promise((resolve) =>
        setTimeout(() => resolve({data: amount}), 500)
    );
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
/**
 * An async thunk function that increments the counter.
 *
 * @param {number} amount - The amount by which to increment.
 * @returns {Promise<number>} - A promise that resolves to the incremented count.
 */
export const incrementAsync = createAsyncThunk(
    "counter/fetchCount",
    async (amount) => {
        const response = await fetchCount(amount);
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

/**
 * Creates a Redux slice for the counter state.
 * @name counterSlice
 * @type {Object}
 * @property {string} name - The name of the slice.
 * @property {*} initialState - The initial state of the slice.
 * @property {Object} reducers - The reducers for the slice.
 * @property {function} reducers.increment - Increments the counter value by 1.
 * @property {function} reducers.decrement - Decrements the counter value by 1.
 * @property {function} reducers.incrementByAmount - Increments the counter value by the specified amount.
 * @property {Object} extraReducers - Additional reducers for the slice.
 * @property {function} extraReducers.builder - The builder function for defining extra reducers.
 */
export const counterSlice = createSlice({
    name: "counter",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        increment: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.value += 1;
        },
        decrement: (state) => {
            state.value -= 1;
        },
        // Use the PayloadAction type to declare the contents of `action.payload`
        incrementByAmount: (state, action) => {
            state.value += action.payload;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(incrementAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(incrementAsync.fulfilled, (state, action) => {
                state.status = "idle";
                state.value += action.payload;
            });
    },
});

export const {increment, decrement, incrementByAmount} = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
/**
 * Retrieves the value of the 'counter' property from the provided state object.
 * @param {object} state - The state object.
 * @returns {number} The value of the 'counter' property.
 */
export const selectCount = (state) => state.counter.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/**
 * Increments a value if it is odd.
 *
 * @param {number} amount - The amount to increment by.
 * @param {Function} dispatch - A function to dispatch an action.
 * @param {Function} getState - A function to get the current state.
 * @returns {void}
 */
export const incrementIfOdd = (amount) => (dispatch, getState) => {
    const currentValue = selectCount(getState());
    if (currentValue % 2 === 1) {
        dispatch(incrementByAmount(amount));
    }
};

export default counterSlice.reducer;
