import React, {useContext, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Alert, Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {AlertCircle, Check, CreditCard, File, Folder, FolderPlus, Home, Info, Key, Map, Percent, Star, Tag, User} from "react-feather";
import {useParams} from "react-router-dom";
import OfferAccounts from "../../components/verkoop/OfferAccounts";
import offersApi from "../../../api/offertes";
import {euroFormat} from "../../../utils/usefullFunctions";
import moment from "moment";
import OfferCalculations from "../../components/verkoop/calculation/OfferCalculations";
import OfferProjectForms from "../../components/verkoop/projectforms/OfferProjectForms";
import ActivityMenuOffers from "../../components/activiteitenMenu/ActivityMenuOffers";
import DatePicker from "react-datepicker";
import AddOfferModal from "../../components/verkoop/AddOfferModal";
import EditFinanceModal from "../../components/verkoop/calculation/EditFinanceModal";
import Steps, {Step} from "rc-steps";
import "rc-steps/assets/index.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faDoorClosed, faFolder, faLockOpen} from "@fortawesome/free-solid-svg-icons";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import Loader from "../../../components/Loader";
import PermissionsContext from "../../../utils/context";
import {EuroSymbol} from "@mui/icons-material";
import notyf from "../../../utils/notyfApi";
import {localeDatapicker} from "../../../constants";
import OfferServiceForms from "../../components/verkoop/serviceforms/OfferServiceForms";

moment.locale("nl")

/**
 * Dictionary representing the different phases of a process.
 * Each key represents a phase name, and its corresponding value represents the phase's index.
 *
 * @type {Object<string, number>}
 */
const phaseDict = {
    Kwalificeren: 0,
    Voorstel: 1,
    Ontwikkeling: 2,
    Binnengehaald: 3
}
/**
 * An object representing the different phases and their corresponding status words.
 *
 * @type {Object.<string, string>}
 * @property {string} Kwalificeren - The status word for the "Kwalificeren" phase.
 * @property {string} Voorstel - The status word for the "Voorstel" phase.
 * @property {string} Ontwikkeling - The status word for the "Ontwikkeling" phase.
 * @property {string} Binnengehaald - The status word for the "Binnengehaald" phase.
 *
 * @example
 * // Accessing the status word for the "Kwalificeren" phase
 * let kwaliStatus = phaseDictWords.Kwalificeren; // "Aangevraagd"
 *
 * @example
 * // Accessing the status word for the "Voorstel" phase
 * let voorstelStatus = phaseDictWords.Voorstel; // "Voorgesteld"
 */
const phaseDictWords = {
    Kwalificeren: "Aangevraagd",
    Voorstel: 'Voorgesteld',
    Ontwikkeling: 'in Onderhandeling',
    Binnengehaald: 'Gesloten'
}

/**
 * Represents the OfferDetail component.
 *
 * The OfferDetail component displays the details of a specific offer.
 * It uses the useParams hook to get the offer ID from the URL parameters.
 * It fetches the offer data from the server using the offersApi.getOffer() method.
 * The fetched offer is stored in the state variable 'offer' using the useState hook.
 * The component also tracks the current step and handles step changes, offer status changes,
 * planning changes (boolean and date), and other actions related to the offer.
 *
 * @returns {JSX.Element} The OfferDetail component.
 */
const OfferDetail = () => {
    const {id} = useParams();
    const [offer, setOffer] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const {permissions} = useContext(PermissionsContext)


    useEffect(() => {
        getData();
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        const resOffer = await offersApi.getOffer(id);
        if (!resOffer.ok) return notyf.error("Error " + resOffer.status)
        setOffer(resOffer.data);
        setCurrentStep(phaseDict[resOffer.data.sales_offer_phase])

    };

    const handleStepChange = async (val) => {
        if (!permissions.includes("write:offers")) return null
        const resOffer = await offersApi.editExistingOffer(id, {
            ...offer,
            sales_offer_phase: Object.keys(phaseDict).find(k => phaseDict[k] === val)
        });
        if (!resOffer.ok) return notyf.error("Error " + resOffer.status)
        getData()
    }

    const handleOfferStatus = async (val) => {
        const resOffer = await offersApi.editExistingOfferStatus(id, val);
        if (!resOffer.ok) return notyf.error("Error " + resOffer.status)
        getData()
    }
    const handlePlanningChangeBool = async (val) => {
        const resOffer = await offersApi.editExistingOffer(id, {
            ...offer,
            sales_offer_planning: val
        });
        if (!resOffer.ok) return notyf.error("Error " + resOffer.status)
        getData()
    }
    const handlePlanningChangeDate = async (val) => {
        const resOffer = await offersApi.editExistingOffer(id, {
            ...offer,
            sales_offer_planning_date: moment(val).format("YYYY-MM-DD")
        });
        if (!resOffer.ok) return notyf.error("Error " + resOffer.status)
        getData()
    }

    if (!offer) return <Loader/>;
    return (
        <React.Fragment>
            <Helmet title={`Offerte - ${offer.sales_offer_reference} ${offer.sales_offer_name}`}/>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3 d-flex justify-content-between">Offerte
                    - {offer.sales_offer_reference} {offer.sales_offer_name}
                    <span className={"added-tablet-m-right"}>
                    <GuardWrapper role={"write:offers"}>
                        {offer.sales_offer_status === "Open" ? <>
                            <Button className={"me-1 extremeSmallBtn"}
                                    onClick={() => handleOfferStatus("Binnengehaald")}>
                                <FontAwesomeIcon icon={faCheck} className={"me-1"}/> <span
                                className={"hideOn576"}>Binnengehaald</span>
                            </Button>
                            <Button variant={"danger"} onClick={() => handleOfferStatus("Gemist")}
                                    className={"me-1 extremeSmallBtn"}>
                                <FontAwesomeIcon icon={faDoorClosed} className={"me-1"}/>
                                <span
                                    className={"hideOn576"}>Gemist</span>
                            </Button>
                        </> : <Button className={"me-1 extremeSmallBtn"} onClick={() => handleOfferStatus("Open")}>
                            <FontAwesomeIcon icon={faLockOpen} className={"me-1 "}/>
                            <span
                                className={"hideOn576"}> Open</span>
                        </Button>}
                    </GuardWrapper>
                    <Button href={offer.sales_offer_document_link}
                            disabled={!offer.sales_offer_document_link}
                            variant={!offer.sales_offer_document_link ? "outline-secondary" : "secondary"}
                            style={{cursor: !offer.sales_offer_document_link ? "not-allowed" : ""}}
                            target={"_blank"}
                            rel={"noreferrer"}
                            className={"extremeSmallBtn"}
                    >
                        <FontAwesomeIcon icon={faFolder} className={"me-1"}/>
                        <span
                            className={"hideOn576"}>Documenten</span>
                    </Button>
                    </span>
                </h1>
                <Row className={"d-flex align-items-center justify-content-center"}>
                    <Col>
                        <Alert className={"h-100"}
                               variant={offer.sales_offer_status === "Open" ? "info" : offer.sales_offer_status === "Binnengehaald" ? "success" : "danger"}>
                            <div className="alert-icon">

                                {offer.sales_offer_status === "Open" ?
                                    <Info/> : offer.sales_offer_status === "Binnengehaald" ? <Check/> : <AlertCircle/>}
                            </div>
                            <div className="alert-message">
                                <strong>De offerte
                                    is {phaseDictWords[offer.sales_offer_phase]} en {offer.sales_offer_status}</strong>
                            </div>
                        </Alert>
                    </Col>
                    <Col>
                        <Alert className={"h-100"} variant={"info"}>
                            <div className="alert-message">
                                <Row className={"d-flex align-items-center fs-25px-h"}>
                                    <Col lg={3} className={"d-576-inline-start"}><strong>Planning</strong></Col>
                                    <Col lg={4} className={"hideOn576"}></Col>
                                    <Col lg={2} className={"d-576-inline"}>
                                        <Form.Check
                                            type="switch"
                                            label="Actief"
                                            checked={offer.sales_offer_planning}
                                            onChange={() => handlePlanningChangeBool(!offer.sales_offer_planning)}
                                            disabled={!permissions.includes("write:offers")}
                                        />
                                    </Col>
                                    <Col lg={3} className={"d-576-inline"}>
                                        <DatePicker dateFormat={"dd-MM-yyyy"}
                                                    className={"form-control"}
                                                    locale={localeDatapicker}
                                                    calendarStartDay={1}
                                                    selected={offer.sales_offer_planning_date ? moment(offer.sales_offer_planning_date, "YYYY-MM-DD").toDate() : null}
                                                    onChange={(date) => handlePlanningChangeDate(date)}
                                                    disabled={!permissions.includes("write:offers")}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col xl="5">
                        <Card>
                            <Card.Body>

                                <Steps
                                    current={currentStep}
                                    onChange={val => handleStepChange(val)}
                                >
                                    <Step title="Aanvraag"/>
                                    <Step title="Voorstel"/>
                                    <Step title="Onderhandeling"/>
                                    <Step title="Sluiten"/>
                                </Steps>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Projectdetails
                                    <GuardWrapper role={"write:offers"}>
                                        <AddOfferModal exists={offer} reload={getData}/>
                                    </GuardWrapper>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Key/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Referentie
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {offer.sales_offer_reference}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img ">
                                        <Tag/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Naam
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {offer.sales_offer_name}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <File/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Type
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {offer.sales_offer_type}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <User/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Calculator
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {offer.user_name}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Folder/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Map nr.
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {offer.sales_offer_folder_nr}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Map/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Adres
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <a href={`https://maps.google.com/?q=${offer.sales_offer_offer_street}
                                        ${offer.sales_offer_offer_street_nr}
                                        ${offer.sales_offer_city}
                                        ${offer.sales_offer_city}
                                        ${offer.sales_offer_country}`}
                                           target="_blank" rel="noreferrer">
                                            {`${offer.sales_offer_offer_street || ""} ${offer.sales_offer_offer_street_nr || ""} ${offer.sales_offer_city || ""}`}
                                        </a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Home/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Gebouw
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {offer.service_project_name}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        {/* einde project details & Start financieel */}
                        {/* Fix nog icons ervoor */}
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Financieel
                                    <GuardWrapper role={"write:offers"}>
                                        <EditFinanceModal exists={offer} reload={getData}/>
                                    </GuardWrapper>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <CreditCard/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Marge
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {offer.sales_offer_margin ? `${parseFloat(offer.sales_offer_margin).toFixed(2)}%` : null}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Percent/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Scoringkans
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {offer.sales_offer_scoring_chance ? `${parseFloat(offer.sales_offer_scoring_chance).toFixed(2)}%` : null}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <EuroSymbol/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Offertebedrag
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {offer.sales_offer_quotation_amount ? euroFormat(offer.sales_offer_quotation_amount) : null}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Star/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Winst
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {offer.sales_offer_quotation_amount && offer.sales_offer_margin ?
                                            euroFormat(parseFloat(offer.sales_offer_quotation_amount) * (parseFloat(offer.sales_offer_margin) / 100)) : null}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Folder/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Verwachte opdrachtdatum
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {offer.sales_offer_expected_assignment_date ?
                                            moment(offer.sales_offer_expected_assignment_date).format("dddd D MMMM yyyy") : "Niet bekend"}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <FolderPlus/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Verwachte opleverdatum
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {offer.sales_offer_expected_completion_date ?
                                            moment(offer.sales_offer_expected_completion_date).format("dddd D MMMM yyyy") : "Niet bekend"}                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        {/* Einde financieel */}
                    </Col>
                    <Col xl="7">
                        <ActivityMenuOffers id={id} offer={offer}/>
                        {/* Calculaties */}
                        <OfferCalculations id={id}
                                           name={offer.sales_offer_name}
                                           reference={offer.sales_offer_reference}/>
                        {/* Aanvragers */}
                        <OfferAccounts id={id}/>
                        {/*<OfferProjectForms id={id}*/}
                        {/*                   name={offer.sales_offer_name}*/}
                        {/*                   reference={offer.sales_offer_reference}/>*/}
                        {/*<OfferServiceForms id={id}*/}
                        {/*                   name={offer.sales_offer_name}*/}
                        {/*                   reference={offer.sales_offer_reference}/>*/}
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
};

export default OfferDetail;
