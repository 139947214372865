import client from "./clients/portalClient";

/**
 * Retrieves all debtors from the project debtors dashboard.
 *
 * @returns {Promise} A promise that resolves with the response data.
 */
const getAllDebtors = () => client.get(`/project_debtors/dashboard`);
/**
 * Retrieves the list of project debtors.
 *
 * @returns {Promise} A Promise that resolves with the list of project debtors.
 */
const getDebtors = () => client.get(`/project_debtors`);
/**
 * Retrieves the debtor for a specific project.
 *
 * @param {number} id - The ID of the project debtor.
 * @returns {Promise} - A Promise that resolves to the debtor data.
 */
const getDebtor = (id) => client.get(`/project_debtors/${id}`);
/**
 * Updates a debtor's information in the project debtors collection.
 *
 * @param {string} id - The ID of the debtor to be updated.
 * @param {object} data - The updated information for the debtor.
 * @returns {Promise} A Promise representing the completion of the update operation.
 */
const putDebtor = (id, data) => client.put(`/project_debtors/${id}`, data);

/**
 * Retrieve invoices for a specific category.
 *
 * @param {string} cat - The category for filtering the invoices.
 * @returns {Promise<Response>} - A Promise that resolves with the invoices data.
 */
const getInvoices = (cat) => client.get(`/project_invoices?data=${cat}`);
/**
 * Retrieves invoices based on date range.
 *
 * @param {string} [before] - The optional date string specifying the end of the date range (exclusive).
 * @param {string} [after] - The optional date string specifying the start of the date range (inclusive).
 * @returns {Promise} A Promise that resolves to the invoices retrieved from the server.
 */
const getInvoicesByDate = (before, after) => client.get(`/project_invoices?data=open${before ? `&before=${before}` : ""}${after ? `&after=${after}` : ""}`);
/**
 * Retrieves an invoice for a given ID from the project invoices endpoint.
 *
 * @param {string} id - The ID of the invoice to retrieve.
 * @returns {Promise} - A promise that resolves with the invoice data.
 */
const getInvoice = (id) => client.get(`/project_invoices/${id}`);
/**
 * Updates an invoice with the given ID.
 *
 * @param {string} id - The ID of the invoice to be updated.
 * @param {object} data - The new data for the invoice.
 * @returns {Promise} - A Promise that resolves to the updated invoice response.
 */
const editInvoice = (id, data) => client.put(`/project_invoices/${id}`, data);
/**
 * Retrieve comments for a specific invoice
 *
 * @param {number} id - The ID of the invoice
 * @returns {Promise<Array>} - A Promise that resolves to an array of comments for the specified invoice
 */
const getInvoiceComments = (id) => client.get(`/project_invoices/${id}/comments`);
/**
 * Deletes an invoice comment.
 *
 * @param {number} id - The ID of the project invoice.
 * @param {number} cId - The ID of the comment to be deleted.
 * @returns {Promise} A Promise that resolves when the comment is deleted.
 */
const deleteInvoiceComment = (id, cId) => client.delete(`/project_invoices/${id}/comments/${cId}`);
/**
 * Adds a comment to an invoice.
 *
 * @param {number} id - The ID of the invoice to add a comment to.
 * @param {object} data - The comment data to be posted.
 * @returns {Promise} - A promise that resolves with the response data.
 */
const addInvoiceComment = (id, data) => client.post(`/project_invoices/${id}/comments`, data);


/**
 * This object represents an API for managing debtors and their invoices.
 *
 * @namespace
 */
const debtorsApi = {
    getAllDebtors,
    getDebtors,
    getDebtor,
    putDebtor,

    getInvoices,
    getInvoicesByDate,
    getInvoice,
    editInvoice,
    getInvoiceComments,
    deleteInvoiceComment,
    addInvoiceComment

};

export default debtorsApi;