import React, {useEffect, useState} from "react";
import {useRoutes} from "react-router-dom";
import {Provider} from "react-redux";
import {HelmetProvider, Helmet} from "react-helmet-async";
import {store} from "./redux/store";
import "./assets/css/custom.css";
import routes from "./routes";
import {ThemeProvider} from "./contexts/ThemeContext";
import {SidebarProvider} from "./contexts/SidebarContext";
import {LayoutProvider} from "./contexts/LayoutContext";
import {useAuth0} from "@auth0/auth0-react";
import {setCookie} from "./utils/cookies";
import jwtDecode from "jwt-decode";
import Loader from "./components/Loader";
import PermissionsContext from "./utils/context";

/**
 * App component is the main entry point of the application.
 * It handles authentication, user permissions, and rendering the content of the application.
 *
 * @returns {JSX.Element} The rendered content of the application.
 */
const App = () => {
    const content = useRoutes(routes); // Routes
    const {isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently} = useAuth0(); // Hooks van auth0 voor authentication
    const [permissions, setPermissions] = useState(false)
    const [loading, setloading] = useState(true)

    useEffect(() => {
        if (isAuthenticated) saveToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])

    /**
     * Saves the access token to a cookie and sets permissions.
     *
     * @async
     * @function saveToken
     * @returns {void}
     */
    const saveToken = async () => {
        const accessToken = await getAccessTokenSilently()
        setPermissions(jwtDecode(accessToken).permissions)
        await setCookie('ibess_auth0_token', accessToken, 1 / 3)
        setloading(false)
    }

    if (isLoading) return <Loader/>;
    if (!isAuthenticated) loginWithRedirect({redirectUri: window.location.origin});
    if (!permissions) return <Loader/>;
    if (loading) return <Loader/>;
    else
        return (
            <PermissionsContext.Provider value={{permissions}}>

                <HelmetProvider>
                    <Helmet
                        titleTemplate="%s | iBESS"
                        defaultTitle="iBESS Portal"
                    />
                    <Provider store={store}>
                        <ThemeProvider>
                            <SidebarProvider>
                                <LayoutProvider>
                                    {content}
                                </LayoutProvider>
                            </SidebarProvider>
                        </ThemeProvider>
                    </Provider>
                </HelmetProvider>
            </PermissionsContext.Provider>
        );
};

export default App;
