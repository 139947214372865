import { useContext } from "react";
import { LayoutContext } from "../contexts/LayoutContext";

/**
 * Retrieves the current value of the LayoutContext using the useContext hook.
 *
 * @returns {{layout: string}} The current value of the LayoutContext.
 */
const useLayout = () => useContext(LayoutContext);

export default useLayout;
