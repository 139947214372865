import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Alert,
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    Modal,
    Row,
    Spinner,
    Table,
} from "react-bootstrap";
import {AlertCircle, Archive, DollarSign, File, Home, Info, User, Users,} from "react-feather";
import SendIcon from '@mui/icons-material/Send';
import ProjectResultDetailOrdersTable from "../../components/projecten/ProjectResultDetailOrdersTable";
import ProjectResultDetailFinancialTable from "../../components/projecten/ProjectResultDetailFinancialTable";
import {NavLink, useParams} from "react-router-dom";
import projectsApi from "../../../api/projects";
import {euroFormat} from "../../../utils/usefullFunctions";
import moment from "moment";
import usePalette from "../../../hooks/usePalette";
import {useAuth0} from "@auth0/auth0-react";
import Tree from '@naisutech/react-tree'
import useTheme from "../../../hooks/useTheme";
import {THEME} from "../../../constants";
import ActivityMenuProjectDetails from "../../components/activiteitenMenu/ActivityMenuProjectDetails";
import Loader from "../../../components/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDoorClosed, faFolder} from "@fortawesome/free-solid-svg-icons";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import GuardWrapper from "../../../components/guards/GuardWrapper";
import notyf from "../../../utils/notyfApi";
import ReassesmentModal from "../../components/projecten/ReassesmentModal"
import ProjectResultDetailCalculationsTable from "../../components/projecten/ProjectResultDetailCalculationsTable";


const createSafeFactLink = (project, invoice, user) => {
    let body = "Beste Receptie, \n\n" +
        `Graag voor het project ${project.project_code} ${project.project_name} het termijn ${invoice.projects_invoice_terms_commentary}, ${euroFormat(invoice.projects_invoice_terms_invoice_amount)} factureren.\n` +
        "Met vriendelijke groet, \n" +
        `${user.name} \n`
    let subject = `${project.project_code} - ${project.project_name} - ${invoice.projects_invoice_terms_commentary}`
    return `mailto:receptie@y-con.nl;?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
}

const createSafeMoveLink = (project, invoice, user) => {
    let body = "Beste Receptie, \n\n" +
        `Graag voor het project ${project.project_code} ${project.project_name} het termijn ${invoice.projects_invoice_terms_commentary}, ${euroFormat(invoice.projects_invoice_terms_invoice_amount)} verplaatsen van ${moment(invoice.projects_invoice_terms_planned_for).format("DD MMMM YYYY")} verzetten naar .... in King.\n` +
        "Met vriendelijke groet, \n" +
        `${user.name} \n`
    let subject = `${project.project_code} - ${project.project_name} - ${invoice.projects_invoice_terms_commentary}`
    return `mailto:receptie@y-con.nl;?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
}

const ProjectResultsDetail = () => {
    const {theme} = useTheme();
    const {id} = useParams();
    const palette = usePalette()
    const {user} = useAuth0()
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [projectCalculations, setProjectCalculations] = useState(null);
    const [showReestimate, setShowReestimate] = useState(false);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(!loading) {
            getProjectCalculationsData();
            setLoading(true);
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);


    const getData = async () => {
        const res = await projectsApi.getProject(id);
        if (!res.ok) return notyf.error("Error " + res.status)
        for (let row of res.data.project_structure) {
            row["parentId"] = row.projects_structure_parent_id === 1 ? null : row.projects_structure_parent_id
            row["label"] = row.projects_structure_description
            row["id"] = row.projects_structure_structure_id
        }

        if (res.data.project.project_re_estimate_mat || res.data.project.project_re_estimate_oa || res.data.project.project_re_estimate_arb || res.data.project.project_re_estimate_ove) {
            setShowReestimate(true)
        } else setShowReestimate(false)
        setProject(res.data);
        setLoading(false)
    };

    const getProjectCalculationsData = async () => {
        const res = await projectsApi.getProjectCalculations(project.project.project_code);
        if (!res.ok) return notyf.error("Error " + res.status)
        setProjectCalculations(JSON.parse(res.data));
    };


    const handleGetTemplate = async (cat) => {
        const resOffer = await projectsApi.getTemplates(id, cat);
        if (!resOffer.ok) return notyf.error("Error " + resOffer.status)
        window.open(encodeURI(resOffer.data.response))
    }

    if (!project && !projectCalculations) return <Loader/>;
    return (
        <React.Fragment>
            <Helmet title={`Project ${project.project.project_name}`}/>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3 d-flex justify-content-between">
                    {project.project.project_name}
                    <span>
                    <GuardWrapper role={"write:projects"}>
                        <CloseModal project={project} reload={getData}/>
                    </GuardWrapper>
                        <Button href={project.project.project_document_link}
                                target={"_blank"}
                                rel="noreferrer"
                                disabled={!project.project.project_document_link}
                                className={window.screen.width <= 576 ? "extremeSmallBtn" : "me-1 extremeSmallBtn"}
                                variant={!project.project.project_document_link ? "outline-secondary" : "secondary"}
                                style={{cursor: !project.project.project_document_link ? "not-allowed" : ""}}>
                            <FontAwesomeIcon icon={faFolder}
                                             className={window.screen.width <= 576 ? "" : "me-1"}/> <span
                            className="hideOn576 ">Documenten</span>
                        </Button>
                        <DropdownButton title={window.screen.width <= 576 ? "" : "Templates "}
                                        className="extremeSmallBtn">
                            <Dropdown.Item
                                onClick={() => handleGetTemplate("opleveringsbrief")}>Opleveringsbrief</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleGetTemplate("opleveringsbrief_incl_software")}>Opleveringsbrief incl. software</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleGetTemplate("ibs_rapport")}>IBS rapport</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleGetTemplate("contract")}>Contractbrief</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleGetTemplate("contract_hosting")}>Contractbrief incl. Hosting</Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => handleGetTemplate("hosting_information")}>Hosting informatie</Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => handleGetTemplate("software_overdracht")}>Software Overdracht</Dropdown.Item>
                        </DropdownButton>
                    </span>
                </h1>
                <Alert className={"h-100"} variant={project.project.project_status === 2 ? "info" : "danger"}>
                    <div className="alert-icon">
                        {project.project.project_status === 2 ? <Info/> : <AlertCircle/>}
                    </div>
                    <div className="alert-message" style={{fontSize: 14}}>
                        <strong>{project.project.project_status === 2 ? "Dit project is actief" : "Dit project is inactief"}</strong>
                        {project.project.project_closed_timestamp ? " en is afgesloten op " + moment(project.project.project_closed_timestamp).format("DD-MM-YYYY HH:mm") : ""}
                    </div>
                </Alert>
                {/* 1 rij die zorgt dat beide kolommen naast elkaar zijn */}
                <Row>
                    {/* linker col */}
                    <Col xl="6">
                        {/* blok 1 in linker col */}
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Projectdetails
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Info/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Naam
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {project.project.project_name}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <File/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Referentie
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {project.project.project_code}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Home/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Gebouw/service
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <NavLink to={`/service-detail/${project.project.service_project_id}`}>
                                            {project.project.service_project_name}
                                        </NavLink>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Users/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Klant NAW
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <NavLink to={`/debtor/${project.project.projects_naw_id}`}>
                                            {project.project.projects_naw_company_name}
                                        </NavLink>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <User/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Projectleider
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {project.project.projects_employees_employee_name}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <DollarSign/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Opdrachtbedrag
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {euroFormat(project.project.project_contract_amount)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Archive/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Opdrachtnummer
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {project.project.project_contract_number}
                                    </Col>
                                </Row>
                                {project.project.project_warranty_expiration_date && <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <CalendarTodayIcon/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Garantie verlopen
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {project.project.project_warranty_expiration_date}
                                    </Col>
                                </Row>}
                            </Card.Body>
                        </Card>
                        {/* row 2 in linker col */}
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Financieel overzicht
                                    <GuardWrapper role={"write_manager:projects"}><ReassesmentModal id={id}
                                                                                                    reload={getData}/></GuardWrapper>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={"6"}>
                                        <Table borderless>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <strong>Verkoop</strong>
                                                </td>
                                                <td>{euroFormat(project.project.project_contract_amount)}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Calculatie</strong>
                                                </td>
                                                <td>{euroFormat(project.project.calc_cost_total)}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Geboekt</strong>
                                                </td>
                                                <td>{euroFormat(project.project.booking_cost_total)}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>POC</strong>
                                                </td>

                                                <td>{(!parseFloat(project.project.booking_cost_total) || !parseFloat(project.project.calc_cost_total)) ?
                                                    "" : `${((parseFloat(project.project.booking_cost_total) / parseFloat(project.project.calc_cost_total)) * 100).toFixed(0)}%`}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>

                                    </Col>
                                    <Col lg={"6"}>
                                        <Table borderless>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <strong>Marge</strong>
                                                </td>
                                                <td>
                                                    {
                                                        (!parseFloat(project.project.project_contract_amount) || !parseFloat(project.project.calc_cost_total)) ?
                                                            ""
                                                            : `${((1 - (parseFloat(project.project.calc_cost_total) / parseFloat(project.project.project_contract_amount))) * 100).toFixed(0)}%`
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Marge act.</strong>
                                                </td>
                                                <td>{(!parseFloat(project.project.project_contract_amount) || !parseFloat(project.project.booking_cost_total))
                                                    ? ""
                                                    : `${((1 - (project.project.booking_cost_total / project.project.project_contract_amount)) * 100).toFixed(0)}%`}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Gefactureerd</strong>
                                                </td>
                                                <td>{(!parseFloat(project.project.billed_invoice_amount) || !parseFloat(project.project.project_contract_amount))
                                                    ? ""
                                                    : euroFormat(project.project.billed_invoice_amount)}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Te factureren</strong>
                                                </td>
                                                <td>{project.project.unbilled_invoice_amount && euroFormat(project.project.unbilled_invoice_amount)}</td>
                                            </tr>
                                            </tbody>
                                        </Table>


                                    </Col>
                                </Row>
                                {showReestimate && <>
                                    <hr/>
                                    <Row>
                                        <Col>
                                            <Table borderless>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <strong>Re-estimate materiaal</strong>
                                                    </td>

                                                    <td>{euroFormat(project.project.project_re_estimate_mat)}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Re-estimate onderaanneming</strong>
                                                    </td>
                                                    <td>{euroFormat(project.project.project_re_estimate_oa)}</td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col>
                                            <Table borderless>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <strong>Re-estimate arbeid</strong>
                                                    </td>

                                                    <td>{euroFormat(project.project.project_re_estimate_arb)}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Re-estimate overig</strong>
                                                    </td>
                                                    <td>{euroFormat(project.project.project_re_estimate_ove)}</td>
                                                </tr>
                                                </tbody>
                                            </Table>

                                        </Col>
                                    </Row>
                                </>}
                                <ProjectResultDetailFinancialTable id={id} data={project.project_progress}/>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Bestellingen
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <ProjectResultDetailOrdersTable data={project.project_orders}/>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* Col rechts in row 1 */}
                    <Col xl="6">
                        {/* rij 1 in col 1 */}
                        <ActivityMenuProjectDetails id={id} project={project.project}/>
                        {/* Card 2 rechts */}
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Structuur
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Tree theme={theme === THEME.DARK ? "dark" : "light"}
                                      nodes={project.project_structure}
                                />
                            </Card.Body>
                        </Card>
                        {/* Card 3 rechts */}
                        <Card>
                            <Card.Body>
                                <h4 style={{margin: 10}}>Factuur termijnen</h4>
                                {project.invoice_terms.length ? project.invoice_terms.map(term => <Row
                                    key={term.projects_invoice_terms_id}
                                    className="d-flex p-2">
                                    <Col className={"d-flex align-items-center justify-content-center d-20-left"}>
                                        {term.projects_invoice_terms_status === 3 &&
                                            <SendIcon style={{color: palette.green}}/>}
                                        {
                                            (term.projects_invoice_terms_status === 0 && moment(term.projects_invoice_terms_planned_for) < moment()) &&
                                            <div className="dropdown">
                                                <SendIcon className={"cursor-pointer"} style={{color: palette.red}}/>
                                                <div className="dropdown-content">
                                                    <a href={createSafeFactLink(project.project, term, user)}>Verzenden</a>
                                                    <a href={createSafeMoveLink(project.project, term, user)}>Verzetten</a>
                                                </div>
                                            </div>
                                        }
                                        {(term.projects_invoice_terms_status === 0 && moment(term.projects_invoice_terms_planned_for) > moment()) &&
                                            <div className="dropdown">
                                                <SendIcon className={"cursor-pointer"}
                                                          style={{color: palette.blue}}/>
                                                <div className="dropdown-content">
                                                    <a href={createSafeFactLink(project.project, term, user)}>Verzenden</a>
                                                    <a href={createSafeMoveLink(project.project, term, user)}>Verzetten</a>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                    <Col sm="11 d-80-right">
                                        <strong>{term.projects_invoice_terms_commentary}</strong>
                                        <br/>
                                        <small
                                            className="text-muted d-block">{moment(term.projects_invoice_terms_planned_for).format("DD MMMM YYYY")}</small>
                                        <small
                                            className="text-muted d-block"><strong>{euroFormat(term.projects_invoice_terms_invoice_amount)}</strong></small>
                                    </Col>

                                </Row>) : <Row className="justify-content-center align-self-center w-100 text-center">
                                    Er zijn geen factuur termijnen gevonden.
                                </Row>}
                            </Card.Body>
                        </Card>
                        {/*  Card 4 rechts  */}
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Calculaties
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <ProjectResultDetailCalculationsTable projectCalculations={projectCalculations} reload={getProjectCalculationsData} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {/*<Vordering project={project}/>*/}


            </Container>
        </React.Fragment>
    );
};

export default ProjectResultsDetail;

const CloseModal = ({project, reload}) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (project.invoice_terms.filter(x => x.projects_invoice_terms_status !== 3).length) setDisabled(true)
        if (project.project.mutation_count) setDisabled(true)
        if (project.project.project_closed_timestamp) setDisabled(true)
    }, [project])

    const closeProject = async () => {
        setLoading(true)
        const resOffer = await projectsApi.closeProject(project.project.project_id);
        if (!resOffer.ok) return notyf.error("Error " + resOffer.status)
        reload()
        setLoading(false)
        handleClose()
    }

    if (disabled) return <span style={{cursor: "not-allowed"}}>
        <Button variant={"outline-danger"}
                disabled={true}
                className={window.screen.width <= 576 ? "extremeSmallBtn" : "extremeSmallBtn me-1"}>
            <FontAwesomeIcon
                icon={faDoorClosed} className={"me-1"}/> <span className="hideOn576 extremeSmallBtn">Afsluiten</span>
        </Button>
    </span>
    return (
        <>
            <Button variant={"danger"} onClick={handleShow} disabled={project.project.project_status === 3}
                    className={"me-1"}>
                <FontAwesomeIcon icon={faDoorClosed} className={"me-1"}/> <span
                className="hideOn576 extremeSmallBtn">Afsluiten</span>
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> Afsluiten </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Weet je zeker dat je {project.project.project_name} wilt afsluiten?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Annuleren
                    </Button>
                    <Button variant="danger" onClick={closeProject}>{loading ?
                        <Spinner animation="border" size="sm"/> : "Afsluiten"}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}