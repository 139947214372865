import React, {useEffect, useState} from "react";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {Button, Card, Container} from "react-bootstrap";
import engineeringApi from "../../../api/engineering";
import Loader from "../../../components/Loader";
import FollowingModal from "../../components/engineering/FollowingModal";
import {NavLink} from "react-router-dom";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import notyf from "../../../utils/notyfApi";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import {Helmet} from "react-helmet-async";
import {useExcelDownloder} from "react-xls";
import moment from "moment";
import TableViewIcon from "@mui/icons-material/TableView";


const defaultFilterValue = [
    {name: 'project_name', operator: 'contains', type: 'string', value: ''},
    {name: 'projects_employees_employee_name', operator: 'contains', type: 'string', value: ''},
];

const columns = [
    {
        name: "project_name",
        header: "Projectnaam",
        render: ({value, data}) => <NavLink to={`/project/${data.project_id}`}>{value}</NavLink>,
        flex: 2,
    },
    {
        name: "projects_employees_employee_name",
        header: "Projectleider",
        render: ({value, data}) => <NavLink
            to={encodeURI(`/project_overview/${data.projects_employees_employee_name}`)}>{value}</NavLink>,
        flex: 1,
    },
    {
        name: "ch",
        header: "Calculatie",
        flex: 1,
    },
    {
        name: "bh",
        header: "Actueel",
        classes: (cell, row) => {
            if (cell > row.ch) return "bg-danger text-light";
            return ""
        },
        flex: 1,
    },
    {
        name: "tg",
        header: "To Go",
        flex: 1,
    },
];


const Engineering = () => {
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(true);
    const {ExcelDownloder} = useExcelDownloder();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true)
        const response = await engineeringApi.getCetDashboard();
        if (!response.ok) return notyf.error("Error " + response.status)
        setDataSource({engineering: response.data});
        setLoading(false)

    };

    if (loading) return <Loader/>
    return (
        <React.Fragment>
            <Helmet title="Engineering"/>
            <Container fluid className="p-0">
                <Card className={"table-container"}>
                    <Card.Header>
                        <Card.Title tag="h5" className="mb-0 d-flex justify-content-between">
                            <h3>Engineering</h3>
                            <span>
                            <ExcelDownloder
                                data={dataSource}
                                filename={'Engineering ' + moment().format("DD-MM-YYYY")}
                                type={"link"}
                            >
                                <Button className={"me-1 added-tiny-margin-top remove-margin-top-mob"} variant={"secondary"}>
                                    <TableViewIcon fontSize={"small"}/>
                                </Button>
                            </ExcelDownloder>
                            <GuardWrapper role={"write:cet"}>
                                <FollowingModal reload={getData}/>
                            </GuardWrapper>
                                </span>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <ReactDataGridCustom
                            idProperty="projects_progress_id_project_id"
                            dataSource={dataSource.engineering}
                            columns={columns}
                            defaultLimit={50}
                            defaultFilterValue={defaultFilterValue}
                        />
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    )
};


export default Engineering;

