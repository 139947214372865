import React from "react";
import {Helmet} from "react-helmet-async";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {Container} from "react-bootstrap";
import SupplierTableHandler from "../../components/producten_inkoop/leveranciers/SupplierTableHandler";

const Suppliers = () => (
    <React.Fragment>
        <Helmet title="Leveranciers"/>
        <Container fluid className="p-0">
            <SupplierTableHandler/>
        </Container>
    </React.Fragment>
);

export default Suppliers;