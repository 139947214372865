import React from "react";
import {Helmet} from "react-helmet-async";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {Card, Container} from "react-bootstrap";

const Magazijnvoorraad = () => {
    return (
        <React.Fragment>
            <Helmet title="Magazijnvoorraad"/>
            <Container fluid className="p-0">
                <Card>
                    <Card.Header>
                        <h3 className={"mb-0"}>Magazijnvoorraad</h3>
                    </Card.Header>
                    <Card.Body>
                        <div style={{display: "none"}} className={"showOn576"}>
                            <p style={{textAlign: "center"}}>
                                Helaas! <br/>
                                Op telefoon werkt een iframe niet goed.
                                Als je de planning wilt zien kun je deze op een computer bekijken.
                            </p>
                        </div>
                        <div style={{height: "65vh"}}>
                            <iframe
                                src="https://nordomaticgroup.sharepoint.com/sites/OG-NL-GENERAL/_layouts/15/Doc.aspx?sourcedoc=%7B7894312d-eb65-40d5-bc95-8269633da1d4%7D&action=embedview&AllowTyping=True&ActiveCell=%27ArtikelMagazijn%27!A4&wdHideGridlines=True&wdHideHeaders=True&wdDownloadButton=True&wdInConfigurator=True&wdInConfigurator=True&edesNext=true&resen=false&ed1JS=false"
                                frameBorder="0"
                                height="100%"
                                width="100%"
                                title="Magazijnvoorraad"
                                className={"hideOn576"}
                            >
                                Het iframe werkt niet. Neem contact op met ict en zeg: "Het iframe bij
                                magazijnvoorraad werkt niet".
                            </iframe>
                        </div>
                    </Card.Body>
                </Card>

            </Container>
        </React.Fragment>
    )
};

export default Magazijnvoorraad;
