import client from "./clients/portalClient";

/**
 * Retrieves all rates from the server.
 *
 * @returns {Promise} A promise that resolves to the rates retrieved from the server.
 */
const getAllRates = () => client.get(`/rates`);
/**
 * Sends a POST request to the `/rates` endpoint with the provided data.
 *
 * @param {Object} data - The data to be sent in the request body.
 * @returns {Promise} A Promise representing the asynchronous operation of sending the POST request.
 *
 * @throws {Error} If an error occurs while sending the request.
 */
const postNewRates = (data) => client.post(`/rates`, data);

/**
 * Represents an Admin Rates API object.
 *
 * @typedef {Object} AdminRatesApi
 * @property {function} getAllRates - A function to get all rates.
 * @property {function} postNewRates - A function to post new rates.
 */
const adminRatesApi = {
    getAllRates,
    postNewRates,
};

export default adminRatesApi;
