import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {Badge, Button, ButtonGroup, Card, Container, ToggleButton} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import debtorsApi from "../../../api/debtors";
import moment from "moment";
import {euroFormat} from "../../../utils/usefullFunctions";
import notyf from "../../../utils/notyfApi";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import Loader from "../../../components/Loader";
import {useExcelDownloder} from "react-xls";
import TableViewIcon from "@mui/icons-material/TableView";

const defaultFilterValue = [
    {name: 'projects_invoices_invoicenumber', operator: 'contains', type: 'string', value: ''},
    {name: 'projects_naw_company_name', operator: 'contains', type: 'string', value: ''},
    {name: 'projects_invoices_projectname', operator: 'contains', type: 'string', value: ''},
    {name: 'projects_invoices_paid_amount', operator: 'contains', type: 'string', value: ''},
    {name: 'projects_invoices_outstanding_amount', operator: 'contains', type: 'string', value: ''},
];

const columns = [
    {
        name: "projects_invoices_invoicenumber",
        header: "Factuurnummer",
        render: ({value, data}) => <NavLink to={`/invoice/${data.projects_invoices_id}`}>{value}</NavLink>,
        flex: 1,
    },
    {
        name: "projects_naw_company_name",
        header: "Debiteur",
        render: ({value, data}) => <NavLink to={`/debtor/${data.projects_naw_id}`}>{value}</NavLink>,
        flex: 2,
    },
    {
        name: "projects_invoices_projectname",
        header: "Project",
        flex: 2.5,
    },
    {
        name: "projects_invoices_paid_amount",
        header: "Betaaldbedrag",
        render: ({value}) => euroFormat(value),
        flex: 1,
    },
    {
        name: "projects_invoices_outstanding_amount",
        header: "Openstaand",
        render: ({value}) => euroFormat(value),
        flex: 1,
    },
    {
        name: "projects_invoices_invoice_date",
        header: "Factuurdatum",
        render: ({value}) => moment(value).format("DD/MM/YY"),
        flex: 1,
    },
    {
        name: "projects_invoices_expiration_date",
        header: "Vervaldatum",
        render: ({value}) => moment(value).format("DD/MM/YY"),
        flex: 1,
    },
    {
        name: "Status",
        header: "Status",
        render: ({data}) => {
            if (parseFloat(data.projects_invoices_paid_amount) < 0) return <Badge
                bg={"warning"}>Credit</Badge>
            else if (parseFloat(data.projects_invoices_outstanding_amount) > 0 && !data.projects_invoices_paid && moment(data.projects_invoices_expiration_date) > moment()) return <Badge
                bg={"info"}>Openstaand</Badge>
            else if (parseFloat(data.projects_invoices_outstanding_amount) > 0 && !data.projects_invoices_paid && moment(data.projects_invoices_expiration_date) <= moment()) return <Badge
                bg={"danger"}>Over tijd</Badge>
            else return <Badge bg={"success"}>Betaald</Badge>
        },
        flex: 1,
    },
];


const Invoices = () => {
    const [dataSource, setDataSource] = useState([])
    const [cat, setCat] = useState("open")
    const {ExcelDownloder} = useExcelDownloder();
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cat])


    const getData = async () => {
        const res = await debtorsApi.getInvoices(cat)
        if (!res.ok) return notyf.error("Error", res.status)
        setDataSource({invoices: res.data})
        setLoading(false)
    }

    if (loading) return <Loader/>
    return (
        <React.Fragment>

            <Helmet title="Facturenoverzicht"/>
            <Container fluid className="p-0">
                <Card>
                    <Card.Header>
                        <Card.Title tag="h5" className="mb-0 d-flex">
                            <div className={"w-100 d-flex justify-content-between"}>
                                <h3 className={"mb-0"}>Facturenoverzicht</h3>
                                <span>
                                <ButtonGroup className={"custom-576-100w"}>
                                    <ToggleButton type="radio" checked={cat === "credit"} onClick={() => setCat("credit")}>Credit</ToggleButton>
                                    <ToggleButton type="radio" checked={cat === "open"} onClick={() => setCat("open")}>Openstaand</ToggleButton>
                                    <ToggleButton type="radio" checked={cat === "overdue"} onClick={() => setCat("overdue")}>Over tijd</ToggleButton>
                                    <ToggleButton type="radio" checked={cat === "all"} onClick={() => setCat("all")}>Alles</ToggleButton>

                                </ButtonGroup>
                                    <ExcelDownloder
                                        data={dataSource}
                                        filename={'Facturen ' + moment().format("DD-MM-YYYY")}
                                        type={"link"}
                                    >
                                         <Button className={"mx-1 added-tiny-margin-top remove-margin-top-mob"} variant={"secondary"}>
                                            <TableViewIcon fontSize={"small"}/>
                                        </Button>
                                    </ExcelDownloder>
                                </span>
                            </div>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>

                        <ReactDataGridCustom
                            idProperty="projects_invoices_id"
                            dataSource={dataSource.invoices}
                            columns={columns}
                            pagination
                            defaultLimit={50}
                            defaultFilterValue={defaultFilterValue}
                        />
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    )
};

export default Invoices;
