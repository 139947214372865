import {Button, ButtonGroup, Card, ToggleButton} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import nawCompaniesApi from "../../../api/nawCompanies";
import Loader from "../../../components/Loader";
import NawCompaniesAddCompanyModal from "./NawCompaniesAddCompanyModal";
import {NavLink} from "react-router-dom";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import {useExcelDownloder} from "react-xls";
import moment from "moment";
import TableViewIcon from "@mui/icons-material/TableView";


/**
 * Represents the default filter value for a list of sales accounts.
 *
 * @type {Array}
 * @property {string} name - The name of the sales account field.
 * @property {string} operator - The operator used for filtering the sales account field.
 * @property {string} type - The type of the sales account field.
 * @property {string} value - The value to filter the sales account field.
 */
const defaultFilterValue = [
    {name: 'sales_account_name', operator: 'contains', type: 'string', value: ''},
    {name: 'sales_account_city', operator: 'contains', type: 'string', value: ''},
    {name: 'sales_account_type', operator: 'contains', type: 'string', value: ''},
    {name: 'sales_account_website', operator: 'contains', type: 'string', value: ''},
];
/**
 * Represents an array of column configurations for a table.
 * Each column configuration has a name, header, render function (optional), and flex value.
 *
 * @typedef {Object} Column
 * @property {string} name - The name of the column.
 * @property {string} header - The header text of the column.
 * @property {Function} [render] - A function that renders the content of the column. Takes an object with the value and data as parameters.
 * @property {number} flex - The flex value of the column.
 */
const columns = [
    {
        name: "sales_account_name",
        header: "Bedrijf",
        render: ({value, data}) => <NavLink to={`/account/${data.sales_account_id}`}>{value}</NavLink>,
        flex: 1,


    },
    {
        name: "sales_account_city",
        header: "Adres",
        render: ({data}) => <a href={encodeURI(`https://maps.google.com/?q=${data.sales_account_street || ""}+
                                        ${data.sales_account_postal_code || ""}+
                                        ${data.sales_account_city || ""}+
                                        ${data.sales_account_country || ""}`)}
                               target="_blank"
                               rel="noreferrer">{`${data.sales_account_street ? data.sales_account_street + "," : ""}
        ${data.sales_account_postal_code ? data.sales_account_postal_code : ""}
        ${data.sales_account_city ? data.sales_account_city + "," : ""} ${data.sales_account_country || ""}`}</a>,
        flex: 2,
    },
    {
        name: "sales_account_type",
        header: "Categorie",
        flex: 1,
    },
    {
        name: "sales_account_website",
        header: "Website",
        render: ({value}) => <a href={value} target={"_blank"} rel="noreferrer">{value}</a>,
        flex: 1,
    },
    {
        name: "sales_account_active",
        header: "Status",
        render: ({value}) => {
            if (value === true) return <span className="badge bg-success">Actief</span>;
            else if (value === false) return <span className="badge bg-danger">Inactief</span>;
            else return <span className="badge bg-warning text-dark">Onbekend</span>;
        },
        flex: 0.5,
    },
];
/**
 * Fetches data from API and displays a table with the fetched data.
 *
 * @return {React.Element} - A Card component that contains the NAW-overzicht table.
 */
export default function NawCompaniesTableHandler() {
    //get data from api
    const [dataSource, setDataSource] = useState(false);
    const [category, setCategory] = useState("active")
    const [loading, setLoading] = useState(true);
    const {ExcelDownloder} = useExcelDownloder();


    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category]);

    const getData = async () => {
        setLoading(true)

        const response = await nawCompaniesApi.getAllNawCompanies(category);
        if (response.ok) {
            setDataSource({NAW_bedrijven: response.data});
        }

        setLoading(false)
    };

    //laat tabel zien met gegevens via props
    if (loading) return <Loader/>
    return <Card>
        <Card.Header>
            <Card.Title tag="h5" className="mb-0 d-flex">
                <div className={"w-100 d-flex justify-content-between"}>
                    <h3 className={"mb-0"}>NAW-overzicht</h3>

                    <div>

                        <ButtonGroup className={"me-1"}>
                            <ToggleButton type="radio" checked={category === "active"} value={1}
                                          onClick={() => setCategory("active")}>
                                Actief
                            </ToggleButton>
                            <ToggleButton type="radio" checked={category === "inactive"} value={2}
                                          onClick={() => setCategory("inactive")}>
                                Inactief
                            </ToggleButton>
                            <ToggleButton type="radio" checked={category === "all"} value={3}
                                          onClick={() => setCategory("all")}>
                                Alles
                            </ToggleButton>
                        </ButtonGroup>
                        <ExcelDownloder
                            data={dataSource}
                            filename={'NAW contacten ' + moment().format("DD-MM-YYYY")}
                            type={"link"}
                        >
                            <Button className={"me-1 added-tiny-margin-top remove-margin-top-mob"} variant={"secondary"}>
                                <TableViewIcon fontSize={"small"}/>
                            </Button>
                        </ExcelDownloder>
                        <GuardWrapper role={"write:naw"}>
                            <NawCompaniesAddCompanyModal reload={getData}/>
                        </GuardWrapper>

                    </div>
                </div>
            </Card.Title>
        </Card.Header>
        <Card.Body>
            <ReactDataGridCustom
                idProperty="sales_account_id"
                dataSource={dataSource.NAW_bedrijven}
                columns={columns}
                pagination
                defaultLimit={50}
                defaultFilterValue={defaultFilterValue}
            />
        </Card.Body>
    </Card>;
}
