import React, {useEffect, useRef, useState} from "react";
import {useFormikContext} from "formik";
import ErrorMessage from "./ErrorMessage";
import {Col, Form, Row} from "react-bootstrap";

/**
 * The API key used for accessing the Google API.
 *
 * @type {string}
 * @description This variable should be set as an environment variable with the name 'REACT_APP_GOOGLE_API_KEY'.
 * It is used to authenticate requests made to the Google API.
 *
 * @example
 * // Set the environment variable
 * REACT_APP_GOOGLE_API_KEY=YOUR_API_KEY_GOES_HERE
 *
 * // Access the apiKey in your code
 * const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
 */
const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
/**
 * The `mapApiJs` variable is used to store the URL of the Google Maps API JavaScript file.
 *
 * @type {string}
 * @constant
 */
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';

/**
 * Load an external script asynchronously.
 *
 * @param {string} src - The URL of the script to load.
 * @return {Promise<HTMLScriptElement>} A promise that resolves with the loaded script.
 */
function loadAsyncScript(src) {
    return new Promise(resolve => {
        const script = document.createElement("script");
        Object.assign(script, {
            type: "text/javascript",
            async: true,
            src
        })
        script.addEventListener("load", () => resolve(script));
        document.head.appendChild(script);
    })
}

/**
 * Extracts the address information from a place object.
 *
 * @param {object} place - The place object containing address information.
 * @returns {object} - An object representing the extracted address information.
 */
const extractAddress = (place) => {
    const address = {
        number: "",
        street: "",
        city: "",
        zip: "",
        plain() {
            const number = this.number ? this.number + ", " : "";
            const street = this.address ? this.street + ", " : "";
            const city = this.city ? this.city + ", " : "";
            const zip = this.zip ? this.zip + ", " : "";
            return street + number + city + zip;
        }
    }

    if (!Array.isArray(place?.address_components)) {
        return address;
    }

    place.address_components.forEach(component => {
        const types = component.types;
        const value = component.long_name;
        if (types.includes("street_number")) {
            address.street = value;
        }
        if (types.includes("route")) {
            address.street = value + " " + address.street;
        }
        if (types.includes("locality")) {
            address.city = value;
        }
        if (types.includes("postal_code")) {
            address.zip = value;
        }
    });
    return address;
}

/**
 * Displays a form field for an address order.
 *
 * @param {object} options - The options for the form field.
 * @param {object} options.names - The names of the form fields for the address components (number, street, city, state, zip, country).
 * @param {string} options.label - The label for the form field.
 * @return {JSX.Element} - The rendered address form field component.
 */
function AppFormFieldAddressOrder({names, label}) {
    const {setFieldTouched, setFieldValue, errors, touched, values} = useFormikContext();
    const searchInput = useRef(null);
    const [address, setAddress] = useState({
        number: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "",
    });
    const [firstRun, setFirstRun] = useState(true)

    // init gmap script
    const initMapScript = () => {
        // if script already loaded
        if (window.google) {
            return Promise.resolve();
        }
        const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
        return loadAsyncScript(src);
    }

    // do something on address change
    const onChangeAddress = (autocomplete) => {
        const place = autocomplete.getPlace();
        setAddress(extractAddress(place));
    }

    // init autocomplete
    const initAutocomplete = () => {
        if (!searchInput.current) return;
        const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
        autocomplete.setFields(["address_component", "geometry"]);
        autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));
    }
    useEffect(() => {
        if (firstRun) setFirstRun(false)
        else {
            for (let x of Object.keys(names)) {
                setFieldValue(names[x], address[x])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address])

    // load map script after mounted
    useEffect(() => {
        initMapScript().then(() => initAutocomplete())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Row className="search mb-1 mt-3">
                <Col>
                    {label && <Form.Label>{label}</Form.Label>}
                    <div style={{position: "relative"}}>
                        <Form.Control
                            onBlur={() => setFieldTouched(values[names.city])}
                            ref={searchInput}
                        />
                    </div>
                </Col>
            </Row>
            <Form.Group>
                <Form.Label>Straat</Form.Label>
                <Form.Control disabled={true} value={address.street || values[names.street]}/>
            </Form.Group>
            <Row className="mt-3">
                <Col lg={2}>
                    <Form.Group>
                        <Form.Label>Postcode</Form.Label>
                        <Form.Control disabled={true} value={address.zip || values[names.zip]}/>
                    </Form.Group>
                </Col>
                <Col lg={10}>
                    <Form.Group>
                        <Form.Label>Plaats</Form.Label>
                        <Form.Control disabled={true} value={address.city || values[names.city]}/>
                    </Form.Group>
                </Col>
            </Row>
            <ErrorMessage error={errors[names.city]} visible={touched[names.city]}/>
        </>
    );
}

export default AppFormFieldAddressOrder;
