import React, {useEffect, useState} from "react";
import {useFormikContext} from "formik";
import ErrorMessage from "./ErrorMessage";
import {Form} from "react-bootstrap";
import Select from "react-select";
import nawCompaniesApi from "../../api/nawCompanies";
import notyf from "../../utils/notyfApi";


/**
 * Renders a select field for a form with options fetched from an API.
 *
 * @param {Object} props - The properties for the select field.
 * @param {string} props.name - The name of the field.
 * @param {string} props.width - The width of the field.
 * @param {string} props.label - The label for the field.
 * @param {Object} props.otherProps - Other additional props for the field.
 * @return {JSX.Element} - The rendered select field.
 */
function AppFormSelectField({name, width, label, ...otherProps}) {
    const {setFieldTouched, setFieldValue, errors, touched, values} = useFormikContext();
    const [options, setOptions] = useState([])
    const [defaultValue, setDefaultValue] = useState(false)
    const [loading, setLoading] = useState(true)
    const getOptions = async () => {
        const res = await nawCompaniesApi.getAllNawCompanies("active")
        if (!res.ok) return notyf.error("Error " + res.status)
        let temp = []
        for (let a of res.data) {
            if (a.sales_account_id === values[name]) {
                setDefaultValue({label: a.sales_account_name, value: a.sales_account_id})
            }
            temp.push({
                value: a.sales_account_id,
                label: a.sales_account_name
            })
        }
        setOptions(temp)
        setLoading(false)
    }

    useEffect(() => {
        getOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loading) return <></>
    return (
        <>
            {label && <Form.Label>{label}</Form.Label>}
            <Select options={options}
                    onBlur={() => setFieldTouched(name)}
                    onChange={e => setFieldValue(name, e.value)}
                    defaultValue={defaultValue}
                    width={width}
                    noOptionsMessage={() => 'Geen bedrijven gevonden'}
                    {...otherProps}
            />
            <ErrorMessage error={errors[name]} visible={touched[name]}/>
        </>
    );
}

export default AppFormSelectField;
