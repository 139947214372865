import {create} from "apisauce";
import {getCookie, setCookie} from "../../utils/cookies";
import jwtDecode from "jwt-decode";

/**
 * Creates an instance of the API client.
 *
 * @param {Object} options - The options for configuring the client.
 * @param {string} options.baseURL - The base URL for the API.
 * @param {Object} options.headers - The headers to be included in API requests.
 * @returns {Object} - The API client instance.
 */
const apiClient = create({
    baseURL: process.env.REACT_APP_CALCULATION_URL,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
    },
});

apiClient.addAsyncRequestTransform(async (request) => {

    const authToken = getCookie("ibess_auth0_token")
    if (jwtDecode(authToken).exp < Math.round(Date.now() / 1000)) {
        setCookie("ibess_auth0_token", null)
        window.location.reload()
    }
    if (!authToken) return;
    if (!request.headers["authorization"]) request.headers["authorization"] = "Bearer " + authToken;

});

export default apiClient;
