import {Button, Card, Col, Form, Modal, Row, Tab, Tabs} from "react-bootstrap";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {Trash} from "react-feather";
import * as Yup from "yup";
import {Form as FormikForm, FormField, FormFieldDatePicker, SubmitButton} from "../../../components/forms";
import adminUsersApi from "../../../api/adminUsers";
import offersApi from "../../../api/offertes";
import {useAuth0} from "@auth0/auth0-react";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import notyf from "../../../utils/notyfApi";

/**
 * Represents a component for displaying details of an activity menu service.
 *
 * @param {object} props - The props object containing the id and offer properties.
 * @returns {JSX.Element} The rendered React component.
 */
const ActivityMenuServiceDetail = ({id, offer}) => {
    const {user} = useAuth0()
    const [logs, setLogs] = useState([])
    const [messages, setMessages] = useState([])
    const [tasks, setTasks] = useState([])
    const [activityFull, setActivityFull] = useState(false)

    useEffect(() => {
        getLogs()
        getMessages()
        getTasks()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offer])


    const getLogs = async () => {
        const resHistory = await offersApi.getHistory(id)
        if (!resHistory.ok) return notyf.error("Error " + resHistory.status)
        setLogs(resHistory.data.logs)
    }
    const getMessages = async () => {
        const resMessages = await offersApi.getMessages(id)
        if (!resMessages.ok) return notyf.error("Error " + resMessages.status)
        setMessages(resMessages.data)
    }
    const getTasks = async () => {
        const resTasks = await offersApi.getTasks(id)

        if (!resTasks.ok) return notyf.error("Error " + resTasks.status)
        setTasks(resTasks.data)
    }


    const deleteMessage = async (sId) => {
        const r = await offersApi.deleteMessage(id, sId)

        if (!r.ok) return notyf.error("Error " + r.status)
        getMessages()
    }

    const handleTaskToggle = async (task) => {

        const r = await offersApi.editTask(id, task.sales_offer_task_id, {
            ...task,
            sales_offer_task_completed: !task.sales_offer_task_completed
        })
        if (!r.ok) return notyf.error("Error " + r.status)
        getTasks()
    }

    const deleteTask = async (mId) => {
        const r = await offersApi.deleteTask(id, mId)

        if (!r.ok) return notyf.error("Error " + r.status)
        getTasks()
    }
    return (<Card>
            <Card.Body>
                <div style={{display: "block"}}>
                    <Tabs defaultActiveKey="Activiteit">
                        <Tab eventKey="Activiteit" title="Activiteit">
                            <ul className="timeline mt-2">
                                {logs.length ? (activityFull ? logs.map(log => <li key={log.log_detail_timestamp}
                                                                                   className="timeline-item">
                                    <strong>{log.log_detail_type}</strong>
                                    <span
                                        className="float-end text-muted text-sm">{moment(log.log_detail_timestamp).format("DD-MM-YYYY HH:mm")}</span>
                                    <p>
                                        {log.log_detail_message.replace(/<\/?[^>]+(>|$)/g, "")} {log.log_detail_created_by}
                                    </p>
                                </li>) : logs.slice(0, 10).map(log => <li key={log.log_detail_timestamp}
                                                                          className="timeline-item">
                                    <strong>{log.log_detail_type}</strong>
                                    <span
                                        className="float-end text-muted text-sm">{moment(log.log_detail_timestamp).format("DD-MM-YYYY HH:mm")}</span>
                                    <p>
                                        {log.log_detail_message.replace(/<\/?[^>]+(>|$)/g, "")} {log.log_detail_created_by}
                                    </p>
                                </li>)) : <Row style={{marginTop: "1rem"}}
                                               className="justify-content-center align-self-center w-100 text-center">
                                    Er zijn geen activiteiten gevonden.
                                </Row>}
                                {(logs.length > 10 && !activityFull) && <Button className={"float-end btn btn-primary"}
                                                                                onClick={() => setActivityFull(true)}>Meer
                                    laden</Button>}
                            </ul>
                        </Tab>
                        <Tab eventKey="Berichten" title="Berichten">
                            <div className={"pt-3"}>
                                <Row><Col>
                                    <GuardWrapper role={"write:offers"}>
                                        <AddMessageModal id={id} reload={getMessages}/>
                                    </GuardWrapper>
                                </Col></Row>
                                {!messages.length &&
                                    <Row className="justify-content-center align-self-center w-100 text-center">
                                        Geen berichten gevonden.
                                    </Row>}
                                {messages?.map(m => <div key={m.sales_offer_message_id} className={"mt-1"}>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <strong>{m.user_name}</strong>
                                            <br/>
                                            {m.user_name === user.name &&
                                                <Trash onClick={() => deleteMessage(m.sales_offer_message_id)}
                                                       className={"cursor-pointer float-end"}/>}
                                            <p>{m.sales_offer_message_comment}</p>
                                            <small
                                                className="text-muted">{moment(m.sales_offer_message_created_on).format("DD-MM-YYYY HH:mm")}</small>
                                            <br/>
                                        </div>
                                    </div>
                                    <hr/>
                                </div>)}
                            </div>
                        </Tab>
                        <Tab eventKey="Taken" title="Taken">
                            <div className={"pt-3"}>
                                <Row>
                                    <Col>
                                        <GuardWrapper role={"write:offers"}>
                                            <AddTaskModal id={id} exists={false} reload={getTasks}/>
                                        </GuardWrapper>
                                    </Col>
                                </Row>
                                {!tasks.length &&
                                    <Row className="justify-content-center align-self-center w-100 text-center">
                                        Geen taken gevonden.
                                    </Row>}
                                {tasks.map(task => <Row key={task.sales_offer_task_id} className="d-flex">
                                        <Col lg={2} className={"d-flex align-items-center justify-content-center"}>
                                            <Form.Check>
                                                <Form.Check.Input className={"big-checkbox"}
                                                                  type={"checkbox"}
                                                                  label={`Bestaand contactpersoon`}
                                                                  checked={task.sales_offer_task_completed}
                                                                  onChange={() => handleTaskToggle(task)}/>

                                            </Form.Check>
                                        </Col>

                                        <Col>
                                            {task.sales_offer_task_completed ?
                                                <del><strong>{task.sales_offer_task_title}</strong></del> :
                                                <strong>{task.sales_offer_task_title}</strong>}
                                            <br/>
                                            <p>
                                                {task.sales_offer_task_comment}
                                            </p>
                                            {moment(task.sales_offer_task_created_on).format("DD-MM-YYYY")}

                                            <small className="text-muted"> - {task.user_name}</small>

                                        </Col>
                                        <Col lg={2}>
                                            <AddTaskModal id={id} exists={task} reload={getTasks}/>
                                            <Trash className={"cursor-pointer mt-2"}
                                                   onClick={() => deleteTask(task.sales_offer_task_id)}/>

                                        </Col>
                                        <hr/>
                                    </Row>
                                )}
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </Card.Body>
        </Card>
    )
}

export default ActivityMenuServiceDetail

const AddMessageModal = (
    {
        id, reload
    }
) => {
    const [show, setShow] = useState(false);
    const [comment, setComment] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleSubmit = async () => {
        if (comment) {
            const r = await offersApi.postMessage(id, {sales_offer_message_comment: comment})
            if (!r.ok) return notyf.error("Error " + r.status)

            setComment("")
            handleClose()
            reload()
        }
    }

    return (
        <>
            <Button className={"position-absolute top-0 end-0 m-3"} onClick={handleShow}>
                +
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Berichten
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Opmerking toevoegen</Form.Label>
                        <Form.Control value={comment} onChange={e => setComment(e.target.value)} as="textarea"
                                      placeholder="Opmerking"/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Annuleren
                    </Button>
                    <Button variant="primary" disabled={!comment} onClick={handleSubmit}>
                        Toevoegen
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}
/**
 * AddTaskModal component represents a modal for adding tasks.
 *
 * @param {object} props - The component properties.
 * @param {number} props.id - The identifier of the modal.
 * @param {boolean} props.exists - Determines if the task already exists.
 * @param {Function} props.reload - Function to reload the tasks.
 *
 * @returns {JSX.Element} The AddTaskModal component.
 */
const AddTaskModal = (
    {
        id, exists, reload
    }
) => {
    const [show, setShow] = useState(false);
    const [users, setUsers] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        const res = await adminUsersApi.getUsersByRole()
        if (!res.ok) return notyf.error("Error " + res.status)
        setUsers(res.data.users)

    }

    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(false)
        const res = await offersApi.postTask(id, {
            ...values,
            sales_offer_task_date_reminder: moment(values.sales_offer_task_date_reminder).format("YYYY-MM-DD")
        })
        if (!res.ok) return notyf.error("Error " + res.status)

        handleClose()
        reload()

    }
    if (!users.length) return <></>
    return (
        <>
            <Button className={"position-absolute top-0 end-0 m-3"} onClick={handleShow}>
                +
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Taak aanmaken
                    </Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={{
                        sales_offer_task_user_id: exists?.sales_offer_task_user_id || "",
                        sales_offer_task_title: exists?.sales_offer_task_title || "",
                        sales_offer_task_comment: exists?.sales_offer_task_comment || "",
                        sales_offer_task_date_reminder: moment(exists?.sales_offer_task_date_reminder).toDate() || "",

                        sales_offer_task_completed: exists?.sales_offer_task_completed || false,

                    }}


                    validationSchema={Yup.object({

                        sales_offer_task_user_id: Yup.string().required('Persoon moet ingevuld worden'),
                        sales_offer_task_title: Yup.string().required('Onderwerp moet ingevuld worden'),
                        sales_offer_task_comment: Yup.string().required('Opmerking moet ingevuld worden'),
                        sales_offer_task_date_reminder: Yup.date().required('Herinnering moet ingevuld worden'),
                    })
                    }

                    onSubmit={handleSubmit}
                >
                    <Modal.Body>
                        <FormField name={"sales_offer_task_title"}
                                   label={"Taak"}
                                   width={"100%"}
                                   className={"mb-3"}
                                   control={"input"}
                        />

                        <FormFieldDatePicker name={"sales_offer_task_date_reminder"}
                                             label={"Herinnering datum"}
                                             width={"100%"}
                                             className={"mb-3 form-control"}
                                             control={"input"}
                        />
                        <FormField name={"sales_offer_task_user_id"}
                                   label={"Voor"}
                                   width={"100%"}
                                   className={"mb-3"}
                                   as={"select"}
                        >
                            <option value="">-</option>
                            {users.map(u => <option key={u.user_id} value={u.user_id}>{u.name}</option>)}
                        </FormField>

                        <FormField name={"sales_offer_task_comment"}
                                   label={"Opmerking"}
                                   width={"100%"}
                                   className={"mb-3"}
                                   control={"input"}
                                   as={"textarea"}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => setShow(false)}>
                            Annuleren
                        </Button>
                        <SubmitButton title={"Opslaan"} variant="primary"/>
                    </Modal.Footer>
                </FormikForm>

            </Modal>
        </>
    );
}