import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import React, {useEffect, useState} from "react";
import {Card, Row} from "react-bootstrap";
import offersApi from "../../../../api/offertes";
import OfferProjectFormsModal from "./OfferProjectFormsModal";
import GuardWrapper from "../../../../components/guards/GuardWrapper";
import notyf from "../../../../utils/notyfApi";
import OfferDeleteProjectFormModal from "./OfferDeleteProjectFormModal";
import Loader from "../../../../components/Loader";

/**
 * Renders a list of project forms for a given project.
 *
 * @param {Object} props - The props object.
 * @param {string} props.id - The project ID.
 * @param {string} props.name - The project name.
 * @param {string} props.reference - The project reference.
 * @returns {JSX.Element} The rendered project forms component.
 */
function OfferProjectForms({id, name, reference}) {
    const columns = [
        {
            dataField: "project_form_project_id",
            text: "id",
            hidden: true,
        },
        {
            dataField: "project_form_name",
            text: "naam",
            formatter: (_c, r) => {
                return <a target="_blank" rel="noreferrer"
                          href={`https://project-formulier.y-con.nl/${r.project_form_id}`}>
                    {r.project_form_code} {r.project_form_name}
                </a>
            },
            sort: true,
        },
        {
            text: "acties",
            dataField: "project_form_code",
            formatter: (_c, r) => {
                return <GuardWrapper role={"write:offers"}>
                    <OfferDeleteProjectFormModal id={r.project_form_id} reload={getData}/>
                </GuardWrapper>
            },
            sort: true,
        },
    ];

    const [list, setList] = useState(null);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        const res = await offersApi.getAllForms(id);
        if (!res.ok) return notyf.error("Error " + res.status)
        setList(res.data);
    };

    if (!list) return <Loader/>;
    return (
        <Card>
            <Card.Header>
                <Card.Title tag="h5" className="mb-0 ">
                    Projectformulieren
                    <GuardWrapper role={"write:offers"}>
                        <OfferProjectFormsModal id={id} name={name} reference={reference} reload={getData}/>
                    </GuardWrapper>
                </Card.Title>
            </Card.Header>
            <Card.Body>
                {list.length ? <ToolkitProvider
                    keyField="project_form_project_id"
                    data={list}
                    columns={columns}
                    search
                >
                    {(props) => (
                        <div>
                            <BootstrapTable
                                {...props.baseProps}
                                pagination={paginationFactory()}
                            />
                        </div>
                    )}
                </ToolkitProvider> : <Row className="justify-content-center align-self-center w-100 text-center">
                    Er zijn geen projectformulieren gevonden.
                </Row>}
            </Card.Body>
        </Card>
    );
}

export default OfferProjectForms;
