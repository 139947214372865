import React, {useEffect, useState} from "react";
import Loader from "../../../components/Loader";
import HourlyRatesTable from "./HourlyRatesTable";
import adminRates from "../../../api/adminRates";

/**
 * Retrieves hourly rates data from an API and displays it in a table.
 *
 * @returns {React.Component} - A React component that either renders a table with the hourly rates data
 * or displays a loader while fetching the data.
 */
export default function HourlyRatesTableHandler() {
    //get data from api
    const [hourlyRate, setHourlyRate] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const response = await adminRates.getAllRates();
        if (response.ok) {
            setHourlyRate(response.data);
        }
    };

    //laat tabel zien met gegevens via props
    if (hourlyRate.length > 0) return <HourlyRatesTable reload={getData} hourlyRate={hourlyRate}/>;
    return <Loader/>
}
