import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Button, Card, Col, Container, Modal, Row} from "react-bootstrap";
import {Compass, File, Folder, Info, Mail, Map, Phone, Tool, Truck,} from "react-feather";
import {useNavigate, useParams} from "react-router-dom";
import NawCompaniesDetailOffersTableHandler from "../../components/algemeen/NawCompaniesDetailOffersTableHandler";
import nawCompaniesApi from "../../../api/nawCompanies";
import NawCompaniesDetailsContactTableHandler from "../../components/algemeen/NawCompaniesDetailContactTableHandler";
import ActivityMenuCompanies from "../../components/activiteitenMenu/ActivityMenuCompanies";
import NawCompaniesAddCompanyModal from "../../components/algemeen/NawCompaniesAddCompanyModal";
import Loader from "../../../components/Loader";
import NawContactsAddContactModal from "../../components/algemeen/NawContactsAddContactModal";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import notyf from "../../../utils/notyfApi";

// Show the company contact details
const NawCompaniesDetail = () => {
    const {id} = useParams();
    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState(null);
    const [companyOffers, setCompanyOffers] = useState(null); // Offertes card
    const [companyContactPersons, setCompanyContactPersons] = useState(null); // Contactpersonen van dat bedrijf

    useEffect(() => {
        getNawCompanyData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getNawCompanyData = async () => {
        const res = await nawCompaniesApi.getNawCompany(id);
        if (!res.ok) return notyf.error("Error " + res.status)
        setCompany(res.data.account);
        setCompanyOffers(res.data.offers);
        setCompanyContactPersons(res.data.contacts);
        setLoading(false);
    };
    if (loading) return <Loader/>;
    return (
        <React.Fragment>
            <Helmet title={company.sales_account_name}/>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">{company.sales_account_name} {company.sales_account_document_link &&
                    <Button href={company.sales_account_document_link}
                            disabled={!company.sales_account_document_linkk}
                            variant={!company.sales_account_document_link ? "outline-secondary" : "secondary"}
                            style={{cursor: !company.sales_account_document_link ? "not-allowed" : ""}}
                            className={"me-1"}
                            target="_blank"
                            rel="noreferrer"
                    >
                        KES documenten
                    </Button>
                }
                    {company.sales_account_active &&
                        <GuardWrapper role={"write:naw"}><DeleteModal company={company}/></GuardWrapper>}
                </h1>
                <Row>
                    <Col xl="4">
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Bedrijfsdetails
                                    <GuardWrapper role={"write:naw"}>
                                        <NawCompaniesAddCompanyModal exists={company} reload={getNawCompanyData}/>
                                    </GuardWrapper>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <File/>
                                    </Col>
                                    <Col lg={"4"} className="d-576-inline">
                                        Bedrijfsnaam
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {company.sales_account_name}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Map/>
                                    </Col>
                                    <Col lg={"4"} className="d-576-inline">
                                        Adres
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <a href={encodeURI(`https://maps.google.com/?q=${company.sales_account_street || ""}+
                                        ${company.sales_account_street_nr || ""}+${company.sales_account_city || ""}`)}>
                                            {company.sales_account_street}{company.sales_account_street_nr} {company.sales_account_city}
                                        </a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Phone/>
                                    </Col>
                                    <Col lg={"4"} className="d-576-inline">
                                        Telefoonnummer
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {company.sales_account_phone_nr}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Mail/>
                                    </Col>
                                    <Col lg={"4"} className="d-576-inline">
                                        Emailadres
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <a href={"mailto:" + company.sales_account_email}>{company.sales_account_email}</a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Info/>
                                    </Col>
                                    <Col lg={"4"} className="d-576-inline">
                                        KVK nummer
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {company.sales_account_kvk_nr}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Folder/>
                                    </Col>
                                    <Col lg={"4"} className="d-576-inline">
                                        BTW nummer
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {company.sales_account_btw_nr}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Tool/>
                                    </Col>
                                    <Col lg={"4"} className="d-576-inline">
                                        Type
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {company.sales_account_type}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Compass/>
                                    </Col>
                                    <Col lg={"4"} className="d-576-inline">
                                        Postcode
                                    </Col>
                                    <Col
                                        lg={"7"}
                                        className="d-576-inline">{company.sales_account_postal_code} {company.sales_account_city}</Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="d-576-inline-img">
                                        <Truck/>
                                    </Col>
                                    <Col lg={"4"} className="d-576-inline">
                                        Postbus
                                    </Col>
                                    <Col
                                        lg={"7"} className="d-576-inline">{`${company.sales_account_mailbox_nr || ""} 
                                        ${company.sales_account_mailbox_postal_code || ""} 
                                        ${company.sales_account_mailbox_city || ""} 
                                        ${company.sales_account_mailbox_country || ""}`}</Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        {/* tabel NAW BEDRIJVEN detail contactpersonen */}
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Contactpersonen
                                    <GuardWrapper role={"write:naw"}>
                                        <NawContactsAddContactModal
                                            company={{sales_contact_account_id: company.sales_account_id}}
                                            reload={getNawCompanyData}/>
                                    </GuardWrapper>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {/* Table contactpersonen */}
                                <NawCompaniesDetailsContactTableHandler
                                    companyContactPersons={companyContactPersons}/>
                            </Card.Body>
                        </Card>
                        {/* Table end */}
                    </Col>
                    <Col xl="4">
                        <ActivityMenuCompanies id={id} company={company}/>
                    </Col>
                    {/* Table offertes */}
                    <Col xl="4">
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Offertes
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <NawCompaniesDetailOffersTableHandler companyOffers={companyOffers}/>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default NawCompaniesDetail;

const DeleteModal = ({company}) => {
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDelete = async () => {
        const res = await nawCompaniesApi.deleteCompany(company.sales_account_id)
        if (!res.ok) return notyf.error("Error " + res.status)
        navigate("/account_overview")
    }

    return (
        <>
            <Button variant={"danger"} className={"float-end mb-0 btn-576-float-none"} onClick={handleShow}>
                {/*<FontAwesomeIcon icon={faTrash} className={"me-1"}/> */}
                Deactiveren
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> Deactiveren </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Weet je zeker dat je {company.sales_account_name} wilt deactiveren?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Annuleren
                    </Button>
                    <Button variant="danger" onClick={handleDelete}> Deactiveren </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}