import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Alert, Button, Card, Col, Container, Row} from "react-bootstrap";
import debtorsApi from "../../../api/debtors";
import {NavLink, useParams} from "react-router-dom";
import Loader from "../../../components/Loader";
import {euroFormat} from "../../../utils/usefullFunctions";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {BookOpen, Check, Trash} from "react-feather";
import usePalette from "../../../hooks/usePalette";
import {useAuth0} from "@auth0/auth0-react";
import InvoiceDetailAddCommentModal from "../../components/debiteuren/InvoiceDetailAddCommentModal";
import DatePicker from "react-datepicker";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import notyf from "../../../utils/notyfApi";
import {localeDatapicker} from "../../../constants";

const InvoicesDetail = () => {
    const {id} = useParams()
    const palette = usePalette()
    const {user} = useAuth0()
    const [invoice, setInvoice] = useState()
    const [comments, setComments] = useState([])

    useEffect(() => {
        getData()
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const res = await debtorsApi.getInvoice(id)
        if (!res.ok) return notyf.error("Error " + res.status)
        const resCom = await debtorsApi.getInvoiceComments(id)
        if (!resCom.ok) return notyf.error("Error " + resCom.status)
        setComments(resCom.data)
        setInvoice(res.data)
    }

    const columns = [
        {
            dataField: "projects_invoices_comment_id",
            hidden: true
        },
        {
            dataField: "projects_invoices_comment",
            text: "Opmerking",
            sort: true,
        },
        {
            dataField: "user_name",
            text: "Door",
            sort: true,
        },
        {
            dataField: "projects_invoices_created_on",
            text: "Gemaakt op",
            formatter: c => moment(c).format("DD/MM/YYYY"),
            sort: true,
        },
        {
            dataField: "action",
            text: "Actie",
            formatter: (_c, r) => <div>{user.name === r.user_name ?
                <Trash className={"cursor-pointer"} onClick={() => deleteComment(r.projects_invoices_comment_id)}
                       color={palette.danger}/> : ""}</div>
        },
    ]

    const deleteComment = async (cId) => {
        const res = await debtorsApi.deleteInvoiceComment(id, cId)
        if (!res.ok) return notyf.error("Error " + res.status)
        getData()

    }
    const addComment = async (comment) => {
        const res = await debtorsApi.addInvoiceComment(id, {projects_invoices_comment: comment})
        if (!res.ok) return notyf.error("Error " + res.status)
        getData()

    }
    const togglePayed = async () => {
        const res = await debtorsApi.editInvoice(id, {
            projects_invoices_paid: !invoice.projects_invoices_paid,
            projects_invoices_expected_payment_date: invoice.projects_invoices_expected_payment_date
        })
        if (!res.ok) return notyf.error("Error " + res.status)
        getData()
    }

    const handleDateChange = async (date) => {
        const res = await debtorsApi.editInvoice(id, {
            projects_invoices_expected_payment_date: moment(date).format("YYYY-MM-DD"),
            projects_invoices_paid: invoice.projects_invoices_paid
        })
        if (!res.ok) return notyf.error("Error " + res.status)
        getData()
    }

    if (!invoice) return <Loader/>
    return (
        <React.Fragment>
            <Helmet title={invoice.projects_invoices_description1}/>
            <GuardWrapper role={"write:invoices"}>
                <Button onClick={togglePayed} className={"float-end added-custom-button-margin576"}>
                    {invoice.projects_invoices_paid ? "Openen" : "Betaald"}
                </Button>
            </GuardWrapper>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">{invoice.projects_invoices_description1}</h1>

                {invoice.projects_invoices_paid ? <Alert variant={"primary"}>
                        <div className="alert-icon">
                            <Check/>
                        </div>
                        <div className="alert-message" style={{fontSize: 14}}>
                            <strong>Betaald</strong>
                        </div>
                    </Alert> :
                    <Alert variant={"danger"}>
                        <div className="alert-icon">
                            <BookOpen/>
                        </div>
                        <div className="alert-message" style={{fontSize: 14}}>
                            <strong>Open</strong>
                        </div>
                    </Alert>}
                <Row>
                    {/* linker col */}
                    <Col xl="4">
                        {/* blok 1 in linker col */}
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Factuurdetails
                                    <a className={"float-end"} target="_blank" rel="noreferrer"
                                       href={`https://yconnl.sharepoint.com/sites/administratie/ycon/Ycon%20Debiteuren/PROJECTFACTUREN/${moment(invoice.projects_invoices_invoice_date).format("YYYY")}/Factuur_${invoice.projects_invoices_invoicenumber}.pdf`}><PictureAsPdfIcon
                                        fontSize="medium"/></a>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Factuurnummer
                                    </Col>
                                    <Col lg={"8"} className="mb-3 d-576-inline">
                                        {invoice.projects_invoices_invoicenumber}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Debiteurnaam
                                    </Col>
                                    <Col lg={"8"} className="mb-3 d-576-inline">
                                        <NavLink
                                            to={`/debtor/${invoice.projects_naw_id}`}>{invoice.projects_naw_company_name}</NavLink>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Factuurdatum
                                    </Col>
                                    <Col lg={"8"} className="mb-3 d-576-inline">
                                        {moment(invoice.projects_invoices_invoice_date).format("DD/MM/YYYY")}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Vervaldatum
                                    </Col>
                                    <Col lg={"8"} className="mb-3 d-576-inline">
                                        {moment(invoice.projects_invoices_expiration_date).format("DD/MM/YYYY")}
                                    </Col>
                                    <hr/>
                                </Row>
                                <Row className={"d-flex align-items-center"}>
                                    <Col lg={"4"} className="mb-3">
                                        Verwachte betaaldatum
                                    </Col>
                                    <Col lg={"8"} className="mb-3">
                                        {<DatePicker
                                            className={"form-control"}
                                            dateFormat={"dd-MM-yyyy"}
                                            locale={localeDatapicker}
                                            calendarStartDay={1}
                                            selected={invoice.projects_invoices_expected_payment_date && moment(invoice.projects_invoices_expected_payment_date).toDate()}
                                            onChange={(date) => handleDateChange(date)}

                                        />}
                                    </Col>
                                    <hr/>
                                </Row>
                                <Row>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Betaald bedrag
                                    </Col>
                                    <Col lg={"8"} className="mb-3 d-576-inline">
                                        {euroFormat(invoice.projects_invoices_paid_amount)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Factuur bedrag
                                    </Col>
                                    <Col lg={"8"} className="mb-3 d-576-inline">
                                        {euroFormat(invoice.projects_invoices_total_incl_vat)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Betaald bedrag
                                    </Col>
                                    <Col lg={"8"} className="mb-3 d-576-inline">
                                        {euroFormat(invoice.projects_invoices_outstanding_amount)}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Card.Title>Factuurtekst</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <p>{invoice.projects_invoices_description1}</p>
                                <p><strong>{invoice.projects_invoices_description2}</strong></p>
                                <p>{invoice.projects_invoices_description3}</p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Card.Title>Opmerkingen <InvoiceDetailAddCommentModal submit={addComment}/>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {comments.length ?
                                    <BootstrapTable
                                        columns={columns}
                                        keyField={"projects_invoices_comment_id"}
                                        data={comments}
                                    /> : <Row className="justify-content-center align-self-center w-100 text-center">
                                        Er zijn geen opmerkingen gevonden.
                                    </Row>}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
};

export default InvoicesDetail;
