import React from "react";
import {Helmet} from "react-helmet-async";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {Container} from "react-bootstrap";
import ProductOverviewTableHandler from "../../components/producten_inkoop/product_overview/ProductOverviewTableHandler";

const Productoverview = () => (
    <React.Fragment>
        <Helmet title="Productenoverzicht"/>
        <Container fluid className="p-0">
            <ProductOverviewTableHandler/>
        </Container>
    </React.Fragment>
);

export default Productoverview;
