import React from "react";

import { THEME } from "../constants";
import useSettingsState from "../hooks/useSettingsState";

/**
 * Represents the initial state of the application.
 *
 * @typedef {Object} initialState
 * @property {string} theme - The default theme of the application.
 */
const initialState = {
  theme: THEME.DEFAULT,
};

/**
 * Creates a Context for managing theme state in a React application.
 *
 * @param {object} initialState - The initial state of the theme.
 * @returns {object} - The ThemeContext object.
 */
const ThemeContext = React.createContext(initialState);

/**
 * Provides a theme to components within its child hierarchy.
 *
 * @param {Object} props - The properties for the ThemeProvider component.
 * @param {ReactNode} props.children - The child components to be rendered within the ThemeProvider.
 *
 * @return {ReactNode} - The rendered child components wrapped within the ThemeProvider.
 */
function ThemeProvider({ children }) {
  const [theme, setTheme] = useSettingsState("theme", THEME.DEFAULT);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, ThemeContext };
