import React from "react";
import {Row} from "react-bootstrap";
import NawCompaniesDetailsOffersTable from "./NawCompaniesDetailOffersTable";

/**
 * Renders a table with company offers or a message indicating no offers found.
 *
 * @param {Array} companyOffers - The array of company offers.
 *
 * @return {React.Element} - The rendered table or a message indicating no offers found.
 */
export default function NawCompaniesDetailOffersTableHandler({companyOffers}) {
  //laat tabel zien met gegevens via props
  if (companyOffers.length > 0) return <NawCompaniesDetailsOffersTable companyOffers={companyOffers} />;
  else
    return (
      <Row className="justify-content-center align-self-center w-100 text-center">
        Geen offertes van dit bedrijf gevonden.
      </Row>
    );
}

