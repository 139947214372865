import React, {useEffect, useState} from "react";
import {Button, Card} from "react-bootstrap";
import adminUsersApi from "../../../api/adminUsers";
import {NavLink} from "react-router-dom";
import Loader from "../../../components/Loader";
import {Edit2} from "react-feather";
import notyf from "../../../utils/notyfApi";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import {useExcelDownloder} from "react-xls";
import moment from "moment";
import TableViewIcon from "@mui/icons-material/TableView";

/**
 * Represents a default filter value.
 *
 * @typedef {Object} DefaultFilterValue
 * @property {string} name - The name of the filter.
 * @property {string} operator - The operator to apply on the filter value.
 * @property {string} type - The data type of the filter value.
 * @property {string} value - The default value for the filter.
 */
const defaultFilterValue = [
    {name: 'year', operator: 'contains', type: 'string', value: ''},
    {name: 'weekly', operator: 'contains', type: 'string', value: ''},
    {name: 'full_name', operator: 'contains', type: 'string', value: ''},
];

/**
 * Represents the column configuration for a table.
 * @typedef {Object} Column
 * @property {string} name - The name of the column.
 * @property {string} header - The header text for the column.
 * @property {number} flex - The flex value for the column.
 * @property {Function} render - A function for rendering the column's value.
 */
const columns = [
    {
        name: "year",
        header: "Jaar",
        flex: 1,
    },
    {
        name: "weekly",
        header: "Week",
        flex: 1,
    },
    {
        name: "full_name",
        header: "Door",
        flex: 1,
    },
    {
        name: "sum_hours",
        header: "Aantal uren",
        flex: 1,

        render: ({value}) => {
            if(value) return parseFloat(value)
        },
    },
    {
        name: "completed",
        header: "Actief",
        render: ({value}) => {
            if (value === true) {
                return <span className="badge bg-success">INGEDIEND</span>;
            } else {
                return <span className="badge bg-danger">OPENSTAAND</span>;
            }
        },
        flex: 1,
    },
    {
        name: "Acties",
        header: "",
        render: ({data}) => {
            return <NavLink to={`/timesheets/${data.year}/${data.weekly}/${data.user_id}`}> <Edit2/> </NavLink>
        },
        flex: 1,
    },
];

/**
 * Retrieve and display the week statistics for administrative purposes.
 * @returns {JSX.Element} - The component that displays the week statistics.
 */
export default function AdminWeekTableHandler() {
    //get data from api
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(true);
    const {ExcelDownloder} = useExcelDownloder(); // Hook voor excel download van tabel


    useEffect(() => {
        getData();
    }, []);


    const getData = async () => {
        const response = await adminUsersApi.getWeekstaten();
        if (!response.ok) return notyf.error("Error " + response.status)
        response.data.forEach((item, i) => {
            item.id = i + 1;
        })
        setDataSource({adminWeek: response.data})
        setLoading(false)

    };

    if (loading) return <Loader/>

    return (
        <Card>
            <Card.Header>
                <div className={"w-100 d-flex justify-content-between"}>
                    <h3 className={"mb-0"}>Weekstaten</h3>

                                                     <ExcelDownloder
                                                         data={dataSource}
                                                         filename={'Users ' + moment().format("DD-MM-YYYY")}
                                                         type={"link"}
                                                     >
                                <Button className={"me-1 added-tiny-margin-top remove-margin-top-mob"} variant={"secondary"}>
                                    <TableViewIcon fontSize={"small"}/>
                                </Button>
                            </ExcelDownloder>
                </div>
            </Card.Header>
            <Card.Body>
                <ReactDataGridCustom
                    idProperty="id"
                    dataSource={dataSource.adminWeek}
                    columns={columns}
                    pagination
                    defaultLimit={50}
                    defaultFilterValue={defaultFilterValue}
                />
            </Card.Body>
        </Card>
    );
}
