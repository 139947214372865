import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {Badge, Button, Card, Container} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import serviceProjectApi from "../../../api/service";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import Loader from "../../../components/Loader";
import moment from "moment";
import TableViewIcon from "@mui/icons-material/TableView";
import {useExcelDownloder} from "react-xls";

const defaultFilterValue = [
    {name: 'service_project_name', operator: 'contains', type: 'string', value: ''},
    {name: 'service_project_street', operator: 'contains', type: 'string', value: ''},
    {name: 'service_project_alias', operator: 'contains', type: 'string', value: ''},
];

const columns = [
    {
        name: "service_project_name",
        header: "Projectnaam",
        render: ({value, data}) => <NavLink to={`/service-detail/${data.service_project_id}`}>{value}</NavLink>,
        flex: 2,
    },
    {
        name: "service_project_street",
        header: "Adres",
        render: ({data}) => <a
            href={encodeURI(`https://maps.google.com/?q=${data.service_project_street}+${data.service_project_street_nr}+${data.service_project_city}`)}>{`${data.service_project_street || ""} 
            ${data.service_project_street_nr ? data.service_project_street_nr + "," : ""} ${data.service_project_city || ""}`}</a>,
        flex: 2,

    },
    {
        name: "projects_count",
        header: "Projecten",
        render: ({data}) => <>
            {data.active_count ? <div><Badge bg={"success"}>{data.active_count} Active </Badge></div> : ""}
            {data.projects_count ? <div><Badge bg={"danger"}>{data.projects_count} Afgesloten</Badge></div> : ""}
            {data.host_count ? <div><Badge bg={"info"}>Onderhoud</Badge></div> : ""}
        </>,
        flex: 1,

    },
    {
        name: "Tickets",
        header: "Tickets",
        render: ({data}) => <>
            {data.open_ticket_count ? <div><Badge bg={"danger"}>{data.open_ticket_count} Open</Badge></div> : ""}
            {data.closed_ticket_count ? <div><Badge bg={"info"}>{data.closed_ticket_count} Afgesloten </Badge></div> : ""}
        </>,
        flex: 1,

    },
    {
        name: "service_project_alias",
        header: "Alias",
        flex: 2,
    },
];


const ServiceList = () => {
    const [dataSource, setDataSource] = useState([])
    const {ExcelDownloder} = useExcelDownloder();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData()
    }, [])


    const getData = async () => {
        setLoading(true)
        const res = await serviceProjectApi.getAllServiceProjects()
        if (!res.ok) return null
        setDataSource({service: res.data})
        setLoading(false)
    }

    if (loading) return <Loader/>
    return (
        <Card>
            <Card.Header>
                <Card.Title className="mb-0 d-flex justify-content-between">
                    <h3 className="mb-0">Service</h3>
                    <ExcelDownloder
                        data={dataSource}
                        filename={'service ' + moment().format("DD-MM-YYYY")}
                        type={"link"}
                    >
                        <Button className={"me-1 added-tiny-margin-top remove-margin-top-mob"} variant={"secondary"}>
                            <TableViewIcon fontSize={"small"}/>
                        </Button>
                    </ExcelDownloder>

                </Card.Title>
            </Card.Header>
            <Card.Body>
                <ReactDataGridCustom
                    idProperty="service_project_id"
                    dataSource={dataSource.service}
                    columns={columns}
                    pagination
                    defaultLimit={50}
                    defaultFilterValue={defaultFilterValue}
                />
            </Card.Body>
        </Card>
    )
};

const ServicePage = () => (
    <React.Fragment>
        <Helmet title="Service"/>
        <Container fluid className="p-0">
            <ServiceList/>
        </Container>
    </React.Fragment>
);

export default ServicePage;
