import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import React, {useEffect, useState} from "react";
import {Badge, Card, Row} from "react-bootstrap";
import offersApi from "../../../../api/offertes";
import OfferCalculationsModal from "./OfferCalculationsModal";
import OfferDeleteCalculationModal from "./OfferDeleteCalculationModal";
import GuardWrapper from "../../../../components/guards/GuardWrapper";
import notyf from "../../../../utils/notyfApi";
import moment from "moment";

/**
 * Perform calculations related to offers.
 *
 * @param {object} params - The parameters object.
 * @param {string} params.id - The ID of the offer.
 * @param {string} params.name - The name of the offer.
 * @param {string} params.reference - The reference of the offer.
 *
 * @return {ReactNode} - The JSX element representing the offer calculations card.
 */
function OfferCalculations({id, name, reference}) {
    const {SearchBar} = Search;
    const columns = [
        {
            dataField: "calculation_id",
            text: "Id",
            hidden: true,
        },
        {
            dataField: "naam",
            text: "Naam",
            formatter: (_c, r) => {
                return <a target="_blank"
                          rel="noreferrer"
                          href={`https://calculatie.ibess.nl/${r.calculation_id}/overzicht`}>{r.calculation_reference} {r.calculation_name}</a>
            },
            sort: true,
        },
        {
            dataField: "badge",
            text: "Status",
            formatter: (_c, r) => {
                if (r.got_m) return <Badge bg={"success"}>Binnengehaald</Badge>
                return <Badge bg={"primary"}>Openstaand</Badge>
            },
            sort: true,
        },
        {
            dataField: "naw_contact_name",
            text: "Aanvrager",
            sort: true,
        },
        {
            dataField: "created_on",
            text: "Aangemaakt op",
            formatter: (c) => {
                return moment(c).format("DD-MM-YYYY")
            },
            sort: true,
        },
        {
            dataField: "acties",
            text: "Acties",
            formatter: (_c, r) => {
                return <GuardWrapper role={"write:offers"}>
                    <OfferCalculationsModal id={id} cId={r.calculation_id} name={r.calculation_name}
                                            reference={r.calculation_reference} reload={getData} duplicate={true}/>
                    <OfferDeleteCalculationModal id={r.calculation_id} reload={getData}/>
                </GuardWrapper>
            },
            sort: true,
            style: {width: "12%"}
        },
    ];

    const [list, setList] = useState(null);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        const res = await offersApi.getCalculations(id);
        if (!res.ok) return notyf.error("Error " + res.status)
        setList(JSON.parse(res.data));
    };

    if (!list) return <></>;
    return (
        <Card>
            <Card.Header>
                <Card.Title tag="h5" className="mb-0 ">
                    Calculaties
                    <GuardWrapper role={"write:offers"}>
                        <OfferCalculationsModal id={id} name={name} reference={reference} reload={getData}/>
                    </GuardWrapper>
                </Card.Title>
            </Card.Header>
            <Card.Body>
                {list.length ? <ToolkitProvider
                    keyField="calculation_id"
                    data={list}
                    columns={columns}
                    search
                >
                    {(props) => (
                        <div>
                            <SearchBar srText={null}
                                       {...props.searchProps}
                                       placeholder={"Filter: Typ om te zoeken"}/>
                            <hr/>
                            <BootstrapTable
                                {...props.baseProps}
                                pagination={paginationFactory()}
                            />
                        </div>
                    )}
                </ToolkitProvider> : <Row className="justify-content-center align-self-center w-100 text-center">
                    Er zijn geen offertes gevonden.
                </Row>}
            </Card.Body>
        </Card>
    );
}

export default OfferCalculations;
