import React, {useEffect, useState} from "react";
import {Bar} from "react-chartjs-2";
import {Card} from "react-bootstrap";
import Loader from "../../../components/Loader";
import moment from "moment";

/**
 * Creates a Bar Chart for a person's hours data.
 *
 * @param {Object} hoursType - Type of hours (e.g. "PRODUCTIEF", "NIET PRODUCTIEF")
 * @param {Object} user - User object
 * @param {Array} persondata - Array of person data
 * @param {string} actualColour - Color for actual data
 * @param {boolean} limit - Whether to include a limit indicator
 *
 * @returns {JSX.Element} - Bar Chart component
 */
const BarChartForPerson = ({
                               hoursType,
                               user,
                               persondata,
                               actualColour,
                               limit
                           }) => {
        const [loading, setLoading] = useState(true)
        const [overLimit, setOverlimit] = useState("")
        const [actualArrayCumlatief, setActualArrayCumlatief] = useState([])
        const [actualArrayNonCumlatief, setActualArrayNonCumlatief] = useState([])
        const [budgetArrayCumlatief, setBudgetArrayCumlatief] = useState([])
        const [budgetArrayNonCumlatief, setBudgetArrayNonCumlatief] = useState([])
        const [deltaValueArray, setDeltaValueArray] = useState([])

        useEffect(() => {
            setLoading(true);
            initialRunOnce();
            setLoading(false);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [user]);


    // let stapGrootte;

        useEffect(() => {
            if (hoursType === "PRODUCTIEF") {
                // stapGrootte = 200;
                if (limit && budgetArrayCumlatief.length > 1 && actualArrayCumlatief.length) {
                    const length = moment().month() - 1
                    const budget = budgetArrayCumlatief[length]
                    const actual = actualArrayCumlatief[length]

                    if (actual / budget <= 0.925 && actual / budget >= 0.85) {
                        setOverlimit("warning")
                    } else if (actual / budget < 0.85) {
                        setOverlimit("danger")
                    } else {
                        setOverlimit("")
                    }
                }
            }

            // if (hoursType === "NIET PRODUCTIEF") {
            //     stapGrootte = 100;
            // }
            // if (hoursType === "VRIJ") {
            //     stapGrootte = 50;
            // }
            // if (hoursType === "ZIEK") {
            //     stapGrootte = 30;
            // }

            //Kan zijn dat limit hier wegmoet als er problemen zijn bij het "stoplicht" van lyon
        }, [budgetArrayCumlatief, actualArrayCumlatief, limit]);

        const initialRunOnce = async () => {
            let tempActualCumlativeArray = [];
            let additioncounterActual = 0;
            let additioncounterBudget = 0;
            let tempActualNonCumlativeArray = [];
            let tempBudgetCumlativeArray = [];
            let tempBudgetNonCumlativeArray = [];

            persondata.forEach(e => {
                if (hoursType === "PRODUCTIEF") {
                    additioncounterActual = additioncounterActual + parseInt(e.actual_productivity_hours);
                    additioncounterBudget = additioncounterBudget + parseInt(e.budget_productivity_hours);
                    tempActualCumlativeArray.push(parseInt(additioncounterActual));
                    tempActualNonCumlativeArray.push(parseInt(e.actual_productivity_hours));
                    tempBudgetCumlativeArray.push(parseInt(additioncounterBudget));
                    tempBudgetNonCumlativeArray.push(parseInt(e.budget_productivity_hours));
                }
                else if (hoursType === "NIET PRODUCTIEF") {
                    additioncounterActual = additioncounterActual + parseInt(e.actual_no_productivity_hours);
                    additioncounterBudget = additioncounterBudget + parseInt(e.budget_no_productivity_hours) +  parseInt(e.budget_training_hours);
                    tempActualCumlativeArray.push(parseInt(additioncounterActual));
                    tempActualNonCumlativeArray.push(parseInt(e.actual_no_productivity_hours));
                    tempBudgetCumlativeArray.push(parseInt(additioncounterBudget));
                    tempBudgetNonCumlativeArray.push(parseInt(e.budget_no_productivity_hours) + parseInt(e.budget_training_hours));
                }
                else if (hoursType === "VRIJ") {
                    additioncounterActual = additioncounterActual + parseInt(e.actual_holiday_hours);
                    additioncounterBudget = additioncounterBudget + parseInt(e.budget_holiday_hours);
                    tempActualCumlativeArray.push(parseInt(additioncounterActual));
                    tempActualNonCumlativeArray.push(parseInt(e.actual_holiday_hours));
                    tempBudgetCumlativeArray.push(parseInt(additioncounterBudget));
                    tempBudgetNonCumlativeArray.push(parseInt(e.budget_holiday_hours));
                }
                else if (hoursType === "ZIEK") {
                    additioncounterActual = additioncounterActual + parseInt(e.actual_sick_hours);
                    additioncounterBudget = additioncounterBudget + parseInt(e.budget_sick_hours);
                    tempActualCumlativeArray.push(parseInt(additioncounterActual));
                    tempActualNonCumlativeArray.push(parseInt(e.actual_sick_hours));
                    tempBudgetCumlativeArray.push(parseInt(additioncounterBudget));
                    tempBudgetNonCumlativeArray.push(parseInt(e.budget_sick_hours));
                }
            })

            let DeltaArray = tempActualCumlativeArray.map((item, index) => item - tempBudgetCumlativeArray[index]);

            setActualArrayCumlatief(tempActualCumlativeArray);
            setActualArrayNonCumlatief(tempActualNonCumlativeArray);
            setBudgetArrayCumlatief(tempBudgetCumlativeArray);
            setBudgetArrayNonCumlatief(tempBudgetNonCumlativeArray);
            setDeltaValueArray(DeltaArray);
        }

        let data;
        data = {
            labels: ["Jan", "Feb", "Mrt", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
            datasets: [
                {
                    type: "line",
                    label: "Budget Cumlatief",
                    data: budgetArrayCumlatief,
                    backgroundColor: [
                        "#2c3e50",
                    ],
                    fill: false,
                    borderColor: "#2c3e50",
                    order: 3,

                },
                {
                    type: "line",
                    label: "Actual " + hoursType +  " Cumlatief",
                    data: actualArrayCumlatief,
                    backgroundColor: [
                        actualColour,
                    ],
                    fill: false,
                    borderColor: actualColour,
                    order: 1,
                },
                {
                    type: "line",
                    label: "Delta",
                    data: deltaValueArray,
                    backgroundColor: [
                        "#c0392b",
                    ],
                    fill: false,
                    borderColor: "#c0392b",
                    order: 1,

                },
                {
                    label: "Actual " + hoursType,
                    data: actualArrayNonCumlatief,
                    backgroundColor: [
                        actualColour,
                        actualColour,
                        actualColour,
                        actualColour,
                        actualColour,
                        actualColour,
                        actualColour,
                        actualColour,
                        actualColour,
                        actualColour,
                        actualColour,
                        actualColour,
                    ],
                    borderColor: "transparent",
                    order: 2,
                },
                {
                    label: "Budget",
                    data: budgetArrayNonCumlatief,
                    backgroundColor: [
                        "#2c3e50",
                        "#2c3e50",
                        "#2c3e50",
                        "#2c3e50",
                        "#2c3e50",
                        "#2c3e50",
                        "#2c3e50",
                        "#2c3e50",
                        "#2c3e50",
                        "#2c3e50",
                        "#2c3e50",
                        "#2c3e50",
                    ],
                    borderColor: "transparent",
                    order: 2,
                },

            ],
        };

        const options = {
            maintainAspectRatio: false,
            legend: {
                display: true,
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        let dataset = data.datasets[tooltipItem.datasetIndex];
                        return dataset.data[tooltipItem.index];
                    },
                    title: function (tooltipItem, data) {
                        return data.labels[tooltipItem[0].index];
                    }
                }
            },
            scales: {
                yAxes: [
                    {
                        gridLines: {
                            display: true,
                        },
                        stacked: false,
                        // ticks: {
                        //     stepSize: 200,
                        // },
                    },
                ],
                xAxes: [
                    {
                        stacked: false,
                        gridLines: {
                            color: "transparent",
                        },
                    }
                ],
            }
        };

        if (loading) return <Card><Loader/></Card>
        return (
            <>
                {overLimit === "warning" && hoursType === "PRODUCTIEF" ?
                    <div className={"comboh2 yellowCircle"}></div> : null}
                {overLimit === "danger" && hoursType === "PRODUCTIEF" ? <div className={"comboh2 redCircle"}></div> : null}
                {overLimit === "" && limit === true && hoursType === "PRODUCTIEF" ?
                    <div className={"comboh2 greenCircle"}></div> : null}
                <div style={{height: "25em"}}>
                    <Bar data={data} options={options}/>
                </div>
            </>
        );
    }
;


export default BarChartForPerson;
