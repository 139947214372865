import React from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import AdminWeekTableHandler from "../../components/admin/AdminWeekTableHandler";

/**
 * Represents the admin week component.
 *
 * @function
 * @returns {React.Fragment} - The rendered admin week component.
 */
const AdminWeek = () => (
    <React.Fragment>
        <Helmet title="Beheer weekstaten"/>
        <Container fluid className="p-0">
            <AdminWeekTableHandler/>
        </Container>
    </React.Fragment>
);

export default AdminWeek;
