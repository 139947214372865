import client from "./clients/portalClient";

/**
 * Retrieves the CET (Critical Event Timeline) dashboard data from the server.
 *
 * @returns {Promise} A promise that resolves with the response data from the server.
 */
const getCetDashboard = () => client.get(`/engineering/cet_dashboard`);
/**
 * Update Engineering Information for a Project
 *
 * @param {number} id - The ID of the project to update the engineering information for.
 * @param {Object} data - The updated engineering information for the project.
 * @returns {Promise} - A Promise that resolves to the response of the server.
 *
 * @throws {Error} - If the ID is not a number or the data is not an object.
 *
 * @example
 * // Usage example:
 * editEngineering(123, { startDate: '2021-07-01', endDate: '2021-12-31' })
 *   .then(response => {
 *     console.log(response);
 *   })
 *   .catch(error => {
 *     console.error(error);
 *   });
 */
const editEngineering = (id, data) => client.put(`/projects/${id}/cet`, data);


/**
 * @typedef {Object} EngineeringApi
 * @property {function} getCetDashboard - Get CET dashboard data.
 * @property {function} editEngineering - Edit engineering data.
 */
const engineeringApi = {
    getCetDashboard,
    editEngineering
};

export default engineeringApi;
