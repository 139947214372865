import React from "react";

/**
 * PermissionsContext is a React Context that provides the permissions data to its descendants.
 *
 * @type {React.Context}
 */
const PermissionsContext = React.createContext();

export default PermissionsContext;
