import React, {useEffect, useState} from "react";
import Loader from "../../components/Loader";
import notyf from "../../utils/notyfApi";
import elasticSearchApi from "../../api/elasticSearch";
import ElasticSearchResults from "./ElasticSearchResults";
import {Col, Row, Card} from "react-bootstrap";
import noDataImage from "../../assets/img/illustrations/searching.png";

/**
 * Retrieves data entries from ElasticSearch API based on the given ID.
 *
 * @param {Object} options - The options for retrieving data entries.
 * @param {string} options.id - The ID used to fetch specific data entries.
 *
 * @returns {JSX.Element} - Either a loader, a card with a "No data found" message and an image, or the ElasticSearchResults component displaying the retrieved data entries.
 */
export default function ElasticSearchHandler({id}) {
    //get data from api
    const [dataEntries, setDataEntries] = useState([]);
    const [isLoading, setIsloading] = useState(true);

    useEffect(() => {
        getData();
    }, [id]);

    const getData = async () => {
        setIsloading(true);
        const response = await elasticSearchApi.getAllDataEntries(id);
        if (!response.ok) return notyf.error("Error " + response.status)
        setDataEntries(response.data.results);
        setIsloading(false);
    };

    //laat tabel zien met gegevens via props
    if (isLoading) return <Loader/>
    if (dataEntries.length === 0) return <Card>
        <Card.Body><p style={{fontSize: "20px"}}>Geen data gevonden.</p>
            <Row className="justify-content-center align-self-center w-100 text-center">
                <Col>
                  <img src={noDataImage}
                         width="350" height="auto"/>
                </Col>
            </Row>
        </Card.Body>
    </Card>
    else return <ElasticSearchResults dataEntries={dataEntries}/>;
}