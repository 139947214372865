import React, {useContext, useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Form as FormikForm, FormField, FormFieldDatePicker, SubmitButton} from "../../../../components/forms"
import * as Yup from 'yup';
import axios from "axios";
import Select from "react-select";
import moment from "moment";
import productsApi from "../../../../api/products";
import usePalette from "../../../../hooks/usePalette";
import PermissionsContext from "../../../../utils/context";
import notyf from "../../../../utils/notyfApi";

/**
 * Represents a modal component for displaying and editing product order details.
 *
 * @param {Object} options - The options for the modal.
 * @param {Object} options.order - The order object.
 * @param {Object} options.orderDetails - The order details object.
 * @param {Function} options.reload - The function to reload the data.
 * @param {React.ReactNode} options.children - The child components.
 *
 * @return {React.ReactNode} The modal component.
 */
function ProductOrdersDetailModel({order, orderDetails = {}, reload, children}) {
    const [show, setShow] = useState(false);
    const [diverse, setDiverse] = useState(orderDetails.product_name === "DIV");
    const [editable,] = useState(!(order.product_order_status === "besteld" || order.product_order_status === "incompleet"));
    const [concept,] = useState(order.product_order_status !== "incompleet");
    const [searchTerm, setSearchTerm] = useState("")
    const [options, setOptions] = useState([])
    const [product, setProduct] = useState(false)
    const palette = usePalette()
    const {permissions} = useContext(PermissionsContext)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm])

    const getOptions = () => {
        if (!searchTerm) return setOptions([])
        axios.get(`${process.env.REACT_APP_PORTAL_URL}/search/products?q=${searchTerm}`).then(r => {
                let temp = []
                for (let p of r.data.products) {
                    temp.push({
                        value: p,
                        label: p.product_name
                    })
                }
                setOptions(temp)
            }
        ).catch(() => {
            notyf.error("Error met opties ophalen")
        })
    }

    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(false)
        values.product_order_detail_expected_delivery_date = moment(values.product_order_detail_expected_delivery_date).format("YYYY-MM-DD")
        values.product_order_detail_product = product.product_id || 10551
        values.product_order_id = order.product_order_id
        values.product_order_detail_price_total = values.product_order_detail_price_per_unit * values.product_order_detail_amount

        if (orderDetails?.product_order_detail_id) {
            if (!product) {
                values.product_name = orderDetails.product_name
                values.product_order_detail_product = orderDetails.product_order_detail_product
            }
            const res = await productsApi.editExistingProductOrderDetail(orderDetails.product_order_detail_id, {
                ...values,
                product_order_detail_amount: parseInt(values.product_order_detail_amount),
                product_order_detail_price_per_unit: parseFloat(values.product_order_detail_price_per_unit)
            })
            if (!res.ok) return notyf.error("Error " + res.status)
        } else {
            const res = await productsApi.postNewProductOrderDetail({
                ...values,
                product_order_detail_amount: parseInt(values.product_order_detail_amount),
                product_order_detail_price_per_unit: parseFloat(values.product_order_detail_price_per_unit)
            })
            if (!res.ok) return notyf.error("Error " + res.status)
        }
        reload()
        handleClose()
    };


    let ctrlDown = false,
        ctrlKey = 17,
        vKey = 86;

    const keyHandler = e => {
        if (e.keyCode === ctrlKey) ctrlDown = true;
        const input = String.fromCharCode(e.keyCode);
        if (ctrlDown && (e.keyCode === vKey)) {
            navigator.clipboard.readText()
                .then(text => {
                    setSearchTerm(text)
                })
                .catch(err => {
                    console.error('Failed to read clipboard contents: ', err);
                });
        }
        if (/[a-zA-Z]/.test(input)) setSearchTerm(e.target.value + e.key)
        if (e.keyCode === 8) setSearchTerm(searchTerm.slice(0, -1))
    }



    return (
        <>
            {children ? permissions.includes("write:products") ?
                <div className={"cursor-pointer"} style={{color: palette.primary}} onClick={handleShow}>
                    {children}
                </div> : <div>
                    {children}
                </div> : <Button onClick={handleShow} className={"float-end"}>+</Button>}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> Levering details
                    </Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={{
                        product_name: product ? product.product_name : orderDetails?.product_name || "",
                        product_order_detail_amount: product ? 1 : orderDetails?.product_order_detail_amount || "",
                        product_order_detail_comment: orderDetails?.product_order_detail_comment || "",
                        product_order_detail_expected_delivery_date: moment(orderDetails?.product_order_detail_expected_delivery_date) || moment(),
                        product_order_detail_price_per_unit: product ? parseFloat(product.product_list) : parseFloat(orderDetails?.product_order_detail_price_per_unit) || 0,
                        product_order_detail_product: orderDetails?.product_order_detail_product || "",
                        product_order_detail_status: orderDetails?.product_order_detail_status || "",
                    }}

                    validationSchema={!diverse ? Yup.object({
                        product_name: Yup.string().required('Product moet gekozen worden'),
                        product_order_detail_amount: Yup.number().min(1, "Moet een positief getal zijn").required("Aantal moet ingevuld worden"),
                        product_order_detail_price_per_unit: Yup.number().required("Prijs moet ingevuld worden"),
                        product_order_detail_expected_delivery_date: Yup.date(),
                        product_order_detail_status: Yup.string().required('Status moet gekozen worden'),
                    }) : Yup.object(
                        {
                            product_order_detail_comment: Yup.string().required('Product moet ingevuld worden'),
                            product_order_detail_amount: Yup.number().min(1, "Moet een positief getal zijn").required("Prijs moet ingevuld worden"),
                            product_order_detail_price_per_unit: Yup.number().required("Aantal moet ingevuld worden"),
                            product_order_detail_expected_delivery_date: Yup.date(),
                            product_order_detail_status: Yup.string().required('Status moet gekozen worden'),
                        }
                    )}
                    onSubmit={handleSubmit}
                >
                    <Modal.Body>
                        <Row>
                            <Row>
                                <Col className={"d-flex"}>
                                    <Form.Check className={"big-checkbox"}
                                                type="switch"
                                                label={`Diverse`}
                                                checked={diverse}
                                                onChange={() => setDiverse(!diverse)}
                                                disabled={concept}/>
                                </Col>
                            </Row>
                            <Col className={"m-4"}>
                                <Row>
                                    Product selectie
                                    <hr/>
                                </Row>
                                {diverse ?
                                    <Row>
                                        <FormField name={"product_order_detail_comment"}
                                                   label={"Diverse:"}
                                                   className={"mb-3"}
                                                   control={"input"}
                                                   disabled={concept}
                                        />
                                    </Row> :
                                    <Row>
                                        <Form.Label>Product:</Form.Label>
                                        <Select options={options}
                                                onKeyDown={keyHandler}
                                                onChange={e => setProduct(e.value)}
                                                className={"mb-3 p-0"}
                                                defaultValue={{
                                                    label: orderDetails?.product_name,
                                                    value: orderDetails?.product_name
                                                }}
                                                isDisabled={concept}
                                                noOptionsMessage={() => 'Er zijn geen producten gevonden'}
                                        />
                                    </Row>
                                }
                                <Row>
                                    <FormField name={"product_order_detail_amount"}
                                               label={"Aantal:"}
                                               className={"mb-3"}
                                               control={"input"}
                                               disabled={concept}
                                               type={"number"}
                                    />
                                </Row>
                                <Row>
                                    <FormField name={"product_order_detail_price_per_unit"}
                                               label={"Nettoprijs per stuk (€):"}
                                               className={"mb-3"}
                                               control={"input"}
                                               disabled={concept}
                                               type={"number"}
                                    />
                                </Row>
                            </Col>
                            <Col className={"m-4"}>
                                <Row>
                                    LEVERING DETAILS
                                    <hr/>
                                </Row>
                                <Row>
                                    <FormField name={"product_order_detail_status"}
                                               label={"Status:"}
                                               className={"mb-3"}
                                               control={"input"}
                                               disabled={editable}
                                               as={"select"}
                                    >
                                        <option value={""}>Selecteer een status</option>
                                        <option value={"besteld"}>Besteld</option>
                                        <option value={"geleverd"}>Geleverd</option>
                                        <option value={"retour"}>Retour</option>
                                    </FormField>
                                </Row>
                                <Row>
                                    <FormFieldDatePicker
                                        name={"product_order_detail_expected_delivery_date"}
                                        label={"Verwachte leverdatum:"}
                                        className={"mb-3 form-control"}
                                        control={"input"}
                                        disabled={editable}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Annuleren
                        </Button>
                        <SubmitButton title={"Opslaan"} variant="primary"/>
                    </Modal.Footer>
                </FormikForm>
            </Modal>
        </>
    );
}

export default ProductOrdersDetailModel;
