import React, {useEffect, useState} from "react";
import Loader from "../../../components/Loader";
import notyf from "../../../utils/notyfApi";
import {Col, Row, Card} from "react-bootstrap";
import dashboardApi from "../../../api/dashboard";
import {useParams} from "react-router-dom";
import PersonChartHandler from "../../charts/Chartjs/PersonChartHandler";

/**
 * Handles the content for the product department details.
 * Retrieves and sets the user data for the department, and displays a chart based on the data.
 * @return {JSX.Element} The JSX element representing the content for the product department details.
 */
export default function ProdDepDetailContentHandler() {
    const {department} = useParams()
    const [loading, setLoading] = useState(true)
    const [allDepUserData, setAllDepUserData] = useState([])

    useEffect(() => {
        setLoading(true);
        getProductivityDepartmentUserInfo();
        setLoading(false);
    }, []);

    //dit is de data voor de accountgegevens
    const getProductivityDepartmentUserInfo = async () => {
        const res = await dashboardApi.getProductivityDepartmentUserInfo(department);
        if (!res.ok) return notyf.error("Error " + res.status);
        setAllDepUserData(res.data);
    };

    //laat tabel zien met gegevens via props
    if (loading) return <Loader/>
    return <>
        <Row>
            <Col xs={12}>
                <Card>
                    <Card.Header>
                        <Card.Title tag="h5">Productiviteitsoverzicht voor {department} </Card.Title>
                        <h6 className="card-subtitle text-muted">
                            Dit overzicht is gebaseerd op actuele data
                        </h6>
                    </Card.Header>
                    <Card.Body>
                        <PersonChartHandler department={department}
                                            allDepUserData={allDepUserData}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
}