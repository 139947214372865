import React from "react";
import {Helmet} from "react-helmet-async";
import {Col, Container, Row} from "react-bootstrap";
import HourlyRatesTableHandler from "../../components/admin/HourlyRatesTableHandler";

/**
 * Represents the HourlyRates component.
 *
 * @component
 * @example
 *  <HourlyRates/>
 */
const HourlyRates = () => (
    <React.Fragment>
        <Helmet title="Uurtarieven"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Uurtarieven</h1>
            <Row>
                <Col xl="12">
                    <HourlyRatesTableHandler/>
                </Col>
            </Row>
        </Container>
    </React.Fragment>
);

export default HourlyRates;
