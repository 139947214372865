import React from "react";
import {Helmet} from "react-helmet-async";
import ProdVisAllDepsContentHandler from "../../components/dashboard/ProdVisAllDepsContentHandler";

const ProdVisAllDeps = () => {
    return (
        <React.Fragment>
            <Helmet title="Productiviteit per afdeling"/>
                <ProdVisAllDepsContentHandler />
        </React.Fragment>
    )
};

export default ProdVisAllDeps;
