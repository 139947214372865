import React, {useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {Form as FormikForm, FormField, SubmitButton} from "../../../../components/forms/index"
import * as Yup from 'yup';
import {useParams} from "react-router-dom";
import supplierApi from "../../../../api/suppliers";
import {Edit2} from "react-feather";
import notyf from "../../../../utils/notyfApi";

/**
 * SupplierAddEditSupplierModal is a component that displays a modal for adding or editing a supplier.
 *
 * @param {Object} options - The options for the component.
 * @param {Object} options.supplier - The supplier object to be edited. If provided, the modal operates in edit mode. If not provided, the modal operates in add mode.
 * @param {Function} options.reload - The function to be called after the supplier is added or edited successfully.
 *
 * @returns {JSX.Element} The SupplierAddEditSupplierModal component.
 */
function SupplierAddEditSupplierModal({supplier, reload}) {
    const {id} = useParams();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const supplierData = async (sid, data) => {
        let response
        if (supplier) {
            //hij bestaat dus edit
            response = await supplierApi.editExistingSupplier(sid, data);
        } else {
            //Hij bestaat niet dus aanmaken
            response = await supplierApi.postNewSupplier(data);
        }
        if (!response.ok) return notyf.error("Error " + response.status)
        reload()
        handleClose();
    };

    //regex voor telefoon + website
    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/
    const websiteRegExp = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig

    return (
        <>

            {supplier ? <Edit2 className="float-end cursor-pointer" onClick={handleShow}/>
                : <Button className={"float-end btn btn-primary"} onClick={handleShow}>+</Button>}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    {supplier ? <Modal.Title>Leverancier aanpassen</Modal.Title>
                        : <Modal.Title>Leverancier toevoegen</Modal.Title>}
                </Modal.Header>
                <FormikForm
                    initialValues={{
                        product_supplier_name: supplier?.product_supplier_name || "",
                        product_supplier_phone: supplier?.product_supplier_phone || "",
                        product_supplier_email: supplier?.product_supplier_email || "",
                        product_supplier_website: supplier?.product_supplier_website || "",
                        product_supplier_category: supplier?.product_supplier_category || "",
                        product_supplier_address: supplier?.product_supplier_address || "",
                        product_supplier_province: supplier?.product_supplier_province || "",
                        product_supplier_postcode: supplier?.product_supplier_postcode || "",
                        product_supplier_city: supplier?.product_supplier_city || "",
                        product_supplier_country: supplier?.product_supplier_country || "",
                        product_supplier_comment: supplier?.product_supplier_comment || "",
                    }}

                    // Hier is het validatieschema van alle velden
                    validationSchema={Yup.object({
                        product_supplier_name: Yup.string()
                            .max(250, 'Moet minder dan 250 karakters zijn')
                            .trim()
                            .required('Leveranciernaam moet ingevuld worden'),
                        product_supplier_phone: Yup.string()
                            .trim()
                            .required('Telefoonnummer moet ingevuld worden')
                            .matches(phoneRegExp, 'Telefoonnummer klopt niet. (voldoet niet aan regex expressie)'),
                        product_supplier_email: Yup.string()
                            .trim()
                            .email('Email klopt niet')
                            .required('Emailadres moet ingevuld worden'),
                        product_supplier_website: Yup.string()
                            .trim()
                            .matches(websiteRegExp, 'URL klopt niet')
                            .required('Website adres moet ingevuld worden'),
                        product_supplier_category: Yup.string()
                            .trim()
                            .required('Type moet ingevuld worden'),
                        product_supplier_address: Yup.string()
                            .required('Adres moet ingevuld worden')
                            .trim(),
                        product_supplier_province: Yup.string()
                            .required('Provincie moet ingevuld worden')
                            .trim(),
                        product_supplier_postcode: Yup.string()
                            .required('Postcode moet ingevuld worden')
                            .trim(),
                        product_supplier_city: Yup.string()
                            .required('Stad moet ingevuld worden')
                            .trim(),
                        product_supplier_country: Yup.string()
                            .required('Land moet ingevuld worden')
                            .trim(),
                        product_supplier_comment: Yup.string()
                            .trim(),
                    })}

                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(false);
                        let valuesparsed = {
                            product_supplier_name: values.product_supplier_name,
                            product_supplier_phone: values.product_supplier_phone,
                            product_supplier_email: values.product_supplier_email,
                            product_supplier_website: values.product_supplier_website,
                            product_supplier_category: values.product_supplier_category,
                            product_supplier_address: values.product_supplier_address,
                            product_supplier_province: values.product_supplier_province,
                            product_supplier_postcode: values.product_supplier_postcode,
                            product_supplier_city: values.product_supplier_city,
                            product_supplier_country: values.product_supplier_country,
                            product_supplier_comment: values.product_supplier_comment,
                        };
                        supplierData(id, valuesparsed);
                    }}
                >
                    <Modal.Body>
                        <h4>Leverancier details</h4>
                        <hr/>
                        <FormField name={"product_supplier_name"}
                                   label={"Leverancier naam:"}
                                   width={"100%"}
                                   placeholder="Vul de Leveranciersnaam hier in"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"product_supplier_phone"}
                                   label={"Telefoonnummer:"}
                                   width={"100%"}
                                   placeholder={"Vul hier het bijbehorende telefoonnummer van de leverancier in"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"product_supplier_email"}
                                   label={"Email:"}
                                   width={"100%"}
                                   placeholder={"Vul hier het emailadres van de leverancier in"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"product_supplier_website"}
                                   label={"Website:"}
                                   width={"100%"}
                                   placeholder={"Vul hier de website van de leverancier in"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"product_supplier_category"}
                                   label={"Type:"}
                                   width={"100%"}
                                   placeholder={"pas aan naar dropdown opties click "}
                                   className={"mb-3"}
                                   as={"select"}
                        >
                            <option value="">Kies een type</option>
                            <option value="Veldapparatuur">Veldapparatuur</option>
                            <option value="Veldapparatuur & Regelapparatuur">Veldapparatuur & Regelapparatuur</option>
                            <option value="DDC Apparatuur">DDC Apparatuur</option>
                            <option value="Regelkast Materiaal">Regelkast Materiaal</option>
                            <option value="Anders">Anders</option>
                        </FormField>
                        <FormField name={"product_supplier_comment"}
                                   label={"Commentaar:"}
                                   width={"100%"}
                                   placeholder={"Commentaar"}
                                   className={"mb-3"}
                                   as={"textarea"}
                        />
                        {/*<FormField name={"supplier_id"}*/}
                        {/*           label={"Adres:"}*/}
                        {/*           width={"100%"}*/}
                        {/*           placeholder={"Vul hier het adres van de leverancier in"}*/}
                        {/*           className={"mb-3"}*/}
                        {/*           control={"input"}*/}
                        {/*/>*/}
                        <h4>Adres details</h4>
                        <hr/>
                        <Row>
                            <Col xs="12">
                                <FormField name={"product_supplier_address"}
                                           label={"Straat + huisnummer"}
                                           width={"100%"}
                                           placeholder={"Vul hier het adres en nummer in"}
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="3">
                                <FormField name={"product_supplier_province"}
                                           label={"Provincie"}
                                           width={"100%"}
                                           placeholder={"Vul hier de provincie in"}
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                            <Col xs="3">
                                <FormField name={"product_supplier_postcode"}
                                           label={"Postcode"}
                                           width={"100%"}
                                           placeholder={"Vul hier de postcode in"}
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                            <Col xs="6">
                                <FormField name={"product_supplier_city"}
                                           label={"Plaats"}
                                           width={"100%"}
                                           placeholder={"Vul hier de stad in"}
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                        </Row>
                        <FormField name={"product_supplier_country"}
                                   label={"Land:"}
                                   width={"100%"}
                                   placeholder={"Vul hier het land in"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Niet aanmaken
                        </Button>
                        <SubmitButton title={"Opslaan"} variant="primary"/>
                    </Modal.Footer>
                </FormikForm>
            </Modal>
        </>
    )
        ;
}

export default SupplierAddEditSupplierModal;
