import {Badge, Button, Card, Col, Form, Modal, Row, Tab, Tabs} from "react-bootstrap";
import moment from "moment";
import React, {useEffect, useState} from "react";
import serviceProjectApi from "../../../api/service";
import {Mail, Phone, Trash, User} from "react-feather";
import * as Yup from "yup";
import {Form as FormikForm, FormField, FormSelectFieldContacts, SubmitButton} from "../../../components/forms";
import adminUsersApi from "../../../api/adminUsers";
import {NavLink} from "react-router-dom";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import notyf from "../../../utils/notyfApi";

/**
 * Represents the detail view of an activity menu service.
 * @param {Object} props - The properties object.
 * @param {number} props.id - The ID of the activity menu service.
 * @param {Object} props.service - The service object.
 * @returns {JSX.Element} - The rendered activity menu service detail component.
 */
const ActivityMenuServiceDetail = ({id, service}) => {

    const [logs, setLogs] = useState([])
    const [tickets, setTickets] = useState([])
    const [messages, setMessages] = useState([])
    const [activityFull, setActivityFull] = useState(false)
    useEffect(() => {
        getTickets()
        getHistory()
        getMessages()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [service])

    const getMessages = async () => {

        const resMessages = await serviceProjectApi.getServiceMessages(id)
        if (!resMessages.ok) return notyf.error("Error " + resMessages.status)

        setMessages(resMessages.data)
    }
    const deleteMessage = async (mId) => {

        const r = await serviceProjectApi.deleteServiceMessages(id, mId)
        if (!r.ok) return notyf.error("Error " + r.status)

        getMessages()
    }
    const getTickets = async () => {
        const resTickets = await serviceProjectApi.getServiceProjectTickets(id)
        if (!resTickets.ok) return notyf.error("Error " + resTickets.status)
        setTickets(resTickets.data)
    }
    const getHistory = async () => {
        const resHistory = await serviceProjectApi.getServiceProjectHistory(id)
        if (!resHistory.ok) return notyf.error("Error " + resHistory.status)

        setLogs(resHistory.data.logs)
    }
    return (<Card>
        <Card.Body>
            <div style={{display: "block"}}>
                <Tabs defaultActiveKey="Berichten">
                    <Tab eventKey="Berichten" title="Berichten">
                        <div className={"pt-3"}>
                            <Row><Col> <GuardWrapper role={"write:service"}>
                                <AddMessageModal id={id} reload={getMessages}/>
                            </GuardWrapper>
                            </Col></Row>
                            {!messages.length &&
                            <Row className="justify-content-center align-self-center w-100 text-center">
                                Geen berichten gevonden.
                            </Row>}
                            {messages.map(m => <div key={m.service_projects_comment_id}>
                                <Row>
                                    <Col className={"d-80-left"}>
                                        <div>
                                            <strong>{m.user_name}</strong>
                                            <br/>
                                            <p>{m.service_projects_comment}</p>
                                            <small
                                                className="text-muted">{moment(m.service_projects_comment_created_on).format("DD-MM-YYYY HH:mm")}</small>
                                            <br/>
                                        </div>
                                    </Col>
                                    <Col lg={1} className={"d-20-right"}>
                                        <Trash className={"cursor-pointer mt-2"}
                                               onClick={() => deleteMessage(m.service_projects_comment_id)}/>
                                    </Col>
                                </Row>

                            </div>)}
                        </div>
                    </Tab>
                    <Tab eventKey="Activiteit" title="Activiteit">
                        <ul className="timeline mt-2">
                            {logs.length ? (activityFull ? logs.map(log => <li key={log.log_detail_timestamp}
                                                                               className="timeline-item">
                                <strong>{log.log_detail_type}</strong>
                                <span
                                    className="float-end text-muted text-sm">{moment(log.log_detail_timestamp).format("DD-MM-YYYY HH:mm")}</span>
                                <p>
                                    {log.log_detail_message.replace(/<\/?[^>]+(>|$)/g, "")} {log.log_detail_created_by}
                                </p>
                            </li>) : logs.slice(0, 10).map(log => <li key={log.log_detail_timestamp}
                                                                      className="timeline-item">
                                <strong>{log.log_detail_type}</strong>
                                <span
                                    className="float-end text-muted text-sm">{moment(log.log_detail_timestamp).format("DD-MM-YYYY HH:mm")}</span>
                                <p>
                                    {log.log_detail_message.replace(/<\/?[^>]+(>|$)/g, "")} {log.log_detail_created_by}
                                </p>
                            </li>)) : <Row style={{marginTop: "1rem"}}
                                           className="justify-content-center align-self-center w-100 text-center">
                                Er zijn geen activiteiten gevonden.
                            </Row>}
                            {(logs.length > 10 && !activityFull) &&
                            <Button className={"float-end btn btn-primary"} onClick={() => setActivityFull(true)}>Meer
                                laden</Button>}
                        </ul>
                    </Tab>
                    <Tab eventKey="Tickets" title="Tickets">
                        <div className={"pt-3"}>

                            <Row>
                                <Col>
                                    <GuardWrapper role={"write:service"}><AddTicketModal id={id} exists={false}
                                                                                         reload={getTickets}/>
                                    </GuardWrapper>
                                </Col>
                            </Row>
                            {!tickets.length &&
                            <Row className="justify-content-center align-self-center w-100 text-center">
                                Geen tickets gevonden.
                            </Row>}
                            {tickets.map(ticket => <Row key={ticket.service_project_ticket_id}>

                                <Col lg={1} className={"d-20-left"}>
                                    <NavLink to={`/ticket/${id}/${ticket.service_project_ticket_id}`}>
                                        {ticket.service_project_ticket_ticket_type === "Telefoon" ? <Phone size={36}/>
                                            : ticket.service_project_ticket_ticket_type === "Mail" ? <Mail size={36}/>
                                                : <User size={36}/>}
                                    </NavLink>
                                </Col>
                                <Col lg={11} className={"d-80-right"}>

                                    <NavLink to={`/ticket/${id}/${ticket.service_project_ticket_id}`}>
                                        <strong>{ticket.service_project_ticket_subject}</strong>
                                    </NavLink>
                                    <br/>
                                    <p>
                                        {ticket.service_project_ticket_completed ?
                                            <Badge className={"me-1"} bg={"success"}>AFGEROND</Badge> :
                                            <Badge className={"me-1"} bg={"danger"}>Open</Badge>}
                                        {ticket.service_project_ticket_direction === "Binnengekomen" ?
                                            <Badge bg={"primary"}>Binnengekomen</Badge> :
                                            <Badge bg={"warning"}>Uitgaand</Badge>}
                                    </p>

                                    <strong>Eigenaar: {ticket.user_name}</strong>
                                    <br/>
                                    <strong>Contactpersoon:
                                        {!ticket.service_project_ticket_from_naw ?
                                            <span> {ticket.service_project_ticket_from_text}</span> :
                                            <span> {`${ticket.sales_contact_firstname || ""} ${ticket.sales_contact_surname_prefix || ""} ${ticket.sales_contact_lastname || ""}`}</span>}</strong>
                                    <br/>
                                    {ticket.service_project_ticket_text}
                                    <br/>
                                    <p>
                                        <small
                                            className="text-muted">{moment(ticket.service_project_ticket_created_on).format("DD-MM-YYYY HH:mm")}</small>
                                    </p>
                                </Col>


                            </Row>)}

                        </div>
                    </Tab>
                </Tabs>
            </div>
        </Card.Body>
    </Card>)
}

export default ActivityMenuServiceDetail


/**
 * Component for adding a ticket modal.
 * @param {Object} props - The props object.
 * @param {string} props.id - The ID of the ticket.
 * @param {boolean} props.exists - Flag indicating if the ticket already exists.
 * @param {function} props.reload - Function to reload the data after adding a ticket.
 */
const AddTicketModal = ({id, exists, reload}) => {
    const [show, setShow] = useState(false);
    const [users, setUsers] = useState([]);
    const [contactExists, setContactExists] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        const res = await adminUsersApi.getUsersByRole("Service")
        if (!res.ok) return notyf.error("Error " + res.status)
        setUsers(res.data.users)

    }

    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(false)
        const res = await serviceProjectApi.postServiceProjectTickets(id, {
            ...values,
            service_project_ticket_from_naw: values.service_project_ticket_from_naw?.sales_contact_id || null
        })
        if (!res.ok) return notyf.error("Error " + res.status)
        handleClose()
        reload()

    }
    if (!users.length) return <></>
    return (
        <>
            <Button className={"position-absolute top-0 end-0 m-3"} onClick={handleShow}>
                +
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Ticket aanmaken
                    </Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={{
                        service_project_ticket_subject: exists?.service_project_ticket_subject || "",
                        service_project_ticket_from_naw: exists?.service_project_ticket_from_naw || null,
                        service_project_ticket_phone_number: exists?.service_project_ticket_phone_number || "",
                        service_project_ticket_email: exists?.service_project_ticket_email || "",
                        service_project_ticket_direction: exists?.service_project_ticket_direction || "",
                        service_project_ticket_text: exists?.service_project_ticket_text || "",
                        service_project_ticket_owner: exists?.service_project_ticket_owner || "",
                        service_project_ticket_from_text: exists?.service_project_ticket_from_account_text || "",
                        service_project_ticket_from_account_text: exists?.service_project_ticket_from_account_text || "",
                        service_project_ticket_ticket_type: exists?.service_project_ticket_ticket_type || "",

                    }}


                    validationSchema={contactExists ? Yup.object({

                        service_project_ticket_subject: Yup.string().required('Onderwerp moet ingevuld worden'),
                        service_project_ticket_from_naw: Yup.object().required('Contactpersoon moet ingevuld worden').nullable(),
                        service_project_ticket_phone_number: Yup.string(),
                        service_project_ticket_email: Yup.string(),
                        service_project_ticket_direction: Yup.string().required("Richting moet ingevoerd worden"),
                        service_project_ticket_text: Yup.string().required("Opmerking moet ingevoerd worden"),
                        service_project_ticket_owner: Yup.string().required("Eigenaar moet gekozen worden"),
                        service_project_ticket_ticket_type: Yup.string().required("Communicatie middel moet ingevoerd worden"),
                    }) : Yup.object({

                        service_project_ticket_subject: Yup.string().required('Onderwerp moet ingevuld worden'),
                        service_project_ticket_phone_number: Yup.string(),
                        service_project_ticket_email: Yup.string(),
                        service_project_ticket_direction: Yup.string().required("Richting moet ingevoerd worden"),
                        service_project_ticket_text: Yup.string().required("Opmerking moet ingevoerd worden"),
                        service_project_ticket_owner: Yup.string().required("Eigenaar moet gekozen worden"),
                        service_project_ticket_from_text: Yup.string().required('Contactpersoon moet ingevuld worden'),
                        service_project_ticket_from_account_text: Yup.string().required('Bedrijf moet ingevuld worden'),
                        service_project_ticket_ticket_type: Yup.string().required("Communicatie middel moet ingevoerd worden")
                    })
                    }

                    onSubmit={handleSubmit}
                >
                    <Modal.Body>
                        <FormField name={"service_project_ticket_subject"}
                                   label={"Onderwerp"}
                                   width={"100%"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <Row className={"my-2"}>
                            <Col className={"d-flex align-items-center "}>
                                <Form.Check className={"d-flex align-items-center "}>
                                    <Form.Check.Input className={"big-checkbox"} type={"checkbox"}
                                                      label={`Bestaand contactpersoon`}
                                                      checked={contactExists}
                                                      onChange={() => setContactExists(!contactExists)}/>
                                    <Form.Check.Label>Bestaand contactpersoon</Form.Check.Label>

                                </Form.Check>


                            </Col>
                        </Row>
                        {contactExists ?

                            <FormSelectFieldContacts name={"service_project_ticket_from_naw"}
                                                     label={"Contactpersoon"}
                                                     width={"100%"}
                                                     className={"mb-3"}
                            />

                            : <>
                                <FormField name={"service_project_ticket_from_text"}
                                           label={"Contactpersoon"}
                                           width={"100%"}
                                           className={"mb-3"}
                                           control={"input"}

                                />
                                <FormField name={"service_project_ticket_from_account_text"}
                                           label={"Bedrijf"}
                                           width={"100%"}
                                           className={"mb-3"}
                                           control={"input"}

                                />
                            </>
                        }


                        <FormField name={"service_project_ticket_ticket_type"}
                                   label={"Communicatie via"}
                                   width={"100%"}
                                   className={"mb-3"}
                                   as={"select"}
                        >
                            <option value="">Selecteer een communicatie middel</option>
                            <option value="Telefoon">Telefoon</option>
                            <option value="Email">Email</option>
                            <option value="Intern">Intern</option>
                            <option value="Overig">Overig</option>
                        </FormField>
                        <FormField name={"service_project_ticket_phone_number"}
                                   label={"Telefoonnummer"}
                                   width={"100%"}
                                   className={"mb-3"}
                                   control={"input"}
                                   display={{name: "service_project_ticket_ticket_type", value: "Telefoon"}}
                        />
                        <FormField name={"service_project_ticket_email"}
                                   label={"Email"}
                                   width={"100%"}
                                   className={"mb-3"}
                                   control={"input"}
                                   display={{name: "service_project_ticket_ticket_type", value: "Email"}}

                        />

                        <FormField name={"service_project_ticket_direction"}
                                   label={"Richting"}
                                   width={"100%"}
                                   className={"mb-3"}
                                   as={"select"}
                        >
                            <option value="">Selecteer een optie</option>
                            <option value="Binnengekomen">Binnengekomen</option>
                            <option value="Uitgaand">Uitgaand</option>
                        </FormField>
                        <FormField name={"service_project_ticket_text"}
                                   label={"Opmerking"}
                                   width={"100%"}
                                   placeholder={"Vul hier een opmerking in"}
                                   className={"mb-3"}
                                   control={"input"}
                                   as={"textarea"}

                        />
                        <FormField name={"service_project_ticket_owner"}
                                   label={"Eigenaar"}
                                   width={"100%"}
                                   className={"mb-3"}
                                   as={"select"}
                        >
                            <option value={""}>Selecteer een eigenaar</option>
                            {users.map(user => <option key={user.user_id} value={user.user_id}>{user.name}</option>)}
                        </FormField>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => setShow(false)}>
                            Annuleren
                        </Button>
                        <SubmitButton title={"Opslaan"} variant="primary"/>
                    </Modal.Footer>
                </FormikForm>

            </Modal>
        </>
    );
}


/**
 * Represents a modal for adding messages.
 * @param {Object} props - The properties for the AddMessageModal.
 * @param {string} props.id - The ID of the modal.
 * @param {Function} props.reload - A function to reload the modal.
 * @returns {ReactNode} The AddMessageModal component.
 */
const AddMessageModal = (
    {
        id, reload
    }
) => {
    const [show, setShow] = useState(false);
    const [comment, setComment] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleSubmit = async () => {
        if (comment) {
            const r = await serviceProjectApi.postServiceMessages(id, {service_projects_comment: comment})
            if (!r.ok) return notyf.error("Error " + r.status)
            handleClose()
            reload()
        }
    }

    return (
        <>
            <Button className={"position-absolute top-0 end-0 m-3"} onClick={handleShow}>
                +
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Berichten
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Opmerking toevoegen</Form.Label>
                        <Form.Control value={comment} onChange={e => setComment(e.target.value)} as="textarea"
                                      placeholder="Opmerking"/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Annuleren
                    </Button>
                    <Button variant="primary" disabled={!comment} onClick={handleSubmit}>
                        Toevoegen
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}