import React, {useEffect, useState} from "react";
import serviceMatrixApi from "../../../api/service_matrix";
import ServiceMatrixTable from "./MatrixTable";
import notyf from "../../../utils/notyfApi";
import Loader from "../../../components/Loader";

/**
 * Retrieves service matrix data from an API and displays it in a table.
 *
 * @returns {JSX.Element} A JSX element representing the table of service matrix data or a loader component.
 */
export default function ServiceMatrixTableHandler() {
    //get data from api
    const [serviceMatrixRow, setServiceMatrixRow] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const response = await serviceMatrixApi.getAllServiceMatrixData();
        if (!response.ok) return notyf.error("Error " + response.status)
        setServiceMatrixRow(response.data);
    };

    //laat tabel zien met gegevens via props
    if (serviceMatrixRow.length > 0) return <ServiceMatrixTable serviceMatrixRow={serviceMatrixRow}/>;
    return <Loader/>;
}