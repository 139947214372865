import React from "react";
import {Badge, Card} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import usePalette from "../../hooks/usePalette";

/**
 * Renders a list of search results based on the given data entries.
 *
 * @param {Array} dataEntries - The array of data entries to render.
 *
 * @return {React.Element} - The rendered list of search results.
 */
export default function ElasticSearchResults({dataEntries}) {
    let palette = usePalette()
    return (
        <Card>
            <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                    {dataEntries.length} resultaten gevonden
                </Card.Title>
            </Card.Header>
            <Card.Body>
                {dataEntries.map((item) => {
                        let variableBody;
                        switch (item.index) {
                            case "accounts-prod":
                                variableBody = <li key={item.id} className="removeListStyle">
                                    <hr/>
                                    <div>
                                        <h6><NavLink
                                            to={`../../account/${item.account_id}`}>{item.account_name}</NavLink>
                                        </h6>
                                        <ul className="list-inline list-inline-dotted text-muted mb-2">
                                            <li className="list-inline-item">
                                                <Badge bg="info" className={"me-1"}>Bedrijf</Badge>
                                                {item.account_active ?
                                                    <Badge bg="primary">Actief</Badge> :
                                                    <Badge bg="danger">Inactief</Badge>}
                                            </li>
                                            <li className="list-inline-item">Score: {item.score}</li>
                                        </ul>
                                        {item.account_street || item.account_postal_code || item.account_city ?
                                            <div>{"Adres: " + item.account_street + " " + item.account_postal_code + " " + item.account_city + "\n"}</div> : null}
                                        {item.account_phone_nr ?
                                            <div>{"Telefoonnummer: " + item.account_phone_nr + "\n"}</div> : null}
                                    </div>
                                </li>
                                break;
                            case "contacts-prod":
                                variableBody = <li key={item.id} className="removeListStyle">
                                    <hr/>
                                    <div>
                                        <h6><NavLink
                                            to={`../../contact/${item.contact_id}`}>{item.contact_firstname} {item.contact_surname_prefix} {item.contact_lastname}</NavLink>
                                        </h6>
                                        <ul className="list-inline list-inline-dotted text-muted mb-2">
                                            <li className="list-inline-item">

                                                <Badge bg="info" className={"me-1"}>Contactpersoon</Badge>
                                                {item.contact_active ?
                                                    <Badge bg="primary">Actief</Badge> :
                                                    <Badge bg="danger">Inactief</Badge>}

                                            </li>
                                            <li className="list-inline-item">Score: {item.score}</li>
                                        </ul>
                                        {item.contact_account_name ?
                                            <div>{"Bedrijf: " + item.contact_account_name + "\n"}</div> : null}
                                        {item.contact_email ?
                                            <div>{"Email: " + item.contact_email + "\n"}</div> : null}
                                        {item.contact_work_nr ?
                                            <div>{"Telefoonnummer: " + item.contact_work_nr + "\n"}</div> : null}
                                    </div>
                                </li>
                                break;
                            case "offers-prod":
                                variableBody = <li key={item.id} className="removeListStyle">
                                    <hr/>
                                    <div>
                                        <h6><NavLink
                                            to={`../../offer/${item.sales_offer_id}`}>{item.sales_offer_reference} {" - "} {item.sales_offer_name}</NavLink>
                                        </h6>
                                        <ul className="list-inline list-inline-dotted text-muted mb-2">
                                            <li className="list-inline-item">
                                                <Badge bg="warning">Offerte</Badge>
                                            </li>
                                            <li className="list-inline-item">Score: {item.score}</li>
                                        </ul>
                                        {item.sales_offer_status ?
                                            <div>{"Status: " + item.sales_offer_status + "\n"}</div> : null}
                                        {item.sales_offer_margin ?
                                            <div>{"Marge: " + parseFloat(item.sales_offer_margin) + " %" + "\n"}</div> : null}
                                        {item.sales_offer_phase ?
                                            <div>{"Fase: " + item.sales_offer_phase + "\n"}</div> : null}
                                    </div>
                                </li>
                                break;
                            case "orders-prod":
                                variableBody = <li key={item.id} className="removeListStyle">
                                    <hr/>
                                    <div>
                                        <h6><NavLink
                                            to={`../../order/${item.product_order_id}`}>{item.product_order_customer}</NavLink>
                                        </h6>
                                        <ul className="list-inline list-inline-dotted text-muted mb-2">
                                            <li className="list-inline-item">
                                                <Badge bg="secondary">Bestelling</Badge>
                                            </li>
                                            <li className="list-inline-item">Score: {item.score}</li>
                                        </ul>
                                        {item.product_order_status ?
                                            <div>{"Status: " + item.product_order_status + "\n"}</div> : null}
                                        {item.product_order_reference ?
                                            <div>{"Referentie: " + item.product_order_reference + "\n"}</div> : null}
                                        {item.product_order_priority ?
                                            <div>{"Prioriteit: " + item.product_order_priority + "\n"}</div> : null}
                                    </div>
                                </li>
                                break;
                            case "products-prod":
                                variableBody = <li key={item.id} className="removeListStyle">
                                    <hr/>
                                    <div>
                                        <h6><NavLink
                                            to={`../../product/${item.product_id}`}>{item.product_name}</NavLink>
                                        </h6>
                                        <ul className="list-inline list-inline-dotted text-muted mb-2">
                                            <li className="list-inline-item">
                                                <Badge bg="primary" className={"me-1"}>Producten</Badge>
                                                {item.product_discontinued ?
                                                    <Badge bg="danger">Niet (meer) leverbaar</Badge> :
                                                    <Badge bg="primary">Leverbaar</Badge>}
                                            </li>
                                            <li className="list-inline-item">Score: {item.score}</li>
                                        </ul>
                                        {item.supplier_id ? <div>{"Leverancier: " + item.supplier_id + "\n"}</div> : null}
                                        {item.product_description}
                                    </div>
                                </li>
                                break;
                            case "service-prod":
                                variableBody = <li key={item.id} className="removeListStyle">
                                    <hr/>
                                    <div>
                                        <h6><NavLink
                                            to={`../../service-detail/${item.service_project_id}`}>{item.service_project_name}</NavLink>
                                        </h6>
                                        <ul className="list-inline list-inline-dotted text-muted mb-2">
                                            <li className="list-inline-item">

                                                <Badge bg="light" style={{color: palette["primary-light"]}}>Service</Badge>
                                            </li>
                                            <li className="list-inline-item">Score: {item.score}</li>
                                        </ul>
                                        {item.service_project_code ?
                                            <div>{"Projectcode: " + item.service_project_code + "\n"}</div> : null}
                                        {item.service_project_street || item.service_project_street_nr || item.service_project_postcode || item.service_project_city ?
                                            <div>{"Adres: " + item.service_project_street + " " + item.service_project_street_nr + " "
                                                + item.service_project_postcode + " " + item.service_project_city + "\n"}</div> : null}
                                    </div>
                                </li>
                                break;
                            case "suppliers-prod":
                                variableBody = <li key={item.id} className="removeListStyle">
                                    <hr/>
                                    <div>
                                        <h6><NavLink
                                            to={`../../supplier/${item.product_supplier_id}`}>{item.product_supplier_name}</NavLink>
                                        </h6>
                                        <ul className="list-inline list-inline-dotted text-muted mb-2">
                                            <li className="list-inline-item">
                                                <Badge bg="dark">Leverancier</Badge>
                                            </li>
                                            <li className="list-inline-item">Score: {item.score}</li>
                                        </ul>
                                        {item.product_supplier_category ?
                                            <div>{"Categorie: " + item.product_supplier_category + "\n"}</div> : null}
                                        {item.product_supplier_phone ?
                                            <div>{"Telefoonnummer: " + item.product_supplier_phone + "\n"}</div> : null}
                                        {item.product_supplier_email ?
                                            <div>{"Emailadres: " + item.product_supplier_email + "\n"}</div> : null}
                                    </div>
                                </li>
                                break;
                            case "tickets-prod":
                                variableBody = <li key={item.id} className="removeListStyle">
                                    <hr/>
                                    <div>
                                        <h6><NavLink
                                            to={`../../ticket/${item.service_project_f_id}/${item.service_project_ticket_id}`}
                                            className="text-success">{item.service_project_ticket_reference} {" - "} {item.service_project_ticket_subject}</NavLink>
                                        </h6>
                                        <ul className="list-inline list-inline-dotted text-muted mb-2">
                                            <li className="list-inline-item">
                                                <Badge bg="danger">Tickets</Badge>
                                            </li>
                                            <li className="list-inline-item">Score: {item.score}</li>
                                        </ul>
                                        {item.service_project_ticket_direction ?
                                            <div>{"Richting: " + item.service_project_ticket_direction + "\n"}</div> : null}
                                        {item.service_project_ticket_ticket_type ?
                                            <div>{"Type: " + item.service_project_ticket_ticket_type + "\n"}</div> : null}
                                        {item.service_project_ticket_completed || typeof item.service_project_ticket_completed === "boolean" ?
                                            <div>{item.service_project_ticket_completed ? "Afgerond: Ja" + "\n" : "Afgerond: Nee" + "\n"}</div> : null}
                                        {item.service_project_ticket_text}
                                    </div>
                                </li>
                                break;
                            default:
                                variableBody = <li key={item.id} className="removeListStyle">
                                    <hr/>
                                    <div>
                                        <h6>{item.id}</h6>
                                        <ul className="list-inline list-inline-dotted text-muted mb-2">
                                            <li className="list-inline-item">Score: {item.score}</li>
                                        </ul>
                                        Dit is een datapunt wat wel beschikbaar is maar buiten de elastic search case-scope
                                        valt.
                                        Neem contact op met ICT om te zorgen dat de scopes worden aangepast.
                                    </div>
                                </li>;
                                break;
                        }
                        return variableBody;
                    }
                )}
            </Card.Body>
        </Card>
    );
}
