import React from "react";
import {Button, Card} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import SupplierAddSupplierModal from "./SupplierAddEditSupplierModal";
import ReactDataGridCustom from "../../../../components/customComponents/ReactDataGridCustom";
import {useExcelDownloder} from "react-xls";
import moment from "moment";
import TableViewIcon from "@mui/icons-material/TableView";

/**
 * Represents the default filter value for a search filter.
 *
 * @type {Array}
 * @default
 * [
 *    {name: 'product_supplier_name', operator: 'contains', type: 'string', value: ''},
 *    {name: 'product_supplier_email', operator: 'contains', type: 'string', value: ''},
 *    {name: 'product_supplier_address', operator: 'contains', type: 'string', value: ''},
 *    {name: 'product_supplier_website', operator: 'contains', type: 'string', value: ''},
 *    {name: 'product_supplier_category', operator: 'contains', type: 'string', value: ''},
 * ]
 */
const defaultFilterValue = [
    {name: 'product_supplier_name', operator: 'contains', type: 'string', value: ''},
    {name: 'product_supplier_email', operator: 'contains', type: 'string', value: ''},
    {name: 'product_supplier_address', operator: 'contains', type: 'string', value: ''},
    {name: 'product_supplier_website', operator: 'contains', type: 'string', value: ''},
    {name: 'product_supplier_category', operator: 'contains', type: 'string', value: ''},
];


/**
 * Represents the columns for a table that displays product supplier information.
 *
 * @typedef {Object} Column
 * @property {string} name - The name of the column.
 * @property {string} header - The header text for the column.
 * @property {Function} render - A function that renders the cell value and data.
 * @property {number} flex - The flex value for the column.
 */
const columns = [
    {
        name: "product_supplier_name",
        header: "Leverancier",
        render: ({value, data}) => <NavLink to={`/supplier/${data.product_supplier_id}`}>{value}</NavLink>,
        flex: 1,
    },
    {
        name: "product_supplier_email",
        header: "Email",
        render: ({value}) => <a
            href={`mailto:${value}`}>{value}</a>,
        flex: 1,
    },
    {
        name: "product_supplier_address",
        header: "Adres",
        render: ({data}) => {
            let stringRow = "";
            if (data.product_supplier_address) {
                stringRow = stringRow + data.product_supplier_address;
            }
            if (data.product_supplier_city) {
                stringRow = stringRow + " " + data.product_supplier_city;
            }
            if (data.product_supplier_country) {
                stringRow = stringRow + " " + data.product_supplier_country;
            }
            return stringRow;
        },
        flex: 1,
    },
    {
        name: "product_supplier_website",
        header: "Website",
        render: ({value, data}) => <a href={data.product_supplier_website} target={"_blank"} rel="noreferrer">{value}</a>,
        flex: 1,
    },
    {
        name: "product_supplier_category",
        header: "Categorie",
        flex: 1,
    },
];

/**
 * Renders a table component for suppliers.
 *
 * @param {Object} props - The properties for the SupplierTable component.
 * @param {Object} props.dataSource - The data source for the table.
 * @param {Function} props.reload - The reload function to use.
 *
 * @return {JSX.Element} - The rendered SupplierTable component.
 */
export default function SupplierTable({dataSource, reload}) {
    const {ExcelDownloder} = useExcelDownloder();

    return (
        <Card>
            <Card.Header>
                <Card.Title className="mb-0 d-flex">
                    <div className={"w-100 d-flex justify-content-between "}>
                        <h3 className={"mb-0"}>Leveranciers</h3>
                        <span>
                            <SupplierAddSupplierModal reload={reload}/>
                                                     <ExcelDownloder
                                                         data={dataSource}
                                                         filename={'Leveranciers ' + moment().format("DD-MM-YYYY")}
                                                         type={"link"}
                                                     >
                                <Button className={"me-1 added-tiny-margin-top remove-margin-top-mob"} variant={"secondary"}>
                                    <TableViewIcon fontSize={"small"}/>
                                </Button>
                            </ExcelDownloder>
                        </span>
                    </div>
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <ReactDataGridCustom
                    idProperty="product_supplier_id"
                    dataSource={dataSource.suppliers}
                    columns={columns}
                    pagination
                    defaultLimit={50}
                    defaultFilterValue={defaultFilterValue}
                />
            </Card.Body>
        </Card>
    );
}