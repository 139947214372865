import React from "react";

import SidebarNavListItem from "./SidebarNavListItem";
import SidebarNavList from "./SidebarNavList";

/**
 * Reduces child routes and adds them to the items array.
 *
 * @param {Object} props - The properties containing the items, page, and depth.
 * @param {Array} props.items - The array to add the child routes to.
 * @param {Object} props.page - The page object containing properties like icon, title, href, and badge.
 * @param {number} props.depth - The depth of the current page.
 * @returns {Array} The modified items array with the child routes added.
 */
const reduceChildRoutes = (props) => {
    const {items, page, depth} = props;

    if (page.children) {
        const open = false;

        items.push(
            <SidebarNavListItem
                depth={depth}
                icon={page.icon}
                key={page.title}
                badge={page.badge}
                open={!!open}
                title={page.title}
                href={page.href}
            >
                <SidebarNavList depth={depth + 1} pages={page.children}/>
            </SidebarNavListItem>
        );
    } else {
        items.push(
            <SidebarNavListItem
                depth={depth}
                href={page.href}
                icon={page.icon}
                key={page.title}
                badge={page.badge}
                title={page.title}
            />
        );
    }

    return items;
};

export default reduceChildRoutes;
