import {Button, Card} from "react-bootstrap";
import React, {useMemo} from "react";
import {NavLink} from "react-router-dom";
import WatchdogAddEditModal from "./WatchdogAddEditModal";
import moment from "moment";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import TableViewIcon from "@mui/icons-material/TableView";
import {useExcelDownloder} from "react-xls";

/**
 * Represents the default filter value for a given filter array.
 *
 * @type {Array<Object>}
 * @property {string} name - The name of the filter.
 * @property {string} operator - The operator used in the filter.
 * @property {string} type - The type of the filter value.
 * @property {string} value - The default value of the filter.
 */
const defaultFilterValue = [
    {name: 'service_project_name', operator: 'contains', type: 'string', value: ''},
    {name: 'watchdog_project_search_query', operator: 'contains', type: 'string', value: ''},
];

/**
 * Display a table of watchdog projects.
 *
 * @param {Object} dataSource - The data source for the table.
 * @param {function} reload - The function to reload the data source.
 *
 * @return {JSX.Element} - The rendered WatchdogTable component.
 */
export default function WatchdogTable({dataSource, reload}) {
    const {ExcelDownloder} = useExcelDownloder();

    const columns = useMemo(() => [
        {
            name: "service_project_name",
            header: "Project",
            render: ({value, data}) => <NavLink to={`/service-detail/${data.service_project_f_id}`}>{value}</NavLink>,
            flex: 1,
        },
        {
            name: "watchdog_project_search_query",
            header: "Zoekterm",
            flex: 1,
        },
        {
            name: "watchdog_project_status",
            header: "Status",
            render: ({value}) => {
                if (value === "success") return <span className="badge bg-primary">Goed</span>;
                else if (value === "warning") return <span className="badge bg-danger">{value}</span>;
                else return <span className="badge bg-warning text-dark">Onbekend</span>;
            },
            flex: 1,
        },
        {
            name: "watchdog_project_sc_polling_timestamp",
            header: "Laatste succesvolle polling",
            render: ({value}) => {
                if (value) {
                    return moment(value).format("DD-MM-YYYY HH:mm")
                } else if (value === null) {
                    return "Geen tijd bekend"
                }
            },
            flex: 1,
        },
        {
            name: "watchdog_project_polling_timestamp",
            header: "Laatste polling",
            render: ({value}) => {
                if (value) {
                    return moment(value).format("DD-MM-YYYY HH:mm")
                } else if (value === null) {
                    return "Geen tijd bekend"
                }
            },
            flex: 1,
        },
        {
            name: "Aanpassen",
            header: "Aanpassen",
            render: ({data}) => <GuardWrapper role={"write:watchdog"}><WatchdogAddEditModal watchdog={data}
                                                                                            id={data.watchdog_project_id}
                                                                                            reload={reload}/></GuardWrapper>,
            flex: 1,
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [dataSource]);

    return (
        <Card>

            <Card.Header>
                <Card.Title tag="h5" className="mb-0 d-flex">
                    <div className={"w-100 d-flex justify-content-between"}>
                        <h3 className={"mb-0"}>Watchdog</h3>
                        <span>
                        <ExcelDownloder
                            data={dataSource}
                            filename={'Watchdog ' + moment().format("DD-MM-YYYY")}
                            type={"link"}
                        >
                            <Button className={"me-1 added-tiny-margin-top remove-margin-top-mob"}
                                    variant={"secondary"}>
                                <TableViewIcon fontSize={"small"}/>
                            </Button>
                        </ExcelDownloder>
                        <WatchdogAddEditModal reload={reload}/>
                            </span>
                    </div>
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <ReactDataGridCustom
                    idProperty="watchdog_project_id"
                    dataSource={dataSource.watchdog}
                    columns={columns}
                    pagination
                    defaultLimit={50}
                    defaultFilterValue={defaultFilterValue}
                />
            </Card.Body>
        </Card>
    );
}