import { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";

/**
 * Retrieves and returns the current theme from the ThemeContext.
 *
 * @function
 * @returns {Object} The current theme object from the ThemeContext.
 */
const useTheme = () => useContext(ThemeContext);

export default useTheme;
