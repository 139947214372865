import ToolkitProvider, {CSVExport, Search} from "react-bootstrap-table2-toolkit";
import {Card} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import React from "react";
import TableViewIcon from "@mui/icons-material/TableView";
import HourlyRatesAddModal from "./HourlyRatesAddModal";
import moment from "moment";
import {euroFormat} from "../../../utils/usefullFunctions";

const {SearchBar} = Search;
const {ExportCSVButton} = CSVExport;

/**
 * Represents an array of columns for a table.
 *
 * @typedef {Object[]} TableColumns
 *
 * @property {string} dataField - The data field used for displaying data in the table.
 * @property {string} text - The display text for the column header.
 * @property {boolean} [sort] - Specifies whether the column is sortable.
 * @property {Formatter} [formatter] - The function used to format the cell value.
 *
 * @typedef {(cell: any) => string | JSX.Element} Formatter - The function used to format the cell value.
 */
const columns = [
    {
        dataField: "project_rate_id",
        text: "Uurtarief id",
        sort: true,
        hidden: true,
    },

    {
        dataField: "project_rate_reference",
        text: "Referentie",
        sort: true,
    },
    {
        dataField: "project_rate_engineering",
        text: "Engineering",
        sort: true,
        formatter: (cell) => {
            return euroFormat(cell);
        }
    },
    {
        dataField: "project_rate_ibs",
        text: "IBS",
        sort: true,
        formatter: (cell) => {
            return euroFormat(cell);
        }
    },
    {
        dataField: "project_rate_manager",
        text: "Projectleider",
        sort: true,
        formatter: (cell) => {
            return euroFormat(cell);
        }
    },

    {
        dataField: "project_rate_pan",
        text: "Paneelbouw op locatie",
        sort: true,
        formatter: (cell) => {
            return euroFormat(cell);
        }
    },

    {
        dataField: "project_rate_tek",
        text: "Tekenwerk",
        sort: true,
        formatter: (cell) => {
            return euroFormat(cell);
        }
    },
    {
        dataField: "project_rate_warranty",
        text: "Garantie in jaren",
        sort: true,
        formatter: (cell) => {
            return parseFloat(cell).toFixed(1);
        }
    },
    {
        dataField: "project_rate_risc",
        text: "Risico",
        sort: true,
        formatter: (cell) => {
            return parseFloat(cell).toFixed(1) + "%";
        }
    },
    {
        dataField: "user_name",
        text: "Aangemaakt door",
        sort: true,
    },
    {
        dataField: "project_rate_created_on",
        text: "Aangemaakt op",
        sort: true,
        formatter: (cell) => {
            return moment(cell).format("DD/MM/YYYY")
        }
    },
    {
        dataField: "project_rate_current_rate",
        text: "Actief",
        formatter: (cell) => {
            if (cell === true) {
                return <span className="badge bg-success">Ja</span>;
            } else {
                return <span className="badge bg-danger">Nee</span>;
            }
        },
        sort: true,
    },
];

/**
 * Render a table with hourly rates.
 *
 * @param {Object} params - The parameters for the table.
 * @param {Array} params.hourlyRate - The hourly rates to display in the table.
 * @param {Function} params.reload - A function to reload the table.
 * @returns {JSX.Element} The rendered table.
 */
export default function HourlyRatesTable({hourlyRate, reload}) {
    return (
        <Card>
            <ToolkitProvider
                keyField="project_rate_id"
                data={hourlyRate}
                columns={columns}
                search
                exportCSV
            >
                {(props) => (
                    <>
                        <Card.Header>
                            <Card.Title tag="h5" className="mb-0 d-flex">
                                <div className={"w-100 d-flex justify-content-between"}>

                                    <SearchBar
                                        className={"form-control w-100"}
                                        placeholder={"Filter: Typ om te zoeken"}
                                        srText={null}
                                        {...props.searchProps}
                                    />
                                    <span>
                                    <ExportCSVButton
                                        className={"btn btn-secondary me-1"} {...props.csvProps}><TableViewIcon
                                        fontSize={"small"}/></ExportCSVButton>
                                        <HourlyRatesAddModal reload={reload} />
                                    </span>
                                </div>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <BootstrapTable
                                {...props.baseProps}
                            />
                        </Card.Body>
                    </>
                )}
            </ToolkitProvider>
        </Card>
    );
}