import React, {useEffect, useState} from "react";
import gmApi from "../../../api/gm";
import GmTable from "./GmTable";
import Loader from "../../../components/Loader";
import notyf from "../../../utils/notyfApi";

/**
 * Retrieve data from API and display it in a table
 *
 * @return {JSX.Element} - A table component displaying the retrieved data
 */
export default function GmTableHandler() {
    //get data from api
    const [gm, setGm] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const response = await gmApi.getAllGms();
        if (!response.ok) return notyf.error("Error " + response.status)
        setGm(response.data);
    };

    //laat tabel zien met gegevens via props
    if (gm.length > 0) return <GmTable gm={gm}/>;
    else
        return <Loader/>;
}
