import React from "react";
import {Badge, Card} from "react-bootstrap";
import {i18nTable} from "../../../../constants";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import moment from "moment";
import {NavLink} from "react-router-dom";
import {euroFormat} from "../../../../utils/usefullFunctions";

/**
 * Represents the columns for a table.
 * @typedef {Object[]} Columns
 * @property {string} name - The name of the column.
 * @property {string} header - The header text of the column.
 * @property {number} flex - The flex value of the column.
 * @property {Function} [render] - A function to customize the rendering of the column.
 * @property {string} render.value - The value of the column.
 * @property {Object} [render.data] - Additional data for rendering.
 * @returns {JSX.Element|string} - The rendered value of the column.
 */
const columns = [
    {
        name: "title",
        header: "Titel",
        flex: 1,
        render: ({value, data}) => <NavLink to={`/shopifywebshop/${data.id}`}>{value}</NavLink>,
    },
    {
        name: "vendor",
        header: "Verkoper",
        flex: 1,
    },
    {
        name: "product_type",
        header: "Product type",
        flex: 1,
    },
    {
        name: "created_at",
        header: "Aangemaakt op",
        flex: 1,
        render: ({value}) => {
            if (value) {
                return moment(value).format("HH:mm op DD/MM/YY");
            } else {
                return "Onbekend";
            }
        },
    },
    {
        name: "updated_at",
        header: "Geüpdatet op",
        flex: 1,
        render: ({value}) => {
            if (value) {
                return moment(value).format("HH:mm op DD/MM/YY");
            } else {
                return "Onbekend";
            }
        },
    },
    {
        name: "published_at",
        header: "Gepubliceerd op",
        flex: 1,
        render: ({value}) => {
            if (value) {
                return moment(value).format("HH:mm op DD/MM/YY");
            } else {
                return "Onbekend";
            }
        },
    },
    {
        name: "status",
        header: "Status",
        flex: 1,
        render: ({value}) => {
            if (value === "active") {
                return <Badge bg={"success"}>Actief</Badge>;
            } else if (value === "draft") {
                return <Badge bg={"warning"}>In afwachting</Badge>
            } else if (value === "inactive") {
                return <Badge bg={"danger"}>Inactief</Badge>
            } else {
              return <Badge bg={"info"}>{value}</Badge>
            }
        },
    },
    {
        header: "Verkoopprijs",
        flex: 1,
        render: ({data}) => {
            if (data.variants[0].price !== "0.00") {
                return euroFormat(data.variants[0].price);
            } else if (data.variants[0].price === "0.00") {
                return euroFormat(data.variants[0].price);
            } else {
                return "Onbekend";
            }
        },
    },
];


/**
 * Renders a webshop table component displaying a list of products.
 *
 * @param {object} options - An object containing the options for rendering the component.
 * @param {array} options.products - An array of product objects to be displayed in the table.
 *
 * @return {ReactElement} - The rendered webshop table component.
 */
export default function XconnectWebshopTable({products}) {
    return (
        <Card>
            <Card.Header>
                <h5>Productenoverzicht</h5>
            </Card.Header>
            <Card.Body>
                <ReactDataGrid
                    idProperty="id"
                    dataSource={products}
                    columns={columns}
                    style={{height: "70vh"}}
                    pagination
                    defaultLimit={50}
                    i18n={i18nTable}
                />
            </Card.Body>
        </Card>
    );
}

