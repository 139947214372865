import {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import offersApi from "../../../api/offertes";
import {Form as FormikForm, FormSelectFieldContacts, SubmitButton} from "../../../components/forms/index"
import * as Yup from "yup";
import notyf from "../../../utils/notyfApi";

/**
 * Displays a modal for adding a new contact to an offer.
 * @param {Object} props - The properties for the OfferteAccountModal component.
 * @param {number} props.id - The ID of the offer.
 * @param {function} props.reload - The function to reload the offer data.
 * @return {React.Element} The OfferteAccountModal component.
 */
function OfferteAccountModal({id, reload}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(false);
        const res = await offersApi.addContactToOffer(id, {
            ...values.sales_offer_detail_contact_id,
            sales_contact_name: `${values.sales_offer_detail_contact_id.sales_contact_firstname} ${values.sales_offer_detail_contact_id.sales_contact_surname_prefix || ""} ${values.sales_offer_detail_contact_id.sales_contact_lastname}`
        })
        if (!res.ok) return notyf.error("Error " + res.status)
        reload()
        handleClose()
    };

    return (
        <>
            <Button variant="primary" className={"float-end"} onClick={handleShow}>
                +
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Nieuwe aanvrager aanmaken
                    </Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={{sales_offer_detail_contact_id: null}}
                    validationSchema={Yup.object({sales_offer_detail_contact_id: Yup.object().required("Aanvrager moet geselecteerd worden").nullable()})}
                    onSubmit={handleSubmit}
                >
                    <Modal.Body>
                        <FormSelectFieldContacts name={"sales_offer_detail_contact_id"}
                                                 label={"Aanvrager"}
                                                 width={"100%"}
                                                 className={"mb-3"}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <SubmitButton title={"Opslaan"}/>
                    </Modal.Footer>
                </FormikForm>
            </Modal>
        </>
    );
}

export default OfferteAccountModal;
