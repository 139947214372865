import React, {useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {Form as FormikForm, FormField, FormFieldAddress, SubmitButton} from "../../../components/forms";
import * as Yup from "yup";
import nawCompaniesApi from "../../../api/nawCompanies";
import {Edit2} from "react-feather";
import notyf from "../../../utils/notyfApi";


/**
 * A modal component used for adding or editing company data.
 *
 * @param {object} props - The properties object.
 * @param {boolean} props.exists - Indicates if the company already exists. If true, the modal will be in edit mode.
 * @param {function} props.reload - A function to reload the data after creating or editing a company.
 * @returns {object} - The NawCompaniesAddCompanyModal component.
 */
export default function NawCompaniesAddCompanyModal({exists, reload}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const createCompanyData = async (data) => {
        let res
        if (exists) res = await nawCompaniesApi.editCompany(exists.sales_account_id, {
            ...data,
            sales_account_mailbox_nr: data.sales_account_mailbox_nr ? parseInt(data.sales_account_mailbox_nr) : null
        });
        else res = await nawCompaniesApi.postNewCompany({
            ...data,
            sales_account_mailbox_nr: data.sales_account_mailbox_nr ? parseInt(data.sales_account_mailbox_nr) : null
        });
        if (!res.ok) return notyf.error("Error " + res.status)
        reload()
        handleClose();
    };

    //regex voor telefoon + fax + website + kvk + btw
    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/
    const websiteRegExp = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig


    return (
        <React.Fragment>
            {exists ? <Edit2 className={"cursor-pointer float-end"} onClick={handleShow}/> :
                <Button variant="primary" className={"float-end"} onClick={handleShow}>
                    +
                </Button>}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{exists ? "Bedrijf aanpassen" : "Nieuw bedrijf toevoegen"}</Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={{
                        sales_account_name: exists?.sales_account_name || "",
                        sales_account_phone_nr: exists?.sales_account_phone_nr || "",
                        sales_account_fax_nr: exists?.sales_account_fax_nr || "",
                        sales_account_website: exists?.sales_account_website || "",
                        sales_account_email: exists?.sales_account_email || "",
                        sales_account_btw_nr: exists?.sales_account_btw_nr || "",
                        sales_account_kvk_nr: exists?.sales_account_kvk_nr || "",
                        sales_account_type: exists?.sales_account_type || "",
                        sales_account_street_nr: exists?.sales_account_street_nr || "",
                        sales_account_street: exists?.sales_account_street || "",
                        sales_account_postal_code: exists?.sales_account_postal_code || "",
                        sales_account_city: exists?.sales_account_city || "",
                        sales_account_province: exists?.sales_account_province || "",
                        sales_account_country: exists?.sales_account_country || "",
                        sales_account_mailbox_nr: exists?.sales_account_mailbox_nr || "",
                        sales_account_mailbox_postal_code: exists?.sales_account_mailbox_postal_code || "",
                        sales_account_mailbox_city: exists?.sales_account_mailbox_city || "",
                        sales_account_mailbox_country: exists?.sales_account_mailbox_country || "",
                    }}

                    // Hier is het validatieschema van alle velden
                    validationSchema={Yup.object({
                        sales_account_name: Yup.string()
                            .required('Bedrijfsnaam moet ingevuld worden.'),
                        sales_account_phone_nr: Yup.string()
                            .matches(phoneRegExp, 'Telefoonnummer klopt niet. (voldoet niet aan regex expressie)'),
                        sales_account_fax_nr: Yup.string()
                            .matches(phoneRegExp, 'Faxnummer klopt niet. (voldoet niet aan regex expressie)'),
                        sales_account_website: Yup.string()
                            .matches(websiteRegExp, 'URL klopt niet. (voldoet niet aan regex expressie)'),
                        sales_account_email: Yup.string()
                            .email()
                            .required('Email moet ingevuld worden.'),
                        sales_account_btw_nr: Yup.string(),
                        sales_account_kvk_nr: Yup.string(),
                        sales_account_type: Yup.string()
                            .required('Type moet geselecteerd worden.'),
                        sales_account_city: Yup.string()
                            .required('Adres moet ingevuld worden.'),
                    })}

                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(false);
                        createCompanyData(values);
                    }}
                >
                    <Modal.Body>
                        <FormField name={"sales_account_name"}
                                   label={"Bedrijfsnaam:*"}
                                   width={"100%"}
                                   placeholder="Vul de bedrijfsnaam hier in"
                                   className={"mb-3"}
                                   control={"input"}
                        />

                        <Row>
                            <Col>
                                <FormField name={"sales_account_phone_nr"}
                                           label={"Telefoonnummer:"}
                                           width={"100%"}
                                           placeholder={"Vul hier het telefoonnummer in"}
                                           className={"mb-3 "}
                                           control={"input"}
                                />
                            </Col>
                            <Col>
                                <FormField name={"sales_account_fax_nr"}
                                           label={"Faxnummer:"}
                                           width={"100%"}
                                           placeholder={"Vul hier het faxnummer in"}
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                        </Row>
                        <FormField name={"sales_account_website"}
                                   label={"Website:"}
                                   width={"100%"}
                                   placeholder="Vul het webadres hier in"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"sales_account_email"}
                                   label={"Email:*"}
                                   width={"100%"}
                                   placeholder={"Vul hier het emailadres in"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <Row>
                            <Col>
                                <FormField name={"sales_account_btw_nr"}
                                           label={"BTW nummer:"}
                                           placeholder="Vul het BTW nummer hier in"
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                            <Col>
                                <FormField name={"sales_account_kvk_nr"}
                                           label={"KVK nummer:"}
                                           placeholder="Vul het kvk nummer hier in"
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                        </Row>
                        <FormField name={"sales_account_type"}
                                   label={"Type:*"}
                                   width={"100%"}
                                   placeholder={"Vul hier het type in"}
                                   className={"mb-3"}
                                   as={"select"}
                        >
                            <option value="">Selecteer een type</option>
                            <option value="Installateur">Installateur</option>
                            <option value="Leverancier">Leverancier</option>
                            <option value="Servicebedrijf">Servicebedrijf</option>
                            <option value="Eindgebruiker">Eindgebruiker</option>
                            <option value="Anders">Anders</option>
                        </FormField>
                        <h3>Adresgegevens</h3>
                        <hr/>
                        <FormFieldAddress names={{
                            number: "sales_account_street_nr",
                            street: "sales_account_street",
                            city: "sales_account_city",
                            state: "sales_account_province",
                            zip: "sales_account_postal_code",
                            country: "sales_account_country",
                        }}
                                          label={"Adres"}
                        />
                        <div
                            style={{
                                color: 'hsl(0, 0%, 40%)',
                                display: 'inline-block',
                                fontSize: 12,
                                fontStyle: 'italic',
                                marginTop: '1em',
                            }}
                        >
                        </div>
                        <h3>Postbus</h3>
                        <hr/>
                        <Row>
                            <Col>
                                <FormField name={"sales_account_mailbox_nr"}
                                           label={"Postbus nummer:"}
                                           width={"100%"}
                                           placeholder={"Vul hier het postbus nummer in"}
                                           className={"mb-3"}
                                           control={"input"}
                                           type={"number"}
                                />
                            </Col>
                            <Col>
                                <FormField name={"sales_account_mailbox_postal_code"}
                                           label={"Postbus postcode:"}
                                           width={"100%"}
                                           placeholder={"Vul hier de postcode in"}
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormField name={"sales_account_mailbox_city"}
                                           label={"Postbus plaats:"}
                                           width={"100%"}
                                           placeholder={"Vul hier de plaats in"}
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                            <Col>
                                <FormField name={"sales_account_mailbox_country"}
                                           label={"Postbus land:*"}
                                           width={"100%"}
                                           placeholder={"Vul hier het land in"}
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Niet aanmaken
                        </Button>
                        <SubmitButton title={"Opslaan"} variant="primary"/>
                    </Modal.Footer>
                </FormikForm>
            </Modal>
        </React.Fragment>
    );
}

