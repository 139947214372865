import React, {useEffect, useState} from "react";
import adminUsersApi from "../../../api/adminUsers";
import AdminUserTable from "./AdminUserTable";
import Loader from "../../../components/Loader";

/**
 * Retrieves user data from an API and renders an admin user table.
 *
 * @return {JSX.Element} The rendered admin user table or a loader component.
 */
export default function AdminUserTableHandler() {
    //get data from api
    const [user, setUser] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const response = await adminUsersApi.getAllUsers();
        if (response.ok) {
            setUser(response.data.users);
        }
    };

    //laat tabel zien met gegevens via props
    if (user.length > 0) return <AdminUserTable user={user}/>;
    return <Loader/>
}
