import React from "react";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import DashboardLayout from "./layouts/Dashboard";

// Pages
import Weeklyreport from "./pages/pages/algemeen/WeeklyReport";
import Nawcompanies from "./pages/pages/algemeen/NawCompanies";
import Nawcontacts from "./pages/pages/algemeen/NawContacts";
import Offeroverview from "./pages/pages/verkoop/OfferOverview";
import Projectresults from "./pages/pages/projecten/ProjectResults";
import Gm from "./pages/pages/projecten/Gm";
import Engineering from "./pages/pages/engineering/Engineering";
import Service from "./pages/pages/service/Service";
import Tickets from "./pages/pages/service/Tickets";
import Hosting from "./pages/pages/service/Hosting";
import Watchdog from "./pages/pages/service/Watchdog";
import Orderoverview from "./pages/pages/producten_inkoop/OrderOverview";
import Supplier from "./pages/pages/producten_inkoop/Supplier";
import Productoverview from "./pages/pages/producten_inkoop/ProductOverview";
import Productdetail from "./pages/pages/producten_inkoop/ProductDetail";
import Nawcompaniesdetail from "./pages/pages/algemeen/NawCompaniesDetail";
import Nawcontactsdetail from "./pages/pages/algemeen/NawContactsDetail";
import OfferDetail from "./pages/pages/verkoop/OfferDetail";
import ProjectResultsDetail from "./pages/pages/projecten/ProjectResultsDetail";
import ServiceDetail from "./pages/pages/service/ServiceDetail";
import Matrix from "./pages/pages/service/Matrix";
import Ddashboard from "./pages/pages/debiteuren/Ddashboard";
import Debtors from "./pages/pages/debiteuren/Debtors";
import Invoices from "./pages/pages/debiteuren/Invoices";
import OrderDetail from "./pages/pages/producten_inkoop/OrderDetail";
import DebtorsDetail from "./pages/pages/debiteuren/DebtorsDetail";
import SupplierDetail from "./pages/pages/producten_inkoop/SupplierDetail";
import TicketsDetail from "./pages/pages/service/TicketsDetail";
import Users from "./pages/pages/admin/Users";
import UserAdminDetails from "./pages/pages/admin/UserAdminDetails";
import Magazijnvoorraad from "./pages/pages/producten_inkoop/Magazijnvoorraad";
import PlanningKastenbouw from "./pages/pages/projecten/PlanningKastenbouw";
import InvoicesDetail from "./pages/pages/debiteuren/InvoicesDetail";
import UserProfile from "./pages/pages/profile/UserProfile";
import XconnectWebshopProductDetail from "./pages/pages/verkoop/xconnectWebshopDetail";
import XconnectWebshop from "./pages/pages/verkoop/xconnectWebshop";
import ProdVisAllDeps from "./pages/pages/dashboard/ProdVisAllDeps";
import ProdDepDetail from "./pages/pages/dashboard/ProdDepDetail";

// Protected routes
import Redirect from "./pages/pages/redirect/Redirect";
import AdminWeek from "./pages/pages/admin/AdminWeek";
import UserDashboard from "./pages/pages/dashboard/UserDashboard";
import PermissionGuard from "./components/guards/PermissionGuard";
import ErrorPage from "./pages/pages/error/ErrorPage";
import ElasticSearch from "./pages/pages/ElasticSearch";
import HourlyRates from "./pages/pages/admin/HourlyRates";
import Budget from "./pages/pages/admin/AdminBudget";

/**
 * Represents the routes used in the application.
 * @type {Array}
 */
const routes = [
    {
        path: "/",
        element: (
            <DashboardLayout>
                <UserDashboard/>
            </DashboardLayout>
        ),
    },
    {
        path: "/dashboard",
        element: (
            <DashboardLayout>
                <UserDashboard/>
            </DashboardLayout>
        ),
    },
    {
        path: "",
        element: <DashboardLayout/>,
        children: [

            {
                path: "redirect/:url",
                element: <Redirect/>,
            },
            {
                path: "timesheets/:year/:week/:id",
                element: <PermissionGuard role={"user:timesheets"}><Weeklyreport/></PermissionGuard>,
            },
            {
                path: "timesheets",
                element: <PermissionGuard role={"user:timesheets"}><Weeklyreport/></PermissionGuard>,
            },
            {
                path: "account_overview",
                element: <PermissionGuard role={"read:naw"}><Nawcompanies/></PermissionGuard>,
            },
            {
                path: "contact_overview",
                element: <PermissionGuard role={"read:naw"}><Nawcontacts/></PermissionGuard>,
            },
            {
                path: "account/:id",
                element: <PermissionGuard role={"read:naw"}><Nawcompaniesdetail/></PermissionGuard>,
            },
            {
                path: "contact/:id",
                element: <PermissionGuard role={"read:naw"}><Nawcontactsdetail/></PermissionGuard>,
            },
            {
                path: "offer_overview",
                element: <PermissionGuard role={"read:offers"}><Offeroverview/></PermissionGuard>,
            },
            {
                path: "offer/:id",
                element: <PermissionGuard role={"read:offers"}><OfferDetail/></PermissionGuard>,
            },
            {
                path: "project_overview/:leader",
                element: <PermissionGuard role={"read:projects"}><Projectresults/></PermissionGuard>,
            },
            {
                path: "project_overview/",
                element: <PermissionGuard role={"read:projects"}><Projectresults/></PermissionGuard>,
            },
            {
                path: "project/:id",
                element: <PermissionGuard role={"read:projects"}><ProjectResultsDetail/></PermissionGuard>,
            },
            {
                path: "poc_dashboard",
                element: <PermissionGuard role={"read:projects"}><Gm/></PermissionGuard>,
            },
            {
                path: "cet_dashboard",
                element: <PermissionGuard role={"read:cet"}><Engineering/></PermissionGuard>,
            },
            {
                path: "service_overview",
                element: <PermissionGuard role={"read:service"}><Service/></PermissionGuard>,
            },
            {
                path: "tickets_overview",
                element: <PermissionGuard role={"read:tickets"}><Tickets/></PermissionGuard>,
            },
            {
                path: "matrix_overview",
                element: <PermissionGuard role={"read:service_matrix"}><Matrix/></PermissionGuard>,
            },
            {
                path: "hosting_overview",
                element: <PermissionGuard role={"read:service_hosting"}><Hosting/></PermissionGuard>,
            },
            {
                path: "watchdog_overview",
                element: <PermissionGuard role={"read:watchdog"}><Watchdog/></PermissionGuard>,
            },
            {
                path: "debtors_dashboard",
                element: <PermissionGuard role={"read:debtors"}><Ddashboard/></PermissionGuard>,
            },

            {
                path: "debtors_overview",
                element: <PermissionGuard role={"read:debtors"}><Debtors/></PermissionGuard>,
            },

            {
                path: "invoice_overview",
                element: <PermissionGuard role={"read:invoices"}><Invoices/></PermissionGuard>,
            },
            {
                path: "invoice/:id",
                element: <PermissionGuard role={"read:invoices"}><InvoicesDetail/></PermissionGuard>,
            },
            {
                path: "purchase_overview",
                element: <PermissionGuard role={"read:products"}><Orderoverview/></PermissionGuard>,
            },
            {
                path: "supplier_overview",
                element: <PermissionGuard role={"read:products"}><Supplier/></PermissionGuard>,
            },
            {
                path: "products_overview",
                element: <PermissionGuard role={"read:products"}><Productoverview/></PermissionGuard>,
            },
            {
                path: "product/:id",
                element: <PermissionGuard role={"read:products"}><Productdetail/></PermissionGuard>,
            },
            {
                path: "admin/user_management",
                element: <PermissionGuard role={"admin:admin"}><Users/></PermissionGuard>,
            },
            {
                path: "admin/hourly_rates",
                element: <PermissionGuard role={"admin:admin"}><HourlyRates/></PermissionGuard>,
            },
            {
                path: "admin/budget",
                element: <PermissionGuard role={"admin:admin"}><Budget/></PermissionGuard>,
            },
            {
                path: "admin/timesheets",
                element: <PermissionGuard role={"admin:timesheets"}><AdminWeek/></PermissionGuard>,
            },
            {
                path: "service-detail/:id",
                element: <PermissionGuard role={"read:service"}><ServiceDetail/></PermissionGuard>,
            },
            {
                path: "order/:id",
                element: <PermissionGuard role={"read:products"}><OrderDetail/></PermissionGuard>,
            },
            {
                path: "debtor/:id",
                element: <PermissionGuard role={"read:debtors"}><DebtorsDetail/></PermissionGuard>,
            },
            {
                path: "supplier/:id",
                element: <PermissionGuard role={"read:products"}><SupplierDetail/></PermissionGuard>,
            },
            {
                path: "ticket/:id/:tId",
                element: <PermissionGuard role={"read:tickets"}><TicketsDetail/></PermissionGuard>,
            },
            {
                path: "users/:id",
                element: <PermissionGuard role={"admin:admin"}><UserAdminDetails/></PermissionGuard>,
            },
            {
                path: "storage_supply",
                element: <PermissionGuard role={"read:products"}><Magazijnvoorraad/></PermissionGuard>,
            },
            {
                path: "planning_kastenbouw",
                element: <PermissionGuard role={"read_manager:projects"}><PlanningKastenbouw/></PermissionGuard>,
            },
            {
                path: "userprofile",
                element: <UserProfile/>,
            },
            {
                path: "xconnect_webshop",
                element: <XconnectWebshop/>,
            },
            {
                path: "productivitydepartments",
                element: <PermissionGuard role={"admin:admin"}><ProdVisAllDeps/></PermissionGuard>,
            },
            {
                path: "productivitydetail/:department",
                element: <PermissionGuard role={"admin:admin"}><ProdDepDetail/></PermissionGuard>,
            },
            {
                path: "xconnect_webshop/:id",
                element: <XconnectWebshopProductDetail/>,
            },
            {
                path: "search/:id",
                element: <ElasticSearch/>,
            },
        ],
    },
    {
        path: "/error",
        element: <ErrorPage/>
    },
    {
        path: "*",
        element: <ErrorPage/>
    }
]
export default routes;
