import React from "react";
import {Helmet} from "react-helmet-async";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {Container} from "react-bootstrap";
import NawCompaniesTableHandler from "../../components/algemeen/NawCompaniesTableHandler";

// /account_overview component which shows the companies of the contacts
const NawCompanies = () => (
    <React.Fragment>
        <Helmet title="NAW bedrijven"/>
        <Container fluid className="p-0">
            <NawCompaniesTableHandler/>
        </Container>
    </React.Fragment>
);

export default NawCompanies;
