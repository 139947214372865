import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Container, Row, Col, Card} from "react-bootstrap";
import DashboardBarChart from "../../components/debiteuren/dashboard/DashboardBarChart";
import OpenstaandTable from "../../components/debiteuren/dashboard/OpenstaandTable";
import BlacklistTable from "../../components/debiteuren/dashboard/BlacklistTable";
import debtorsApi from "../../../api/debtors";
import {euroFormat} from "../../../utils/usefullFunctions";
import Loader from "../../../components/Loader";
import notyf from "../../../utils/notyfApi";

// Debiteuren dashboard
const Ddashboard = () => {
    useEffect(() => {
        getData()
    }, [])

    const [cardData, setCardData] = useState()
    const [cashflowData, setCashflowData] = useState([])
    const [openOverdue, setOpenOverdue] = useState([])
    const [blacklist, setBlacklist] = useState([])

    const getData = async () => {
        const res = await debtorsApi.getAllDebtors()
        if (!res.ok) return notyf.error("Error " + res.status)
        setCardData(res.data.open_overdue_total)
        setCashflowData(res.data.cashflow)
        setOpenOverdue(res.data.open_overdue_per_debtor)
        setBlacklist(res.data.blacklist)
    }

    if (!cardData) return <Loader/>
    return (
        <React.Fragment>
            <Helmet title="Debiteuren Dashboard"/>
            <Container fluid className="p-0">
                <h3>Dashboard debiteuren</h3>
                <Row xl className="d-flex">
                    <Col md="6" xl className="d-flex">
                        <Card className="flex-fill">
                            <Card.Body>
                                <div className="d-flex align-items-center justify-content-center">
                                    <div><h3 className="mb-2">Totaal over tijd</h3></div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center">
                                    <h3 className="mb-2 font-weight-bold"
                                        style={{color: "#ff5722"}}>{euroFormat(cardData.count_overdue_amount)}</h3>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="6" xl className="d-flex">
                        <Card className="flex-fill">
                            <Card.Body>
                                <div className="d-flex align-items-center justify-content-center">
                                    <div><h3 className="mb-2">Totaal openstaand</h3></div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center">
                                    <h3 className="mb-2 font-weight-bold">{euroFormat(cardData.count_outstanding_amount)}</h3>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row xl className="d-flex">
                    <Col lg={12}>
                        {cashflowData && <DashboardBarChart data={cashflowData}/>}
                    </Col>
                </Row>
                <Row xl className="d-flex">
                    <Col lg={6}>
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Openstaand & Over tijd per debiteur
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <OpenstaandTable data={openOverdue}/>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Blacklist
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <BlacklistTable data={blacklist}/>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
};

export default Ddashboard;
