import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {Badge, Button, Card, Col, Container, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {Truck} from "react-feather";
import productsApi from "../../../api/products";
import AddOrderModal from "../../components/producten_inkoop/product_orders/AddOrderModal";
import PakbonModalOverview from "../../components/producten_inkoop/product_order_details/PakbonModalOverview";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import Loader from "../../../components/Loader";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import {useExcelDownloder} from "react-xls";
import moment from "moment";
import TableViewIcon from "@mui/icons-material/TableView";

const defaultFilterValue = [
    {name: 'product_order_reference', operator: 'contains', type: 'string', value: ''},
    {name: 'project_name', operator: 'contains', type: 'string', value: ''},
    {name: 'user_name', operator: 'contains', type: 'string', value: ''},
    {name: 'project_order_order_status', operator: 'contains', type: 'string', value: ''},
];

const columns = [
    {
        name: "product_order_reference",
        header: "Referentie",
        render: ({value, data}) => <NavLink to={`/order/${data.product_order_id}`}>{value}</NavLink>,
        flex: 1
    },
    {
        name: "project_name",
        header: "Project",
        render: ({value, data}) => <NavLink to={`/project/${data.project_id}`}>{value}</NavLink>,
        flex: 3
    },
    {
        name: "project_order_order_date",
        header: "Orderdatum",
        flex: 1
    },
    {
        name: "project_order_order_status",
        header: "Status",
        render: ({value}) => {
            if (value === "geleverd") return <Badge bg={"success"}>{value}</Badge>
            if (value === "besteld") return <Badge bg={"info"}>{value}</Badge>
            if (value === "retour") return <Badge bg={"dark"}>{value}</Badge>
            if (value === "incompleet") return <Badge bg={"danger"}>{value}</Badge>
            return value
        },
        flex: 1,
        filterEditor: SelectFilter,
        filterEditorProps: {
            placeholder: 'Alles',
            dataSource: [
                {
                    id: "besteld",
                    label: "besteld"
                },
                {
                    id: "geleverd",
                    label: "geleverd"
                },
                {
                    id: "incompleet",
                    label: "incompleet"
                },
                {
                    id: "retour",
                    label: "retour"
                },
            ]
        },
    },
    {
        name: "product_supplier_name",
        header: "Leverancier",
        render: ({value, data}) => <NavLink to={`/supplier/${data.product_supplier_id}`}>{value}</NavLink>,
        flex: 1
    },
    {
        name: "user_name",
        header: "Inkoper",
        flex: 1
    }
];


const Orderoverview = () => {
    const [dataSource, setDataSource] = useState([])
    const [loading, setLoading] = useState(true);
    const {ExcelDownloder} = useExcelDownloder();


    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        const res = await productsApi.getAllProductOrders()
        if (!res.ok) return null
        setDataSource({orders: res.data})
        setLoading(false)
    }
    if (loading) return <Loader/>
    return (
        <React.Fragment>
            <Helmet title="Bestellingenoverzicht"/>
            <Container fluid className="p-0">
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Card.Title className="mb-0 d-flex">
                                    <div className={"w-100 d-flex justify-content-between"}>
                                        <h3 className={"mb-0"}>Bestellingenoverzicht</h3>

                                        <ExcelDownloder
                                            data={dataSource}
                                            filename={'Bestellingen ' + moment().format("DD-MM-YYYY")}
                                            type={"link"}
                                        >
                                            <Button className={"me-1 added-tiny-margin-top remove-margin-top-mob"} variant={"secondary"}>
                                                <TableViewIcon fontSize={"small"}/>
                                            </Button>
                                        </ExcelDownloder>
                                    </div>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <ReactDataGridCustom
                                    idProperty="product_order_id"
                                    dataSource={dataSource.orders}
                                    columns={columns}
                                    pagination
                                    defaultLimit={50}
                                    defaultFilterValue={defaultFilterValue}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                    <GuardWrapper role={"write:products"}>
                        <Col xl="2">
                            <Card>
                                <Card.Body>
                                    <AddOrderModal/>
                                    <Button className={"p-1 m-1"} variant={"danger"} style={{height: 90, width: 90}}>
                                        <div
                                            style={{fontSize: 30}}>{dataSource.orders.filter(o => o.project_order_order_status !== "geleverd" && o.project_order_order_status !== "retour").length}
                                        </div>
                                        <div style={{fontSize: 12}}>Openstaand</div>
                                    </Button>

                                    <NavLink to={"/supplier_overview"}>
                                        <Button className={"p-2 m-1"} variant={"info"}
                                                style={{height: 90, width: 90}}>
                                            <Truck size={50}/>
                                            <div style={{fontSize: 11}}>Leveranciers</div>
                                        </Button>
                                    </NavLink>
                                    <PakbonModalOverview/>
                                </Card.Body>
                            </Card>
                        </Col>
                    </GuardWrapper>
                </Row>
            </Container>
        </React.Fragment>
    )
};

export default Orderoverview;
