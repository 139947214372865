import client from "./clients/portalClient";
import calculationClient from "./clients/calculationClient";

/**
 * Retrieves all projects based on the provided category.
 *
 * @param {string} cat - The category to filter the projects.
 * @return {Promise} - A promise that resolves to the response of the API request.
 */
const getAllProjects = (cat) => client.get(`/projects?data=${cat}`);
/**
 * Retrieves project information from the server using the given ID.
 *
 * @param {number} id - The ID of the project to retrieve.
 * @returns {Promise<Object>} - A promise that resolves to the project information.
 */
const getProject = (id) => client.get(`/projects/${id}`);
/**
 * Closes a project with the specified ID.
 *
 * @param {string|number} id - The ID of the project to be closed.
 * @returns {Promise} - A promise that resolves when the project is successfully closed.
 */
const closeProject = (id) => client.post(`/projects/${id}/close`);
/**
 * Updates the status of a project to "close" the king project.
 *
 * @param {string} id - The ID of the project to close.
 * @returns {Promise} - A promise that resolves when the request is complete.
 */
const closeKingProject = (id) => client.post(`/projects/${id}/king`);
/**
 * Retrieves project calculations from the server.
 *
 * @param {number} id - The ID of the project.
 * @returns {Promise} A Promise that resolves with the project calculations.
 */
const getProjectCalculations = (id) => calculationClient.get(`/get/project/${id}`);

/**
 * Retrieves the booking detail of a project.
 *
 * @param {string} id - The ID of the project.
 * @param {string} cat - The category article for the booking detail.
 * @returns {Promise} A promise containing the booking detail data.
 */
const getProjectBookingDetail = (id, cat) => client.get(`/projects/${id}/booking_detail?article=${cat}`);

/**
 * Retrieves the history of a project.
 *
 * @param {number} id - The ID of the project.
 * @return {Promise} A promise that resolves to the project history data.
 */
const getProjectHistory = (id) => client.get(`/projects/${id}/history`);
/**
 * Retrieves the messages associated with a project.
 *
 * @param {string} id - The ID of the project.
 * @returns {Promise} - A promise that resolves with the messages associated with the project.
 */
const getProjectMessages = (id) => client.get(`/projects/${id}/comments`);
/**
 * Sends a POST request to create a new comment on a project.
 *
 * @param {string} id - The ID of the project to post the comment to.
 * @param {object} data - The data containing the comment to be posted.
 * @returns {Promise} - A promise that resolves when the request is successful or rejects on failure.
 */
const postProjectMessages = (id, data) => client.post(`/projects/${id}/comments`, data);
/**
 * Deletes project messages.
 *
 * @param {number} id - The project ID.
 * @param {number} tId - The message ID.
 * @returns {Promise} - A Promise that resolves when the deletion is successful, or rejects with an error.
 */
const deleteProjectMessages = (id, tId) => client.delete(`/projects/${id}/comments/${tId}`);

/**
 * Retrieves the tasks for a given project ID.
 *
 * @param {number} id - The ID of the project.
 * @return {Promise} A promise that resolves to the tasks for the project.
 */
const getProjectTasks = (id) => client.get(`/projects/${id}/tasks`);
/**
 * Create a post project task
 *
 * @param {string} id - The project id
 * @param {object} data - The task data to be created
 * @returns {Promise} - A promise that resolves with the created task data
 */
const postProjectTasks = (id, data) => client.post(`/projects/${id}/tasks`, data);
/**
 * Edit project tasks
 *
 * This function updates a specific task within a project identified by its project ID and task ID.
 *
 * @param {string} id - The ID of the project.
 * @param {string} tId - The ID of the task to be updated.
 * @param {object} data - The updated task data to be sent to the API.
 *
 * @returns {Promise} - A promise that resolves with the result of the API call.
 *
 * @throws {Error} - If any required argument is missing or invalid.
 *
 * @example
 *
 * // Usage example:
 * const projectId = 'abc123';
 * const taskId = 'def456';
 * const updatedTaskData = {
 *   title: 'Updated Task',
 *   description: 'This task has been updated',
 *   dueDate: '2022-12-31'
 * };
 *
 * editProjectTasks(projectId, taskId, updatedTaskData)
 *    .then(result => {
 *      console.log(result); // Output: Success message or updated task data
 *    })
 *    .catch(error => {
 *      console.error(error); // Output: Error message or detailed error information
 *    });
 */
const editProjectTasks = (id, tId, data) => client.put(`/projects/${id}/tasks/${tId}`, data);
/**
 * Deletes a project task.
 *
 * @param {string} id - The ID of the project.
 * @param {string} tId - The ID of the task to be deleted.
 * @returns {Promise} - A Promise that resolves when the task is successfully deleted.
 */
const deleteProjectTasks = (id, tId) => client.delete(`/projects/${id}/tasks/${tId}`);

/**
 * Retrieves templates based on project ID and template category.
 *
 * @param {string} id - The ID of the project.
 * @param {string} cat - The template category.
 * @returns {Promise<Object>} - A promise that resolves to an object containing the templates.
 */
const getTemplates= (id, cat) => client.get(`/projects/${id}/template?template=${cat}`)

/**
 * Retrieve the project structure for a given project ID.
 *
 * @param {string|number} id - The ID of the project.
 * @returns {Promise} - A Promise that resolves with the project structure.
 */
const getProjectStructure = (id) => client.get(`/projects/${id}/structure`);

/**
 * Updates the reassessment data of a project.
 *
 * @param {string} id - The ID of the project.
 * @param {Object} data - The reassessment data to be updated.
 * @returns {Promise} - A promise which resolves to the result of the PUT request.
 */
const editProjectReassessment = (id,data) => client.put(`/projects/${id}/re_estimate`, data);

/**
 * An object containing various methods to interact with project data.
 *
 * @namespace
 */
const projectsApi = {
    getAllProjects,
    getProject,
    closeProject,
    closeKingProject,
    getProjectCalculations,
    getProjectBookingDetail,

    getProjectHistory,
    getProjectMessages,
    postProjectMessages,
    deleteProjectMessages,

    getProjectTasks,
    postProjectTasks,
    editProjectTasks,
    deleteProjectTasks,

    getProjectStructure,
    getTemplates,

    editProjectReassessment,
};

export default projectsApi;
