import React, {useEffect, useState} from "react";
import {Doughnut} from "react-chartjs-2";
import usePalette from "../../../hooks/usePalette";

/**
 * A doughnut chart component that displays the distribution of hours in different categories.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.hours - The hours in different categories.
 * @returns {JSX.Element} - The rendered component.
 */
const DoughnutChart = ({hours}) => {
    const palette = usePalette();

    const [data, setData] = useState({
        labels: ["Productief", "Niet productief", "Ziek en verlof", "Overig"],
        datasets: [
            {
                data: [hours.productief, hours.nietpr, hours.vrij, 40 - (hours.productief + hours.nietpr + hours.vrij) > 0 ? 40 - (hours.productief + hours.nietpr + hours.vrij) : 0],
                backgroundColor: [
                    palette.primary,
                    palette.success,
                    palette.warning,
                    "#E8EAED",
                ],
                borderColor: "transparent",
            },
        ],
    });

    const options = {
        maintainAspectRatio: false,
        cutoutPercentage: 65,
        legend: {
            display: false,
        },
    };

    useEffect(() => {
        setData({
            labels: ["Productief", "Niet productief", "Ziek en verlof", "Overig"],
            datasets: [
                {
                    data: [hours.productief, hours.nietpr, hours.vrij, 40 - (hours.productief + hours.nietpr + hours.vrij) > 0 ? 40 - (hours.productief + hours.nietpr + hours.vrij) : 0],
                    backgroundColor: [
                        palette.primary,
                        palette.danger,
                        palette.warning,
                        "#E8EAED",
                    ],
                    borderColor: "transparent",
                },
            ],
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hours])

    return (
        <div className="chart chart-sm">
            <Doughnut data={data} options={options}/>
        </div>
    );
};

export default DoughnutChart;
