import {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {Trash} from "react-feather";
import offersApi from "../../../../api/offertes";
import notyf from "../../../../utils/notyfApi";

/**
 * Deletes a calculation for an offer.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.id - The ID of the calculation to delete.
 * @param {function} params.reload - The function to reload data after deletion.
 *
 * @return {JSX.Element} - The JSX element for the component.
 */
function OfferDeleteCalculationModel({id, reload}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDelete = async () => {
        const res = await offersApi.deleteCalculation(id)
        if (!res.ok) return notyf.error("Error " + res.status)
        reload()
        handleClose()
    }

    return (
        <>
            <Trash className={"cursor-pointer"} onClick={handleShow}/>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> Verwijderen? </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Weet je het zeker?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Niet aanpassen
                    </Button>
                    <Button variant="danger" onClick={handleDelete}> Verwijder </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default OfferDeleteCalculationModel;
