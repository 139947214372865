import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Alert, Button, Card, Col, Container, Modal, Row} from "react-bootstrap";
import {AlertCircle, Briefcase, Flag, Home, Info, Mail, Phone, Smartphone, User} from "react-feather";
import NawContactsDetailOffersTableHandler from "../../components/algemeen/NawContactsDetailOffersTableHandler";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import nawContactsApi from "../../../api/nawContacts";
import ActivityMenuContacts from "../../components/activiteitenMenu/ActivityMenuContacts";
import Loader from "../../../components/Loader";
import NawContactsAddContactModal from "../../components/algemeen/NawContactsAddContactModal";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import notyf from "../../../utils/notyfApi";

const NawContactsDetail = () => {
    const {id} = useParams();
    const [contact, setContact] = useState(null);
    const [detailOffers, setDetailOffers] = useState([]);

    useEffect(() => {
        getNawContactData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getNawContactData = async () => {
        const res = await nawContactsApi.getNawContact(id);
        if (!res.ok) return notyf.error("Error " + res.status)
        setContact(res.data.contact);
        setDetailOffers(res.data.offers);
    };

    if (!contact) return <Loader/>;
    return (
        <React.Fragment>
            <Helmet
                title={`${contact.sales_contact_firstname} ${contact.sales_contact_surname_prefix || ""} ${contact.sales_contact_lastname}`}/>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3"> {contact.sales_contact_firstname} {contact.sales_contact_surname_prefix || null} {contact.sales_contact_lastname}
                    <GuardWrapper role={"write:naw"}>
                        <NawContactsAddContactModal exists={contact}
                                                    reload={getNawContactData}/>
                        {contact.sales_contact_active && <DeleteModal contact={contact}/>}
                    </GuardWrapper>
                </h1>
                {!contact.sales_contact_active && <Alert variant={"danger"}>
                    <div className="alert-icon">
                        <AlertCircle/>
                    </div>
                    <div className="alert-message" style={{fontSize: 14}}>
                        <strong>Inactief</strong>
                    </div>
                </Alert>}
                <Row>
                    <Col xl="6">
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Zakelijke details
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <User/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Naam
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {contact.sales_contact_title} {contact.sales_contact_firstname} {contact.sales_contact_surname_prefix || null} {contact.sales_contact_lastname}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Briefcase/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Bedrijf
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <NavLink
                                            to={`/account/${contact.sales_contact_account_id}`}>{contact.sales_account_name}</NavLink>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Smartphone/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Zakelijke nummer
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {contact.sales_contact_mobile_nr}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Mail/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Email
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <a href={"mailto:" + contact.sales_contact_email}>{contact.sales_contact_email}</a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Info/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Functie
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {contact.sales_contact_job_title}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* Rechterkolom 1e rij */}
                    <Col xl="6">
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Persoonlijke details
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Flag/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Woonplaats
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {`${contact.sales_contact_personal_postal_code || ""} 
                                        ${contact.sales_contact_personal_city ? contact.sales_contact_personal_city + "," : ""} 
                                        ${contact.sales_contact_personal_province || ""} 
                                        ${contact.sales_contact_personal_country || ""}`}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Home/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Adres
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {contact.sales_contact_personal_street} {contact.sales_contact_personal_street_nr}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Phone/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Persoonlijke nummer
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {contact.sales_contact_personal_phone_nr}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Mail/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Persoonlijke email
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {contact.sales_contact_personal_email}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {/* Volgende rij */}
                <Row>
                    <Col xl="6">
                        <ActivityMenuContacts id={id} contact={contact}/>
                    </Col>
                    <Col xl="6">
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Offertes
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <NawContactsDetailOffersTableHandler detailOffers={detailOffers}/>
                            </Card.Body>
                        </Card>
                    </Col></Row>
            </Container>
        </React.Fragment>
    );
};


export default NawContactsDetail;

const DeleteModal = ({contact}) => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDelete = async () => {
        const res = await nawContactsApi.deleteNawContact(contact.sales_contact_id)
        if (!res.ok) return notyf.error("Error " + res.status)
        navigate("/account_overview")
    }

    return (
        <>
            <Button variant={"danger"} className={"me-2 float-end mb-0 btn-576-float-none"} onClick={handleShow}>
                Deactiveren
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> Deactiveren </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Weet je zeker dat
                    je {contact.sales_contact_firstname} {contact.sales_contact_surname_prefix || null} {contact.sales_contact_lastname} wilt
                    deactiveren?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Annuleren
                    </Button>
                    <Button variant="danger" onClick={handleDelete}> Deactiveren </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}