import {useState} from "react";
import {Col, InputGroup, Button, Modal, Form, Row} from "react-bootstrap";
import {Form as FormikForm, FormField, SubmitButton} from "../../../components/forms/index"
import * as Yup from 'yup';
import productsApi from "../../../api/products";
import {useParams} from "react-router-dom";

/**
 * Renders a modal for editing or creating a product item.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.product - The product item to be edited. If null, a new product item will be created.
 * @param {function} props.reload - A function to reload the product list after saving the changes.
 *
 * @returns {JSX.Element} The rendered modal component.
 */
function ProductItemModal({product, reload}) {
    const {id} = useParams();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const productData = async (sid, data) => {
        if (product) {
            //hij bestaat dus edit
            const response = await productsApi.editExistingProduct(sid, data);
            if (response.ok) {
                reload()
            }
        } else {
            //Hij bestaat niet dus aanmaken
            const response = await productsApi.postNewProduct(data);
            if (response.ok) {
                reload()
            }
        }
    };

    return (
        <>
            <Button variant="primary" className={"float-end mb-0"} onClick={handleShow}>
                {product ? "Aanpassen" : "+"}
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> {product ? "Bestaand product aanpassen - " + product.product_id + " " + product.product_name : "Nieuw product aanmaken"}
                    </Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={{
                        product_name: product?.product_name || "",
                        product_description: product?.product_description || "",
                        supplier_id: product?.supplier_id || "",
                        product_cost: product?.product_cost || 0,
                        product_list: product?.product_list || 0,
                        product_comment: product?.product_comment || "",
                        product_di: product?.product_di || 0,
                        product_ai: product?.product_ai || 0,
                        product_do: product?.product_do || 0,
                        product_ao: product?.product_ao || 0,
                        product_panel_opp: product?.product_panel_opp || 0,
                        product_levcode: product?.product_levcode || "",
                    }}

                    // Hier is het validatieschema van alle velden
                    validationSchema={Yup.object({
                        product_name: Yup.string()
                            .max(250, 'Moet minder dan 250 karakters zijn')
                            .trim()
                            .required('Productnaam moet ingevuld worden'),
                        product_description: Yup.string()
                            .trim()
                            .required('Productomschrijving moet ingevuld worden'),
                        supplier_id: Yup.string()
                            .trim()
                            .required('Leverancier moet ingevuld worden')
                            .max(100, 'Moet minder dan 100 karakters zijn'),
                        product_cost: Yup.number()
                            .min(0,'Het moet een positief getal zijn')
                            .required('Netto kosten moeten ingevuld worden'),
                        product_list: Yup.number()
                            .min(0, 'Het moet een positief getal zijn')
                            .required('Bruto kosten moeten ingevuld worden'),
                        product_comment: Yup.string()
                            .trim(),
                        product_di: Yup.number()
                            .required('DI moet ingevuld worden')
                            .min(0, 'Het moet een positief getal zijn'),
                        product_ai: Yup.number()
                            .required('AI moet ingevuld worden')
                            .min(0, 'Het moet een positief getal zijn'),
                        product_do: Yup.number()
                            .required('DO moet ingevuld worden')
                            .min(0, 'Het moet een positief getal zijn'),
                        product_ao: Yup.number()
                            .required('AO moet ingevuld worden')
                            .min(0, 'Het moet een positief getal zijn'),
                        product_panel_opp: Yup.number()
                            .min(0, 'Het moet een positief getal zijn')
                            .required('Paneeloppervlakte moet ingevuld worden'),
                        product_levcode: Yup.string()
                            .trim()
                            .max(255, 'Moet minder dan 255 karakters zijn'),
                    })}

                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(false);
                        let valuesparsed = {
                            product_name: values.product_name,
                            product_description: values.product_description,
                            supplier_id: values.supplier_id,
                            product_cost: parseFloat(values.product_cost),
                            product_list: parseFloat(values.product_list),
                            product_comment: values.product_comment,
                            product_di: parseInt(values.product_di),
                            product_ai: parseInt(values.product_ai),
                            product_do: parseInt(values.product_do),
                            product_ao: parseInt(values.product_ao),
                            product_panel_opp: parseInt(values.product_panel_opp),
                            product_levcode: values.product_levcode,
                        };
                        productData(id, valuesparsed);
                        reload()
                        handleClose()
                    }}
                >
                    <Modal.Body>
                        <FormField name={"product_name"}
                                   label={"Productnaam:"}
                                   width={"100%"}
                                   placeholder="Vul de productnaam hier in"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"product_description"}
                                   label={"Productbeschrijving"}
                                   width={"100%"}
                                   placeholder={"Vul de productbeschrijving hier in"}
                                   as={"textarea"}
                                   rows={5}
                                   className={"mb-3"}
                        />
                        <FormField name={"supplier_id"}
                                   label={"Leverancier:"}
                                   width={"100%"}
                                   placeholder={"Vul hier de leverancier, volledig in hoofdletters, in"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <Row>
                            <Col xs="6">
                                <Form.Label htmlFor="inlineFormInputGroup">Netto:</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>€</InputGroup.Text>
                                    <FormField name={"product_cost"}
                                               width={"100%"}
                                               placeholder={"Vul hier de netto prijs in € in"}
                                               control={"input"}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs="6">
                                <Form.Label htmlFor="inlineFormInputGroup">Bruto:</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>€</InputGroup.Text>
                                    <FormField name={"product_list"}
                                               width={"100%"}
                                               placeholder={"Vul hier de bruto prijs in € in"}
                                               control={"input"}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <FormField name={"product_comment"}
                                   label={"Opmerking:"}
                                   width={"100%"}
                                   placeholder={"Vul hier een eventuele opmerking in"}
                                   className={"mb-3"}
                                   rows={3}
                                   as={"textarea"}
                        />
                        <hr/>
                        <Row>
                            <Col xs="3">
                                <FormField name={"product_di"}
                                           label={"DI:"}
                                           width={"100%"}
                                           placeholder={"0"}
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                            <Col xs="3">
                                <FormField name={"product_ai"}
                                           label={"AI:"}
                                           width={"100%"}
                                           placeholder={"0"}
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                            <Col xs="3">
                                <FormField name={"product_do"}
                                           label={"DO:"}
                                           width={"100%"}
                                           placeholder={"0"}
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                            <Col xs="3">
                                <FormField name={"product_ao"}
                                           label={"AO:"}
                                           width={"100%"}
                                           placeholder={"0"}
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                <FormField name={"product_panel_opp"}
                                           label={"Panneel oppervlakte:"}
                                           width={"100%"}
                                           placeholder={"0"}
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                            <Col xs="6">
                                <FormField name={"product_levcode"}
                                           label={"Leverancier bestelcode:"}
                                           width={"100%"}
                                           placeholder={"Optioneel"}
                                           className={"mb-3"}
                                           control={"input"}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Niet aanpassen
                        </Button>
                        <SubmitButton title={"Opslaan"} variant="primary"/>
                    </Modal.Footer>
                </FormikForm>
            </Modal>
        </>
    );
}

export default ProductItemModal;
