import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import serviceProjectApi from "../../../api/service";
import {Edit2, Trash} from "react-feather";
import {Button, Modal, Row} from "react-bootstrap";
import {Form as FormikForm, FormField, FormFieldSelect, SubmitButton} from "../../../components/forms";
import * as Yup from "yup";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import notyf from "../../../utils/notyfApi";
import {TextWithCopy} from "../../../utils/usefullFunctions";

/**
 * Renders a table of service detail controllers.
 *
 * @returns {JSX.Element} - The rendered table.
 */
function ServiceDetailControllerTable() {
    const {id} = useParams()
    const [controllers, setControllers] = useState([])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const resLogin = await serviceProjectApi.getServiceProjectController(id)
        if (!resLogin.ok) return notyf.error("Error " + resLogin.status)
        setControllers(resLogin.data)
    }

    const columns = [
        {
            dataField: "service_project_ip_controller_id",
            text: "id",
            hidden: true
        },
        {
            dataField: "service_project_ip_controller_ip_v",
            text: "IP",
            formatter: c => <TextWithCopy text={c}/>
        },
        {
            dataField: "service_project_ip_controller_name",
            text: "Naam",
        },
        {
            dataField: "service_project_ip_controller_software_version",
            text: "Software",
        },
        {
            dataField: "Opties",
            text: "Opties",
            formatter: (_c, r) => <><ControllerModal id={id} exists={r} reload={getData}/> <Trash
                onClick={() => deleteItem(r.service_project_ip_controller_id)}
                className={"cursor-pointer"}/></>
        },

    ];

    const deleteItem = async (rId) => {
        const res = await serviceProjectApi.deleteServiceProjectController(id, rId)
        if (!res.ok) return notyf.error("Error " + res.status)
        getData()
    }

    return (
        <ToolkitProvider keyField="service_project_ip_controller_id" data={controllers} columns={columns} search>
            {(props) => (
                <div>
                    <h4 className={"mb-3"} style={{height: "32px", lineHeight: "32px"}}>Controllers <GuardWrapper
                        role={"write:service"}>
                        <ControllerModal id={id} exists={false} reload={getData}/>
                    </GuardWrapper>
                    </h4>
                    {controllers.length ? <BootstrapTable
                        {...props.baseProps}
                    /> : <Row className="justify-content-center align-self-center w-100 text-center">
                        Er zijn geen controllers gevonden.
                    </Row>}
                </div>
            )}
        </ToolkitProvider>
    );
}

export default ServiceDetailControllerTable;

/**
 * ControllerModal is a component that displays a modal for editing or adding an IP controller.
 * @param {Object} props - The properties of the component.
 * @param {number} props.id - The ID of the service project.
 * @param {Object|null} props.exists - Information about an existing IP controller. If null, a new IP controller will be added.
 * @param {Function} props.reload - A function to reload the data after making changes.
 * @returns {JSX.Element} The ControllerModal component.
 */
const ControllerModal = ({id, exists, reload}) => {
    const [show, setShow] = useState(false);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (show) getData()
    }, [show])

    const getData = async () => {
        const resDdc = await serviceProjectApi.getDDC()
        if (!resDdc.ok) return notyf.error("Error " + resDdc.status)
        let temp = [{
            value: "",
            label: "-"
        }]
        for (let i of resDdc.data) {
            temp.push({
                value: i.service_project_ddc_software,
                label: i.service_project_ddc_software
            })
        }
        setOptions(temp)
    }
    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(false);
        let res
        if (exists) {
            res = await serviceProjectApi.editServiceProjectController(id, exists.service_project_ip_controller_id, values)
        } else {
            res = await serviceProjectApi.postServiceProjectController(id, values)
        }
        if (!res.ok) return notyf.error("Error " + res.status)
        reload()
        setShow(false)
    }
    return (
        <>
            {exists ? <Edit2 className={"cursor-pointer"} onClick={() => setShow(!show)}/> :
                <Button className={"float-end"} onClick={() => setShow(!show)}>+</Button>}
            <Modal show={show} onHide={() => setShow(!show)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {exists ? "IP Aanpassen" : "IP Toevoegen"}
                    </Modal.Title>
                </Modal.Header><FormikForm
                initialValues={{
                    service_project_ip_controller_ip_v: exists?.service_project_ip_controller_ip_v || "",
                    service_project_ip_controller_name: exists?.service_project_ip_controller_name || "",
                    service_project_ip_controller_comment: exists?.service_project_ip_controller_comment || "",
                    service_project_ip_controller_software_version: exists?.service_project_ip_controller_software_version || "",
                }}

                // Hier is het validatieschema van alle velden
                validationSchema={Yup.object({
                    service_project_ip_controller_ip_v: Yup.string().required('IP moet ingevuld worden'),
                    service_project_ip_controller_name: Yup.string().required('Naam moet ingevuld worden'),
                    service_project_ip_controller_comment: Yup.string(),
                    service_project_ip_controller_software_version: Yup.string(),
                })}
                onSubmit={handleSubmit}
            >
                <Modal.Body>
                    <FormField name={"service_project_ip_controller_ip_v"}
                               label={"IP"}
                               width={"100%"}
                               placeholder="Vul hier het IP adres in"
                               className={"mb-3"}
                               control={"input"}
                    />
                    <FormField name={"service_project_ip_controller_name"}
                               label={"Naam"}
                               width={"100%"}
                               placeholder={"Vul hier de naam in"}
                               className={"mb-3"}
                               control={"input"}
                    />
                    <FormFieldSelect name={"service_project_ip_controller_software_version"}
                                     label={"Software"}
                                     width={"100%"}
                                     placeholder={"Vul hier eventueel de software in"}
                                     className={"mb-3"}
                                     control={"input"}
                                     options={options}
                    />
                    <FormField name={"service_project_ip_controller_comment"}
                               label={"Opmerking"}
                               width={"100%"}
                               placeholder={"Vul hier eventueel een opmerking in"}
                               className={"mb-3"}
                               control={"input"}
                               as={"textarea"}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShow(false)}>
                        Annuleren
                    </Button>
                    <SubmitButton title={"Opslaan"} variant="primary"/>
                </Modal.Footer>
            </FormikForm>
            </Modal>
        </>
    )
}