import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Button, Modal, Row} from "react-bootstrap";
import serviceProjectApi from "../../../api/service";
import {Edit2, Trash} from "react-feather";
import * as Yup from "yup";
import {Form as FormikForm, FormField, SubmitButton} from "../../../components/forms";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import notyf from "../../../utils/notyfApi";
import {parseLink} from "../../../utils/usefullFunctions";

/**
 * Renders a table displaying service detail connection installation data.
 *
 * @param {Object} options - The data options.
 * @param {Array} options.data - The data to be displayed in the table.
 *
 * @return {JSX.Element} The rendered table component.
 */
function ServiceDetailConnectionInstallationTable({data}) {
    const {id} = useParams()
    const [ip, setIp] = useState(data)
    const columns = [
        {
            dataField: "service_project_ip_id",
            hidden: true,
            text: "IP",
        },
        {
            dataField: "service_project_ip_v",
            text: "IP",
            formatter: c => <a href={parseLink(c)} target={"_blank"} rel="noreferrer">{c}</a>,
            sort: true,
        },
        {
            dataField: "service_project_ip_name",
            text: "Naam",
            sort: true,
        },
        {
            dataField: "Opties",
            text: "Opties",
            formatter: (_c, r) => <><IpModal id={id} exists={r} reload={getData}/> <Trash className={"cursor-pointer"}
                                                                                          onClick={() => deleteItem(r.service_project_ip_id)}/></>,
            sort: true,
        },
    ];

    const deleteItem = async (rId) => {
        const res = await serviceProjectApi.deleteServiceProjectIp(id, rId)
        if (!res.ok) return notyf.error("Error " + res.status)
        getData()
    }

    const getData = async () => {
        const resIp = await serviceProjectApi.getServiceProjectIp(id)
        if (!resIp.ok) return notyf.error("Error " + resIp.status)
        setIp(resIp.data)
    }

    return (
        <ToolkitProvider keyField="service_project_ip_id" data={ip} columns={columns} search>
            {(props) => (
                <div>
                    <h4 className={"mb-3"} style={{height: "32px", lineHeight: "32px"}}>Verbinding Installatie <IpModal
                        id={id} exists={false} reload={getData}/></h4>
                    {ip.length ? <BootstrapTable
                        {...props.baseProps}
                    /> : <Row className="justify-content-center align-self-center w-100 text-center">
                        Er zijn geen verbindingen gevonden.
                    </Row>}
                </div>
            )}
        </ToolkitProvider>
    );
}

export default ServiceDetailConnectionInstallationTable;

/**
 * Represents a component for managing IP settings.
 * @constructor
 * @param {Object} props - The properties of the component.
 * @param {string} props.id - The ID of the component.
 * @param {boolean} props.exists - Indicates if the IP exists.
 * @param {function} props.reload - The function to reload the component.
 */
const IpModal = ({id, exists, reload}) => {
    const [show, setShow] = useState(false);
    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(false);
        let res
        if (exists) {
            res = await serviceProjectApi.editServiceProjectIp(id, exists.service_project_ip_id, values)
        } else {
            res = await serviceProjectApi.postServiceProjectIp(id, values)
        }
        if (!res.ok) return notyf.error("Error " + res.status)
        reload()
        setShow(false)
    }
    return (
        <>
            <GuardWrapper role={"write:service"}>
                {exists ? <Edit2 className={"cursor-pointer"} onClick={() => setShow(!show)}/> :
                    <Button className={"float-end"} onClick={() => setShow(!show)}>+</Button>}
            </GuardWrapper>
            <Modal show={show} onHide={() => setShow(!show)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {exists ? "IP Aanpassen" : "IP Toevoegen"}
                    </Modal.Title>
                </Modal.Header><FormikForm
                initialValues={{
                    service_project_ip_v: exists?.service_project_ip_v || "",
                    service_project_ip_name: exists?.service_project_ip_name || "",
                    service_project_ip_comment: exists?.service_project_ip_comment || "",
                }}

                // Hier is het validatieschema van alle velden
                validationSchema={Yup.object({
                    service_project_ip_v: Yup.string()
                        .max(250, 'Moet minder dan 250 karakters zijn')
                        .trim()
                        .required('IP moet ingevuld worden'),
                    service_project_ip_name: Yup.string()
                        .trim()
                        .required('Naam moet ingevuld worden'),
                    service_project_ip_comment: Yup.string()
                        .trim(),
                })}
                onSubmit={handleSubmit}
            >
                <Modal.Body>
                    <FormField name={"service_project_ip_v"}
                               label={"IP:"}
                               width={"100%"}
                               placeholder="Vul de IP hier in"
                               className={"mb-3"}
                               control={"input"}
                    />
                    <FormField name={"service_project_ip_name"}
                               label={"Naam:"}
                               width={"100%"}
                               placeholder={"Vul hier de naam in"}
                               className={"mb-3"}
                               control={"input"}
                    />
                    <FormField name={"service_project_ip_comment"}
                               label={"Comment:"}
                               width={"100%"}
                               placeholder={"Vul hier eventueel een opmerking in"}
                               className={"mb-3"}
                               control={"input"}
                               as={"textarea"}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShow(false)}>
                        Annuleren
                    </Button>
                    <SubmitButton title={"Opslaan"} variant="primary"/>
                </Modal.Footer>
            </FormikForm>
            </Modal>
        </>
    )
}

