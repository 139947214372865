import client from "./clients/portalClient";

/**
 * Retrieves all data entries associated with a given id from the client.
 *
 * @param {string} id - The id used to search for data entries.
 * @returns {Promise} - A promise that resolves to the result of the HTTP GET request.
 */
const getAllDataEntries = (id) => client.get(`/search?q=${id}`);


/**
 * An object representing the ElasticSearch API.
 *
 * @namespace
 * @property {function} getAllDataEntries - A function to retrieve all data entries from ElasticSearch.
 */
const elasticSearchApi = {
    getAllDataEntries,
};

export default elasticSearchApi;
