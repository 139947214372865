import React, {useEffect, useState} from "react";
import WeeklyReportDayItem from "./WeeklyReportDayItem";
import moment from "moment";

/**
 * Generates the weekly report for a specific week.
 *
 * @param {Object} options - The options object.
 * @param {boolean} options.completed - Indicates whether the report is completed.
 * @param {boolean} options.reload - Indicates whether to reload the report.
 * @param {Object} options.weekstaat - The weekstaat object.
 * @param {Array} options.userTimesheetPermissions - The permissions related to the user's timesheet.
 *
 * @return {JSX.Element} - The rendered weekly report for the given week.
 */
export default function WeeklyReportWeekPart({completed, reload, weekstaat, userTimesheetPermissions}) {
    const [dates, setDates] = useState([])// All dates in the week

    useEffect(() => {
        // When the weekstaat is changed go through the array and find the start and last day of the weekstaat
        let datesTemp = [];
        let currDate = moment(weekstaat.week_start).startOf('day');
        let lastDate = moment(weekstaat.week_end).startOf('day');

        do {
            datesTemp.push({
                date: currDate.clone().toDate(),
                weekstaat: weekstaat.timesheet.filter(date => moment(date.timesheet_date).format("D") === moment(currDate).format("D"))
            });
            currDate.add(1, "d")
        }
        while (currDate.diff(lastDate, "d") <= 0)
        setDates(datesTemp)

    }, [weekstaat]);
    return (
        <>
            {dates.map(day => <WeeklyReportDayItem
                completed={completed}
                reload={reload}
                userTimesheetPermissions={userTimesheetPermissions}
                key={day.date}
                date={day.date}
                weekstaat={day.weekstaat}
            />)}
        </>);
};