import React, {useState} from "react";
import serviceProjectApi from "../../../api/service";
import {Edit2} from "react-feather";
import {Button, Modal} from "react-bootstrap";
import {Form as FormikForm, FormField, SubmitButton} from "../../../components/forms";
import * as Yup from "yup";
import notyf from "../../../utils/notyfApi";

/**
 * Represents a modal component for editing VPN details of a service project.
 * @param {object} props - The input props.
 * @param {number} props.id - The ID of the service project.
 * @param {object} props.exists - The existing VPN details of the service project.
 * @param {function} props.reload - The function to reload the service project data.
 * @returns {JSX.Element} The VPN detail modal component.
 */
const ServiceDetailVPNModal = ({id, exists, reload}) => {
    const [show, setShow] = useState(false);
    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(false);
        const res = await serviceProjectApi.editServiceProject(id, {...exists, ...values})
        if (!res.ok) return notyf.error("Error " + res.status)
        reload()
        setShow(false)
    }

    return (
        <>
            <Edit2 className={"float-end cursor-pointer"} onClick={() => setShow(!show)}/>
            <Modal show={show} onHide={() => setShow(!show)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        VPN
                    </Modal.Title>
                </Modal.Header><FormikForm
                initialValues={{
                    service_project_vpn_tool: exists?.service_project_vpn_tool || "",
                    service_project_vpn_hyperlink: exists?.service_project_vpn_hyperlink || "",
                    service_project_vpn_ip: exists?.service_project_vpn_ip || "",
                    service_project_vpn_username: exists?.service_project_vpn_username || "",
                    service_project_vpn_password: exists?.service_project_vpn_password || "",
                    service_project_vpn_comment: exists?.service_project_vpn_comment || "",
                }}

                // Hier is het validatieschema van alle velden
                validationSchema={Yup.object({
                    service_project_vpn_tool: Yup.string(),
                    service_project_vpn_hyperlink: Yup.string(),
                    service_project_vpn_ip: Yup.string(),
                    service_project_vpn_username: Yup.string(),
                    service_project_vpn_password: Yup.string(),
                    service_project_vpn_comment: Yup.string(),
                })}
                onSubmit={handleSubmit}
            >
                <Modal.Body>
                    <FormField name={"service_project_vpn_tool"}
                               label={"Tool"}
                               width={"100%"}
                               placeholder="Vul hier de VPN tool in"
                               className={"mb-3"}
                               control={"input"}
                    />
                    <FormField name={"service_project_vpn_hyperlink"}
                               label={"Download link"}
                               width={"100%"}
                               placeholder={"Vul hier de link in"}
                               className={"mb-3"}
                               control={"input"}
                    />
                    <FormField name={"service_project_vpn_ip"}
                               label={"IP"}
                               width={"100%"}
                               placeholder={"Vul hier het ip in"}
                               className={"mb-3"}
                               control={"input"}
                    />
                    <FormField name={"service_project_vpn_username"}
                               label={"Gebruikersnaam"}
                               width={"100%"}
                               placeholder={"Vul hier het gebruikersnaam in"}
                               className={"mb-3"}
                               control={"input"}
                    />
                    <FormField name={"service_project_vpn_password"}
                               label={"wachtwoord"}
                               width={"100%"}
                               placeholder={"Vul hier het telefoonnummer in"}
                               className={"mb-3"}
                               control={"input"}
                               type={"password"}
                    />
                    <FormField name={"service_project_vpn_comment"}
                               label={"Opmerking"}
                               width={"100%"}
                               placeholder={"Vul hier een opmerking in"}
                               className={"mb-3"}
                               control={"input"}
                               as={"textarea"}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShow(false)}>
                        Annuleren
                    </Button>
                    <SubmitButton title={"Opslaan"} variant="primary"/>
                </Modal.Footer>
            </FormikForm>
            </Modal>
        </>
    )
}

export default ServiceDetailVPNModal;