import client from "./clients/portalClient";

/**
 * Retrieves all service matrix data from the server.
 *
 * @returns {Promise} - A promise that resolves with the requested service matrix data.
 */
const getAllServiceMatrixData = () => client.get(`/service_matrix`);

/**
 * Service Matrix API
 *
 * @namespace
 */
const serviceMatrixApi = {
  getAllServiceMatrixData,
};

export default serviceMatrixApi;
