import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import React, {useEffect, useState} from "react";
import {euroFormat} from "../../../utils/usefullFunctions";
import {Button, Modal, Row} from "react-bootstrap";
import projectsApi from "../../../api/projects";
import moment from "moment";
import usePalette from "../../../hooks/usePalette";
import notyf from "../../../utils/notyfApi";

/**
 * Renders a financial table for project result details.
 *
 * @param {Object} options - The options for rendering the table.
 * @param {string} options.id - The ID of the project.
 * @param {Array} options.data - The data to be displayed in the table.
 *
 * @return {JSX.Element|null} The rendered financial table or null if no data is available.
 */
function ProjectResultDetailFinancialTable({id, data}) {
    const palette = usePalette();
    const columns = [
        {
            dataField: "projects_progress_id_article_id",
            text: "id",
            hidden: true,
        },
        {
            dataField: "projects_articles_article_code",
            text: "Categorie",
            formatter: (c, r) => <ShowDetailModal id={id} cat={r.projects_progress_id_article_id}>{c}</ShowDetailModal>,
            sort: true,
        },
        {
            dataField: "sum_calculation_cost",
            text: "Gecalculeerd",
            formatter: c => euroFormat(c),
            sort: true,
        },
        {
            dataField: "sum_booking_cost",
            text: "Geboekt",
            formatter: c => euroFormat(c),
            sort: true,
        },
        {
            dataField: "percentage_booked_price",
            text: "%",
            formatter: (_c, r) => {
                if ((!parseFloat(r.sum_booking_cost) || !parseFloat(r.sum_calculation_cost))) return null
                const value = (parseFloat(r.sum_booking_cost) / parseFloat(r.sum_calculation_cost))
                return <span style={{color: value > 1 ? palette.danger : ""}}>{`${(value * 100).toFixed(0)}%`}</span>
            },
            sort: true,
        },
        {
            dataField: "sum_calculation_amount",
            text: "Uren gecalculeerd",
            formatter: c => c ? c : 0,
            sort: true,

        },
        {
            dataField: "sum_booking_amount",
            text: "Uren geboekt",
            formatter: c => c ? c : 0,
            sort: true,
        },
        {
            dataField: "percentage_booked_amount",
            text: "%",
            formatter: (_c, r) => {
                if ((!parseFloat(r.sum_booking_amount) || !parseFloat(r.sum_calculation_amount))) return null
                const value = ((parseFloat(r.sum_booking_amount) / parseFloat(r.sum_calculation_amount)) || 0)
                return <span style={{color: value > 1 ? palette.danger : ""}}>{`${(value * 100).toFixed(0)}%`}</span>
            },
            sort: true,
        },
    ];
    if (data.length > 0)
        return (
            <div>
                <BootstrapTable
                    keyField="projects_progress_id_article_id"
                    data={data}
                    columns={columns}
                    />
            </div>
        );
    else
        return (<>
            <hr/>
            <Row className="justify-content-center align-self-center w-100 text-center">
                Er zijn geen gegevens gevonden om in deze tabel weer te geven.
            </Row></>)
}

export default ProjectResultDetailFinancialTable;

/**
 * Render a modal component to show booking details.
 * @param {Object} props - The props object.
 * @param {ReactNode} props.children - The content to display as the modal trigger.
 * @param {string} props.id - The ID of the booking.
 * @param {string} props.cat - The category of the booking.
 * @returns {ReactNode} - The rendered modal component.
 */
const ShowDetailModal = ({children, id, cat}) => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (show) getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const getData = async () => {
        const res = await projectsApi.getProjectBookingDetail(id, cat);
        if (!res.ok) return notyf.error("Error " + res.status)
        setData(res.data);
    };

    const columns = [
        {
            dataField: "projects_employees_employee_name",
            text: "Door",
            sort: true,
        },
        {
            dataField: "projects_booking_amount",
            text: "#",
            formatter: c => parseFloat(c),
            sort: true,
        },
        {
            dataField: "projects_booking_cost_each",
            text: "Prijs per stuk",
            formatter: c => euroFormat(c),
            sort: true,
        },
        {
            dataField: "projects_booking_cost_total",
            text: "Prijs totaal",
            formatter: c => euroFormat(c),
            sort: true,
        },
        {
            dataField: "projects_booking_description",
            text: "Opmerking",
            sort: true,
        },
        {
            dataField: "projects_booking_timestamp",
            text: "Aangemaakt op",
            formatter: c => moment(c).format("DD-MM-YYYY HH:mm"),
            sort: true,
        },
    ];

    return (
        <>
            <span onClick={handleShow} className={"cursor-pointer make-underlined"} style={{color: "#1d82cc"}}>
                {children}
            </span>
            <Modal show={show} dialogClassName={"modal-75w"} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Boekingen
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ToolkitProvider
                        keyField="projects_booking_timestamp"
                        data={data || []}
                        columns={columns}
                        search
                    >
                        {(props) => (
                            <div>
                                {data.length ? <BootstrapTable
                                    {...props.baseProps}
                                    pagination={paginationFactory()}

                                /> : <Row className="justify-content-center align-self-center w-100 text-center">
                                    Er zijn geen boekingen gevonden.
                                </Row>}
                            </div>
                        )}
                    </ToolkitProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        sluiten
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}