import client from "./clients/portalClient";

/**
 * Retrieves all dates for productivity.
 *
 * @returns {Promise} A promise that resolves to the response from the API.
 */
const getALlDates = () => client.get(`/productivity`);
/**
 * Sends post request to server with productivity data.
 *
 * @param {Object} data - The productivity data to be sent.
 * @returns {Promise} - A promise that represents the result of the post request.
 */
const postNewYearData = (data) => client.post(`/productivity`, data);
/**
 * Edit existing year data
 *
 * @param {string} id - The id of the year data to be edited
 * @param {object} data - The new year data to replace the existing data
 *
 * @returns {Promise} - A promise that resolves with the updated year data
 */
const editExistingYearData = (id, data) => client.put(`/productivity/${id}`, data);
/**
 * Deletes the year data for a given ID.
 *
 * @param {number} id - The ID of the year data to delete.
 * @returns {Promise} - A promise that resolves when the year data is deleted.
 */
const deleteYearData = (id) => client.delete(`/productivity/${id}`);

/**
 * Retrieves all the budget rules for productivity details.
 *
 * @function getEverythingForBudgetRules
 * @returns {Promise} A promise that resolves to the budget rules for productivity details.
 */
const getEverythingForBudgetRules = () => client.get(`/productivity_details`);

/**
 * Represents an API for administrating budget data.
 * @namespace
 */
const adminBudgetApi = {
    getALlDates,
    postNewYearData,
    editExistingYearData,
    deleteYearData,
    getEverythingForBudgetRules
};

export default adminBudgetApi;
