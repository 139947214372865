import React from "react";
import {useFormikContext} from "formik";
import ErrorMessage from "./ErrorMessage";
import {Form} from "react-bootstrap";
import moment from "moment";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";

/**
 * Renders a date picker field for a form.
 *
 * @function AppFormFieldDatePicker
 * @param {Object} props - The props object.
 * @param {string} props.name - The name of the form field.
 * @param {Object} props.display - The display configuration object.
 * @param {string} props.display.name - The name of the field to check for display.
 * @param {string} props.display.value - The value to match for display.
 * @param {string} props.label - The label for the field.
 * @return {JSX.Element|null} - The rendered date picker field or null if not displayed.
 */
function AppFormFieldDatePicker({name, display, label}) {
    const {setFieldTouched, setFieldValue, errors, touched, values} = useFormikContext();
    if (display) {
        if (values[display.name] !== display.value) return <></>
    }

    return (
        <>
            {label && <div><Form.Label>{label}</Form.Label></div>}
            <Flatpickr
                data-enable-time
                value={values[name].toDate()}
                style={{width: "100%", border: 1, borderColor: "#ced4da", borderStyle: "solid", borderRadius: "0.2rem"}}
                onChange={(e) => setFieldValue(name, moment(e[0]))}
                onBlur={() => setFieldTouched(name)}
                options={{
                    minuteIncrement: 30,
                    enableTime: true,
                    noCalendar: true,
                    time_24hr: true,
                }}
            />
            <ErrorMessage error={errors[name]} visible={touched[name]}/>
        </>
    );
}

export default AppFormFieldDatePicker;
