import React, {useEffect, useState} from "react";
import Chart from "react-apexcharts";
import {Card} from "react-bootstrap";
import usePalette from "../../../hooks/usePalette";
import dashboardApi from "../../../api/dashboard";
import notyfApi from "../../../utils/notyfApi";
import {euroFormat} from "../../../utils/usefullFunctions";

/**
 * Represents a Revenue Dashboard component.
 * @constructor
 */
const RevenueDashboard = () => {
    const palette = usePalette();
    const [dataObject, setDataObject] = useState({
        budgetCol: [],
        actualCol: [],
        budgetLine: [],
        actualLine: [],
        deltaLine: [],
    })

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        const budgetCol = []
        const actualCol = []
        const budgetLine = []
        const actualLine = []
        const deltaLine = []
        const res = await dashboardApi.getRevenueInfo()
        if (!res.ok) return notyfApi.error("Error " * res.status)
        for (let x of res.data) {
            budgetCol.push(parseFloat(x.periode_budget) || null)
            actualCol.push(parseFloat(x.periode_omzet) || null)
            budgetLine.push(parseFloat(x.periode_budget_cum) || null)
            actualLine.push(parseFloat(x.periode_omzet_cum) || null)
            deltaLine.push(parseFloat(x.delta) || null)
        }
        setDataObject({
            budgetCol,
            actualCol,
            budgetLine,
            actualLine,
            deltaLine,
        })
    }
    const data = [
        {
            name: "Budget",
            type: "column",
            data: dataObject.budgetCol,
        },
        {
            name: "Actual",
            type: "column",
            data: dataObject.actualCol,
        },
        {
            name: "Budget Cumulatief",
            type: "line",
            data: dataObject.budgetLine,
        },
        {
            name: "Actual Cumulatief",
            type: "line",
            data: dataObject.actualLine,
        },
        {
            name: "Delta",
            type: "line",
            data: dataObject.deltaLine,
        },
    ];

    const options = {
        chart: {
            stacked: false,
            foreColor: palette.black

        },
        stroke: {
            width: [4, 4, 4, 4, 4],
        },
        fill: {
            opacity: [1, 1, 1, 1, 1],
        },
        labels: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
        ],
        markers: {
            size: 0,
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return euroFormat(value);
                }
            },
            title: {
                text: "Euro",

            },
            min: 0,
            max: 4800000,
            tickAmount: 6
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y === "number" && !isNaN(y)) {
                        return euroFormat(y);
                    }
                    return "";
                },
            },
        },
        colors: [
            palette.primary,
            palette.success,
            palette.warning,
            palette.info,
            palette.danger,
        ],
    };

    return (
        <Card style={{height: "100%", width: "100%"}}>
            <Card.Header>
                <Card.Title><h3>Omzet</h3></Card.Title>
            </Card.Header>
            <Card.Body>
                <div className="chart">
                    <Chart options={options} series={data} type="line"/>
                </div>
            </Card.Body>
        </Card>
    );
};

export default RevenueDashboard;
