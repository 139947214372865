import React from "react";
import {Helmet} from "react-helmet-async";
import ProdDepDetailContentHandler from "../../components/dashboard/ProdDepDetailContentHandler";

const ProdDepDetail = () => {
    return (
        <React.Fragment>
            <Helmet title="Productiviteit per afdeling"/>
                <ProdDepDetailContentHandler />
        </React.Fragment>
    )
};

export default ProdDepDetail;
