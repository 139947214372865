import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import React from "react";
import {NavLink} from "react-router-dom";
import {Row} from "react-bootstrap";

/**
 * Renders a table for displaying active debtor projects.
 *
 * @param {object} options - The options for rendering the table.
 * @param {array} options.data - The data array containing the debtor projects.
 *
 * @return {React.Component} - The rendered table component.
 */
function DebtorsDetailActiveProjectTable({data}) {
    const {SearchBar} = Search;
    const columns = [
        {
            dataField: "project_id",
            text: "id",
            hidden: true
        },
        {
            dataField: "project_name",
            text: "Project",
            formatter: (c, r) => <NavLink to={`/project/${r.project_id}`}>{c}</NavLink>,
            sort: true,
        },
        {
            dataField: "projects_employees_employee_name",
            text: "Projectleider",
            sort: true,
        },
    ];

    if (data.length === 0) return <Row className="justify-content-center align-self-center w-100 text-center">
        Er zijn geen actieve projecten gevonden.
    </Row>

    return (
        <ToolkitProvider
            keyField="project_id"
            data={data}
            columns={columns}
            search
        >
            {(props) => (
                <div>
                    <SearchBar srText={null}
                               {...props.searchProps}
                               placeholder={"Filter: Typ om te zoeken"}/>
                    <hr/>
                    <BootstrapTable
                        {...props.baseProps}
                        pagination={paginationFactory()}
                    />
                </div>
            )}
        </ToolkitProvider>
    );
}

export default DebtorsDetailActiveProjectTable;
