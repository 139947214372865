import client from "./clients/portalClient";

/**
 * Retrieves all watchdog projects from the server.
 *
 * @function getAllWatchdogs
 * @returns {Promise} A promise that resolves to the response object containing the list of watchdog projects.
 * @throws {Error} If the server request fails.
 */
const getAllWatchdogs = () => client.get(`/watchdog_projects`);
/**
 * Edit an existing watchdog project
 *
 * @param {string} id - The ID of the watchdog project to be edited
 * @param {object} data - The data to update the watchdog project with
 * @returns {Promise} - A Promise that resolves to the updated watchdog project
 */
const editExistingWatchdog = (id, data) => client.put(`/watchdog_projects/${id}`, data);
/**
 * Creates a new watchdog project.
 *
 * @param {Object} data - The data required to create the watchdog project.
 * @returns {Promise} A promise that resolves to the new watchdog project response from the server.
 */
const postNewWatchdog = (data) => client.post(`/watchdog_projects`, data);

/**
 * Represents a Watchdog API for managing watchdogs.
 *
 * @namespace
 */
const watchdogApi = {
  getAllWatchdogs,
  editExistingWatchdog,
  postNewWatchdog,
};

export default watchdogApi;
