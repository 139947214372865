import client from "./clients/portalClient";

/**
 * Retrieves all GMs from the GM Dashboard.
 *
 * @function getAllGms
 * @returns {Promise} A promise that resolves to the response from the server.
 */
const getAllGms = () => client.get(`/gm_dashboard`);
/**
 * The gmApi object provides access to various GM (Game Master) functionalities.
 *
 * @namespace
 * @name gmApi
 */
const gmApi = {
    getAllGms,
};

export default gmApi;
