import {Button, Card, Col, Form, Modal, Row} from "react-bootstrap";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Trash} from "react-feather";
import productsApi from "../../../api/products";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import notyf from "../../../utils/notyfApi";

/**
 * A component for displaying activity menu and handling messages related to the activity.
 *
 * @param {object} props - The props object containing the following properties:
 *   - id: The id of the activity.
 *   - order: The order related to the activity.
 *
 * @returns {React.Element} The rendered component.
 */
const ActivityMenu = ({id, order}) => {
    const {user} = useAuth0()
    const [messages, setMessages] = useState([])
    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order])

    const getData = async () => {
        const resMessages = await productsApi.getProductOrderMessages(id)
        if (!resMessages.ok) return notyf.error("Error " + resMessages.status)

        setMessages(resMessages.data)
    }
    const deleteMessage = async (sId) => {
        const r = await productsApi.deleteProductOrderMessages(id, sId)
        if (!r.ok) return notyf.error("Error " + r.status)

        getData()
    }
    return (<Card>
        <Card.Header>
            <Card.Title>
                Opmerkingen
            </Card.Title>
        </Card.Header>
        <Card.Body>
            <Row><Col> <GuardWrapper role={"write:products"}><AddMessageModal id={id} reload={getData}/>
            </GuardWrapper></Col></Row>
            {!messages.length && <Row className="justify-content-center align-self-center w-100 text-center">
        Er zijn geen opmerkingen gevonden.
    </Row>}
            {messages?.map(m => <div key={m.product_order_comment_id} className={"mt-1"}>

                <div className="d-flex">
                    <div className="flex-grow-1">
                        <strong>{m.user_name}</strong>
                        <br/>
                        {m.user_name === user.name && <Trash onClick={() => deleteMessage(m.product_order_comment_id)}
                                                             className={"cursor-pointer float-end"}/>}
                        <p>{m.product_order_comment_comment}</p>
                        <small
                            className="text-muted">{moment(m.product_order_comment_created_on).format("DD-MM-YYYY HH:mm")}</small>
                        <br/>
                    </div>
                </div>
                <hr/>
            </div>)}
        </Card.Body>
    </Card>)
}

export default ActivityMenu


/**
 * Represents a modal for adding messages.
 *
 * @param {object} AddMessageModal - The props object for AddMessageModal.
 * @param {string} AddMessageModal.id - The id of the message.
 * @param {function} AddMessageModal.reload - The reload function.
 * @returns {React.Element} The AddMessageModal component.
 */
const AddMessageModal = ({id, reload}) => {
    const [show, setShow] = useState(false);
    const [comment, setComment] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleSubmit = async () => {
        if (comment) {
            const r = await productsApi.postProductOrderMessages(id, {product_order_comment_comment: comment})
            if (!r.ok) return notyf.error("Error " + r.status)
            setComment("")
            handleClose()
            reload()
        }
    }

    return (
        <>
            <Button className={"position-absolute top-0 end-0 m-3"} onClick={handleShow}>
                +
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Berichten
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Opmerking toevoegen</Form.Label>
                        <Form.Control value={comment} onChange={e => setComment(e.target.value)} as="textarea"
                                      placeholder="Opmerking"/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Annuleren
                    </Button>
                    <Button variant="primary" disabled={!comment} onClick={handleSubmit}>
                        Toevoegen
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}
