import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Alert, Badge, Button, Card, Col, Container, Modal, Row} from "react-bootstrap";
import {
    AtSign,
    Award,
    Bell,
    BookOpen,
    Calendar,
    Check,
    Chrome,
    File,
    Home,
    Info,
    Mail,
    Phone,
    Send,
    User,
    Users,
} from "react-feather";
import OrderDetailOrderTable from "../../components/verkoop/OrderDetailOrderTable";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import productsApi from "../../../api/products";
import moment from "moment";
import ProductOrdersPakbonModal from "../../components/producten_inkoop/product_order_details/ProductOrdersPakbonModal";
import OrderBonModal from "../../components/producten_inkoop/product_order_details/OrderBonModal";
import ActivityMenuOrderDetails from "../../components/activiteitenMenu/ActivityMenuOrderDetails";
import ProductOrdersDetailModel from "../../components/producten_inkoop/product_order_details/ProductOrdersDetailModal";
import {useAuth0} from "@auth0/auth0-react";
import Loader from "../../../components/Loader";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import AddOrderModal from "../../components/producten_inkoop/product_orders/AddOrderModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolder} from "@fortawesome/free-solid-svg-icons";

const OrderDetail = () => {
    const {id} = useParams();
    const {user} = useAuth0();
    const [order, setOrder] = useState(null);
    const [orderDetailsList, setOrderDetailsList] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(order) {
            handleEdit()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order?.product_order_status])

    const getData = async () => {
        const resOrder = await productsApi.getProductOrder(id);
        if (resOrder.ok) setOrder(resOrder.data);
        const resOrderDetails = await productsApi.getAllProductOrderDetails(id);
        if (resOrderDetails.ok) setOrderDetailsList(resOrderDetails.data);
    };

    const handleEdit = async () => {
        const resOrder = await productsApi.editExistingProductOrder(id, order);
        if (resOrder.ok) getData();

    }

    const handleDelete = async () => {
        const resOrder = await productsApi.deleteProductOrder(id);
        if (resOrder.ok) navigate("/purchase_overview")
    }

    if (!order) return <Loader/>;
    return (
        <React.Fragment>
            <Helmet title={`Order - ${order.product_order_reference}`}/>
            <Container fluid className="p-0">
                <div>
                    <GuardWrapper role={"write:products"}>
                    <span className={"d-576-flex d-576-flex-even added-tiny-margin-top"}>
                    <Button href={order.project_document_link}
                            disabled={!order.project_document_link}
                            variant={!order.project_document_link ? "outline-secondary" : "secondary"}
                            style={{cursor: !order.project_document_link ? "not-allowed" : ""}}
                            target={"_blank"}
                            rel={"noreferrer"}
                            className={"float-end mx-1 btn-576-float-none"}
                    >
                        <FontAwesomeIcon icon={faFolder} className={"me-1"}/>
                        Documenten
                    </Button>
                    <ProductOrdersPakbonModal order={order} orderDetailsList={orderDetailsList}/>
                    <OrderBonModal order={order} orderDetailsList={orderDetailsList}/>
                        </span>
                        <span className={"d-576-flex d-576-flex-even added-tiny-margin-top"}>
                    {(order.product_order_status === "geleverd" || order.product_order_status === "retour" || order.product_order_status === "incompleet") ?
                        <>
                            <Button className={"float-end btn-576-float-none mx-1"}
                                    onClick={() => setOrder({
                                        ...order,
                                        product_order_status: "besteld"
                                    })}>Besteld</Button>
                            {user.name === order.product_order_contact && <DeleteModal task={handleDelete}/>}
                        </> :
                        <>
                            <Button className={"float-end btn-576-float-none mx-1"}
                                    onClick={() => setOrder({
                                        ...order,
                                        product_order_status: "geleverd"
                                    })}>Geleverd</Button>
                            <Button className={"float-end btn-576-float-none mx-1"}
                                    onClick={() => setOrder({...order, product_order_status: "retour"})}>Retour</Button>
                            <Button variant={"danger"} className={"float-end btn-576-float-none mx-1"}
                                    onClick={() => setOrder({
                                        ...order,
                                        product_order_status: "incompleet"
                                    })}>Incompleet</Button>
                        </>
                    }</span>
                    </GuardWrapper>
                    <h1 className="h3 mb-3">Order - {order.product_order_reference}</h1>
                </div>
                {order.product_order_status === "geleverd" && <Alert variant={"success"}>
                    <div className="alert-icon">
                        <Check/>
                    </div>
                    <div className="alert-message" style={{fontSize: 14}}>
                        <strong>Geleverd</strong>
                    </div>
                </Alert>}
                {order.product_order_status === "incompleet" && <Alert variant={"secondary"}>
                    <div className="alert-icon">
                        <Bell/>
                    </div>
                    <div className="alert-message" style={{fontSize: 14}}>
                        <strong>Incompleet</strong>
                    </div>
                </Alert>}
                {order.product_order_status === "retour" && <Alert variant={"danger"}>
                    <div className="alert-icon">
                        <Send/>
                    </div>
                    <div className="alert-message" style={{fontSize: 14}}>
                        <strong>Retour</strong>
                    </div>
                </Alert>}
                {order.product_order_status === "besteld" && <Alert variant={"info"}>
                    <div className="alert-icon">
                        <BookOpen/>
                    </div>
                    <div className="alert-message" style={{fontSize: 14}}>
                        <strong>Besteld</strong>
                    </div>
                </Alert>}
                <Row>
                    <Col xl="8">
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Orders
                                    {order.product_order_status === "incompleet" &&
                                        <ProductOrdersDetailModel order={order} reload={getData}/>}
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {orderDetailsList &&
                                    <OrderDetailOrderTable order={order} reload={getData} list={orderDetailsList}/>}
                            </Card.Body>
                        </Card>
                        <ActivityMenuOrderDetails id={id} order={order}/>
                    </Col>
                    <Col xl="4">
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Order
                                    <AddOrderModal exists={order} reload={getData}/>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Info/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Naam en project
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <NavLink
                                            to={`/project/${order.product_order_project_id}`}>{order.project_name}</NavLink>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <File/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Projectnummer
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <NavLink
                                            to={`/project/${order.product_order_project_id}`}>{order.project_code}</NavLink>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <User/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Ordereigenaar
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {order.product_order_contact}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Award/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Urgentie
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <Badge>{order.product_order_priority}</Badge>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Calendar/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Aanvraagdatum
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {moment(order.product_order_order_date).format("DD/MM/YYYY")}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Leverancier
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Users/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Bedrijf
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">

                                        <NavLink
                                            to={`/supplier/${order.product_order_supplier}`}>{order.product_supplier_name}</NavLink>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <AtSign/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Contact email
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <a href={`mailto:${order.product_supplier_email}`}>{order.product_supplier_email}</a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Phone/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Telefoon
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {order.product_supplier_phone}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Chrome/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Webadres
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <a target="_blank" rel="noreferrer"
                                           href={`${order.product_supplier_website}`}>{order.product_supplier_website}</a>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Verzend adres
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Users/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Aanvraagbedrijf
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {order.product_order_customer}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <User/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Aanvrager
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {order.product_order_contact}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Home/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Adres
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {order.product_order_address ? order.product_order_address + "," : ""}
                                        {order.product_order_postcode ? order.product_order_postcode + "," : ""}
                                        {order.product_order_city}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Mail/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Email
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {order.product_order_email ? order.product_order_email : "Geen email adres beschikbaar"}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
};

export default OrderDetail;

const DeleteModal = ({task}) => {
    const [show, setShow] = useState(false);
    return <>
        <Button variant={"danger"} className={"float-end btn-576-float-none mx-1"}
                onClick={() => setShow(!show)}>Verwijderen</Button>
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header>
                <Modal.Title>Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Weet u het zeker?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    setShow(false)
                    task()
                }} className={"float-end"}>Verwijderen</Button>
                <Button onClick={() => setShow(!show)} variant={"secondary"} className={"float-end"}>Annuleren</Button>
            </Modal.Footer>
        </Modal>
    </>
}