import React from "react";
import {useFormikContext} from "formik";

import ErrorMessage from "./ErrorMessage";
import {Form} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import {localeDatapicker} from "../../constants";

/**
 * Renders a date picker form field component.
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.name - The name of the field.
 * @param {Object} props.display - The display object that determines if the field should be displayed or not.
 * @param {string} props.display.name - The name of the field that determines if display is true or false.
 * @param {string} props.display.value - The value to compare with the display field for conditional rendering.
 * @param {string} props.label - The label for the field.
 * @param {Object} props.otherProps - Additional props to be passed to the DatePicker component.
 * @returns {JSX.Element} - The rendered component.
 */
function AppFormFieldDatePicker({name, display, label, ...otherProps}) {
    const {setFieldTouched, setFieldValue, errors, touched, values} = useFormikContext();
    if (display) {
        if (values[display.name] !== display.value) return <></>
    }

    return (
        <>
            {label && <Form.Label>{label}</Form.Label>}
            <DatePicker dateFormat={"dd-MM-yyyy"}
                        locale={localeDatapicker}
                        calendarStartDay={1}
                        onBlur={() => setFieldTouched(name)}
                        selected={values[name] ? moment(values[name]).toDate() : null}
                        onChange={(date) => setFieldValue(name, date)}
                        {...otherProps}
            />
            <ErrorMessage error={errors[name]} visible={touched[name]}/>
        </>
    );
}

export default AppFormFieldDatePicker;
