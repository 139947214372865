import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Button, Card, Container} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import debtorsApi from "../../../api/debtors";
import notyf from "../../../utils/notyfApi";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import Loader from "../../../components/Loader";
import moment from "moment";
import TableViewIcon from "@mui/icons-material/TableView";
import {useExcelDownloder} from "react-xls";

const defaultFilterValue = [
    {name: 'projects_naw_company_name', operator: 'contains', type: 'string', value: ''},
    {name: 'projects_naw_phonenumber', operator: 'contains', type: 'string', value: ''},
    {name: 'projects_naw_email', operator: 'contains', type: 'string', value: ''},
];

const columns = [
    {
        name: "projects_naw_company_name",
        header: "Naam",
        render: ({value, data}) => <NavLink to={`/debtor/${data.projects_naw_id}`}>{value}</NavLink>,
        flex: 1,
    },
    {
        name: "adres",
        header: "Adres",
        render: ({data}) => <a
            href={"https://maps.google.com/?q=" + data.projects_naw_street + data.projects_naw_house_number + data.projects_naw_city}
            target="_BLANK" rel="noreferrer">
            {`${data.projects_naw_street} ${data.projects_naw_house_number}, ${data.projects_naw_city}`}
        </a>,
        flex: 1,
    },
    {
        name: "projects_naw_phonenumber",
        header: "Telefoonnummer",
        flex: 1,
    },
    {
        name: "projects_naw_email",
        header: "Email",
        render: ({value}) => <a href={`mailto:${value}`}>{value}</a>,
        flex: 1,
    },
    {
        name: "projects_naw_term_of_payment",
        header: "Betalingstermijn",
        flex: 1,
    },
];


const Debtors = () => {
    const [dataSource, setDataSource] = useState([])
    const [loading, setLoading] = useState(true);
    const {ExcelDownloder} = useExcelDownloder();

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        const res = await debtorsApi.getDebtors()
        if (!res.ok) return notyf.error("Error " + res.status)
        setDataSource({debtors: res.data})
        setLoading(false)

    }

    if (loading) return <Loader/>
    return (
        <React.Fragment>
            <Helmet title="Debiteuren"/>
            <Container fluid className="p-0">
                <Card>
                    <Card.Header>

                        <div className={"w-100 d-flex justify-content-between"}>
                            <h3 className={"mb-0"}>Debiteuren</h3>

                            <ExcelDownloder
                                data={dataSource}
                                filename={'Debiteuren ' + moment().format("DD-MM-YYYY")}
                                type={"link"}
                            >
                                <Button className={"me-1 added-tiny-margin-top remove-margin-top-mob"} variant={"secondary"}>
                                    <TableViewIcon fontSize={"small"}/>
                                </Button>
                            </ExcelDownloder>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <ReactDataGridCustom
                            idProperty="projects_naw_company_name"
                            dataSource={dataSource.debtors}
                            columns={columns}
                            pagination
                            defaultLimit={50}
                            defaultFilterValue={defaultFilterValue}
                        />
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    )
};

export default Debtors;
