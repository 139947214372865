import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {Form as FormikForm, FormField, SubmitButton} from "../../../components/forms";
import * as Yup from "yup";
import notyf from "../../../utils/notyfApi";
import {Edit2} from "react-feather";
import adminBudgetApi from "../../../api/adminBudget";

/**
 * Displays a modal for editing budget book year data.
 *
 * @param {Object} props - The props for the component.
 * @param {number} props.id - The ID of the budget book year data.
 * @param {Object} props.exists - The existing budget book year data.
 * @param {function} props.reload - The function to reload the data after editing.
 *
 * @return {React.Fragment} The BudgetBookYearEditModal component.
 */
function BudgetBookYearEditModal({id, exists, reload}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(false);

        let valuesparsed = {
            productivity_year_year: parseInt(values.productivity_year_year),
            productivity_year_productivity_percentage: parseFloat(values.productivity_year_productivity_percentage),
            productivity_year_no_productivity_percentage: parseFloat(values.productivity_year_no_productivity_percentage),
            productivity_year_holiday_percentage: parseFloat(values.productivity_year_holiday_percentage),
            productivity_year_sick_days_percentage: parseFloat(values.productivity_year_sick_days_percentage),
            productivity_year_prod_pl_percentage: parseFloat(values.productivity_year_prod_pl_percentage),
            productivity_year_prod_eng_percentage: parseFloat(values.productivity_year_prod_eng_percentage),
            productivity_year_pl_rate: parseFloat(values.productivity_year_pl_rate),
            productivity_year_eng_rate: parseFloat(values.productivity_year_eng_rate),
            productivity_year_ibs_rate: parseFloat(values.productivity_year_ibs_rate),
            productivity_year_tek_rate: parseFloat(values.productivity_year_tek_rate),
            productivity_year_werkvb_rate: parseFloat(values.productivity_year_werkvb_rate),
            productivity_year_pan_rate: parseFloat(values.productivity_year_pan_rate),
        };
        const res = await adminBudgetApi.editExistingYearData(id, {...exists, ...valuesparsed})
        if (!res.ok) return notyf.error("Error " + res.status)
        setShow(false)
        reload();
    }


    return (
        <React.Fragment>
            <Edit2 className={"float-end cursor-pointer"} onClick={handleShow}/>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Boekjaar gegevens {exists?.productivity_year_year} aanpassen</Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={{
                        productivity_year_year: exists?.productivity_year_year || "",
                        productivity_year_productivity_percentage: exists?.productivity_year_productivity_percentage || "",
                        productivity_year_no_productivity_percentage: exists?.productivity_year_no_productivity_percentage || "",
                        productivity_year_holiday_percentage: exists?.productivity_year_holiday_percentage || "",
                        productivity_year_sick_days_percentage: exists?.productivity_year_sick_days_percentage || "",
                        productivity_year_prod_pl_percentage: exists?.productivity_year_prod_pl_percentage || "",
                        productivity_year_prod_eng_percentage: exists?.productivity_year_prod_eng_percentage || "",
                        productivity_year_pl_rate: exists?.productivity_year_pl_rate || "",
                        productivity_year_eng_rate: exists?.productivity_year_eng_rate || "",
                        productivity_year_ibs_rate: exists?.productivity_year_ibs_rate || "",
                        productivity_year_tek_rate: exists?.productivity_year_tek_rate || "",
                        productivity_year_werkvb_rate: exists?.productivity_year_werkvb_rate || "",
                        productivity_year_pan_rate: exists?.productivity_year_pan_rate || "",
                    }}

                    // Hier is het validatieschema van alle velden
                    validationSchema={Yup.object({
                        productivity_year_year: Yup.number(),
                        productivity_year_productivity_percentage: Yup.number(),
                        productivity_year_no_productivity_percentage: Yup.number(),
                        productivity_year_holiday_percentage: Yup.number(),
                        productivity_year_sick_days_percentage: Yup.number(),
                        productivity_year_prod_pl_percentage: Yup.number(),
                        productivity_year_prod_eng_percentage: Yup.number(),
                        productivity_year_pl_rate: Yup.number(),
                        productivity_year_eng_rate: Yup.number(),
                        productivity_year_ibs_rate: Yup.number(),
                        productivity_year_tek_rate: Yup.number(),
                        productivity_year_werkvb_rate: Yup.number(),
                        productivity_year_pan_rate: Yup.number(),
                    })}
                    onSubmit={handleSubmit}
                >
                    <Modal.Body>
                        <FormField name={"productivity_year_year"}
                                   label={"Jaar"}
                                   width={"100%"}
                                   placeholder="Vul hier het gewenste jaar in om het aan te maken (2022, 2023)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_productivity_percentage"}
                                   label={"productieve Uren (gebruik geen %)"}
                                   width={"100%"}
                                   placeholder="Vul hier de gewenste productieve uren op jaarbasis in in procenten (40.5, 68.2 of 80.8)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_no_productivity_percentage"}
                                   label={"indirecte Uren (gebruik geen %)"}
                                   width={"100%"}
                                   placeholder="Vul hier het aantal indirecte uren op jaarbasis in in procenten (40.5, 68.2 of 80.8)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_holiday_percentage"}
                                   label={"snipperdagen Uren (gebruik geen %)"}
                                   width={"100%"}
                                   placeholder="Vul hier het aantal snipperdagen uren op jaarbasis in in procenten (40.5, 68.2 of 80.8)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_sick_days_percentage"}
                                   label={"Ziekte Uren (gebruik geen %)"}
                                   width={"100%"}
                                   placeholder="Vul hier het aantal ziekte uren op jaarbasis in in procenten (40.5, 68.2 of 80.8)"
                                   className={"mb-3"}
                                   control={"input"}
                        />

                        <FormField name={"productivity_year_prod_pl_percentage"}
                                   label={"ProdPL"}
                                   width={"100%"}
                                   placeholder="Vul hier de gewenste waarde in in procenten (40.5, 68.2 of 80.8)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_prod_eng_percentage"}
                                   label={"ProdENG"}
                                   width={"100%"}
                                   placeholder="Vul hier de gewenste waarde in in procenten (40.5, 68.2 of 80.8)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_pl_rate"}
                                   label={"PL"}
                                   width={"100%"}
                                   placeholder="Vul hier de gewenste waarde in in euro's (78.00, 67.50, 74.25)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_eng_rate"}
                                   label={"ENG"}
                                   width={"100%"}
                                   placeholder="Vul hier de gewenste waarde in in euro's (78.00, 67.50, 74.25)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_ibs_rate"}
                                   label={"IBS"}
                                   width={"100%"}
                                   placeholder="Vul hier de gewenste waarde in in euro's (78.00, 67.50, 74.25)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_tek_rate"}
                                   label={"TEK"}
                                   width={"100%"}
                                   placeholder="Vul hier de gewenste waarde in in euro's (78.00, 67.50, 74.25)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_werkvb_rate"}
                                   label={"WERKVB"}
                                   width={"100%"}
                                   placeholder="Vul hier de gewenste waarde in in euro's (78.00, 67.50, 74.25)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_year_pan_rate"}
                                   label={"PAN"}
                                   width={"100%"}
                                   placeholder="Vul hier de gewenste waarde in in euro's (78.00, 67.50, 74.25)"
                                   className={"mb-3"}
                                   control={"input"}
                        />

                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="secondary" onClick={handleClose}>
                            Niet aanpassen
                        </Button>
                        <SubmitButton title={"Aanpassen"} variant="primary"/>
                    </Modal.Footer>
                </FormikForm>
            </Modal>
        </React.Fragment>
    );
}

export default BudgetBookYearEditModal;
