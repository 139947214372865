import React, {useContext, useEffect, useState} from "react";

import SidebarNavSection from "./SidebarNavSection";
import PermissionsContext from "../../utils/context";

/**
 * Sidebar navigation component.
 *
 * @param {Object} props - Component props.
 * @param {Array} props.items - An array of items representing the sidebar sections.
 * @returns {JSX.Element} - A JSX element representing the sidebar navigation.
 */
const SidebarNav = ({items}) => {
    const {permissions} = useContext(PermissionsContext)
    const [cleanItemes, setCleanItems] = useState([])

    useEffect(() => {
        for (let item of items) {
            for (let p of item.pages) {
                if (p.children?.length) {
                    p.children = p.children.filter(c => permissions.includes(c.role) || c.role === undefined)
                }
            }
        }
        setCleanItems(items)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!permissions) return <></>

    return (
        <ul className="sidebar-nav">
            {cleanItemes &&
            cleanItemes.map((item) => (
                < SidebarNavSection
                    key={item.title}
                    pages={item.pages.filter(p => permissions.includes(p.role) || p.role === undefined)}
                    title={item.title}
                />

            ))}
        </ul>
    );
};

export default SidebarNav;
