import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import {NavLink} from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React from "react";
import moment from "moment";

/**
 * Represents a search bar component.
 *
 * @class
 * @constructor
 * @memberof module:components
 */
const {SearchBar} = Search;
/**
 * Represents the columns of a sales offer table.
 *
 * @typedef {object} Column
 * @property {string} dataField - The key used to access the data for this column from the row object.
 * @property {string} text - The display text for this column.
 * @property {function} [formatter] - A function used to format the cell value. Takes the cell value and the row object as parameters and should return a React component.
 * @property {boolean} [sort] - Indicates whether the column is sortable.
 */
const columns = [
    {
        dataField: "sales_offer_reference",
        text: "Referentie",
        formatter: (cell, row) => <NavLink
            to={`/offer/${row.sales_offer_id}`}>{row.sales_offer_reference}</NavLink>,
        sort: true,
    },
    {
        dataField: "sales_offer_name",
        text: "Offertenaam",
        formatter: (cell, row) => <NavLink
            to={`/offer/${row.sales_offer_id}`}>{row.sales_offer_name}</NavLink>,
        sort: true,
    },
    {
        dataField: "sales_offer_status",
        text: "Status",
        formatter: (cell) => {
            if (cell === "Binnengehaald") return <span className="badge bg-success">Binnengehaald</span>;
            else if (cell === "Gemist") return <span className="badge bg-danger">Gemist</span>;
            else if (cell === "Open") return <span className="badge bg-info">Open</span>;
            else return <span className="badge bg-warning text-dark">Onbekend</span>;
        },
        sort: true,
    },
    {
        dataField: "sales_offer_created_on",
        text: "Datum",
        formatter: (cell, row) => moment(row.sales_offer_created_on).format("DD MMMM YYYY"),
        sort: true,
    },
];

export default function NawCompaniesDetailsOffersTable({companyOffers}) {
    return (
        <ToolkitProvider keyField="sales_offer_reference" data={companyOffers} columns={columns} search>
            {(props) => (
                <>
                    <div tag="h5" className="mb-0 pb-4 d-flex">
                        <div className={"w-100 d-flex justify-content-between"}>
                            <SearchBar
                                className={"form-control w-100"}
                                placeholder={"Filter: Typ om te zoeken"}
                                srText={null}
                                {...props.searchProps}
                            />
                        </div>
                    </div>
                    <div>
                        <BootstrapTable
                            {...props.baseProps}
                            pagination={paginationFactory()}
                        />
                    </div>
                </>
            )}
        </ToolkitProvider>
    );
};