import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import serviceProjectApi from "../../../api/service";
import {Edit2, Trash} from "react-feather";
import {Button, Modal, Row} from "react-bootstrap";
import {Form as FormikForm, FormField, SubmitButton} from "../../../components/forms";
import * as Yup from "yup";
import {DisplayPassword, TextWithCopy} from "../../../utils/usefullFunctions";
import GuardWrapper from "../../../components/guards/GuardWrapper";

/**
 * Renders a table component that displays user details for a service.
 *
 * @param {Object} data - The user data.
 * @param {string} data.id - The ID of the service.
 *
 * @returns {JSX.Element} The rendered table component.
 */
function ServiceDetailUsersTable({data}) {
    const {id} = useParams()
    const [login, setLogin] = useState(data)
    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const resLogin = await serviceProjectApi.getServiceProjectLogin(id)
        setLogin(resLogin.data)
    }

    const columns = [
        {
            dataField: "service_project_login_id",
            text: "Gebruikersnaam",
            hidden: true,
        },
        {
            dataField: "service_project_login_username",
            text: "Gebruikersnaam",
            sort: true,
            formatter: c => <TextWithCopy text={c}/>,
        },
        {
            dataField: "service_project_login_password",
            text: "Wachtwoord",
            formatter: c => <DisplayPassword text={c}/>,
            sort: true,
        },
        {
            dataField: "service_project_login_authorization",
            text: "Rol",
            sort: true,
        },
        {
            dataField: "Opties",
            text: "Opties",
            formatter: (_c, r) => <span className={"showOn576 d-50-width"}><LoginModal id={id} exists={r}
                                                                                       reload={getData}/> <Trash
                className={"cursor-pointer"}
                onClick={() => deleteItem(r.service_project_login_id)}/></span>,
            sort: true,
        },
    ];

    const deleteItem = async (rId) => {
        const res = await serviceProjectApi.deleteServiceProjectLogin(id, rId)
        if (res.ok) getData()
    }

    return (
        <ToolkitProvider
            keyField="service_project_login_id"
            data={login}
            columns={columns}
            search
        >
            {(props) => (
                <div>
                    <h4 className={"mb-3"} style={{height: "32px", lineHeight: "32px"}}>
                        Gebruikers
                        <LoginModal id={id}
                                    exists={false}
                                    reload={getData}/>
                    </h4>
                    {data.length ? <BootstrapTable
                        {...props.baseProps}
                    /> : <Row className="justify-content-center align-self-center w-100 text-center">
                        Er zijn geen gebruikers gevonden.
                    </Row>}
                </div>
            )}
        </ToolkitProvider>
    );
}

export default ServiceDetailUsersTable;

/**
 * Represents a Login Modal component.
 *
 * @typedef {Object} LoginModal
 * @property {string} id - The ID of the login modal.
 * @property {boolean} exists - Indicates if the login modal exists.
 * @property {Function} reload - Function to reload the login modal.
 */
const LoginModal = ({id, exists, reload}) => {
    const [show, setShow] = useState(false);
    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(false);
        let res
        if (exists) {
            res = await serviceProjectApi.editServiceProjectLogin(id, exists.service_project_login_id, values)
        } else {
            res = await serviceProjectApi.postServiceProjectLogin(id, values)
        }
        if (res.ok) {
            reload()
            setShow(false)
        }
    }
    return (
        <>
            <GuardWrapper role={"write:service"}>
                {exists ? <Edit2 className={"cursor-pointer"} onClick={() => setShow(!show)}/> :
                    <Button className={"float-end"} onClick={() => setShow(!show)}>+</Button>}
            </GuardWrapper>
            <Modal show={show} onHide={() => setShow(!show)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {exists ? "IP Aanpassen" : "IP Toevoegen"}
                    </Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={{
                        service_project_login_username: exists?.service_project_login_username || "",
                        service_project_login_password: exists?.service_project_login_password || "",
                        service_project_login_authorization: exists?.service_project_login_authorization || "",
                        service_project_login_comment: exists?.service_project_login_comment || "",
                    }}

                    // Hier is het validatieschema van alle velden
                    validationSchema={Yup.object({
                        service_project_login_username: Yup.string(),
                        service_project_login_password: Yup.string()
                            .required('Wachtwoord moet ingevuld worden'),
                        service_project_login_authorization: Yup.string(),
                        service_project_login_comment: Yup.string(),
                    })}
                    onSubmit={handleSubmit}
                >
                    <Modal.Body>
                        <FormField name={"service_project_login_username"}
                                   label={"Naam"}
                                   width={"100%"}
                                   placeholder="Vul hier de naam in"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"service_project_login_password"}
                                   label={"Wachtwoord"}
                                   width={"100%"}
                                   placeholder={"Vul hier het wachtwoord"}
                                   className={"mb-3"}
                                   control={"input"}
                                   type="password"
                        />
                        <FormField name={"service_project_login_authorization"}
                                   label={"Rol"}
                                   width={"100%"}
                                   placeholder={"Vul hier eventueel de rol van de gebruiker in"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"service_project_login_comment"}
                                   label={"Opmerking"}
                                   width={"100%"}
                                   placeholder={"Vul hier eventueel een opmerking in"}
                                   className={"mb-3"}
                                   control={"input"}
                                   as={"textarea"}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => setShow(false)}>
                            Annuleren
                        </Button>
                        <SubmitButton title={"Opslaan"} variant="primary"/>
                    </Modal.Footer>
                </FormikForm>
            </Modal>
        </>
    )
}
