import client from "./clients/portalClient";

/**
 * Retrieves all products from the XCONNECT webshop.
 *
 * @returns {Promise<Response>} A Promise representing the server's response.
 */
const getAllProducts = () => client.get(`/webshop/products`);
/**
 * Retrieve a single product from the webshop.
 *
 * @param {number} id - The ID of the product to retrieve.
 * @param {Object} data - Additional data to be included in the request (optional).
 * @returns {Promise} A promise that resolves to the response from the server.
 */
const getSingleProduct = (id, data) => client.get(`/webshop/products/${id}`, data);

/**
 * The `xconnectWebshopApi` object provides methods to interact with the XCONNECT webshop API.
 *
 * @namespace xconnectWebshopApi
 */
const xconnectWebshopApi = {
    getAllProducts,
    getSingleProduct,
};

export default xconnectWebshopApi;
