import React from "react";
import classNames from "classnames";

/**
 * Renders the main component with the given class name and children.
 *
 * @param {String} className - The class name to be applied to the main component.
 * @param {Node} children - The child components or elements to be rendered inside the main component.
 *
 * @return {ReactElement} The rendered main component with the provided class name and children.
 */
const Main = ({ className, children }) => (
  <div className={classNames("main", className)}>{children}</div>
);

export default Main;
