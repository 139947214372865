import React from "react";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {NavLink} from "react-router-dom";


const {SearchBar} = Search;
/**
 * Represents a column in a data table.
 *
 * @typedef {Object} Column
 * @property {string} dataField - The name of the field in the data object that this column represents.
 * @property {string} text - The display text for this column.
 * @property {boolean} [hidden=false] - Whether this column should be hidden.
 * @property {function} [formatter] - A function to format the cell value in this column. It takes two parameters: `cell` (the cell value) and `row` (the entire row object).
 * @property {boolean} [sort=false] - Whether this column should be sortable.
 */
const columns = [
    {
        dataField: "sales_contact_id",
        text: "id",
        hidden: true,
    },
    {
        dataField: "sales_contact_firstname",
        text: "naam",
        formatter: (cell, row) => <NavLink
            to={`/contact/${row.sales_contact_id}`}>{cell} {row.sales_contact_lastname}</NavLink>,
        sort: true,
    },
    {
        dataField: "sales_contact_email",
        text: "email",
        formatter: (cell) => <a href={"mailto:" + cell}>{cell}</a>,
        sort: true,
    },
    {
        dataField: "sales_contact_mobile_nr",
        text: "telefoonnummer",
        sort: true,
    },
];

/**
 * Renders a table with contact details for a company.
 *
 * @param {Object} companyContactPersons - The contact persons of the company.
 * @return {JSX.Element} - The rendered table component.
 */
export default function NawCompaniesDetailsContactTable({companyContactPersons}) {

    return (
        <ToolkitProvider
            keyField="sales_contact_id"
            data={companyContactPersons}
            columns={columns}
            search
        >
            {(props) => (
                <div>
                    <SearchBar srText={null} {...props.searchProps} placeholder={"Filter: Typ om te zoeken"}/>
                    <hr/>
                    <BootstrapTable
                        {...props.baseProps}
                        pagination={paginationFactory()}
                    />
                </div>
            )}
        </ToolkitProvider>
    );
}









