import React from "react";
import {Row} from "react-bootstrap";
import NawCompaniesDetailsContactTable from "./NawCompaniesDetailContactTable";

/**
 * Renders a table of contact persons for a company.
 *
 * @param {Array} companyContactPersons - The array of contact persons for the company.
 *
 * @returns {React.Element} - The rendered table of contact persons, or a message indicating no contact persons found.
 */
export default function NawCompaniesDetailsContactTableHandler({companyContactPersons}) {
    //laat tabel zien met gegevens via props
    if (companyContactPersons.length > 0) return <NawCompaniesDetailsContactTable companyContactPersons={companyContactPersons}/>;
    else
        return (
            <Row className="justify-content-center align-self-center w-100 text-center">
                Geen contactpersonen van dit bedrijf gevonden.
            </Row>
        );
}

