import React, {useState, useEffect} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {Form as FormikForm, FormCheckbox, FormField, SubmitButton} from "../../../components/forms";
import * as Yup from "yup";
import notyf from "../../../utils/notyfApi";
import adminUsersApi from "../../../api/adminUsers";
import adminBudgetApi from "../../../api/adminBudget";

/**
 * Represents a modal component for adding a new productivite budget details for a single user.
 *
 * @param {Object} singleUser - The details of the user for whom the budget details are being added.
 * @param {function} reload - The function to reload the user details after adding the budget details.
 *
 * @returns {JSX.Element} The Budget Modal component.
 */
const UserAdminDetailsBudgetModal = ({singleUser, reload}) => {

    //get year data from api
    const [yearData, setYearData] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        getYear();
    }, []);

    const getYear = async () => {
        const response = await adminBudgetApi.getALlDates();
        if (!response.ok) return notyf.error("Error " + response.status)
        setYearData(response.data);
    };

    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(false);
        let valuesparsed = {
            productivity_year_f_id: parseFloat(values.productivity_year_f_id),
            productivity_department: values.productivity_department,
            productivity_fte: parseFloat(values.productivity_fte),
            productivity_productive_percentage: parseFloat(values.productivity_productive_percentage),
            "productivity_employment_month": {
                jan: values.productivity_employment_month_jan,
                feb:values.productivity_employment_month_feb,
        mrt:values.productivity_employment_month_mar,
            apr:values.productivity_employment_month_apr,
                may:values.productivity_employment_month_may,
                    jun:values.productivity_employment_month_jun,
                        jul:values.productivity_employment_month_jul,
                            aug:values.productivity_employment_month_aug,
                                sep:values.productivity_employment_month_sep,
                                    oct:values.productivity_employment_month_oct,
                                        nov:values.productivity_employment_month_nov,
                                            dec:values.productivity_employment_month_dec,
            },
            productivity_user_f_id: singleUser.user_id,
            productivity_detail_rate: parseFloat(values.productivity_detail_rate),
            productivity_detail_old_age_free_hours: parseFloat(values.productivity_detail_old_age_free_hours),
            productivity_detail_correction_sick_hours: parseFloat(values.productivity_detail_correction_sick_hours),
        };
        const res = await adminUsersApi.postUserBudgetData(valuesparsed)
        if (!res.ok) return notyf.error("Error " + res.status)
        setShow(false)
        reload()
    }

    return (
        <>
            <button className="btn btn-primary float-end" onClick={() => {
                setShow(!show);
            }}>+
            </button>
            <Modal show={show} onHide={() => setShow(!show)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Nieuwe productiviteitsregel voor {singleUser.name}
                    </Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={{
                        productivity_year_f_id: "",
                        productivity_department: "engineering",
                        productivity_fte: "",
                        productivity_productive_percentage: "",
                        productivity_employment_month_jan: true,
                        productivity_employment_month_feb: true,
                        productivity_employment_month_mar: true,
                        productivity_employment_month_apr: true,
                        productivity_employment_month_may: true,
                        productivity_employment_month_jun: true,
                        productivity_employment_month_jul: true,
                        productivity_employment_month_aug: true,
                        productivity_employment_month_sep: true,
                        productivity_employment_month_oct: true,
                        productivity_employment_month_nov: true,
                        productivity_employment_month_dec: true,
                        productivity_detail_rate: "",
                        productivity_detail_old_age_free_hours: 0,
                        productivity_detail_correction_sick_hours: 0,
                    }}

                    // Hier is het validatieschema van alle velden
                    validationSchema={Yup.object({
                        productivity_year_f_id: Yup.string(),
                        productivity_department: Yup.string(),
                        productivity_fte: Yup.number(),
                        productivity_productive_percentage: Yup.number(),
                        productivity_employment_month_jan: Yup.bool(),
                        productivity_employment_month_feb: Yup.bool(),
                        productivity_employment_month_mar: Yup.bool(),
                        productivity_employment_month_apr: Yup.bool(),
                        productivity_employment_month_may: Yup.bool(),
                        productivity_employment_month_jun: Yup.bool(),
                        productivity_employment_month_jul: Yup.bool(),
                        productivity_employment_month_aug: Yup.bool(),
                        productivity_employment_month_sep: Yup.bool(),
                        productivity_employment_month_oct: Yup.bool(),
                        productivity_employment_month_nov: Yup.bool(),
                        productivity_employment_month_dec: Yup.bool(),
                        productivity_detail_rate: Yup.number(),
                        productivity_detail_old_age_free_hours: Yup.number(),
                        productivity_detail_correction_sick_hours: Yup.number(),
                    })}
                    onSubmit={handleSubmit}
                >
                    <Modal.Body>
                        {/* formfield voor select bestaande jaren */}
                        <FormField name={"productivity_year_f_id"}
                                   label={"Jaar:"}
                                   width={"100%"}
                                   placeholder="Vul hier het gewenste jaar in om het aan te maken"
                                   className={"mb-3"}
                                   as={"select"}
                        >
                            <option value="">-</option>
                            {/* Deze kan gemaakt worden wanneer ik de bestaande jaren kan ophalen en gebruiken */}
                            {yearData?.map(item => <option key={item.productivity_year_id}
                                                           value={item.productivity_year_id}>
                                {item.productivity_year_year}
                            </option>)}
                        </FormField>
                        <FormField name={"productivity_department"}
                                   label={"afdeling:"}
                                   width={"100%"}
                                   placeholder="Vul hier de afdeling in"
                                   className={"mb-3"}
                                   as={"select"}
                        >
                            <option value="engineering">Engineering</option>
                            <option value="ict">ICT</option>
                            <option value="service">Service</option>
                            <option value="uitvoering">Uitvoering</option>
                            <option value="xconnect">X-connect</option>
                        </FormField>
                        <FormField name={"productivity_fte"}
                                   label={"fte"}
                                   width={"100%"}
                                   placeholder="Vul hier het aantal fte's in (1, 0.4 of 0.1)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_productive_percentage"}
                                   label={"productiviteit"}
                                   width={"100%"}
                                   placeholder="Vul hier het gewenste productiviteitspercentage in in procenten (40.5, 68.2 of 80.8)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        {/*<FormField name={"productivity_employment_months"}*/}
                        {/*           label={"maanden in dienst"}*/}
                        {/*           width={"100%"}*/}
                        {/*           placeholder="Vul hier het maanden in dienst van het desbetreffende jaar in als heel getal (1, 4, 12)"*/}
                        {/*           className={"mb-3"}*/}
                        {/*           control={"input"}*/}
                        {/*/>*/}
                        <label>Maanden werkzaam:</label>
                        <Row>
                            <Col lg="2">
                                <FormCheckbox name={"productivity_employment_month_jan"}
                                              label={"Jan"}
                                              className={"mb-3"}
                                />
                            </Col>
                            <Col lg="2">
                                <FormCheckbox name={"productivity_employment_month_feb"}
                                              label={"Feb"}
                                              className={"mb-3"}
                                />
                            </Col>
                            <Col lg="2">
                                <FormCheckbox name={"productivity_employment_month_mar"}
                                              label={"Mrt"}
                                              className={"mb-3"}
                                />
                            </Col>
                            <Col lg="2">
                                <FormCheckbox name={"productivity_employment_month_apr"}
                                              label={"Apr"}
                                              className={"mb-3"}
                                />
                            </Col>
                            <Col lg="2">
                                <FormCheckbox name={"productivity_employment_month_may"}
                                              label={"Mei"}
                                              className={"mb-3"}
                                />
                            </Col>
                            <Col lg="2">
                                <FormCheckbox name={"productivity_employment_month_jun"}
                                              label={"Jun"}
                                              className={"mb-3"}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="2">
                                <FormCheckbox name={"productivity_employment_month_jul"}
                                              label={"Jul"}
                                              className={"mb-3"}
                                />
                            </Col>
                            <Col lg="2">
                                <FormCheckbox name={"productivity_employment_month_aug"}
                                              label={"Aug"}
                                              className={"mb-3"}
                                />
                            </Col>
                            <Col lg="2">
                                <FormCheckbox name={"productivity_employment_month_sep"}
                                              label={"Sep"}
                                              className={"mb-3"}
                                />
                            </Col>
                            <Col lg="2">
                                <FormCheckbox name={"productivity_employment_month_oct"}
                                              label={"Okt"}
                                              className={"mb-3"}
                                />
                            </Col>
                            <Col lg="2">
                                <FormCheckbox name={"productivity_employment_month_nov"}
                                              label={"Nov"}
                                              className={"mb-3"}
                                />
                            </Col>
                            <Col lg="2">
                                <FormCheckbox name={"productivity_employment_month_dec"}
                                              label={"Dec"}
                                              className={"mb-3"}
                                />
                            </Col>
                        </Row>
                        <FormField name={"productivity_detail_rate"}
                                   label={"tarief"}
                                   width={"100%"}
                                   placeholder="Vul hier het tarief in als getal (74.50, 40.0, 103.25)"
                                   className={"mb-3"}
                                   control={"input"}
                        />

                        <FormField name={"productivity_detail_old_age_free_hours"}
                                   label={"correctie vrije uren"}
                                   width={"100%"}
                                   placeholder="Vul hier de correctie vrije uren (ouderschap/meenemen/oude dag) in als getal (5, 11.0, 13.5)"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"productivity_detail_correction_sick_hours"}
                                   label={"correctie ziekte uren"}
                                   width={"100%"}
                                   placeholder="Vul hier de correctie ziekte uren (langdurige ziekte/burnout/) in als getal (80, 116.0, 800.5)"
                                   className={"mb-3"}
                                   control={"input"}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => setShow(false)}>
                            Annuleren
                        </Button>
                        <SubmitButton title={"Opslaan"} variant="primary"/>
                    </Modal.Footer>
                </FormikForm>
            </Modal>
        </>
    )
}

export default UserAdminDetailsBudgetModal;