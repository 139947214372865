import {Button, Card} from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";
import {euroFormat} from "../../../utils/usefullFunctions";
import moment from "moment";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import filter from "@inovua/reactdatagrid-community/filter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import TableViewIcon from "@mui/icons-material/TableView";
import {useExcelDownloder} from "react-xls";

/**
 * Represents the default filter value for a set of filters.
 *
 * @type {Object[]}
 * @property {string} name - The name of the filter.
 * @property {string} operator - The operator to be used for filtering.
 * @property {string} type - The type of the filter, such as 'string', 'number', or 'select'.
 * @property {?string|number} value - The default value for the filter, which can be null, an empty string, or a number.
 */
const defaultFilterValue = [
    {name: 'Projectcode', operator: 'contains', type: 'string', value: ''},
    {name: 'Projectnaam', operator: 'contains', type: 'string', value: ''},
    {name: 'Projectleider', operator: 'eq', type: 'select', value: null},
    {name: 'Verkoop', operator: 'gte', type: 'number', value: null},
    {name: 'Calculatie', operator: 'gte', type: 'number', value: null},
    {name: 'Geboekt', operator: 'gte', type: 'number', value: null},
    {name: 'Gefactureerd', operator: 'gte', type: 'number', value: null},
    {name: 'Afgesloten', operator: 'contains', type: 'string', value: ""},
];

/**
 * Represents a list of columns for a table.
 *
 * @typedef {Object[]} Columns
 * @property {string} name - The name of the column.
 * @property {string} header - The header label of the column.
 * @property {number} flex - The flex value of the column.
 * @property {number} minWidth - The minimum width of the column.
 * @property {function} render - The function used to render the column value.
 * @property {function} filterEditor - The filter editor component used for filtering.
 * @property {Object} filterEditorProps - The props passed to the filter editor component.
 */
const columns = [
    {
        name: "Projectcode",
        header: "Projectcode",
        flex: 1,
        minWidth: 100,
    },
    {
        name: "Projectnaam",
        header: "Projectnaam",
        minWidth: 300,
        flex: 1,
        render: ({value}) => {
            if (value) return value
            return ""
        },
    },
    {
        name: "Projectleider",
        header: "Projectleider",
        flex: 1,
        minWidth: 130,
        filterEditor: SelectFilter,
        filterEditorProps: {
            placeholder: 'Alles',
            dataSource: [
                {
                    id: "Dick Bor",
                    label: "Dick Bor"
                },
                {
                    id: "Felicia Brosens",
                    label: "Felicia Brosens"
                },
                {
                    id: "John de Kroon",
                    label: "John de Kroon"
                },
                {
                    id: "Lyon van Hest",
                    label: "Lyon van Hest"
                },
                {
                    id: "Martijn Heerings",
                    label: "Martijn Heerings"
                },
                {
                    id: "Niels Leijen",
                    label: "Niels Leijen"
                },
                {
                    id: "Wilco Versteeg",
                    label: "Wilco Versteeg"
                },
                {
                    id: "Remco van Hoof",
                    label: "Remco van Hoof"
                },
            ]
        },

    },
    {
        name: "Verkoop",
        header: "Verkoop",
        render: ({value}) => {
            if (value) return euroFormat(value)
            return ""
        },
        flex: 1,
        minWidth: 125,
        filterEditor: NumberFilter,

    },
    {
        name: "Calculatie",
        header: "Calculatie",
        render: ({value}) => {
            if (value) return euroFormat(value)
            return ""
        },
        flex: 1,
        minWidth: 125,
        filterEditor: NumberFilter,

    },
    {
        name: "Geboekt",
        header: "Geboekt",
        render: ({value, data}) => {
            if (data.project_id === 2) return euroFormat(value)
            if (value) return euroFormat(value)
            return ""
        },
        flex: 1,
        minWidth: 125,
        filterEditor: NumberFilter,

    },
    {
        name: "Marge",
        header: "Marge",
        render: ({value, data}) => {
            if (data.project_id === 2) return euroFormat(value)
            if (!value) return null
            return `${(value).toFixed(2)}%`
        },
        flex: 1,
        minWidth: 80,
    },
    {
        name: "MargeAct",
        header: "Marge actueel",
        render: ({value, data}) => {
            if (data.project_id === 2) {
                return null
            }
            if (!value) return null
            return `${(value).toFixed(2)}%`
        },
        flex: 1,
        minWidth: 80,
    },
    {
        name: "Gefactureerd",
        header: "Gefactureerd",
        render: ({value}) => {
            if (value) return euroFormat(value)
            return ""
        },
        flex: 1,
        minWidth: 125,
        filterEditor: NumberFilter,

    },
    {
        name: "Afgesloten",
        header: "Afgesloten op",
        render: ({value}) => {
            if (value) {
                return moment(value).format("DD-MM-YYYY HH:mm")
            }
        },
        flex: 1,
        minWidth: 125,
        filterEditor: SelectFilter,
        filterEditorProps: {
            placeholder: 'Alles',
            dataSource: [
                {
                    id: "2023",
                    label: "2023"
                },
                {
                    id: "2022",
                    label: "2022"
                },
                {
                    id: "2021",
                    label: "2021"
                },
                {
                    id: "2020",
                    label: "2020"
                },
                {
                    id: "2019",
                    label: "2019"
                },
                {
                    id: "2018",
                    label: "2018"
                },
                {
                    id: "2017",
                    label: "2017"
                },
            ]
        },
    },
];

/**
 * Generates a GM (General Manager) table with given GM data.
 * @param {Object} gm - The GM data.
 * @returns {React.Component} - The GM table component.
 */
export default function GmTable({gm}) {
    const [dataSource, setDataSource] = useState([]);
    const [gmNew, setGmNew] = useState([]);
    const [filterValue, setFilterValue] = useState(defaultFilterValue);
    const {ExcelDownloder, setData} = useExcelDownloder();

    useEffect(() => {
        const data = []

        for (let p of gm) {
            const t = {
                project_id: p.project_id,
                Projectcode: p.project_code,
                Projectnaam: p.project_name,
                Projectleider: p.projects_employees_employee_name,
                Verkoop: parseFloat(p.project_contract_amount),
                Calculatie: parseFloat(p.calc_cost_total),
                Geboekt: parseFloat(p.booking_cost_total),
                Marge: (!parseFloat(p.project_contract_amount) || !parseFloat(p.calc_cost_total)) ? null : ((1 - (p.calc_cost_total / p.project_contract_amount)) * 100),
                MargeAct: (!parseFloat(p.project_contract_amount) || !parseFloat(p.booking_cost_total)) ? null : ((1 - (p.booking_cost_total / p.project_contract_amount)) * 100),
                Gefactureerd: parseFloat(p.billed_invoice_amount),
                Afgesloten: p.project_closed_timestamp,
            }
            data.push(t)
        }
        setGmNew(data)
        const localFilters = localStorage.getItem("gmFilters")
        if (localFilters) {
            setFilterValue(JSON.parse(localFilters))
            insertRows(filter(data, JSON.parse(localFilters)))
        } else {
            insertRows(data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const calculateSums = (data) => {
        let verkoop = 0
        let calculatie = 0
        let geboekt = 0
        let gefactureerd = 0
        for (let row of data) {
            verkoop += parseFloat(row.Verkoop || 0)
            calculatie += parseFloat(row.Calculatie || 0)
            geboekt += parseFloat(row.Geboekt || 0)
            gefactureerd += parseFloat(row.Gefactureerd || 0)
        }

        return {
            verkoop,
            calculatie,
            geboekt,
            gefactureerd
        }
    }
    const insertRows = (data) => {
        const variables = calculateSums(data)
        const insertedRows = [
            {
                project_id: 1,
                Projectcode: "",
                Projectnaam: "Totalen",
                Projectleider: "",
                Verkoop: variables.verkoop,
                Calculatie: variables.calculatie,
                Geboekt: variables.geboekt,
                Marge: "",
                MargeAct: "",
                Gefactureerd: variables.gefactureerd,
                Afgesloten: "",
            },
            {
                project_id: 2,
                Projectcode: "",
                Projectnaam: "Marge berekend",
                Projectleider: "",
                Verkoop: "",
                Calculatie: variables.gefactureerd - variables.geboekt,
                Geboekt: variables.verkoop - variables.calculatie,
                Marge: (variables.gefactureerd - variables.geboekt) - (variables.verkoop - variables.calculatie),
                MargeAct: `${(((1 - (variables.geboekt / variables.verkoop)) * 100) - ((1 - (variables.calculatie / variables.verkoop)) * 100)).toFixed(2)}% `,
                Gefactureerd: "",
                Afgesloten: "",
            },
        ];
        setDataSource([...insertedRows, ...data])
    }
    const onFilterValueChange = useCallback((f) => {
        const data = filter(gmNew, f)
        setFilterValue(f);
        insertRows(data);
        localStorage.setItem("gmFilters", JSON.stringify(f))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gmNew])

    const onRenderRow = (rowProps) => {
        if (rowProps.data.project_id <= 2) {
            rowProps.className = 'InovuaReactDataGrid__column-header__content'
        }
    }

    useEffect(() => {
        // key value pairs sheets van excel, content
        setData({projecten: dataSource.slice(2)})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource])

    return (
        <Card>
            <Card.Header>
                <h3 className={"mb-0"}>GM
                    <ExcelDownloder
                        data={dataSource}
                        filename={'GM ' + moment().format("DD-MM-YYYY")}
                        type={"link"}
                    >
                        <Button className={"float-end"} variant={"secondary"}><TableViewIcon
                            fontSize={"small"}/></Button>
                    </ExcelDownloder>
                    <Button variant={"info"} className={"float-end me-1"}
                            onClick={() => onFilterValueChange(defaultFilterValue)}>Reset filters</Button>
                </h3>
            </Card.Header>
            <Card.Body>
                <ReactDataGridCustom
                    idProperty="project_id"
                    columns={columns}
                    dataSource={dataSource}
                    onRenderRow={onRenderRow}
                    filterValue={filterValue}
                    onFilterValueChange={onFilterValueChange}
                />
            </Card.Body>
        </Card>
    );
}