import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {Auth0Provider} from "@auth0/auth0-react";
import App from "./App";
import "moment/locale/nl"
import axios from "axios";
import {getCookie} from "./utils/cookies";

// import css for the tables both normal and dark mode
import '@inovua/reactdatagrid-community/index.css'
import "@inovua/reactdatagrid-community/theme/default-dark.css"

// Add authorization from cookie to every request
axios.interceptors.request.use(
    (request) => {
        if (request.url !== `${process.env.REACT_APP_PORTAL_URL}/auth/refresh_token`) {
            request.headers.authorization = "Bearer " + getCookie("ibess_auth0_token");
            return request
        } else {
            return request
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);


ReactDOM.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    >
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Auth0Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log)
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

