import {
    Archive,
    Bookmark,
    Box,
    Briefcase,
    Calendar,
    Clipboard,
    Codepen, DollarSign,
    Eye,
    Grid,
    Hexagon,
    Inbox,
    Layers,
    List,
    Mail,
    Package,
    Search,
    Settings,
    Sliders,
    Smartphone,
    Tag,
    Tool,
    Users,
    Cpu, Trello
} from "react-feather";

/**
 * Represents the structure and content of the pages section in the application.
 *
 * @typedef {Object} Page
 * @property {string} href - The URL of the page.
 * @property {string} icon - The icon to display for the page.
 * @property {string} title - The title of the page.
 * @property {Page[]} [children] - The child pages under this page.
 * @property {string} [role] - The role required to access this page.
 *
 * @typedef {Page[]} PagesSection
 */
const pagesSection = [
    {
        href: "/dashboard",
        icon: Sliders,
        title: "Dashboard",

    },
    {
        href: "",
        icon: Grid,
        title: "Algemeen",
        children: [
            {
                href: "/timesheets",
                title: "Weekstaten",
                role: "user:timesheets",
                icon: Calendar,
            },
            {
                href: "",
                title: "NAW",
                role: "read:naw",
                icon: Users,
                children: [
                    {
                        href: "/account_overview",
                        title: "Bedrijven",
                    },
                    {
                        href: "/contact_overview",
                        title: "Contactpersonen",
                    },
                ],
            },
            {
                href: "/redirect/https%3A%2F%2Fnordomaticgroup.sharepoint.com%2Fsites%2FOG-BE-GENERAL",
                title: "Centraal plein",
                icon: Hexagon,
            },
            // {
            //     href: "/redirect/http%3A%2F%2F192.168.6.51%3A8080%2Fxwiki",
            //     title: "Wikipedia",
            //     icon: Hexagon,
            // },
            {
                href: "/redirect/https%3A%2F%2Fycon-cl-adm1.nordomaticgroup.local%2Frdweb",
                title: "King Remote",
                icon: Hexagon,
            },
            // {
            //     href: "",
            //     title: "Bibliotheken",
            //     icon: Hexagon,
            //     children: [
            //         {
            //             href: "/redirect/https%3A%2F%2Fnordomaticgroup.sharepoint.com%2Fsites%2FOG-NL-PROJECTS%2FRTO",
            //             title: "RTO",
            //         },
            //         {
            //             href: "/redirect/https%3A%2F%2Fnordomaticgroup.sharepoint.com%2Fsites%2FOG-NL-PROJECTS%2FPI%20schemas",
            //             title: "PI schema's",
            //         },
            //         {
            //             href: "/redirect/https%3A%2F%2Fnordomaticgroup.sharepoint.com%2Fsites%2FOG-NL-ENGINEERING%2FDDC%2F",
            //             title: "DDC Documenten",
            //         },
            //     ],
            // },
        ],
    },
    {
        href: "",
        role: "read:offers",
        icon: Tag,
        title: "Verkoop",
        children: [
            {
                href: "/offer_overview",
                title: "Offerte overzicht",
                icon: Clipboard,
            },
            // {
            //     href: "/xconnect_webshop",
            //     title: "Xconnect webshop",
            //     icon: Truck,
            // },
        ],
    },
    {
        href: "",
        icon: Codepen,
        role: 'read:projects',
        title: "Projecten",
        children: [
            {
                href: "/project_overview",
                title: "Projectresultaten",
                icon: Inbox,
            },
            // {
            //     href: "/poc_dashboard",
            //     title: "GM",
            //     role: 'read_manager:projects',
            //
            //     icon: List,
            // },
            // {
            //     href: "/planning_kastenbouw",
            //     title: "Planning kastenbouw",
            //     role: 'read_manager:projects',
            //     icon: Layers,
            // },
        ],
    },
    {
        href: "/cet_dashboard",
        icon: Cpu,
        role: "read:cet",
        title: "Engineering",
    },
    {
        href: "",
        icon: Tool,
        title: "Service",
        role: "read:tickets",
        children: [
            {
                href: "/service_overview",
                title: "Service",
                role: "read:service",
                icon: Eye,
            },
            {
                href: "/tickets_overview",
                title: "Tickets",
                role: "read:tickets",
                icon: Mail,
            },
            // {
            //     href: "/matrix_overview",
            //     title: "Matrix",
            //     role: "read:service_matrix",
            //     icon: Bookmark,
            // },
            // {
            //     href: "/hosting_overview",
            //     title: "Hosting",
            //     role: "read:service_hosting",
            //     icon: Box,
            // },
            // {
            //     href: "/watchdog_overview",
            //     title: "Watchdog",
            //     role: "read:watchdog",
            //     icon: Search,
            // },
        ],
    },
    {
        href: "",
        icon: Tag,
        title: "Debiteuren",
        role: "read:invoices",
        children: [
            {
                href: "/debtors_dashboard",
                title: "Dashboard",
                role: "read:debtors",
                icon: Sliders,
            },
            {
                href: "/debtors_overview",
                title: "Debiteuren",
                role: "read:debtors",
                icon: Briefcase,
            },
            {
                href: "/invoice_overview",
                title: "Facturen",
                role: "read:invoices",
                icon: Package,
            },
        ],
    },
    {
        href: "",
        icon: Package,
        title: "Producten & Inkoop",
        role: "read:products",
        children: [
            {
                href: "",
                title: "Inkoop",
                icon: Smartphone,
                children: [
                    {
                        href: "/purchase_overview",
                        title: "Bestellingenoverzicht",
                    },
                    {
                        href: "/supplier_overview",
                        title: "Leveranciers",
                    },
                ],
            },
            {
                href: "/products_overview",
                title: "Productenoverzicht",
                icon: Clipboard,
            },
            // {
            //     href: "/storage_supply",
            //     title: "Magazijn voorraad",
            //     icon: Archive,
            // },
            // {
            //     href: "/redirect/https%3A%2F%2Fnordomaticgroup.sharepoint.com%2Fsites%2FOG-NL-PROJECTS%2FLeverancierscondities",
            //     title: "Leveranciercondities",
            //     icon: Hexagon,
            // },
        ],
    },
    {
        href: "",
        icon: Settings,
        title: "Admin omgeving",
        role: "admin:timesheets",
        children: [
            {
                href: "/admin/user_management",
                title: "Gebruikers",
                icon: Users,
                role: "admin:admin",

            },
            // {
            //     href: "/admin/hourly_rates",
            //     title: "Uurtarieven",
            //     icon: DollarSign,
            //     role: "admin:admin",
            // },
            // {
            //     href: "/admin/budget",
            //     title: "Budget",
            //     icon: Trello,
            //     role: "admin:admin",
            // },
            {
                href: "/admin/timesheets",
                title: "Weekstaten beheer",
                icon: Calendar,
                role: "admin:timesheets",

            },
        ],
    },
];

const navItems = [
    {
        title: "",
        pages: pagesSection,
    },
];

export default navItems;
