import React, {useEffect, useState} from "react";
import {Pie} from "react-chartjs-2";
import {Card, Button, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import notyf from "../../../utils/notyfApi";
import dashboardApi from "../../../api/dashboard";
import Loader from "../../../components/Loader";
import adminBudgetApi from "../../../api/adminBudget";
import adminUsersApi from "../../../api/adminUsers";
import "chartjs-plugin-datalabels";

// TODO DOORLOPEN OP OVERBODIGE CODE

/**
 * Represents a Pie Chart for Totals.
 *
 * @function PieChartforTotals
 *
 * @returns {JSX.Element} The rendered JSX element.
 */
const PieChartforTotals = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [actualData, setActualData] = useState([0, 0, 0, 0])
    const [budgetData, setBudgetData] = useState([0, 0, 0, 0])

    useEffect(() => {
        setLoading(true);
        getProductivityInfoAll();
        setLoading(false);
    }, []);

    const getProductivityInfoAll = async () => {
        const res = await dashboardApi.getProductivityInfo()
        if (!res.ok) return notyf.error("Error " + res.status)
        setBudgetData([parseFloat(res.data[0].budget_productivity_hours), parseFloat(res.data[0].budget_no_productivity_hours) + parseFloat(res.data[0].budget_training_hours ), parseFloat(res.data[0].budget_sick_hours), parseFloat(res.data[0].budget_holiday_hours)]);
        setActualData([parseFloat(res.data[0].actual_productivity_hours), parseFloat(res.data[0].actual_no_productivity_hours) + parseFloat(res.data[0].actual_training_hours), parseFloat(res.data[0].actual_sick_hours), parseFloat(res.data[0].actual_holiday_hours)]);
    };

    //fix data based on actual data
    const data = {
        labels: ["Productief", "Niet Productief", "Ziek", "Vrij"],
        datasets: [
            {
                data: actualData,
                backgroundColor: [
                    "#2ecc71",
                    "#3498db",
                    "#e74c3c",
                    "#f1c40f",
                ],
                borderColor: "transparent",
            },
        ],
    };

    //fix data based on budget data
    const dataBudget = {
        labels: ["Budget Prod", "Budget N Prod", "Budget Ziek", "Budget Vrij"],
        datasets: [
            {
                data: budgetData,
                backgroundColor: [
                    "#208f4e",
                    "#1f5b83",
                    "#8b2e24",
                    "#917609",
                ],
                borderColor: "transparent",
            },
        ],
    };

// create options for piecharts
    const options = {
        maintainAspectRatio: false,
        legend: {
            display: true,
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    let dataset = data.datasets[tooltipItem.datasetIndex];
                    let meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    let total = meta.total;
                    let currentValue = dataset.data[tooltipItem.index];
                    let percentage = parseFloat((currentValue/total*100).toFixed(1));
                    return currentValue + ' (' + percentage + '%)';
                },
                title: function(tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        }
    };

    //load in actual content if it is loaded otherwise show loader
    if (loading) return <Card><Loader/></Card>
    return (
        <Card style={{minHeight: '33em'}}>
            <Card.Header>
                <Card.Title tag="h5">Productiviteitsoverzicht </Card.Title>
                <h6 className="card-subtitle text-muted">
                    Actuele (licht) en budget (donker) data van dit jaar van alle werknemers.
                </h6>
            </Card.Header>
            <Card.Body>
                <Row>
                    <div className="chart chart-sm" style={{height: "20em", width: "40%", float: "left"}}>
                        <Pie data={data} options={options}/>
                    </div>
                    <div className="chart chart-sm" style={{height: "20em", width: "40%", float: "right"}}>
                        <Pie data={dataBudget} options={options}/>
                    </div>
                </Row>

                <div className={"d-flex justify-content-center mt-2"}>
                    <Button className={"mt-2"} onClick={() => navigate("/productivitydepartments")}>Naar
                        overzichtspagina</Button>
                </div>
            </Card.Body>
        </Card>
    );
};

export default PieChartforTotals;
