import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import React from "react";
import {NavLink} from "react-router-dom";
import {Badge, Row} from "react-bootstrap";
import moment from "moment/moment";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import OfferCalculationsModal from "../verkoop/calculation/OfferCalculationsModal";
import OfferDeleteCalculationModal from "../verkoop/calculation/OfferDeleteCalculationModal";
import offersApi from "../../../api/offertes";
import notyf from "../../../utils/notyfApi";

/**
 * Renders a table for displaying project result details calculations.
 *
 * @param {Object} options - The options for rendering the table.
 * @param {Array} options.projectCalculations - The calculations to be displayed in the table.
 * @param {boolean} options.reload - Indicates whether the table should reload data.
 *
 * @returns {JSX.Element} The rendered table component.
 */
function ProjectResultDetailCalculationsTable({projectCalculations, reload}) {
    const columns = [
        {
            dataField: "calculation_id",
            text: "id",
            hidden: true,
        },
        {
            dataField: "naam",
            text: "Naam",
            formatter: (_column, row) => {
                return <a target="_blank"
                          rel="noreferrer"
                          href={`https://calculatie.ibess.nl/${row.calculation_id}/overzicht`}>{row.calculation_reference} {row.calculation_name}</a>
            },
            sort: true,
        },
        {
            dataField: "got_m",
            text: "Status",
            formatter: (_column, row) => {
                if (row.got_m) return <Badge bg={"success"}>Binnengehaald</Badge>
                return <Badge bg={"primary"}>Openstaand</Badge>
            },
            sort: true,
        },
        {
            dataField: "naw_contact_name",
            text: "Aanvrager",
            sort: true,
        },
        {
            dataField: "created_on",
            text: "Aangemaakt op",
            formatter: (c) => {
                return moment(c).format("DD-MM-YYYY")
            },
            sort: true,
        },
        {
            dataField: "acties",
            text: "Acties",
            formatter: (_column, row) => {
                return <GuardWrapper role={"write:offers"}>
                    <OfferCalculationsModal reload={reload}
                                            cId={row.calculation_id}
                                            name={row.calculation_name}
                                            reference={row.calculation_reference}
                                            duplicate={true}/>
                </GuardWrapper>
            },
            sort: true,
            style: {width: "12%"}
        },
    ];

    if (projectCalculations?.length > 0) return (
        <ToolkitProvider keyField="calculation_id" data={projectCalculations} columns={columns}>
            {(props) => (
                <div>
                    <BootstrapTable
                        {...props.baseProps}
                        pagination={paginationFactory()}
                    />
                </div>
            )}
        </ToolkitProvider>
    );
    else
        return (
            <Row className="justify-content-center align-self-center w-100 text-center">
                Er zijn geen bestellingen gevonden.
            </Row>
        );
}

export default ProjectResultDetailCalculationsTable;
