import {Navigate} from "react-router-dom";
import {useContext} from "react";
import PermissionsContext from "../../utils/context";

// For routes that can only be accessed by authenticated users
/**
 * Checks if a user has the required role permissions before rendering child components.
 * If the user does not have the required role permissions, it redirects to an error page.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to be rendered.
 * @param {string} props.role - The required role permissions.
 *
 * @returns {ReactNode} - The child components if the user has the required role permissions, or a redirect to an error page if the user does not have the required role permissions.
 */
function PermissionGuard({children, role}) {
    const {permissions} = useContext(PermissionsContext)

    if (!permissions.includes(role)) {
        return <Navigate to="/error" state={{code: 403, message: "U heeft niet de juiste machtigingen voor deze pagina"}}/>;
    }

    return <>{children}</>;
}

export default PermissionGuard;
