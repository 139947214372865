import React from "react";
import {useLocation} from "react-router-dom";

import reduceChildRoutes from "./reduceChildRoutes";

/**
 * Represents a sidebar navigation list component.
 *
 * @param {Object} props - The component properties.
 * @param {Array} props.pages - The array of pages to be rendered as navigation items.
 * @param {number} props.depth - The depth of the navigation items.
 * @returns {React.ReactElement} - The rendered sidebar navigation list.
 */
const SidebarNavList = (props) => {
    const {pages, depth} = props;
    const router = useLocation();
    const currentRoute = router.pathname;

    const childRoutes = pages.reduce(
        (items, page) => reduceChildRoutes({items, page, currentRoute, depth}),
        []
    );

    return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
