import client from "./clients/portalClient";

/**
 * Retrieves the weekstaat from the server using the provided data.
 *
 * @param {Object} data - The data object to be sent to the server.
 * @returns {Promise} - A promise that resolves with the retrieved weekstaat.
 */
const getWeekstaat = (data) => client.get(`/timesheet`, data);
/**
 * Resets the timesheet for the current week.
 *
 * @param {Object} data - The data for the timesheet.
 * @returns {Promise} A promise that resolves when the timesheet is successfully reset.
 */
const resetWeekstaat = (data) => client.delete(`/timesheet`, data);
/**
 * Toggles the weekstaat status by making a PUT request to the "/timesheet/status" endpoint.
 *
 * @param {object} data - The data to be sent to the server.
 * @returns {Promise} A Promise that resolves to the response of the PUT request.
 */
const toggleWeekstaat = (data) => client.put(`/timesheet/status`, data);

/**
 * Sends a POST request to `/timesheet/detail` with the provided data object
 *
 * @param {Object} data - The data object to be sent in the request body
 * @returns {Promise} - A Promise that resolves to the response from the server
 */
const postWeekstaatItem = (data) => client.post(`/timesheet/detail`, data);
/**
 * Updates a weekstaat item with the specified ID.
 *
 * @param {string} id - The ID of the weekstaat item to be updated.
 * @param {Object} data - The data to update the weekstaat item with.
 * @returns {Promise} - A promise that resolves when the update is successful or rejects with an error.
 */
const editWeekstaatItem = (id,data) => client.put(`/timesheet/detail/${id}`, data);
/**
 * Deletes a weekstaat item with the given ID.
 *
 * @param {string} id - The ID of the weekstaat item to delete.
 * @returns {Promise} - A promise that resolves when the deletion is successful,
 *                      or rejects with an error if the deletion fails.
 */
const deleteWeekstaatItem = (id) => client.delete(`/timesheet/detail/${id}`);

/**
 * @typedef {Object} WeekstatenApi
 * @property {function()} getWeekstaat - Retrieves the weekstaat data.
 * @property {function()} resetWeekstaat - Resets the weekstaat data to its initial state.
 * @property {function()} toggleWeekstaat - Toggles the weekstaat data between active and inactive states.

 * @property {function()} postWeekstaatItem - Adds a new item to the weekstaat data.
 * @property {function()} editWeekstaatItem - Edits an existing item in the weekstaat data.
 * @property {function()} deleteWeekstaatItem - Deletes an item from the weekstaat data.
 */
const weekstatenApi = {
  getWeekstaat,
  resetWeekstaat,
  toggleWeekstaat,

  postWeekstaatItem,
  editWeekstaatItem,
  deleteWeekstaatItem
};

export default weekstatenApi;
