import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, Card, ToggleButton} from "react-bootstrap";
import nawContactsApi from "../../../api/nawContacts";
import Loader from "../../../components/Loader";
import NawContactsAddContactModal from "./NawContactsAddContactModal";
import {NavLink} from "react-router-dom";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import notyf from "../../../utils/notyfApi";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import {useExcelDownloder} from "react-xls";
import moment from "moment";
import TableViewIcon from "@mui/icons-material/TableView";


/**
 * The defaultFilterValue variable represents an array of filter objects.
 * Each filter object in the array has the following properties:
 * - name: The name of the filter field.
 * - operator: The operator to be used for filtering.
 * - type: The data type of the filter value.
 * - value: The value to be used for filtering.
 *
 * Example usage:
 * const defaultFilterValue = [
 *   {name: 'sales_contact_firstname', operator: 'contains', type: 'string', value: ''},
 *   {name: 'sales_account_name', operator: 'contains', type: 'string', value: ''},
 *   {name: 'sales_contact_email', operator: 'contains', type: 'string', value: ''},
 *   {name: 'sales_contact_mobile_nr', operator: 'contains', type: 'string', value: ''},
 *   {name: 'sales_contact_work_nr', operator: 'contains', type: 'string', value: ''},
 *   {name: 'sales_contact_job_title', operator: 'contains', type: 'string', value: ''},
 * ];
 */
const defaultFilterValue = [
    {name: 'sales_contact_firstname', operator: 'contains', type: 'string', value: ''},
    {name: 'sales_account_name', operator: 'contains', type: 'string', value: ''},
    {name: 'sales_contact_email', operator: 'contains', type: 'string', value: ''},
    {name: 'sales_contact_mobile_nr', operator: 'contains', type: 'string', value: ''},
    {name: 'sales_contact_work_nr', operator: 'contains', type: 'string', value: ''},
    {name: 'sales_contact_job_title', operator: 'contains', type: 'string', value: ''},
];

/**
 * Represents an array of columns for a table.
 *
 * @typedef {Object[]} Columns
 *
 * @property {string} name - The name of the column.
 * @property {string} header - The header text for the column.
 * @property {function} [render] - A function that renders the value of the column.
 * @property {number} [flex] - The flex value of the column.
 */
const columns = [
    {
        name: "sales_contact_firstname",
        header: "Naam",
        render: ({value, data}) => <NavLink
            to={`/contact/${data.sales_contact_id}`}>{value} {data.sales_contact_lastname}</NavLink>,
        flex: 1,
    },
    {
        name: "sales_account_name",
        header: "Bedrijf",
        render: ({value, data}) => <NavLink
            to={`/account/${data.sales_contact_account_id}`}>{value} </NavLink>,
        flex: 2,

    },
    {
        name: "sales_contact_email",
        header: "Email",
        render: ({value}) => <a
            href={`mailto:${value}`}>{value}</a>,
        flex: 1.5,

    },
    {
        name: "sales_contact_mobile_nr",
        header: "Mobiel",
        flex: 1,
    },
    {
        name: "sales_contact_work_nr",
        header: "Telefoon",
        flex: 1,
    },
    {
        name: "sales_contact_job_title",
        header: "Functie",
        flex: 1,
    },
    {
        name: "sales_contact_active",
        header: "Status",
        render: ({value}) => {
            if (value === true) return <span className="badge bg-success">Actief</span>;
            else if (value === false) return <span className="badge bg-danger">Inactief</span>;
            else return <span className="badge bg-warning text-dark">Onbekend</span>;
        },
        flex: 1,
    },
];

/**
 * Fetches data from the API and displays a table of NAW contacts.
 *
 * @returns {JSX.Element} The rendered NAW contacts table.
 */
export default function NawContactsTableHandler() {
    //get data from api
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState("active")
    const {ExcelDownloder} = useExcelDownloder();


    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category]);

    const getData = async () => {
        setLoading(true)
        const response = await nawContactsApi.getAllNawContacts(category);
        if (!response.ok) return notyf.error("Error " + response.status)

        setDataSource({NAW_contacten: response.data});
        setLoading(false)

    };

    //laat tabel zien met gegevens via props
    if (loading) return <Loader/>
    return <Card>


        <Card.Header>
            <Card.Title tag="h5" className="mb-0 d-flex">
                <div className={"w-100 d-flex justify-content-between"}>
                    <h3 className={"mb-0"}>NAW-overzicht</h3>

                    <span>
                                <ButtonGroup className={"me-1"}>
                                    <ToggleButton type="radio" checked={category === "active"} value={1}
                                                  onClick={() => setCategory("active")}>
                                        Actief
                                    </ToggleButton>
                                    <ToggleButton type="radio" checked={category === "inactive"} value={2}
                                                  onClick={() => setCategory("inactive")}>
                                        Inactief
                                    </ToggleButton>
                                    <ToggleButton type="radio" checked={category === "all"} value={3}
                                                  onClick={() => setCategory("all")}>
                                        Alles
                                    </ToggleButton>
                                </ButtonGroup>

                        <ExcelDownloder
                            data={dataSource}
                            filename={'NAW contacten ' + moment().format("DD-MM-YYYY")}
                            type={"link"}
                        >
                            <Button className={"me-1 added-tiny-margin-top remove-margin-top-mob"} variant={"secondary"}>
                                <TableViewIcon fontSize={"small"}/>
                            </Button>
                        </ExcelDownloder>
                                    <GuardWrapper role={"write:naw"}>
                                         <NawContactsAddContactModal reload={getData}/>
                                    </GuardWrapper>
                                </span>
                </div>
            </Card.Title>
        </Card.Header>
        <Card.Body className={"table-container"}>

            <ReactDataGridCustom
                idProperty="sales_contact_id"
                dataSource={dataSource.NAW_contacten}
                columns={columns}
                pagination
                defaultLimit={50}
                defaultFilterValue={defaultFilterValue}
            />
        </Card.Body>
    </Card>;
}
