import React, {useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";

/**
 * Opens a modal for adding a comment and allows submitting the comment.
 *
 * @param {Object} params - The parameters for the function.
 * @param {function} params.submit - The callback function to be called when the comment is submitted.
 *
 * @return {JSX.Element} - The JSX element representing the modal for adding a comment.
 */
function InvoiceDetailAddCommentModal({submit}) {
    const [show, setShow] = useState(false);
    const [comment, setComment] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleSubmit = () => {
        submit(comment)
        setComment("")
        handleClose()
    }

    return (
        <>
            <Button className={"float-end mx-1"} onClick={handleShow}>
                Nieuwe opmerking
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Opmerking toevoegen
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control value={comment} onChange={e => setComment(e.target.value)} as="textarea"
                                      placeholder="Opmerking"/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Annuleren
                    </Button>
                    <Button disabled={!comment} variant="primary" onClick={() => handleSubmit()}>
                        Toevoegen
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default InvoiceDetailAddCommentModal