import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {Alert, Button} from "react-bootstrap";
import {
    SIDEBAR_POSITION,
    SIDEBAR_BEHAVIOR,
    LAYOUT,
    THEME,
} from "../constants";
import useOuterClick from "../hooks/useOuterClick";
import useTheme from "../hooks/useTheme";
import useSidebar from "../hooks/useSidebar";
import useLayout from "../hooks/useLayout";

/**
 * Represents a list of theme options.
 *
 * @type {Array}
 * @property {string} name - The name of the theme option.
 * @property {string} value - The value of the theme option.
 */
const themeOptions = [
    {
        name: "Default",
        value: THEME.DEFAULT,
    },
    {
        name: "Colored",
        value: THEME.COLORED,
    },
    {
        name: "Dark",
        value: THEME.DARK,
    },
    {
        name: "Light",
        value: THEME.LIGHT,
    },
];

/**
 * An array representing the available options for sidebar position.
 * Each option is an object with a name and a value.
 *
 * @type {Array<Object>}
 */
const sidebarPositionOptions = [
    {
        name: "Left",
        value: SIDEBAR_POSITION.LEFT,
    },
    {
        name: "Right",
        value: SIDEBAR_POSITION.RIGHT,
    },
];

/**
 * @typedef {Object} SidebarBehaviorOption
 * @property {string} name - The name of the sidebar behavior option.
 * @property {number} value - The value representing the sidebar behavior option.
 */
const sidebarBehaviorOptions = [
    {
        name: "Sticky",
        value: SIDEBAR_BEHAVIOR.STICKY,
    },
    {
        name: "Fixed",
        value: SIDEBAR_BEHAVIOR.FIXED,
    },
    {
        name: "Compact",
        value: SIDEBAR_BEHAVIOR.COMPACT,
    },
];

/**
 * layoutOptions represents the available layout options.
 * Each option has a name and a corresponding value.
 *
 * @typedef {Object} LayoutOption
 * @property {string} name - The name of the layout option.
 * @property {string} value - The value of the layout option.
 */
const layoutOptions = [
    {
        name: "Fluid",
        value: LAYOUT.FLUID,
    },
    {
        name: "Boxed",
        value: LAYOUT.BOXED,
    },
];

/**
 * Retrieves the query parameters from the current URL.
 *
 * @returns {URLSearchParams} An instance of URLSearchParams containing the query parameters.
 */
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

/**
 * Represents the settings panel component.
 * @constructor
 */
const Settings = () => {
    const query = useQuery();
    const [isOpen, setIsOpen] = useState(false);

    const {theme, setTheme} = useTheme();
    const {position, setPosition, behavior, setBehavior} = useSidebar();
    const {layout, setLayout} = useLayout();

    const innerRef = useOuterClick(() => {
        setIsOpen(false);
    });

    const setSettingByQueryParam = (name, set) => {
        const value = query.get(name);
        if (value) {
            set(value);
        }
    };

    // Read from query parameter (e.g. ?theme=dark)
    // only for demo purposes
    useEffect(() => {
        setSettingByQueryParam("theme", setTheme);
        setSettingByQueryParam("sidebarPosition", setPosition);
        setSettingByQueryParam("sidebarBehavior", setBehavior);
        setSettingByQueryParam("layout", setLayout);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            ref={innerRef}
            className={`settings js-settings ${isOpen ? "open" : ""}`}
        >
            <Button onClick={() => setIsOpen(true)}>
                Thema instellingen
            </Button>
            <div className="settings-panel">
                <div className="settings-content">
                    <div className="settings-title d-flex align-items-center">
                        <button
                            type="button"
                            className="btn-close float-right js-settings-toggle"
                            aria-label="Close"
                            onClick={() => setIsOpen(false)}
                        />
                        <h4 className="mb-0 ms-2 d-inline-block">Theme instellingen</h4>
                    </div>
                    <div className="settings-body">
                        <Alert variant="primary">
                            <div className="alert-message">
                                <strong>Hey!</strong> Hier kun je je eigen stijl selecteren waarin de portal voor jou
                                wordt weergegeven! Wij raden default of dark mode aan.
                            </div>
                        </Alert>

                        <div className="mb-3">
                            <span className="d-block font-size-lg fw-bold">Kleurenthema</span>
                            <span className="d-block text-muted mb-2">
                               Kies hier de kleuren voor het portal.
                              </span>
                            <div className="row g-0 text-center mx-n1 mb-2">
                                {themeOptions.map(({name, value}) => (
                                    <div className="col-6" key={value}>
                                        <label className="mx-1 d-block mb-1">
                                            <input
                                                className="settings-scheme-label"
                                                type="radio"
                                                name="theme"
                                                value={value}
                                                checked={theme === value}
                                                onChange={() => setTheme(value)}
                                            />
                                            <div className="settings-scheme">
                                                <div
                                                    className={`settings-scheme-theme settings-scheme-theme-${value}`}/>
                                            </div>
                                        </label>
                                        {name}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <hr/>
                        <div className="mb-3">
                          <span className="d-block font-size-lg fw-bold">
                            Positie van het menu.
                          </span>
                            <span className="d-block text-muted mb-2">
                                Kies of je het menu links of rechts wilt.
                              </span>
                            <div>
                                {sidebarPositionOptions.map(({name, value}) => (
                                    <label className="me-1" key={value}>
                                        <input
                                            className="settings-button-label"
                                            type="radio"
                                            name="sidebarPosition"
                                            value={value}
                                            checked={position === value}
                                            onChange={() => setPosition(value)}
                                        />
                                        <div className="settings-button">{name}</div>
                                    </label>
                                ))}
                            </div>
                        </div>
                        <hr/>
                        <div className="mb-3">
                          <span className="d-block font-size-lg fw-bold">
                            Hoe gedraagt het menu zich?
                          </span>
                            <span className="d-block text-muted mb-2">
                            Kies hier hoe het menu zich moet gedragen.
                          </span>
                            <div>
                                {sidebarBehaviorOptions.map(({name, value}) => (
                                    <label className="me-1" key={value}>
                                        <input
                                            className="settings-button-label"
                                            type="radio"
                                            name="sidebarBehavior"
                                            value={value}
                                            checked={behavior === value}
                                            onChange={() => setBehavior(value)}
                                        />
                                        <div className="settings-button">{name}</div>
                                    </label>
                                ))}
                            </div>
                        </div>
                        <hr/>
                        <div className="mb-3">
                            <span className="d-block font-size-lg fw-bold">Portal-layout</span>
                            <span className="d-block text-muted mb-2">
                                  Kies hoe de container van het portal wordt weergegeven.
                            </span>
                            <div>
                                {layoutOptions.map(({name, value}) => (
                                    <label className="me-1" key={value}>
                                        <input
                                            className="settings-button-label"
                                            type="radio"
                                            name="layout"
                                            value={value}
                                            checked={layout === value}
                                            onChange={() => setLayout(value)}
                                        />
                                        <div className="settings-button">{name}</div>
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;
