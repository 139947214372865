import {Button, Card, Col, Form, Modal, Row, Tab, Tabs} from "react-bootstrap";
import moment from "moment";
import React, {useEffect, useState} from "react";
import serviceProjectApi from "../../../api/service";
import {Trash} from "react-feather";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {Interweave} from 'interweave';

/**
 * ActivityMenu component.
 *
 * @param {object} props - The props of the component.
 * @param {string} props.id - The ID of the activity menu.
 * @param {string} props.sId - The sID of the activity menu.
 * @param {string} props.ticket - The ticket of the activity menu.
 *
 * @returns {jsx} The rendered ActivityMenu component.
 */
const ActivityMenu = ({id, sId, ticket}) => {
    const [logs, setLogs] = useState([])
    const [messages, setMessages] = useState([])
    const [activityFull, setActivityFull] = useState(false)
    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticket])

    const getData = async () => {

        const resHistory = await serviceProjectApi.getTicketDetailsHistory(sId)
        const resMessages = await serviceProjectApi.getTicketDetailsMessages(id, sId)
        setMessages(resMessages.data)
        setLogs(resHistory.data.logs)
    }

    const deleteMessage = async (mId) => {

        await serviceProjectApi.deleteTicketDetailsMessages(id, sId, mId)
        getData()
    }
    return (<Card>
        <Card.Body>
            <div style={{display: "block"}}>
                <Tabs defaultActiveKey="Berichten">
                    <Tab eventKey="Activiteit" title="Activiteit">
                        <ul className="timeline mt-2">
                            {logs.length ? (activityFull ? logs.map(log => <li key={log.log_detail_timestamp}
                                                                               className="timeline-item">
                                <strong>{log.log_detail_type}</strong>
                                <span
                                    className="float-end text-muted text-sm">{moment(log.log_detail_timestamp).format("DD-MM-YYYY HH:mm")}</span>
                                <p>
                                    {log.log_detail_message.replace(/<\/?[^>]+(>|$)/g, "")} {log.log_detail_created_by}
                                </p>
                            </li>) : logs.slice(0, 10).map(log => <li key={log.log_detail_timestamp}
                                                                      className="timeline-item">
                                <strong>{log.log_detail_type}</strong>
                                <span
                                    className="float-end text-muted text-sm">{moment(log.log_detail_timestamp).format("DD-MM-YYYY HH:mm")}</span>
                                <p>
                                    {log.log_detail_message.replace(/<\/?[^>]+(>|$)/g, "")} {log.log_detail_created_by}
                                </p>
                            </li>)) : <Row style={{marginTop: "1rem"}}
                                           className="justify-content-center align-self-center w-100 text-center">
                                Er zijn geen activiteiten gevonden.
                            </Row>}
                            {(logs.length > 10 && !activityFull) &&
                            <Button onClick={() => setActivityFull(true)}>Meer laden</Button>}
                        </ul>
                    </Tab>
                    <Tab eventKey="Berichten" title="Berichten">
                        <Row>
                            <Col> <GuardWrapper role={"write:tickets"}><AddMessageModal id={id} sId={sId}
                                                                                        reload={getData}/></GuardWrapper>
                            </Col>
                        </Row>
                        {messages.length ? messages.map(m => <div key={m.service_projects_tickets_comment_id}
                                                                  className={"pt-3"}>

                            <div className="d-flex">
                                <Col lg={11} className="flex-grow-1">
                                    <strong>{m.user_name}</strong>
                                    <br/>
                                    <Interweave content={m.service_projects_tickets_comment}/>
                                    <br/>
                                    <small
                                        className="text-muted">{moment(m.service_projects_tickets_comment_created_on).format("DD-MM-YYYY HH:mm")}
                                    </small>
                                    <br/>
                                </Col>
                                <Col lg={1}>
                                    <Trash className={"cursor-pointer mt-2"}
                                           onClick={() => deleteMessage(m.service_projects_tickets_comment_id)}/>
                                </Col>
                            </div>
                        </div>) : <Row style={{marginTop: "1rem"}}
                                       className="justify-content-center align-self-center w-100 text-center">
                            Geen berichten gevonden.
                        </Row>}
                    </Tab>

                </Tabs>
            </div>
        </Card.Body>
    </Card>)
}

export default ActivityMenu


/**
 * Represents an AddMessageModal component.
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.id - The unique identifier for the component.
 * @param {string} props.sId - The unique identifier for the service.
 * @param {function} props.reload - The function to reload the component.
 * @returns {JSX.Element} The rendered AddMessageModal component.
 */
const AddMessageModal = ({id, sId, reload}) => {
    const [show, setShow] = useState(false);
    const [comment, setComment] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleSubmit = async () => {
        if (comment) {
            await serviceProjectApi.postTicketDetailsMessages(id, sId, {service_projects_tickets_comment: comment})
            handleClose()
            reload()
        }
    }

    return (
        <>
            <Button className={"position-absolute top-0 end-0 m-3"} onClick={handleShow}>
                +
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Berichten
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Opmerking toevoegen</Form.Label>
                        <ReactQuill value={comment} onChange={setComment}/>

                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Annuleren
                    </Button>
                    <Button variant="primary" disabled={!comment} onClick={handleSubmit}>
                        Toevoegen
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}
