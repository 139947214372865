import React, {useEffect, useState} from "react";
import serviceProjectApi from "../../../api/service";
import {Edit2} from "react-feather";
import {Button, Modal} from "react-bootstrap";
import Select from "react-select";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import notyf from "../../../utils/notyfApi";

/**
 * Represents a modal for displaying and editing GBS service project details.
 * @typedef {Object} ServiceDetailGBSModal
 * @property {string} id - The ID of the service project.
 * @property {boolean} exists - Indicates whether the service project exists.
 * @property {function} reload - Function to reload the service project.
 */
const ServiceDetailGBSModal = ({id, exists, reload}) => {
    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState("");
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (show) getData()
    }, [show])

    const getData = async () => {
        const resBms = await serviceProjectApi.getBMS()
        if (!resBms.ok) return notyf.error("Error " + resBms.status)

        let temp = [{
            value: "",
            label: "-"
        }]
        for (let i of resBms.data) {
            temp.push({
                value: i.service_project_bms_software,
                label: i.service_project_bms_software
            })
        }
        setOptions(temp)
    }

    const handleSubmit = async () => {
        const res = await serviceProjectApi.editServiceProject(id, {...exists, service_project_bms_software_version: selected})
        if (!res.ok) return notyf.error("Error " + res.status)
        reload()
        setShow(false)
    }

    return (
        <>
            <GuardWrapper role={"write:service"}>
                <Edit2 className={"float-end cursor-pointer"} onClick={() => setShow(!show)}/>
            </GuardWrapper>
            <Modal show={show} onHide={() => setShow(!show)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        GBS
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Select
                        options={options}
                        noOptionsMessage={() => 'Geen contactpersonen gevonden'}
                        onChange={e => setSelected(e.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShow(false)}>
                        Annuleren
                    </Button>
                    <Button variant="primary" onClick={() => handleSubmit()}>
                        Opslaan
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ServiceDetailGBSModal;