import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {Badge, Button, ButtonGroup, Card, Container, Dropdown, DropdownButton, ToggleButton} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import serviceProjectApi from "../../../api/service";
import Loader from "../../../components/Loader";
import moment from "moment";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import TableViewIcon from "@mui/icons-material/TableView";
import {useExcelDownloder} from "react-xls";

const defaultFilterValue = [
    {name: 'service_project_ticket_reference', operator: 'contains', type: 'string', value: ''},
    {name: 'user_name', operator: 'contains', type: 'string', value: ''},
    {name: 'service_project_name', operator: 'contains', type: 'string', value: ''},
    {name: 'service_project_ticket_subject', operator: 'contains', type: 'string', value: ''},
    {name: 'sales_account_name', operator: 'contains', type: 'string', value: ''},
];
const columns = [
    {
        name: "service_project_ticket_reference",
        header: "Ticket referentie",
        render: ({value, data}) => <NavLink
            to={`/ticket/${data.service_project_f_id}/${data.service_project_ticket_id}`}>{value}</NavLink>,
        flex: 1,
    },
    {
        name: "user_name",
        header: "Eigenaar",
        flex: 1,
    },
    {
        name: "service_project_name",
        header: "Gebouw",
        render: ({value, data}) => <NavLink to={`/service-detail/${data.service_project_f_id}`}>{value}</NavLink>,
        flex: 1.5,
    },
    {
        name: "service_project_ticket_subject",
        header: "Onderwerp",
        flex: 2.5,
    },
    {
        name: "service_project_ticket_from_text",
        header: "Contactpersoon",
        render: ({value, data}) => {
            if (data.service_project_ticket_from_naw) return <NavLink
                to={`/contact/${data.service_project_ticket_from_naw}`}>{`${data.sales_contact_firstname} ${data.sales_contact_surname_prefix || ""} ${data.sales_contact_lastname}`}</NavLink>
            return value
        },
        flex: 1,
    },
    {
        name: "sales_account_name",
        header: "Bedrijf",
        render: ({value, data}) => {
            if (value) return value
            return data.service_project_ticket_from_account_text
        },
        flex: 1,
    },
    {
        name: "service_project_ticket_completed",
        header: "Status",
        render: ({value}) => {
            if (value) return <Badge bg={"success"}>AFGEROND</Badge>
            return <Badge bg={"info"}>OPEN</Badge>
        },
        flex: 1,
    },
    {
        name: "service_project_ticket_created_on",
        header: "Aangemaakt op",
        render: ({value}) => moment(value).format("DD-MM-YYYY HH:mm"),
        flex: 1,
    },
];


const TicketPage = () => {
    const [dataSource, setDataSource] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [category, setCategory] = useState("active")
    const [owners, setOwners] = useState([])
    const {ExcelDownloder} = useExcelDownloder();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm, category])

    const getData = async () => {
        setLoading(true)

        const res = await serviceProjectApi.getAllTickets()
        if (!res.ok) return null
        if (searchTerm) {
            setDataSource({tickets: res.data.tickets.filter(data => data.user_name === searchTerm)});
        } else {
            setDataSource({tickets: res.data.tickets})
            setOwners(res.data.owners.reduce(function (previousValue, currentValue) {
                return [...previousValue, currentValue.owner]
            }, []).sort())
        }
        setLoading(false)

    }

    if (loading) return <Loader/>
    return (
        <React.Fragment>
            <Helmet title="Tickets"/>
            <Container fluid className="p-0">
                <Card>
                    <Card.Header>
                        <Card.Title className="mb-0 d-flex">
                            <div className={"w-100 d-flex justify-content-between"}>
                                <h3 className={"mb-0"}>Tickets</h3>
                                <div>
                                    <DropdownButton
                                        onSelect={e => setSearchTerm(e)}
                                        variant={"secondary"}
                                        className={"me-2"} id="dropdown-basic-button"
                                        title="Eigenaar ">
                                        <Dropdown.Item eventKey={""}>Geen selectie</Dropdown.Item>
                                        {owners.map(o => <Dropdown.Item key={o} eventKey={o}>{o}</Dropdown.Item>)}
                                    </DropdownButton>
                                    <ButtonGroup className={"custom-576-100w added-tiny-margin-top2"}>
                                        <ToggleButton type="radio" checked={category === "active"} value={1}
                                                      onClick={() => setCategory("active")}>Openstaand</ToggleButton>
                                        <ToggleButton type="radio" checked={category === "inactive"} value={2}
                                                      onClick={() => setCategory("inactive")}>Afgesloten</ToggleButton>
                                        <ToggleButton type="radio" checked={category === "all"} value={3}
                                                      onClick={() => setCategory("all")}>Alles</ToggleButton>
                                    </ButtonGroup>

                                    <ExcelDownloder
                                        data={dataSource}
                                        filename={'Tickets ' + moment().format("DD-MM-YYYY")}
                                        type={"link"}
                                    >
                                        <Button className={"mx-1 added-tiny-margin-top remove-margin-top-mob"} variant={"secondary"}>
                                            <TableViewIcon fontSize={"small"}/>
                                        </Button>
                                    </ExcelDownloder>
                                </div>
                            </div>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>

                        <ReactDataGridCustom
                            idProperty="service_project_ticket_id"
                            dataSource={dataSource.tickets}
                            columns={columns}
                            pagination
                            defaultLimit={50}
                            defaultFilterValue={defaultFilterValue}
                        />
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>

    )
};


export default TicketPage;
