import React, {useEffect, useState} from "react";
import supplierApi from "../../../../api/suppliers";
import SupplierTable from "./SupplierTable";
import Loader from "../../../../components/Loader";
import notyf from "../../../../utils/notyfApi";

/**
 * Handles the supplier table by retrieving data from an API and rendering it in a table component.
 *
 * @returns {React.Component} Returns a React component representing the supplier table.
 */
export default function SupplierTableHandler() {
    //get data from api
    const [supplier, setSupplier] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const response = await supplierApi.getAllSuppliers();
        if (!response.ok) return notyf.error("Error " + response.status)
        setSupplier({suppliers: response.data});
        setLoading(false)

    };

    //laat tabel zien met gegevens via props
    if (loading) return <Loader/> ;
    else return  <SupplierTable dataSource={supplier} reload={getData}/>;
}
