import React, {useEffect, useState} from "react";
import {useFormikContext} from "formik";
import ErrorMessage from "./ErrorMessage";
import {Form} from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import notyf from "../../utils/notyfApi";


/**
 * Renders a select field for a form.
 *
 * @param {object} props - The properties for the component.
 * @param {string} props.name - The name of the field.
 * @param {string} props.width - The width of the select field.
 * @param {string} props.label - The label for the select field.
 * @param {...object} props.otherProps - Additional properties to spread on the select field.
 * @returns {JSX.Element} - The rendered select field.
 */
function AppFormSelectField({name, width, label, ...otherProps}) {
    const {setFieldTouched, setFieldValue, errors, touched, values} = useFormikContext();
    const [searchTerm, setSearchTerm] = useState("")
    const [options, setOptions] = useState([])
    const getOptions = () => {
        if (!searchTerm) return setOptions([])
        axios.get(`${process.env.REACT_APP_PORTAL_URL}/search/naw?q=${searchTerm}`).then(r => {
                let temp = []
                for (let naw of r.data.naw) {
                    temp.push({
                        value: naw,
                        label: `${naw.sales_contact_firstname} ${naw.sales_contact_surname_prefix || ""} ${naw.sales_contact_lastname} - ${naw.sales_account_name}`
                    })
                }
                setOptions(temp)
            }
        ).catch(() => notyf.error("Error met opties ophalen"))
    }

    useEffect(() => {
        if (typeof values[name] === "number" && typeof searchTerm !== "number") setSearchTerm(values[name])
        getOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm])

    const keyHandler = e => {
        const input = String.fromCharCode(e.keyCode);
        if (/[a-zA-Z]/.test(input)) setSearchTerm(e.target.value + e.key)
        if (e.keyCode === 8) setSearchTerm(searchTerm.slice(0, -1))
    }
    return (
        <>
            {label && <Form.Label>{label}</Form.Label>}
            <Select options={options}
                    tabIndex="0"
                    onKeyDown={keyHandler}
                    onBlur={() => setFieldTouched(name)}
                    onChange={e => setFieldValue(name, e.value)}
                    defaultValue={{label: values[name], value: values[name]}}
                    width={width}
                    noOptionsMessage={() => 'Geen contactpersonen gevonden'}
                    {...otherProps}
            />
            <ErrorMessage error={errors[name]} visible={touched[name]}/>
        </>
    );
}

export default AppFormSelectField;
