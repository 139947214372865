/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {forwardRef} from "react";
import {NavLink} from "react-router-dom";

import {Badge, Collapse} from "react-bootstrap";

/**
 * CustomRouterLink is a higher-order component that wraps the NavLink component from React Router.
 * It allows the usage of a ref to access the underlying DOM node and apply custom logic.
 *
 * @param {Object} props - The props object containing the properties to be passed to NavLink.
 * @param {React.MutableRefObject} ref - The ref object for accessing the underlying DOM node.
 * @returns {React.ReactElement} - A React fragment which contains the NavLink component.
 * @example
 * // Usage:
 * const navLinkRef = useRef();
 *
 * <CustomRouterLink ref={navLinkRef} to="/home" exact activeClassName="active">
 *     Home
 * </CustomRouterLink>
 *
 * // Access the underlying DOM node:
 * const navLinkDOMNode = navLinkRef.current;
 */
const CustomRouterLink = forwardRef((props, ref) => (
    <React.Fragment ref={ref}>
        <NavLink {...props} />
    </React.Fragment>
));

/**
 * Represents a single item in the sidebar navigation.
 * @param {object} props - The properties passed to the component.
 * @param {string} props.title - The title of the sidebar item.
 * @param {string} props.href - The href attribute for the sidebar item.
 * @param {number} props.depth - The depth of the sidebar item, default is 0.
 * @param {ReactNode} props.children - The children of the sidebar item.
 * @param {ReactNode} props.icon - The icon to be displayed next to the title.
 * @param {string} props.badge - The badge to be displayed next to the title.
 * @param {boolean} props.open - The initial open state of the sidebar item, default is false.
 * @returns {JSX.Element} - The rendered sidebar item.
 */
const SidebarNavListItem = (props) => {
    const {
        title,
        href,
        depth = 0,
        children,
        icon: Icon,
        badge,
        open: openProp = false,
    } = props;

    const [open, setOpen] = React.useState(openProp);

    const handleToggle = () => {
        setOpen((state) => !state);
    };

    if (children) {
        return (
            <li className={`sidebar-item ${open ? "active" : ""}`}>
                <a
                    className={`sidebar-link ${open ? "" : "collapsed"}`}
                    data-bs-toggle="collapse"
                    aria-expanded={open ? "true" : "false"}
                    depth={depth}
                    onClick={handleToggle}
                >
                    {Icon && <Icon className="feather align-middle"/>}{" "}
                    <span className="align-middle" depth={depth}>
            {title}
          </span>
                    {badge && (
                        <Badge className="badge-sidebar-primary" bg="" size={18}>
                            {badge}
                        </Badge>
                    )}
                    <div/>
                </a>
                <Collapse in={open}>
                    <ul className="sidebar-dropdown list-unstyled">{children}</ul>
                </Collapse>
            </li>
        );
    }

    return (
        <li className="sidebar-item">
            <CustomRouterLink
                depth={depth}
                to={href}
                activeclassname="active"
                className="sidebar-link"
            >
                {Icon && <Icon className="feather align-middle"/>}{" "}
                <span className="align-middle" depth={depth}>
          {title}
        </span>
                {badge && (
                    <Badge className="badge-sidebar-primary" bg="" size={18}>
                        {badge}
                    </Badge>
                )}
            </CustomRouterLink>
        </li>
    );
};

export default SidebarNavListItem;
