import React from "react";
import { Formik } from "formik";

/**
 * Render a form with initial values, submit handler, validation schema and child components.
 *
 * @param {object} initialValues - The initial form values.
 * @param {function} onSubmit - The submit handler function to be called when the form is submitted.
 * @param {object} validationSchema - The validation schema used to validate the form values.
 * @param {ReactNode} children - The child components to be rendered within the form.
 *
 * @returns {ReactNode} - The rendered form component.
 */
function AppForm({ initialValues, onSubmit, validationSchema, children }) {
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {() => <>{children}</>}
    </Formik>
  );
}

export default AppForm;
