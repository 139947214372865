import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Button, Card, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {Edit2, File, Globe, Info, Mail, Map, Phone} from "react-feather";
import {useParams} from "react-router-dom";
import supplierApi from "../../../api/suppliers";
import SupplierDetailOrderTableHandler
    from "../../components/producten_inkoop/leveranciers/SupplierDetailOrderTableHandler";
import SupplierAddEditSupplierModal from "../../components/producten_inkoop/leveranciers/SupplierAddEditSupplierModal";
import notyf from "../../../utils/notyfApi";
import Loader from "../../../components/Loader";

const SuppliersDetail = () => {
    const {id} = useParams();
    const [supplier, setSupplier] = useState(null);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        const res = await supplierApi.getSupplier(id);
        if (!res.ok) return notyf.error("Error " + res.status)
        setSupplier(res.data);
    };

    if (!supplier) return <Loader/>;
    return (
        <React.Fragment>
            <Helmet title={`Leverancier - ${supplier.product_supplier_name}`}/>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Leverancier - {supplier.product_supplier_name}</h1>
                <Row>
                    <Col xl="8">
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Bestellingsdetail
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <SupplierDetailOrderTableHandler/>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl="4">
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Leverancierdetails
                                    <SupplierAddEditSupplierModal supplier={supplier} reload={getData}/>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <File/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Naam
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {supplier.product_supplier_name}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Map/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Adres
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <a href={`https://maps.google.com/?q=${supplier.product_supplier_address || ""}+
                                        ${supplier.product_supplier_city || ""}+
                                        ${supplier.product_supplier_country || ""}`}
                                           target="_blank"
                                           rel="noreferrer">{supplier.product_supplier_address || ""} {supplier.product_supplier_city || ""} {supplier.product_supplier_country || ""}
                                        </a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Phone/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Telefoonnummer
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {supplier.product_supplier_phone}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Globe/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Categorie
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {supplier.product_supplier_category}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Mail/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Email
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <a href={`mailto:${supplier.product_supplier_email}`}>{supplier.product_supplier_email}</a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Info/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Website
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <a href={supplier.product_supplier_website} target="_blank"
                                           rel="noreferrer">{supplier.product_supplier_website}</a>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Card.Title style={{margin: 10}}>Opmerking <EditCommentModal id={id} reload={getData}
                                                                                             exists={supplier}/></Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {supplier?.product_supplier_comment ? supplier.product_supplier_comment :
                                    <Row className="justify-content-center align-self-center w-100 text-center">
                                        Er is geen commentaar gevonden.
                                    </Row>}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default SuppliersDetail;

const EditCommentModal = ({id, exists, reload}) => {
    const [comment, setComment] = useState(exists.product_supplier_comment || "")
    const [show, setShow] = useState(false)
    const handleSubmit = async () => {
        const res = await supplierApi.editExistingSupplier(id, {
            ...exists,
            product_supplier_comment: comment
        })
        if (!res.ok) return notyf.error("Error " + res.status)
        reload()
        setShow(false)
    }

    return <>
        <Edit2 onClick={() => setShow(true)} className={"float-end cursor-pointer"}/>
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header>
                <Modal.Title>Opmerking</Modal.Title>
            </Modal.Header>
            <Modal.Body className={"px-2"}>
                <Form.Control value={comment}
                              onChange={e => setComment(e.target.value)}
                              placeholder={"Vul hier een opmerking in"}
                              as={"textarea"}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Annuleren
                </Button>
                <Button onClick={handleSubmit}> Opslaan </Button>
            </Modal.Footer>
        </Modal>
    </>
}