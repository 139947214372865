import React from "react";
import {Button, Card} from "react-bootstrap";
import moment from "moment";
import {ExternalLink} from "react-feather";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import TableViewIcon from "@mui/icons-material/TableView";
import {useExcelDownloder} from "react-xls";

/**
 * Default filter value
 *
 * @type {Array.<{name: string, operator: string, type: string, value: string}>}
 */
const defaultFilterValue = [
    {name: 'alias', operator: 'contains', type: 'string', value: ''},
    {name: 'http_verb', operator: 'contains', type: 'string', value: ''},
];

/**
 * Represents a set of columns used for rendering a table.
 *
 * @typedef {Object} Column
 * @property {string} name - The name of the column.
 * @property {string} header - The header text for the column.
 * @property {Function} [render] - The render function used for custom rendering of the column value.
 * @property {number} flex - The flex value for the column width.
 */
const columns = [
    {
        name: "alias",
        header: "Naam",
        render: ({value, data}) => <a href={data.url} target="_blank" rel="noreferrer">{value}</a>,
        flex: 2
    },
    {
        name: "enabled",
        header: "Status Webserver",
        render: ({value}) => {
            if (value) return <span className="badge bg-success">Actief</span>;
            return <span className="badge bg-danger">Inactief</span>;
        },
        flex: 1
    },
    {
        name: "http_verb",
        header: "HTTPVerb",
        flex: 1
    },
    {
        name: "last_status",
        header: "Laatste Status",
        render: ({value}) => {
            if (value) return <span className="badge bg-success">OK</span>;
            return <span className="badge bg-danger">Geen</span>;
        },
        flex: 1
    },
    {
        name: "last_check_at",
        header: "Laatste Check",
        render: ({value}) => moment(value).format("DD-MM-YYYY HH:mm"),
        flex: 1
    },
    {
        name: "next_check_at",
        header: "Volgende Check",
        render: ({value}) => moment(value).format("DD-MM-YYYY HH:mm"),
        flex: 1
    },
    {
        name: "token",
        header: "Details",
        render: ({value}) => {
            return (
                <a href={"https://updown.io/" + value}
                   target="_blank"
                   rel="noreferrer">
                    <ExternalLink
                        className={"cursor-pointer"}
                        rel="noreferrer"/>
                </a>

            );
        },
        flex: 1
    },
];

// Data op successes en errors komen niet helemaal overeen. Dus kijk daar nog eens naar.

/**
 * Renders a hosting table with excel download button.
 *
 * @param {Object} dataSource - The data source object.
 * @param {Array} dataSource.hosting - The hosting data source array.
 * @param {Array} columns - The columns configuration for the hosting table.
 * @param {boolean} pagination - Indicates whether to enable pagination in the hosting table.
 * @param {number} defaultLimit - The default number of rows per page in the hosting table.
 * @param {string} defaultFilterValue - The default filter value to apply to the hosting table.
 *
 * @return {JSX.Element} The rendered hosting table component.
 */
export default function HostingTable({dataSource}) {

    const {ExcelDownloder} = useExcelDownloder();
    return (
        <Card>
            <Card.Header>
                <Card.Title className="mb-0 d-flex">
                    <div className={"w-100 d-flex justify-content-between"}>
                    <h3 className={"mb-0"}>Hosting</h3>

                        <ExcelDownloder
                            data={dataSource}
                            filename={'Hosting ' + moment().format("DD-MM-YYYY")}
                            type={"link"}
                        >
                            <Button className={"me-1 added-tiny-margin-top remove-margin-top-mob"} variant={"secondary"}>
                                <TableViewIcon fontSize={"small"}/>
                            </Button>
                        </ExcelDownloder>
                    </div>
                </Card.Title>
            </Card.Header>
            <Card.Body>

                <ReactDataGridCustom
                    idProperty="token"
                    dataSource={dataSource.hosting}
                    columns={columns}
                    pagination
                    defaultLimit={50}
                    defaultFilterValue={defaultFilterValue}
                />
            </Card.Body>
        </Card>
    );
}
