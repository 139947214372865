import React, {useEffect, useState} from "react";
import {Button, Modal, Spinner} from "react-bootstrap";
import {Form as FormikForm, FormField, FormFieldAddress, FormFieldDatePicker, FormFieldSelect, FormSelectFieldContacts, SubmitButton} from "../../../components/forms/index"
import * as Yup from 'yup';
import adminUsersApi from "../../../api/adminUsers";
import {useAuth0} from "@auth0/auth0-react";
import moment from "moment";
import serviceProjectApi from "../../../api/service";
import offersApi from "../../../api/offertes";
import {useNavigate} from "react-router-dom";
import {Edit2} from "react-feather";
import notyf from "../../../utils/notyfApi";
import projectsApi from "../../../api/projects";

/**
 * Opens a modal to add or edit an offer.
 *
 * @param {object} props - The properties object.
 * @param {object} props.exists - The existing offer to edit. If not provided, a new offer will be created.
 * @param {function} props.reload - The function to reload the offers list after creating or editing an offer.
 *
 * @return {ReactElement} The AddOfferModal component.
 */
function AddOfferModal({exists, reload}) {
    const {user} = useAuth0()
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [users, setUsers] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [currentBuilding, setCurrentBuilding] = useState();
    const [isLoading, setIsloading] = useState(false);
    const [projects, setProjects] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getUsers()
        getServiceProjects()
        getAllActiveProjects()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAllActiveProjects = async () => {
        const res = await projectsApi.getAllProjects("meerwerk");
        if (!res.ok) return notyf.error("Error " + res.status)
        let tempProjects = []
        for (let project of res.data) {
            tempProjects.push({
                value: project.project_id,
                label: `${project.project_code} ${project.project_name}`
            })
        }
        setProjects(tempProjects)
    };
    const getUsers = async () => {
        const res = await adminUsersApi.getUsersByRole("Verkopers")
        if (!res.ok) return null
        setUsers(res.data.users)
    }

    const getServiceProjects = async () => {
        const res = await serviceProjectApi.getAllServiceProjects()
        if (!res.ok) return null
        let temp = [{
            value: 4230,
            label: "Onbekend Gebouw"
        }]
        for (let b of res.data) {
            if (exists && exists.sales_offer_offer_building === b.service_project_id) setCurrentBuilding({
                value: b.service_project_id,
                label: b.service_project_name
            })
            temp.push({
                value: b.service_project_id,
                label: b.service_project_name
            })
        }
        setBuildings(temp)
    }


    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(false);
        setIsloading(true)
        if (exists) {
            const res = await offersApi.editExistingOffer(exists.sales_offer_id, {
                ...exists,
                ...values,
                sales_offer_detail_contact_id: values.sales_offer_detail_contact_id?.sales_contact_id ?
                    values.sales_offer_detail_contact_id.sales_contact_id : exists.sales_offer_detail_contact_id,

                sales_offer_detail_account_id: values.sales_offer_detail_contact_id?.sales_account_id ?
                    values.sales_offer_detail_contact_id.sales_account_id : exists.sales_offer_detail_contact_id,

                sales_offer_planning_date: moment(values.sales_offer_planning_date).format("YYYY-MM-DD"),
            })
            if (!res.ok) return notyf.error("Error " + res.status)
            reload()
            handleClose()
        } else {
            const res = await offersApi.postOffer({
                ...values,
                sales_offer_detail_contact_id: values.sales_offer_detail_contact_id.sales_contact_id,
                sales_offer_detail_account_id: values.sales_offer_detail_contact_id.sales_account_id,
                sales_offer_planning_date: moment(values.sales_offer_planning_date).format("YYYY-MM-DD"),
            })
            if (!res.ok) return notyf.error("Error " + res.status)
            navigate(`/offer/${res.data.sales_offer_id}`)
        }
        setIsloading(false)
    };

    return (
        <>
            {exists ? <Edit2 onClick={handleShow} className={"float-end cursor-pointer"}/>
                : <Button variant="primary" className={"float-end mb-0 ms-1"} onClick={handleShow}>
                    {"+"}
                </Button>}
            <Modal show={show} onHide={handleClose}>
                {isLoading ? <Spinner style={{position: "absolute", top: "50%", left: "calc(50% - 21px)", zIndex: 1000}} animation="border"/> : null}
                <Modal.Header closeButton>
                    <Modal.Title>
                        {exists ? "Offerte aanpassen" : "Nieuw offerte aanmaken"}
                    </Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={{
                        sales_offer_name: exists?.sales_offer_name || "",
                        sales_offer_folder_nr: exists?.sales_offer_folder_nr || "1",
                        sales_offer_type: exists?.sales_offer_type || "",
                        sales_offer_city: exists?.sales_offer_city || "",
                        sales_offer_country: exists?.sales_offer_country || "",
                        sales_offer_calculator: exists?.sales_offer_calculator || user.sub,
                        sales_offer_offer_postcode: exists?.sales_offer_offer_postcode || "",
                        sales_offer_offer_state: exists?.sales_offer_offer_state || "",
                        sales_offer_offer_street: exists?.sales_offer_offer_street || "",
                        sales_offer_offer_street_nr: exists?.sales_offer_offer_street_nr || "",
                        sales_offer_planning_date: exists?.sales_offer_planning_date || moment(),
                        sales_offer_project_id: exists?.sales_offer_project_id || null,
                        sales_offer_offer_building: exists?.sales_offer_offer_building || null,
                        sales_offer_detail_account_id: exists?.sales_offer_detail_account_id || null,
                    }}

                    // Hier is het validatieschema van alle velden
                    validationSchema={Yup.object({
                        sales_offer_name: Yup.string().required('Offertenaam moet ingevuld worden.'),
                        sales_offer_folder_nr: Yup.string(),
                        sales_offer_type: Yup.string().required('Type moet ingevuld worden'),
                        sales_offer_city: Yup.string().required('Stad moet ingevuld worden'),
                        sales_offer_planning_date: Yup.date(),
                        sales_offer_detail_contact_id: !exists ? Yup.object().required("Aanvrager moet geselecteerd worden") : null,
                        sales_offer_offer_building: Yup.number().required("Gebouw moet geselecteerd worden"),
                    })}
                    onSubmit={handleSubmit}
                >
                    <Modal.Body>
                        <FormField name={"sales_offer_name"}
                                   label={"Offertenaam:"}
                                   width={"100%"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormField name={"sales_offer_type"}
                                   label={"Type"}
                                   width={"100%"}
                                   className={"mb-3"}
                                   as={"select"}
                        >
                            <option value={""}>-</option>
                            <option value={"Project"}>Project</option>
                            <option value={"Klein project"}>Klein project</option>
                            <option value={"Service"}>Service</option>
                            <option value={"Meerwerk"}>Meerwerk</option>
                        </FormField>
                        <FormFieldSelect
                            name={"sales_offer_project_id"}
                            label={"Project:"}
                            width={"100%"}
                            placeholder="Vul de productnaam hier in"
                            className={"mb-3"}
                            control={"input"}
                            options={projects}
                            display={{name: "sales_offer_type", value: "Meerwerk"}}
                        />
                        <FormField name={"sales_offer_calculator"}
                                   label={"Calculator"}
                                   width={"100%"}
                                   className={"mb-3"}
                                   as={"select"}
                        >
                            {exists ? <option value={exists.sales_offer_calculator}>{exists.user_name}</option> : <option value={user.sub}>{user.name}</option>}
                            {users.filter(u => u.user_id !== user.sub).map(u => <option key={u.user_id} value={u.user_id}>{u.name}</option>)}
                        </FormField>
                        <FormFieldSelect
                            name={"sales_offer_offer_building"}
                            label={"Gebouw:"}
                            width={"100%"}
                            className={"mb-3"}
                            control={"input"}
                            options={buildings}
                            defaultValue={currentBuilding}
                        />
                        {!exists && <FormSelectFieldContacts name={"sales_offer_detail_contact_id"}
                                                             label={"Aanvrager"}
                                                             width={"100%"}
                                                             className={"mb-3"}
                        />}
                        <FormField name={"sales_offer_folder_nr"}
                                   label={"Map Nr."}
                                   width={"100%"}
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <FormFieldDatePicker name={"sales_offer_planning_date"}
                                             label={"Indienen offerte"}
                                             width={"100%"}
                                             className={"mb-3 form-control"}
                                             control={"input"}
                        />
                        <FormFieldAddress names={{
                            number: "sales_offer_offer_street_nr",
                            street: "sales_offer_offer_street",
                            city: "sales_offer_city",
                            state: "sales_offer_offer_state",
                            zip: "sales_offer_offer_postcode",
                            country: "sales_offer_country",
                        }}
                                          label={"Adres"}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Niet aanpassen
                        </Button>
                        <SubmitButton title={"Opslaan"} disabled={isLoading} variant="primary"/>
                    </Modal.Footer>
                </FormikForm>
            </Modal>
        </>
    );
}

export default AddOfferModal;
