import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import React from "react";
import {Badge} from "react-bootstrap";
import moment from "moment";
import {NavLink} from "react-router-dom";

/**
 * Renders a table component for displaying service detail projects.
 *
 * @param {object} options - The options for rendering the table.
 * @param {Array} options.data - The data to be displayed in the table.
 *
 * @return {*} The rendered table component.
 */
function ServiceDetailProjectTable({data}) {
    const {SearchBar} = Search;
    const columns = [
        {
            dataField: "project_id",
            hidden: true,
        },
        {
            dataField: "project_code",
            text: "Project nummer",
            formatter: (c, r) => <NavLink to={`/project/${r.project_id}`}>{c}</NavLink>,
            sort: true,
        },
        {
            dataField: "project_name",
            text: "Project",
            formatter: (c, r) => <NavLink to={`/project/${r.project_id}`}>{c}</NavLink>,
            sort: true,
        },
        {
            dataField: "project_status",
            text: "Status",
            formatter: c => {
                if (c === 0) return <Badge>Concept</Badge>
                if (c === 1) return <Badge>In voorbereiding</Badge>
                if (c === 2) return <Badge bg="success">ACTIEF</Badge>
                if (c === 3) return <Badge bg="danger">AFGESLOTEN</Badge>
            },
            sort: true,
        },
        {
            dataField: "project_warranty_expiration_date",
            text: "Garantie status",
            formatter: c => {
                if (!c) return <Badge bg={"info"}>ONBEKEND</Badge>
                if (moment(c) < moment()) return <Badge bg="danger">VERVALLEN</Badge>
                return <Badge bg="primary">GARANTIE</Badge>
            },
            sort: true,
        },
    ];

    return (
        <ToolkitProvider keyField="project_id" data={data} columns={columns} search>
            {(props) => (
                <div>
                    <SearchBar srText={null}
                               {...props.searchProps}
                               placeholder={"Filter: Typ om te zoeken"}/>
                    <hr/>
                    <BootstrapTable
                        {...props.baseProps}
                        pagination={paginationFactory()}
                    />
                </div>
            )}
        </ToolkitProvider>
    );
}

export default ServiceDetailProjectTable;
