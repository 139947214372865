import React from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import AdminUserTableHandler from "../../components/admin/AdminUserTableHandler";

// /admin/user_management component which shows the users
/**
 * Represents a functional component for displaying a list of users.
 * @function Users
 * @returns {React.Fragment} - React Fragment containing the component's UI elements
 */
const Users = () => (
    <React.Fragment>
        <Helmet title="Gebruikers"/>
        <Container fluid className="p-0">
            <AdminUserTableHandler/>
        </Container>
    </React.Fragment>
);

export default Users;
