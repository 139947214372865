import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Button, Card, Col, Container, Modal, Row} from "react-bootstrap";
import {
    ArrowRight, Check,
    Clock,
    Codesandbox,
    Command,
    Mail,
    Map,
    MessageSquare,
    Package,
    Phone,
    Tag, Trash,
    User,
    UserCheck,
    Users,
} from "react-feather";
import CancelIcon from '@mui/icons-material/Cancel';
import {NavLink, useNavigate, useParams} from "react-router-dom";
import serviceProjectApi from "../../../api/service";
import Loader from "../../../components/Loader";
import moment from "moment";
import ActivityMenuTicketDetails from "../../components/activiteitenMenu/ActivityMenuTicketDetails";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDoorClosed, faDoorOpen, faFolder} from "@fortawesome/free-solid-svg-icons";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import notyf from "../../../utils/notyfApi";

const SuppliersDetail = () => {
    const {id, tId} = useParams()
    const [ticket, setTicket] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const res = await serviceProjectApi.getServiceProjectTicket(id, tId)
        if (!res.ok) return notyf.error("Error " + res.status)
        setTicket(res.data)
    }
    const toggleTicket = async () => {
        const res = await serviceProjectApi.editServiceProjectTickets(id, tId, {
            ...ticket,
            service_project_ticket_completed: !ticket.service_project_ticket_completed
        })
        if (!res.ok) return notyf.error("Error " + res.status)
        if (res.ok) getData()
    }

    const deleteTicket = async () => {
        const res = await serviceProjectApi.deleteServiceProjectTickets(id, tId)
        if (!res.ok) return notyf.error("Error " + res.status)
        navigate("/tickets_overview")
    }

    if (!ticket) return <Loader/>
    return (
        <React.Fragment>
            <Helmet title="Ticketsdetail"/>
            <GuardWrapper role={"write:tickets"}>
                <span className={"d-20-right d-float-right d-to-front"}>
                    <DeleteModal task={deleteTicket}/>
                    <Button
                        href={ticket.service_project_ticket_document_link}
                        disabled={!ticket.service_project_ticket_document_link}
                        variant={!ticket.service_project_ticket_document_link ? "outline-secondary" : "secondary"}
                        style={{cursor: !ticket.service_project_ticket_document_link ? "not-allowed" : ""}}
                        className={"float-end mx-1 extremeSmallBtn"}
                        target="_blank"
                        rel="noreferrer">
                        <FontAwesomeIcon icon={faFolder} className={"me-1"}/>
                        <span className="hideOn576 extremeSmallBtn">Documenten</span>
                    </Button>
                    {ticket.service_project_ticket_completed ?
                        <Button className={"float-end mx-1 extremeSmallBtn"} onClick={toggleTicket}>
                            <FontAwesomeIcon icon={faDoorOpen} className={"me-1"}/>
                            <span className="hideOn576 ">Order openen</span></Button> :
                        <Button className={"float-end mx-1 extremeSmallBtn"} onClick={toggleTicket}>
                            <FontAwesomeIcon icon={faDoorClosed} className={"me-1"}/>
                            <span className="hideOn576 ">Order afsluiten</span></Button>}
                </span>
            </GuardWrapper>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3 d-80-left">{ticket.service_project_ticket_reference} - {ticket.service_project_ticket_subject}</h1>
                {ticket.service_project_ticket_completed ? <div className="alert d-block-576 alert-danger alert-dismissible">
                    <div className="alert-message d-to-back">
                        <CancelIcon className={"me-2"}/>
                        <strong>Het service ticket is afgesloten</strong>
                    </div>
                </div> : <div className="alert d-block-576 alert-success alert-dismissible">
                    <div className="alert-message d-to-back">
                        <Check className={"me-2"}/>
                        <strong>Het service ticket staat nog open</strong>
                    </div>
                </div>}
                <Row>
                    <Col xl="6">
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Ticketdetails
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Tag/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Ticket
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {ticket.service_project_ticket_reference}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <User/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Contact
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {ticket.service_project_ticket_from_text ? ticket.service_project_ticket_from_text :
                                            <NavLink
                                                to={`/contact/${ticket.service_project_ticket_from_naw}`}>{`${ticket.sales_contact_firstname} ${ticket.sales_contact_surname_prefix || ""} ${ticket.sales_contact_lastname}`}</NavLink>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Users/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Bedrijf
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {ticket.sales_account_name ? ticket.sales_account_name : ticket.service_project_ticket_from_account_text}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Mail/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Onderwerp
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {ticket.service_project_ticket_subject}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <MessageSquare/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Opmerking
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {ticket.service_project_ticket_text}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <UserCheck/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Eigenaar
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {ticket.user_name}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <ArrowRight/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Richting
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {ticket.service_project_ticket_direction}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Command/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Type
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {ticket.service_project_ticket_ticket_type}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Phone/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        {ticket.service_project_ticket_email ? "Email" : "Telefoonnummer"}
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {ticket.service_project_ticket_email ? ticket.service_project_ticket_email : ticket.service_project_ticket_phone_number}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Clock/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Geüpdatet
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {moment(ticket.service_project_ticket_modified_on).format("DD-MM-YYYY HH:mm")}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Clock/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Gemaakt op
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {moment(ticket.service_project_ticket_created_on).format("DD-MM-YYYY HH:mm")}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        {/* Gebouwdetails card */}
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Gebouwdetails
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Codesandbox/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Gebouwnaam
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <NavLink
                                            to={`/service-detail/${ticket.service_project_f_id}`}>{ticket.service_project_name}</NavLink>
                                    </Col>
                                </Row>{
                                ticket.service_project_alias && <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Package/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Alias
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {ticket.service_project_alias}
                                    </Col>
                                </Row>}
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Map/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Adres
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <a href={`https://maps.google.com/?q=${ticket.service_project_street}
                                        ${ticket.service_project_street_nr}
                                        ${ticket.service_project_city}
                                        ${ticket.service_project_state}
                                        ${ticket.service_project_country}`}
                                           target="_blank"
                                           rel="noreferrer">
                                            {`${ticket.service_project_street || ""} ${ticket.service_project_street_nr || ""}, ${ticket.service_project_city || ""}`}
                                        </a>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <ActivityMenuTicketDetails
                            id={id}
                            sId={tId}
                            ticket={ticket}
                        />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
};

export default SuppliersDetail;

const DeleteModal = ({task}) => {
    const [show, setShow] = useState(false);
    return <>
        <Button variant={"danger"} className={"float-end mx-1 extremeSmallBtn"} onClick={() => setShow(!show)}><Trash size={18} />
            <span className="hideOn576">
                Verwijderen
            </span>
        </Button>
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header>
                <Modal.Title>Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Weet u het zeker?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShow(!show)} variant={"secondary"} className={"float-end"}>Annuleren</Button>
                <Button onClick={() => {
                    setShow(false)
                    task()
                }} className={"float-end"}>Verwijderen</Button>
            </Modal.Footer>
        </Modal>
    </>
}