import React from "react";
import {Card} from "react-bootstrap";
import ReactDataGridCustom from "../../../components/customComponents/ReactDataGridCustom";
import {NavLink} from "react-router-dom";

/**
 * @typedef {Object} Column
 * @property {number} id - The unique identifier of the column.
 * @property {string} name - The name of the column.
 * @property {string} header - The header title of the column.
 * @property {function} render - The render function to render the value of the column.
 * @property {number} flex - The flex value for the column.
 * @property {number} minWidth - The minimum width of the column.
 */
const columns = [
    {
        id: 0,
        name: "name",
        header: "Gebruiker",
        render: ({value, data}) => <NavLink to={`/users/${data.user_id}`}>{value}</NavLink>,
        flex: 1,
        minWidth: 90,
    },
    {
        id: 1,
        name: "nickname",
        header: "Gebruikersnaam",
        render: ({value, }) => <div>{value}</div>,
        flex: 1,
        minWidth: 90,
    },
    {
        id: 2,
        name: "email",
        header: "Email",
        render: ({value, }) => <div>{value}</div>,
        flex: 1,
        minWidth: 90,
    },
    {
        id: 3,
        name: "blocked",
        header: "Actief",
        render: ({value, }) => {
            if (value === true) {
                return <span className="badge bg-danger">Non actief</span>;
            } else {
                return <span className="badge bg-success">Actief</span>;
            }
        },
        flex: 1,
        minWidth: 90,
    },
];

/**
 * Renders an Admin User Table component.
 * @param {Object} options - The configuration options for the Admin User Table component.
 * @param {Array} options.user - The user data to be displayed in the table.
 * @return {Object} - The rendered Admin User Table component.
 */
export default function AdminUserTable({user}) {
    return (
        <Card>
            <Card.Header>
                <Card.Title className="mb-0 d-flex">
                    <div className={"w-100 d-flex justify-content-between"}>
                        <h3 className={"mb-0"}>Gebruikers</h3>
                    </div>
                </Card.Title>
            </Card.Header>

            <Card.Body>
                <ReactDataGridCustom
                    idProperty="id"
                    dataSource={user}
                    columns={columns}
                    pagination
                    defaultLimit={50}
                />
            </Card.Body>
            <Card.Footer>
            </Card.Footer>
        </Card>
    );
}