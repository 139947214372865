import React, {useEffect, useState} from "react";
import HostingTable from "./HostingTable";
import serviceHostingApi from "../../../api/service_hosting";
import Loader from "../../../components/Loader";
import notyf from "../../../utils/notyfApi";

/**
 * Handles the hosting table by fetching data from the API and rendering the table component.
 *
 * @returns {JSX.Element} The hosting table component.
 */
export default function HostingTableHandler() {
    //get data from api
    const [hosting, setHosting] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        const response = await serviceHostingApi.getAllServiceHosting();
        if (!response.ok) return notyf.error("Error " + response.status)
        setHosting({hosting: response.data});
        setLoading(false)
    };

    //laat tabel zien met gegevens via props
    if (loading) return <Loader/>
    else return <HostingTable dataSource={hosting}/>;
}
