import React, {useState, useEffect} from "react";
import {Button, Modal} from "react-bootstrap";
import {Form as FormikForm, FormField, FormFieldSelect, SubmitButton} from "../../../components/forms/index"
import * as Yup from 'yup';
import {Edit2} from "react-feather";
import watchdogApi from "../../../api/service_watchdog";
import serviceProjectApi from "../../../api/service";
import notyf from "../../../utils/notyfApi";
import usePalette from "../../../hooks/usePalette";

/**
 * Displays a modal to add or edit a Watchdog.
 *
 * @param {Object} param - The parameters for the modal.
 * @param {number} param.id - The id of the Watchdog.
 * @param {Object} param.watchdog - The details of the Watchdog.
 * @param {function} param.reload - The function to reload the Watchdog data.
 *
 * @return {JSX.Element} The modal component.
 */
function WatchdogAddEditModal({id, watchdog, reload}) {
    const [show, setShow] = useState(false);
    const [error, setError] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const WatchdogData = async (sid, data) => {
        let response
        if (watchdog) {
            //hij bestaat dus edit
            response = await watchdogApi.editExistingWatchdog(sid, data);
        } else {
            //Hij bestaat niet dus aanmaken
            response = await watchdogApi.postNewWatchdog(data);
        }
        if (!response.ok) {
            setError(response.data)
            return notyf.error("Error " + response.status)
        }
        reload()
        handleClose();
    };

    const [options, setOptions] = useState([])
    const [selected, setSelected] = useState()
    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const res = await serviceProjectApi.getAllServiceProjects()
        if (!res.ok) return null
        let temp = []
        for (let b of res.data) {
            if (watchdog && watchdog.service_project_f_id === b.service_project_id) setSelected({
                value: b.service_project_id,
                label: b.service_project_name
            })
            temp.push({
                value: b.service_project_id,
                label: b.service_project_name
            })
        }
        setOptions(temp)
    }

    return (
        <>
            {watchdog ? <Edit2 className={"cursor-pointer"} onClick={handleShow}/>
                : <button className="btn btn-primary d-float-right" onClick={handleShow}>+</button>}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    {watchdog ? <Modal.Title>Watchdog aanpassen</Modal.Title>
                        : <Modal.Title>Watchdog toevoegen</Modal.Title>}
                </Modal.Header>
                <FormikForm
                    initialValues={{
                        watchdog_project_search_query: watchdog?.watchdog_project_search_query || "",
                        service_project_f_id: watchdog?.service_project_f_id || "",
                    }}

                    // Hier is het validatieschema van alle velden
                    validationSchema={Yup.object({
                        watchdog_project_search_query: Yup.string()
                            .max(250, 'Moet minder dan 250 karakters zijn')
                            .trim()
                            .required('Watchdog moet ingevuld worden'),
                        service_project_f_id: Yup.number()
                            .required('Service project gebouw moet ingevuld worden'),
                    })}

                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(false);
                        let valuesparsed = {
                            watchdog_project_search_query: values.watchdog_project_search_query,
                            service_project_f_id: parseInt(values.service_project_f_id),
                        };
                        WatchdogData(id, valuesparsed);
                    }}
                >
                    <Modal.Body>
                        <h4>Watchdog details</h4>
                        <FormField name={"watchdog_project_search_query"}
                                   label={"Watchdog zoekterm:"}
                                   width={"100%"}
                                   placeholder="Vul de unieke Watchdog zoekterm hier in"
                                   className={"mb-3"}
                                   control={"input"}
                        />
                        <p className={"mb-3"} style={{color: usePalette().danger}}>{error}</p>
                        <FormFieldSelect
                            name={"service_project_f_id"}
                            label={"Service gebouw:"}
                            width={"100%"}
                            placeholder="Vul hier het service gebouw in"
                            className={"mb-3"}
                            control={"input"}
                            options={options}
                            defaultValue={selected}
                        />
                        <div
                            style={{
                                color: 'hsl(0, 0%, 40%)',
                                display: 'inline-block',
                                fontSize: 12,
                                fontStyle: 'italic',
                                marginTop: '1em',
                            }}
                        >
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Niet aanmaken
                        </Button>
                        <SubmitButton title={"Opslaan"} variant="primary"/>
                    </Modal.Footer>
                </FormikForm>
            </Modal>
        </>
    );
}

export default WatchdogAddEditModal;
