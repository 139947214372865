import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import React from "react";
import {NavLink} from "react-router-dom";
import {euroFormat} from "../../../utils/usefullFunctions";
import moment from "moment";
import {Row} from "react-bootstrap";

/**
 * Renders a table displaying debtor's open invoices.
 *
 * @param {object} options - The options object.
 * @param {array} options.data - The data containing the debtor's open invoices.
 *
 * @return {JSX.Element} - The rendered table component.
 */
function DebtorsDetailOpenInvoiceTable({data}) {
    const columns = [
        {
            dataField: "projects_invoices_invoicenumber",
            text: "Factuurnummer",
            formatter: (c,r) => <NavLink to={`/invoice/${r.projects_invoices_id}`}>{c}</NavLink>,
            sort: true,
        },
        {
            dataField: "projects_invoices_invoice_date",
            text: "Factuur Datum",
            formatter: c => moment(c).format("DD/MM/YY"),
            sort: true,
        },
        {
            dataField: "projects_invoices_expiration_date",
            text: "Verval Datum",
            formatter: c => moment(c).format("DD/MM/YY"),
            sort: true,

        },
        {
            dataField: "projects_invoices_projectname",
            text: "Project",
            sort: true,
        },
        {
            dataField: "projects_invoices_paid_amount",
            text: "Betaald bedrag",
            formatter: c => euroFormat(c),
            sort: true,
        },
        {
            dataField: "projects_invoices_total_incl_vat",
            text: "Factuur bedrag",
            formatter: c => euroFormat(c),
            sort: true,

        },
        {
            dataField: "projects_invoices_comment",
            text: "Laatste notitie",
            sort: true,
        },
    ];

    if (data.length === 0) return <Row className="justify-content-center align-self-center w-100 text-center">
        Er zijn geen openstaande facturen gevonden.
    </Row>

    return (
        <ToolkitProvider
            keyField="projects_invoices_invoicenumber"
            data={data}
            columns={columns}
            search
        >
            {(props) => (
                <div>
                    <BootstrapTable
                        {...props.baseProps}
                        pagination={paginationFactory()}
                    />
                </div>
            )}
        </ToolkitProvider>
    );
}

export default DebtorsDetailOpenInvoiceTable;
