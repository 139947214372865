import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Badge, Button, Card, Col, Container, Dropdown, DropdownButton, Row} from "react-bootstrap";
import {File, MessageSquare, Navigation, Phone, Server, Tool, Tv,} from "react-feather";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import UpdateIcon from '@mui/icons-material/Update';
import CheckIcon from '@mui/icons-material/Check';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';
import PersonIcon from '@mui/icons-material/Person';
import ServiceDetailProjectTable from "../../components/service/ServiceDetailProjectTable";
import ServiceDetailConnectionInstallationTable
    from "../../components/service/ServiceDetailConnectionInstallationTable";
import ServiceDetailUsersTable from "../../components/service/ServiceDetailUsersTable";
import ServiceDetailControllerTable from "../../components/service/ServiceDetailControllerTable";
import {useParams} from "react-router-dom";
import serviceProjectApi from "../../../api/service";
import Loader from "../../../components/Loader";
import moment from "moment";
import ActivityMenuServiceDetail from "../../components/activiteitenMenu/ActivityMenuServiceDetail";
import {DisplayPassword, parseLink, TextWithCopy} from "../../../utils/usefullFunctions";
import ServiceDetailRDPModal from "../../components/service/ServiceDetailRDPModal";
import AbcIcon from '@mui/icons-material/Abc';
import ServiceDetailEditModal from "../../components/service/ServiceDetailEditModal";
import ServiceDetailVPNModal from "../../components/service/ServiceDetailVPNModal";
import ServiceDetailGBSModal from "../../components/service/ServiceDetailGBSModal";
import {faFolder, faTools} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GuardWrapper from "../../../components/guards/GuardWrapper";
import notyf from "../../../utils/notyfApi";

const ServiceDetail = () => {
    const {id} = useParams()
    const [general, setGeneral] = useState()
    const [typeService, setTypeService] = useState([])

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const resGeneral = await serviceProjectApi.getServiceProject(id)
        setGeneral(resGeneral.data)
        if (resGeneral.data.project.filter(i => i.project_group === 17 && i.project_status === 2).length) setTypeService("Onderhoud en hosting")
        else if (resGeneral.data.project.filter(i => i.project_group === 22 && i.project_status === 2).length) setTypeService("Onderhoud")
        else if (resGeneral.data.project.filter(i => i.project_group === 23 && i.project_status === 2).length) setTypeService("Hosting")
        else setTypeService("Service")
        setLoading(false)
    }
    const handleGetTemplate = async (cat) => {
        const resOffer = await serviceProjectApi.getTemplates(id, cat);
        if (!resOffer.ok) return notyf.error("Error " + resOffer.status)
        window.open(encodeURI(resOffer.data.response))
    }

    if (loading) return <Loader/>
    return (
        <React.Fragment>
            <Helmet title="Serviceproject details"/>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3 d-flex justify-content-between">
                    Service - {general.service_project.service_project_name}
                    <span className={"d-20-right"}>
                        {/*<Button*/}
                        {/*    href={`https://rapportage.y-con.nl/work-report/${general.service_project.service_project_id}`}*/}
                        {/*    variant={"primary"}*/}
                        {/*    target="_blank"*/}
                        {/*    className={"me-1" + window.screen.width <= 576 ? "extremeSmallBtn" : "me-1 extremeSmallBtn"}*/}
                        {/*    rel="noreferrer">*/}
                        {/*    <FontAwesomeIcon icon={faTools} className={"me-1"}/>*/}
                        {/*    <span className="hideOn576 ">Rapportages</span>*/}
                        {/*</Button>*/}
                            <Button href={general.service_project.service_project_document_link}
                                    disabled={!general.service_project.service_project_document_link}
                                    variant={!general.service_project.service_project_document_link ? "outline-secondary" : "secondary"}
                                    style={{cursor: !general.service_project.service_project_document_link ? "not-allowed" : ""}}
                                    className={window.screen.width <= 576 ? "" : "me-1"}
                                    target="_blank"
                                    rel="noreferrer">
                                    <FontAwesomeIcon icon={faFolder}
                                    /> <span
                                className="hideOn576 ">Documenten</span>
                            </Button>
                        <DropdownButton title={window.screen.width <= 576 ? "" : "Templates "}
                                        className="extremeSmallBtn">
                            <Dropdown.Item onClick={() => handleGetTemplate("onderhoudsrapportage_BOA_DDC_OH")}>Onderhoudsrapportage (volledig)</Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => handleGetTemplate("bezoek_rapportage")}>Bezoekrapportage</Dropdown.Item>
                        </DropdownButton>
                    </span>
                </h1>
                <Row>
                    <Col xl="6">
                        <ActivityMenuServiceDetail id={id} service={general.service_project}/>
                    </Col>
                    <Col xl="6">
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Projectdetails <GuardWrapper role={"write:service"}>
                                    <ServiceDetailEditModal id={id} exists={general.service_project} reload={getData}/>
                                </GuardWrapper>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <File/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Gebouwnaam
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {general.service_project.service_project_name}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <AbcIcon/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Alias
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {general.service_project.service_project_alias}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <LocationOnIcon/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Adres
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <a href={`https://maps.google.com/?q=${general.service_project.service_project_street || ""}+
                                        ${general.service_project.service_project_street_nr || ""}+
                                        ${general.service_project.service_project_city || ""}+
                                        ${general.service_project.service_project_country || ""}`}
                                           target="_blank"
                                           rel="noreferrer">{`${general.service_project.service_project_street || ""}
                                        ${general.service_project.service_project_street_nr ? general.service_project.service_project_street_nr + "," : ""}
                                        ${general.service_project.service_project_city ? general.service_project.service_project_city + "," : ""}
                                        ${general.service_project.service_project_country ? general.service_project.service_project_country + "," : ""}`}</a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <UpdateIcon/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Geüpdatet
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {moment(general.service_project.service_project_modified_on).format("DD MMMM YYYY  HH:mm")}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <CheckIcon/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Gemaakt op
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {moment(general.service_project.service_project_created_on).format("DD MMMM YYYY  HH:mm")}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Tool/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Type
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <Badge bg={typeService}>{typeService}</Badge>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <AssignmentIndIcon/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Contactpersoon
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {general.service_project.service_project_contact}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <EmailIcon/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        E-mail
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {general.service_project.service_project_contact_email}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Phone/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Telefoon
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {general.service_project.service_project_contact_phone}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Projecten
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {general.project.length ?
                                    <ServiceDetailProjectTable data={general.project}/> :
                                    <Row className="justify-content-center align-self-center w-100 text-center">
                                        Er zijn geen projecten gevonden.
                                    </Row>}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <h3>Verbindings gegevens</h3>
                <Row>
                    <Col xl="6">
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    VPN <GuardWrapper role={"write:service"}> <ServiceDetailVPNModal id={id}
                                                                                                     reload={getData}
                                                                                                     exists={general.service_project}/></GuardWrapper>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Navigation/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        VPN tool
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {general.service_project.service_project_vpn_tool}
                                    </Col>
                                </Row>
                                {general.service_project.service_project_vpn_hyperlink && <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img"/>
                                    <Col lg={"4"} className="mb-3 d-576-inline"/>
                                    <Col lg={"7"} className="mb-3 d-576-inline">

                                        <a href={parseLink(general.service_project.service_project_vpn_hyperlink)}
                                           target="_blank" rel="noreferrer">Download</a>
                                    </Col>
                                </Row>}
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Server/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        VPN IP
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <TextWithCopy text={general.service_project.service_project_vpn_ip}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <PersonIcon/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        VPN gebruikersnaam
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        <TextWithCopy text={general.service_project.service_project_vpn_username}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <KeyIcon/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        VPN wachtwoord
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {<DisplayPassword text={general.service_project.service_project_vpn_password}/>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <MessageSquare/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        Opmerking
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {general.service_project.service_project_vpn_comment}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl="6">
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Remote Desktop
                                    <GuardWrapper role={"write:service"}><ServiceDetailRDPModal id={id} reload={getData}
                                                                                                exists={general.service_project}/></GuardWrapper>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {general.service_project.service_project_rdp_ip ? <>
                                    <Row>
                                        <Col lg={"1"} className="mb-3 d-576-inline-img">
                                            <Tv/>
                                        </Col>
                                        <Col lg={"4"} className="mb-3 d-576-inline">
                                            RDP IP:
                                        </Col>
                                        <Col lg={"7"} className="mb-3 d-576-inline">
                                            <TextWithCopy text={general.service_project.service_project_rdp_ip}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={"1"} className="mb-3 d-576-inline-img">
                                            <PersonIcon/>
                                        </Col>
                                        <Col lg={"4"} className="mb-3 d-576-inline">
                                            RDP gebruikersnaam:
                                        </Col>
                                        <Col lg={"7"} className="mb-3 d-576-inline">
                                            <TextWithCopy text={general.service_project.service_project_rdp_username}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={"1"} className="mb-3 d-576-inline-img">
                                            <KeyIcon/>
                                        </Col>
                                        <Col lg={"4"} className="mb-3 d-576-inline">
                                            RDP Wachtwoord:
                                        </Col>
                                        <Col lg={"7"} className="mb-3 d-576-inline">
                                            {<DisplayPassword
                                                text={general.service_project.service_project_rdp_password}/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {general.service_project.service_project_rdp_comment}
                                        </Col>
                                    </Row> </> : "Geen RDP"}

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <h3>Technische gegevens</h3>
                <Row>
                    <Col xl="6">
                        <Card>
                            <Card.Body>
                                <ServiceDetailConnectionInstallationTable data={general.ips}/>
                            </Card.Body>
                        </Card>
                        <Card>

                            <Card.Body>
                                <ServiceDetailUsersTable data={general.logins}/>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl="6">
                        <Card>
                            <Card.Body>
                                <ServiceDetailControllerTable data={general.controllers}/>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Software <ServiceDetailGBSModal id={id} reload={getData}
                                                                    exists={general.service_project}/>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={"1"} className="mb-3 d-576-inline-img">
                                        <Tv/>
                                    </Col>
                                    <Col lg={"4"} className="mb-3 d-576-inline">
                                        GBS software versie:
                                    </Col>
                                    <Col lg={"7"} className="mb-3 d-576-inline">
                                        {general.service_project.service_project_bms_software_version || "Geen GBS software bekend"}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
};

export default ServiceDetail;
