import {Button, Card, Tab, Tabs} from "react-bootstrap";
import moment from "moment";
import React, {useEffect, useState} from "react";
import nawCompaniesApi from "../../../api/nawCompanies";
import notyf from "../../../utils/notyfApi";

/**
 * Represents an activity menu component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.id - The ID of the activity menu.
 * @param {string} props.company - The company associated with the activity menu.
 * @returns {JSX.Element} The activity menu component.
 */
const ActivityMenu = ({id, company}) => {
    const [logs, setLogs] = useState([])
    const [activityFull, setActivityFull] = useState(false)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company])

    const getData = async () => {

        const resHistory = await nawCompaniesApi.getHistory(id)
        if (!resHistory.ok) return notyf.error("Error " + resHistory.status)
        setLogs(resHistory.data.logs)
    }
    return (<Card>
        <Card.Body>
            <div style={{display: "block"}}>

                <Tabs defaultActiveKey="Activiteit">
                    <Tab eventKey="Activiteit" title="Activiteit">

                        <ul className="timeline mt-2">
                            {logs.length ? (activityFull ? logs.map(log => <li key={log.log_detail_timestamp}
                                                                               className="timeline-item">
                                <strong>{log.log_detail_type}</strong>
                                <span
                                    className="float-end text-muted text-sm">{moment(log.log_detail_timestamp).format("DD-MM-YYYY HH:mm")}</span>
                                <p>
                                    {log.log_detail_message.replace(/<\/?[^>]+(>|$)/g, "")} {log.log_detail_created_by}
                                </p>
                            </li>) : logs.slice(0, 10).map(log => <li key={log.log_detail_timestamp}
                                                                      className="timeline-item">
                                <strong>{log.log_detail_type}</strong>
                                <span
                                    className="float-end text-muted text-sm">{moment(log.log_detail_timestamp).format("DD-MM-YYYY HH:mm")}</span>
                                <p>
                                    {log.log_detail_message.replace(/<\/?[^>]+(>|$)/g, "")} {log.log_detail_created_by}
                                </p>
                            </li>)) : <p style={{marginTop: "1rem"}}>Er zijn geen activiteiten gevonden</p>}
                            {(logs.length > 10 && !activityFull) &&
                                <Button onClick={() => setActivityFull(true)}>Meer laden</Button>}
                        </ul>
                    </Tab>

                </Tabs>
            </div>
        </Card.Body>
    </Card>)
}

export default ActivityMenu

