import client from "./clients/portalClient";

/**
 * Retrieves all NAWS companies based on the provided category.
 *
 * @param {string} cat - The category for filtering the companies.
 * @returns {Promise} - A Promise that resolves with the result of the API call.
 */
const getAllNawCompanies = (cat) => client.get(`/accounts?data=${cat}`);
/**
 * Retrieves the company information for a given ID.
 *
 * @param {string} id - The ID of the account.
 * @returns {Promise} - A Promise that resolves with the company information.
 *
 * @example
 * getNawCompany('12345')
 *   .then((companyInfo) => {
 *     // Perform operations with the company information
 *   })
 *   .catch((error) => {
 *     // Handle error
 *   });
 */
const getNawCompany = (id) => client.get(`/accounts/${id}`);
/**
 * Returns the account history for the provided ID.
 *
 * @param {string} id - The ID of the account.
 * @returns {Promise} A promise that resolves to the account history.
 */
const getHistory = (id) => client.get(`/accounts/${id}/history`);
/**
 * Creates a new company.
 *
 * @param {Object} data - The data for creating the new company.
 * @returns {Promise} - A promise that resolves to the result of the API call.
 */
const postNewCompany = (data) => client.post(`/accounts`, data);
/**
 * Updates the company information with the given ID.
 *
 * @param {string} id - The ID of the company to be updated.
 * @param {object} data - The updated data for the company.
 * @returns {Promise} A promise that resolves with the response from the server.
 */
const editCompany = (id, data) => client.put(`/accounts/${id}`, data);
/**
 * Deletes a company account based on the provided ID.
 *
 * @param {number} id - The ID of the company account to be deleted.
 * @returns {Promise} A promise that resolves when the deletion is successful, and rejects with an error if it fails.
 */
const deleteCompany = (id) => client.delete(`/accounts/${id}`);


/**
 * Represents an API for managing and retrieving NAW (Name, Address, Website) companies.
 *
 * @namespace nawCompaniesApi
 */
const nawCompaniesApi = {
    getAllNawCompanies,
    getNawCompany,
    getHistory,
    postNewCompany,
    editCompany,
    deleteCompany
};

export default nawCompaniesApi;
