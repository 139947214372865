import React, {useState} from "react";
import serviceProjectApi from "../../../api/service";
import {Edit2} from "react-feather";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {Form as FormikForm, FormField, SubmitButton} from "../../../components/forms";
import * as Yup from "yup";
import notyf from "../../../utils/notyfApi";

/**
 * Represents a modal for editing service details.
 *
 * @param {object} props - The props object containing id, exists, and reload properties.
 * @returns {JSX.Element} - The JSX element representing the service detail edit modal.
 *
 * @example
 * <ServiceDetailEditModal id={123} exists={true} reload={handleReload} />
 */
const ServiceDetailEditModal = ({id, exists, reload}) => {
    const [show, setShow] = useState(false);
    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(false);
        const res = await serviceProjectApi.editServiceProject(id, {...exists, ...values})
        if (!res.ok) return notyf.error("Error " + res.status)
        reload()
        setShow(false)
    }

    return (
        <>
            <Edit2 className={"float-end cursor-pointer"} onClick={() => setShow(!show)}/>
            <Modal show={show} onHide={() => setShow(!show)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Service details
                    </Modal.Title>
                </Modal.Header><FormikForm
                initialValues={{
                    service_project_alias: exists?.service_project_alias || "",
                    service_project_street: exists?.service_project_street || "",
                    service_project_street_nr: exists?.service_project_street_nr || "",
                    service_project_city: exists?.service_project_city || "",
                    service_project_country: exists?.service_project_country || "",
                    service_project_contact: exists?.service_project_contact || "",
                    service_project_contact_email: exists?.service_project_contact_email || "",
                    service_project_contact_phone: exists?.service_project_contact_phone || "",
                }}

                // Hier is het validatieschema van alle velden
                validationSchema={Yup.object({
                    service_project_alias: Yup.string(),
                    service_project_street: Yup.string(),
                    service_project_street_nr: Yup.string(),
                    service_project_city: Yup.string(),
                    service_project_country: Yup.string(),
                    service_project_contact: Yup.string(),
                    service_project_contact_email: Yup.string(),
                    service_project_contact_phone: Yup.string(),
                })}
                onSubmit={handleSubmit}
            >
                <Modal.Body>
                    <FormField name={"service_project_alias"}
                               label={"Naam"}
                               width={"100%"}
                               placeholder="Vul hier het alias in"
                               className={"mb-3"}
                               control={"input"}
                    />
                    <Row>
                        <Col>
                            <FormField name={"service_project_street"}
                                       label={"Straat"}
                                       width={"100%"}
                                       placeholder={"Vul hier de straat in"}
                                       className={"mb-3"}
                                       control={"input"}
                            />
                        </Col>
                        <Col>
                            <FormField name={"service_project_street_nr"}
                                       label={"Nr."}
                                       width={"100%"}
                                       placeholder={"Vul hier het straatnummer in"}
                                       className={"mb-3"}
                                       control={"input"}
                            />
                        </Col>
                    </Row>
                    <FormField name={"service_project_country"}
                               label={"Land"}
                               width={"100%"}
                               placeholder={"Vul hier het Land in"}
                               className={"mb-3"}
                               control={"input"}
                    />
                    <FormField name={"service_project_contact"}
                               label={"Contact"}
                               width={"100%"}
                               placeholder={"Vul hier een contact in"}
                               className={"mb-3"}
                               control={"input"}
                    />
                    <FormField name={"service_project_contact_email"}
                               label={"Contact email"}
                               width={"100%"}
                               placeholder={"Vul hier de contact email in"}
                               className={"mb-3"}
                               control={"input"}
                    />
                    <FormField name={"service_project_contact_phone"}
                               label={"Contact telefoon"}
                               width={"100%"}
                               placeholder={"Vul hier het telefoonnummer in"}
                               className={"mb-3"}
                               control={"input"}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShow(false)}>
                        Annuleren
                    </Button>
                    <SubmitButton title={"Opslaan"} variant="primary"/>
                </Modal.Footer>
            </FormikForm>
            </Modal>
        </>
    )
}

export default ServiceDetailEditModal;