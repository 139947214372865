import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

function Redirect() {
    const {url} = useParams()
    const navigate=  useNavigate()
    useEffect(()=> {
            window.open(url)
            navigate(-1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    return (<div>redirecting</div>)
}

export default Redirect;