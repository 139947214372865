import client from "./clients/portalClient";

/**
 * Returns all NAW (Name, Address, and Work) contacts from the server
 *
 * @param {string} cat - The category of contacts to retrieve
 *
 * @returns {Promise} - A Promise that resolves to the retrieved contacts
 */
const getAllNawContacts = (cat) => client.get(`/contacts?data=${cat}`);
/**
 * Retrieve the contact details for a specific ID.
 *
 * @param {string} id - The ID of the contact.
 * @returns {Promise} A Promise that resolves to the contact details.
 *
 * @throws {Error} If the contact details cannot be retrieved.
 *
 * @example
 * getNawContact('123')
 *   .then((contact) => {
 *     console.log(contact);
 *     // Output: {
 *     //   id: '123',
 *     //   name: 'John Doe',
 *     //   email: 'johndoe@example.com'
 *     // }
 *   })
 *   .catch((error) => {
 *     console.error(error);
 *   });
 */
const getNawContact = (id) => client.get(`/contacts/${id}`);
/**
 * Retrieves the contact history for a given contact ID.
 *
 * @param {string} id - The ID of the contact.
 * @returns {Promise<any>} - A promise that resolves with the contact history data.
 */
const getNawContactHistory = (id) => client.get(`/contacts/${id}/history`);
/**
 * Sends a POST request to create a new contact.
 *
 * @param {Object} data - The data for the new contact.
 * @returns {Promise} A Promise that resolves to the response of the POST request.
 *                   The response contains information about the newly created contact.
 */
const PostNewNawContact = (data) => client.post(`/contacts`, data);
/**
 * Update a specific contact by ID with new data.
 *
 * @param {string} id - The ID of the contact to update.
 * @param {Object} data - The new data to update the contact with.
 * @returns {Promise} A promise that resolves to the updated contact.
 *
 * @example
 * editNawContact('12345', { name: 'John Doe', email: 'john.doe@example.com' })
 *  .then((updatedContact) => {
 *    console.log(updatedContact);
 *  })
 *  .catch((error) => {
 *    console.error(error);
 *  });
 */
const editNawContact = (id,data) => client.put(`/contacts/${id}`, data);
/**
 * Deletes a contact with the given ID.
 *
 * @param {number} id - The ID of the contact to delete.
 * @returns {Promise} A promise that resolves when the contact is deleted successfully.
 */
const deleteNawContact = (id) => client.delete(`/contacts/${id}`);


/**
 * Provides methods for interacting with the NAW Contacts API.
 * @namespace nawContactsApi
 */
const nawContactsApi = {
  getAllNawContacts,
  getNawContact,
  getNawContactHistory,
  PostNewNawContact,
  editNawContact,
  deleteNawContact
};

export default nawContactsApi;
