import React from "react";
import {Helmet} from "react-helmet-async";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {Col, Container} from "react-bootstrap";
import {useParams} from "react-router-dom";
import ElasticSearchHandler from "../components/ElasticSearchHandler";

/**
 * ElasticSearch is a functional component used for searching and displaying search results.
 *
 * @returns {React.Fragment} - The rendered React Fragment containing the search results.
 */
const ElasticSearch = () => {
    const {id} = useParams();

    return (
        <React.Fragment>
            <Helmet title="Zoeken"/>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Gezocht op: {id}</h1>
                <Col xl="12">
                    <ElasticSearchHandler id={id}/>
                </Col>
            </Container>
        </React.Fragment>
    );
}

export default ElasticSearch;
