import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {Edit2} from "react-feather";
import {
    Form as FormikForm,
    FormCheckbox,
    FormField,
    FormFieldSelect,
    FormFieldTimesheet,
    SubmitButton
} from "../../../components/forms";
import * as Yup from "yup";
import moment from "moment";
import projectsApi from "../../../api/projects";
import FormFieldTimePicker from "../../../components/forms/FormFieldTimePicker";
import weekstatenApi from "../../../api/weeklyReports";
import {useAuth0} from "@auth0/auth0-react";
import usePalette from "../../../hooks/usePalette";
import notyf from "../../../utils/notyfApi";
import Loader from "../../../components/Loader";
import {useParams} from "react-router-dom";

// This modal handles adding a booking
/**
 * Renders a modal for adding or editing a weekly report day item.
 *
 * @param {Object} props - The component props.
 * @param {Date} props.date - The date of the weekly report day item.
 * @param {Date} props.curStartHour - The start hour for the timesheet.
 * @param {Function} props.reload - Function to reload the timesheet.
 * @param {Object} props.exists - The existing timesheet item to edit.
 * @param {Array} props.userTimesheetPermissions - The user's timesheet permissions.
 *
 * @return {JSX.Element} The rendered component.
 */
function WeeklyReportDayItemModal({date, curStartHour, reload, exists, userTimesheetPermissions}) {
    const {user} = useAuth0()
    const {id} = useParams()
    const [show, setShow] = useState(false); // modal show state
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [projects, setProjects] = useState([]); // Active projects
    const [structure, setStructure] = useState([]);
    const [selectedProject, setSelectedProject] = useState();
    const [error, setError] = useState();
    const palette = usePalette()

    useEffect(() => {
        // When the modal is opened retrieve active projects
        if (show) {
            getAllActiveProjects();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    useEffect(() => {
        // When a project is selected get the available options for that project
        if (selectedProject) {
            getProjectStructure();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject]);

    const getAllActiveProjects = async () => {
        const res = await projectsApi.getAllProjects("timesheets");
        if (!res.ok) return notyf.error("Error " + res.status)
        let tempProjects = []
        for (let project of res.data) {
            tempProjects.push({
                value: project,
                label: `${project.project_code} ${project.project_name}`
            })
        }
        setProjects(tempProjects)
    };
    const getProjectStructure = async () => {
        const res = await projectsApi.getProjectStructure(selectedProject.project_id);
        if (!res.ok) return notyf.error("Error " + res.status)
        setStructure(res.data)
    };

    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(false);
        // Voor submit each section has different things which need to be send
        let parsedValues
        if (values.timesheet_section === "Nietpr") {
            parsedValues = {
                ...values,
                timesheet_code: values.timesheet_codetimesheet_code,
                timesheet_date: moment(date).format("YYYY-MM-DD"),
                timesheet_project_id: 2440,
                timesheet_user_id: id || user.sub,
                timesheet_start_time: values.timesheet_start_time.format("HH:mm:ss"),
                timesheet_end_time: values.timesheet_end_time.format("HH:mm:ss"),
            }
        }
        if (values.timesheet_section === "Vrij") {
            parsedValues = {
                ...values,
                timesheet_code: values.timesheet_codetimesheet_code,
                timesheet_date: moment(date).format("YYYY-MM-DD"),
                timesheet_project_id: 2440,
                timesheet_user_id: id || user.sub,
                timesheet_start_time: values.timesheet_start_time.format("HH:mm:ss"),
                timesheet_end_time: values.timesheet_end_time.format("HH:mm:ss"),
            }
        }
        if (values.timesheet_section === "Ziek") {
            parsedValues = {
                ...values,
                timesheet_code: "ZIEK",
                timesheet_date: moment(date).format("YYYY-MM-DD"),
                timesheet_project_id: 2440,
                timesheet_user_id: id || user.sub,
                timesheet_start_time: values.timesheet_start_time.format("HH:mm:ss"),
                timesheet_end_time: values.timesheet_end_time.format("HH:mm:ss"),
            }
        }
        if (values.timesheet_section === "Project") {
            parsedValues = {
                ...values,
                timesheet_code: `${values.timesheet_codetimesheet_code}.${values.timesheet_codetimesheet_code_cat}`,
                timesheet_date: moment(date).format("YYYY-MM-DD"),
                timesheet_project_id: values.Project.project_king_id,
                timesheet_user_id: id || user.sub,
                timesheet_start_time: values.timesheet_start_time.format("HH:mm:ss"),
                timesheet_end_time: values.timesheet_end_time.format("HH:mm:ss"),
            }
        }
        let res
        if (exists) {
            res = await weekstatenApi.editWeekstaatItem(exists.timesheet_id, parsedValues)
        } else {
            res = await weekstatenApi.postWeekstaatItem(parsedValues)
        }
        if (res.ok) {
            reload()
            handleClose()
        } else {
            notyf.error("Error " + res.status)
            setError(res.data)
        }
    }

    if (!userTimesheetPermissions) return <Loader/>
    return (<React.Fragment>
        {exists ? <Edit2 className={"me-1 cursor-pointer"} onClick={handleShow}/>
            : <Button variant="primary" className={"float-end"} onClick={handleShow}>
                +
            </Button>}
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Weekstaat toevoegen - {moment(date).format("dddd D MMMM YYYY")}</Modal.Title>
            </Modal.Header>
            <FormikForm
                initialValues={{
                    timesheet_section: "",
                    Project: "",
                    timesheet_codetimesheet_code: exists?.timesheet_code || "",
                    timesheet_codetimesheet_code_cat: "",
                    timesheet_comment: exists?.timesheet_comment || "",
                    timesheet_date: exists?.timesheet_date || date,
                    timesheet_full_day: exists?.timesheet_full_day || false,
                    timesheet_start_time: exists ? moment(exists?.timesheet_start_time, "HH:mm:ss") : curStartHour,
                    timesheet_end_time: exists ? moment(exists?.timesheet_end_time, "HH:mm:ss") : moment(curStartHour).add(1, "hours"),
                }}

                // Hier is het validatieschema van alle velden
                validationSchema={selectedProject ?
                    Yup.object({
                        timesheet_section: Yup.string().required('Onderdeel moet ingevuld worden.'),
                        timesheet_codetimesheet_code: Yup.string().required('Onderdeel moet ingevuld worden.'),
                        timesheet_codetimesheet_code_cat: Yup.string().required('Onderdeel moet ingevuld worden.'),
                        timesheet_comment: Yup.string().required('Toelichting moet ingevuld worden.'),
                        timesheet_start_time: Yup.date().required('Eindtijd moet ingevuld worden.'),
                        timesheet_end_time: Yup.date().required('Begintijd moet ingevuld worden.'),
                    }) : Yup.object({
                        timesheet_section: Yup.string().required('Onderdeel moet ingevuld worden.'),
                        timesheet_codetimesheet_code: Yup.string().required('Onderdeel moet ingevuld worden.'),
                        timesheet_comment: Yup.string().required('Toelichting moet ingevuld worden.'),
                        timesheet_start_time: Yup.date().required('Eindtijd moet ingevuld worden.'),
                        timesheet_end_time: Yup.date().required('Begintijd moet ingevuld worden.'),
                    })}
                onSubmit={handleSubmit}
            >
                <Modal.Body>
                    <FormFieldTimesheet name={"timesheet_section"}
                                        label={"Onderdeel:"}
                                        width={"100%"}
                                        className={"mb-3"}
                                        as={"select"}
                                        feedback={setSelectedProject}
                    >
                        <option value="-">-</option>
                        <option value="Project">Project</option>
                        <option value="Nietpr">Niet productieve uren</option>
                        <option value="Vrij">Snipperdag, ATV of feestdag</option>
                        <option value="Ziek">Ziek, bezoek tandarts, etc.</option>
                    </FormFieldTimesheet>
                    <FormFieldSelect
                        name={"Project"}
                        label={"Project:"}
                        width={"100%"}
                        placeholder="Vul de productnaam hier in"
                        className={"mb-3"}
                        control={"input"}
                        options={projects}
                        feedback={setSelectedProject}
                        display={{name: "timesheet_section", value: "Project"}}
                    />

                    {/* formfield project codes */}
                    <FormField name={"timesheet_codetimesheet_code"}
                               label={"Code:"}
                               width={"100%"}
                               placeholder="Vul de code hier in"
                               className={"mb-3"}
                               as={"select"}
                               display={{name: "timesheet_section", value: "Project"}}
                    >
                        <option value="">-</option>
                        {structure?.map(item => <option key={item.projects_structure_id}
                                                        value={item.projects_structure_searchcode}>{item.projects_structure_searchcode + " - " + item.projects_structure_description}</option>)}
                    </FormField>

                    {/* formfield niet productieve codes */}
                    <FormField name={"timesheet_codetimesheet_code"}
                               label={"Code:"}
                               width={"100%"}
                               placeholder="Vul de code hier in"
                               className={"mb-3"}
                               as={"select"}
                               display={{name: "timesheet_section", value: "Nietpr"}}
                               defaultValue={"INDIRECT"}
                    >
                        <option value={"INDIRECT"}>Indirect niet productieve uren</option>
                        <option value={"CURSUS"}>Cursus</option>
                    </FormField>

                    {/* formfield snipperdag, ATV of feestdag codes */}
                    <FormField name={"timesheet_codetimesheet_code"}
                               label={"Code:"}
                               width={"100%"}
                               placeholder="Vul de code hier in"
                               className={"mb-3"}
                               as={"select"}
                               display={{name: "timesheet_section", value: "Vrij"}}
                    >
                        <option value="">-</option>
                        <option value="ATV">ATV</option>
                        <option value="Feestdag">Vastgestelde of wettelijke feestdag</option>
                        <option value="SNIP">Snipperdag of vakantiedag</option>
                        <option value="TVT">Tijd voor tijd</option>
                    </FormField>

                    {/* formfield ziek, bezoek tandarts, etc. codes */}
                    <FormField name={"timesheet_codetimesheet_code"}
                               label={"Code:"}
                               width={"100%"}
                               placeholder="Vul de code hier in"
                               className={"mb-3"}
                               as={"select"}
                               display={{name: "timesheet_section", value: "Ziek"}}
                    >
                        <option value="ZIEK">Ziek of bezoek arts</option>
                    </FormField>

                    {/* formfield Project uitgevoerde handelingen */}
                    <FormField name={"timesheet_codetimesheet_code_cat"}
                               label={"Handelingen:"}
                               width={"100%"}
                               placeholder="Vul de code hier in"
                               className={"mb-3"}
                               as={"select"}
                               display={{name: "timesheet_section", value: "Project"}}
                    >
                        <option value="">-</option>
                        {userTimesheetPermissions.ENG ? <option value="ENG">Engineering</option> : ""}
                        {userTimesheetPermissions.IBS ? <option value="IBS">Inregelen in bedrijfstellen</option> : ""}
                        {userTimesheetPermissions.PAN ? <option value="PAN">Paneelbouw</option> : ""}
                        {userTimesheetPermissions.PL ? <option value="PL">Projectbegeleiding</option> : ""}
                        {userTimesheetPermissions.WERKVB ? <option value="WERKVB">Werkvoorbereiding</option> : ""}
                        {userTimesheetPermissions.TEK ? <option value="TEK">Tekenwerkzaamheden</option> : ""}
                        {userTimesheetPermissions.BOA ? <option value="BOA">Beheer op afstand</option> : ""}
                        {userTimesheetPermissions.OHDDC ? <option value="OHDDC">Onderhoud dcc apparatuur</option> : ""}
                        {userTimesheetPermissions.OHVELD ?
                            <option value="OHVELD">Onderhoud veldapparatuur</option> : ""}
                        {userTimesheetPermissions.STOR ? <option value="STOR">Storingsafhandeling</option> : ""}
                    </FormField>

                    {/* El toelichting */}
                    <FormField name={"timesheet_comment"}
                               label={"Toelichting:"}
                               width={"100%"}
                               placeholder="Vul hier de toelichting in."
                               className={"mb-3"}
                               as={"textarea"}
                               rows={"5"}
                    />
                    <hr/>
                    <FormField name={"Datum"}
                               label={"Datum:"}
                               width={"100%"}
                               disabled={true}
                               className={"mb-3"}
                               as={"select"}
                    >
                        <option value="">{moment(date).format("dddd D MMMM YYYY")}</option>
                    </FormField>
                    {/* Hele dag checkbox */}
                    {!exists && <FormCheckbox name={"timesheet_full_day"}
                                              label={"Hele dag"}
                                              className={"mb-3"}/>
                    }
                    {/* El tijd */}
                    <Row className={"mt-3"}>
                        <Col xs="6">
                            <FormFieldTimePicker
                                name={"timesheet_start_time"}
                                label={"Begin:"}
                                className={"mb-3 w-100"}
                                control={"input"}
                                display={{name: "timesheet_full_day", value: false}}
                            />
                        </Col>
                        <Col xs="6">
                            <FormFieldTimePicker
                                name={"timesheet_end_time"}
                                label={"Eind:"}
                                className={"mb-3 w-100"}
                                control={"input"}
                                higher={"Begin"}
                                display={{name: "timesheet_full_day", value: false}}
                            />
                        </Col>
                        <Col><p style={{color: palette.danger}}>{error}</p></Col>
                    </Row>
                    <div
                        style={{
                            color: 'hsl(0, 0%, 40%)',
                            display: 'inline-block',
                            fontSize: 12,
                            fontStyle: 'italic',
                            marginTop: '1em',
                        }}
                    >
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        {exists ? "Annuleren" : "Niet aanmaken"}
                    </Button>
                    <SubmitButton title={"Opslaan"} variant="primary"/>
                </Modal.Footer>
            </FormikForm>
        </Modal>
    </React.Fragment>);
}

export default WeeklyReportDayItemModal;
