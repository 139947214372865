import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Card, Col, Container, Form, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import Loader from "../../../components/Loader";
import notyf from "../../../utils/notyfApi";
import xconnectWebshopApi from "../../../api/xconnectWebshop";
import {euroFormat} from "../../../utils/usefullFunctions";
import moment from "moment";

/**
 * Component for displaying the details of a webshop product.
 * The component fetches the product data from an API and renders
 * the product information in a card layout.
 *
 * @returns {JSX.Element} The rendered webshop product detail component.
 */
const XconnectWebshopProductDetail = () => {
    const {id} = useParams();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        const res = await xconnectWebshopApi.getSingleProduct(id);
        if (!res.ok) notyf.error("Error", res.status)
        setProduct(res.data.product);
    };

    if (!product) return <Loader/>;
    return (
        <React.Fragment>
            <Helmet title={`${product.vendor} | ${product.title}`}/>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">{`${product.vendor} | ${product.title}`}</h1>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Card.Title className="mb-0">
                                    Product details
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col md="8">
                                            <Row>
                                                <Form.Group className="mb-3" as={Col} md="6">
                                                    <Form.Label>Product:</Form.Label>
                                                    <Form.Control placeholder={product.title} disabled/>
                                                </Form.Group>
                                                <Form.Group className="mb-3" as={Col} md="6">
                                                    <Form.Label>Verkoopprijs:</Form.Label>
                                                    <Form.Control placeholder={euroFormat(product.variants[0].price)}
                                                                  disabled/>
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group className="mb-3" as={Col} md="4">
                                                    <Form.Label>Status:</Form.Label>
                                                    <Form.Control placeholder={product.status} disabled/>
                                                </Form.Group>
                                                <Form.Group className="mb-3" as={Col} md="4">
                                                    <Form.Label>Leverancier:</Form.Label>
                                                    <Form.Control placeholder={product.vendor} disabled/>
                                                </Form.Group>
                                                <Form.Group className="mb-3" as={Col} md="4">
                                                    <Form.Label>Product type:</Form.Label>
                                                    <Form.Control placeholder={product.product_type} disabled/>
                                                </Form.Group>
                                            </Row>
                                        </Col>
                                        <Col md="4" className="justify-content-center align-self-center text-center">
                                            <img src={product.image.src} style={{height: "100px"}}
                                                 alt={"Dit is de afbeelding van het product waar u de details van bekijkt."}/>
                                        </Col>
                                    </Row>

                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>Beschrijving:</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder={product.body_html}
                                            rows={5}
                                            disabled
                                        />
                                    </Form.Group>

                                    <Row>
                                        <Form.Group className="mb-3" as={Col} md="4">
                                            <Form.Label>Aangemaakt op:</Form.Label>
                                            <Form.Control
                                                placeholder={moment(product.created_at).format("HH:mm op DD/MM/YY")}
                                                disabled/>


                                        </Form.Group>
                                        <Form.Group className="mb-3" as={Col} md="4">
                                            <Form.Label>Geüpdatet op:</Form.Label>
                                            <Form.Control
                                                placeholder={moment(product.updated_at).format("HH:mm op DD/MM/YY")}
                                                disabled/>
                                        </Form.Group>
                                        <Form.Group className="mb-3" as={Col} md="4">
                                            <Form.Label>Gepubliceerd op: </Form.Label>
                                            <Form.Control
                                                placeholder={moment(product.published_at).format("HH:mm op DD/MM/YY")}
                                                disabled/>
                                        </Form.Group>
                                    </Row>

                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};


export default XconnectWebshopProductDetail;
