import {useContext} from "react";
import PermissionsContext from "../../utils/context";

// For routes that can only be accessed by authenticated users
/**
 * Wraps the children components based on the user's role.
 *
 * @param {Object} options - The options object.
 * @param {string} options.role - The role of the user.
 * @param {*} options.children - The components to be wrapped.
 *
 * @return {*} The wrapped components based on user's role.
 */
function GuardWrapper({role, children,}) {
    const {permissions} = useContext(PermissionsContext)
    if (!permissions.includes(role)) {
        return <></>;
    }

    return <>{children}</>;
}

export default GuardWrapper;
