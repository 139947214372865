import client from "./clients/portalClient";

/**
 * Retrieves tasks from the server.
 * @returns {Promise} A promise that resolves with the response from the server.
 */
const getTasks = () => client.get(`/dashboard/my_tasks`);
/**
 * Retrieves the sales planning information from the dashboard.
 *
 * @function getPlanning
 * @returns {Promise} A Promise that resolves to the sales planning data.
 */
const getPlanning = () => client.get(`/dashboard/sales_planning`);
/**
 * Retrieves general information from the dashboard.
 *
 * @returns {Promise} A Promise object that resolves with the general information from the dashboard.
 */
const getGeneral = () => client.get(`/dashboard/general`);
/**
 * Retrieves the revenue information from the server.
 *
 * @function getRevenueInfo
 * @returns {Promise} A Promise that resolves to the revenue information from the server.
 */
const getRevenueInfo = () => client.get(`/dashboard/revenue`);
/**
 * Retrieves productivity information from the dashboard.
 *
 * @returns {Promise} A promise that resolves to the productivity information.
 */
const getProductivityInfo = () => client.get(`/dashboard/productivity`);

/**
 * Retrieves information about the productivity department.
 *
 * @returns {Promise} A promise that resolves to the department information.
 */
const getProductivityDepartmentInfo = () => client.get(`/productivity_department`);
/**
 * Retrieves the user information for a given productivity department from the server.
 *
 * @param {string} department - The name of the productivity department.
 * @returns {Promise<object>} - A Promise that resolves to an object containing the user information.
 */
const getProductivityDepartmentUserInfo = (department) => client.get(`/productivity_department/details?department=${department}`);
/**
 * Retrieves the actual productivity data for the king from the server.
 *
 * @function getKingActualProductivityData
 * @returns {Promise} A promise that resolves with the actual productivity data.
 */
const getKingActualProductivityData = () => client.get(`/productivity_barchart`);

/**
 * API for accessing dashboard data.
 *
 * @namespace dashboardApi
 */
const dashboardApi = {
    getTasks,
    getPlanning,
    getGeneral,
    getRevenueInfo,
    getProductivityInfo,
    getProductivityDepartmentInfo,
    getProductivityDepartmentUserInfo,
    getKingActualProductivityData,
};

export default dashboardApi;