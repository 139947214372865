import React from "react";

/**
 * Renders an error message if `visible` is `true` and `error` is not `null`.
 *
 * @param {Object} props - The props passed to the component.
 * @param {boolean} props.visible - Whether the error message should be visible or not.
 * @param {string} props.error - The error message to be displayed.
 *
 * @return {JSX.Element | null} The rendered error message or `null` if it should not be visible or if there is no error message.
 */
function ErrorMessage({ error, visible }) {
  if (!visible || !error) return null;
  return <div style={{color: 'red'}}> {error}</div>;
}

export default ErrorMessage;
