import usePalette from "../../../../hooks/usePalette";
import React, {useState} from "react";
import {ButtonGroup, Card, Table, ToggleButton} from "react-bootstrap";
import Chart from "react-apexcharts";
import {euroFormat} from "../../../../utils/usefullFunctions";
import DebtorDashboardModal from "./DebtorDashboardModal";
import moment from "moment";

/**
 * Represents a dashboard bar chart component.
 * @param {Object} props - The props for the component.
 * @param {Object} props.data - The data for the chart.
 * @returns {JSX.Element} - The rendered chart component.
 */
const DashboardBarChart = ({data}) => {
    const palette = usePalette();
    const [showGraph, setShowGraph] = useState(true)
    const series = [{
        name: 'Berekend',
        data: [
            parseFloat(data.sum_7_days_or_longer),
            parseFloat(data.sum_just_overdue),
            parseFloat(data.sum_overdue_1_week),
            parseFloat(data.sum_overdue_2_weeks),
            parseFloat(data.sum_overdue_3_weeks),
            parseFloat(data.sum_overdue_4_weeks),
            parseFloat(data.sum_overdue_5_weeks),
            parseFloat(data.sum_overdue_6_weeks),
            parseFloat(data.sum_overdue_7_weeks),
            parseFloat(data.sum_overdue_8_weeks),
            parseFloat(data.sum_overdue_rest),
        ]
    }, {
        name: 'Werkelijk',
        data: [
            parseFloat(data.sum_7_days_or_longer_realistic),
            parseFloat(data.sum_just_overdue_realistic),
            parseFloat(data.sum_overdue_1_week_realistic),
            parseFloat(data.sum_overdue_2_weeks_realistic),
            parseFloat(data.sum_overdue_3_weeks_realistic),
            parseFloat(data.sum_overdue_4_weeks_realistic),
            parseFloat(data.sum_overdue_5_weeks_realistic),
            parseFloat(data.sum_overdue_6_weeks_realistic),
            parseFloat(data.sum_overdue_7_weeks_realistic),
            parseFloat(data.sum_overdue_8_weeks_realistic),
            parseFloat(data.sum_overdue_rest_realistic),
        ]
    }]
    const categories = [
        {
            text: 'Langer dan 7 dagen over de datum',
            before: moment().add(-1, "w").format("YYYY-MM-DD"),
            after: null
        },
        {
            text: 'Net over de datum',
            before: moment().format("YYYY-MM-DD"),
            after: moment().add(-1, "w").format("YYYY-MM-DD"),
        },
        {
            text: 'Vervalt binnen 1 week',
            before: moment().add(1, "w").format("YYYY-MM-DD"),
            after: moment().format("YYYY-MM-DD"),
        },
        {
            text: 'Vervalt binnen 2 weken',
            before: moment().add(2, "w").format("YYYY-MM-DD"),
            after: moment().add(1, "w").format("YYYY-MM-DD"),
        },
        {
            text: 'Vervalt binnen 3 weken',
            before: moment().add(3, "w").format("YYYY-MM-DD"),
            after: moment().add(2, "w").format("YYYY-MM-DD"),
        },
        {
            text: 'Vervalt binnen 4 weken',
            before: moment().add(4, "w").format("YYYY-MM-DD"),
            after: moment().add(3, "w").format("YYYY-MM-DD"),
        },
        {
            text: 'Vervalt binnen 5 weken',
            before: moment().add(5, "w").format("YYYY-MM-DD"),
            after: moment().add(4, "w").format("YYYY-MM-DD"),
        },
        {
            text: 'Vervalt binnen 6 weken',
            before: moment().add(6, "w").format("YYYY-MM-DD"),
            after: moment().add(5, "w").format("YYYY-MM-DD"),
        },
        {
            text: 'Vervalt binnen 7 weken',
            before: moment().add(7, "w").format("YYYY-MM-DD"),
            after: moment().add(6, "w").format("YYYY-MM-DD"),
        },
        {
            text: 'Vervalt binnen 8 weken',
            before: moment().add(8, "w").format("YYYY-MM-DD"),
            after: moment().add(7, "w").format("YYYY-MM-DD"),
        },
        {
            text: 'Restant',
            before: null,
            after: moment().add(8, "w").format("YYYY-MM-DD"),
        },

    ]
    const options = {
        chart: {
            foreColor: palette["gray-600"],
            tools: {
                download: null
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                endingShape: "rounded",
                columnWidth: "55%",
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
        },
        xaxis: {
            categories: ['Langer', 'Net', '1 wk', '2 wk', '3 wk', '4 wk', '5 wk', '6 wk', '7 wk', '8 wk', 'Rest'],
        },
        yaxis: {
            labels: {
                formatter: val => euroFormat(val)
            }
            // title: {
            //     text: "€",
            // },
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return "€ " + val;
                },
            },
        },
        colors: [
            palette.primary,
            palette.success,
            palette.warning,
            palette.danger,
            palette.info,
        ],
    }

    return <Card>
        <Card.Header>
            <Card.Title tag="h5">Cashflow</Card.Title>
        </Card.Header>
        <Card.Body>
            <div className="d-flex align-items-center justify-content-center">
                <ButtonGroup size={"lg"}>
                    <ToggleButton type="radio" checked={showGraph} onClick={() => setShowGraph(true)} variant="primary">Grafiek</ToggleButton>
                    <ToggleButton type="radio" checked={!showGraph} onClick={() => setShowGraph(false)} variant="primary">Tabel</ToggleButton>
                </ButtonGroup>
            </div>
            <div className="chart">
                {showGraph ? <Chart options={options} series={series} type="bar" height={425}/> : <Table>
                    <thead>
                    <tr>
                        <th></th>
                        <th>Berekend</th>
                        <th>Werkelijk</th>
                    </tr>
                    </thead>
                    <tbody>
                    {categories.map((cat, i) => {
                        return <tr key={i}>
                            <td><DebtorDashboardModal before={cat.before} after={cat.after}>{cat.text}</DebtorDashboardModal></td>
                            <td>{euroFormat(series[0].data[i])}</td>
                            <td>{euroFormat(series[1].data[i])}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>}
            </div>
        </Card.Body>
    </Card>
}
export default DashboardBarChart